import React from 'react';

export function TableBody({ children, empty, emptyText, emptyColSpan }) {

    return (
        <>
            {
                empty ? <tbody>
                    <tr>
                        <td className={'react-table__empty'}
                            colSpan={emptyColSpan}
                        >
                            {emptyText}
                        </td>
                    </tr>
                </tbody> :
                    <tbody>
                        {children}
                    </tbody>
            }
        </>

    );
}
