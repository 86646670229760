import { getCompanyName, getUserName, hasAccess, getAuthType } from '../../reselect';
import style from './style.modules.css';
import customStyle1 from './custom.style1.modules.css';
import setOptions from '../../helpers/setOptions';
import { setLinkings } from '../../toolkit/actions';

class HeaderLogoController {
    constructor ($window, $location, $ngRedux, $localStorage, $scope) {
        this.$window = $window;
        this.$location = $location;
        this.scope = $scope;
        this.style = style;
        this.$localStorage = $localStorage;
        this.projectName = window.config.PROJECT_NAME;
        this.projectDescription = window.config.PROJECT_DESCRIPTION;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { setLinkings })(this);
        this.customStyle = null;
        this.config = window.config;
        if (+window.config.CUSTOM_STYLE === 1) {
            this.customStyle = customStyle1;
        }
    }

    mapStateToThis = (state) => {
        return {
            username: getUserName(state),
            companyName: getCompanyName(state),
            isSSO: getAuthType(state) === 3,
            accsessLeyout: hasAccess(state, { sectionId: 5, rightName: 'view' }),
            accsessSchedulerSave: hasAccess(state, { sectionId: 9, rightName: 'scheduler_save' })
        };
    };

    go = (path, hash = 'top') => {
        if ((event.ctrlKey || event.metaKey) && path === '/settings') {
            return;
        }
        this.$location.path(path);
        this.setLinkings(hash);
        if (hash === 'top') {
            this.$location.hash('top');
        }
    };

    logout = () => {
        const { $localStorage, $location } = this;
        let url = this.isSSO ? '/sso/logout' : '/logout';
        // url = '/logout';
        let opts = setOptions();
        fetch(url, opts).then((response) => {
            return response.json();
        }).then((res) => {
            if (res.ssoRedirectUrl) {
                window.location.href = res.ssoRedirectUrl;
            } else {
                $localStorage.userInfo = null;
                $location.path('login');
                this.scope.$apply();
            }
        });
    };
}

HeaderLogoController.$inject = [
    '$window',
    '$location',
    '$ngRedux',
    '$localStorage',
    '$scope'
];

export {
    HeaderLogoController
};
