import {
    getLocales,
    checkDefaultLanguage,
    setDefaultLanguage,
    setLocales
} from '../../helpers/content';
import { contentFetch, fetchEntity } from '../../helpers/contentFetch';
import { defaultConfig } from '../../helpers/modalFooterDefault';
import { fetchCerts } from '../../toolkit/think';

const schema = {
    files: {
        dropzone: 'file',
    },
    data: [
        'name',
        'filename',
        'isDefault',
        'languageId'
    ],
};

class CertsModalController {
    constructor ($ngRedux, certId, gettextCatalog, scope) {
        this.certId = certId;
        this.scope = scope;
        this.gettextCatalog = gettextCatalog;
        this.headerText = certId ? gettextCatalog.getString('Редактирование сертификата') : gettextCatalog.getString('Новый сертификат');
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { fetchCerts })(this);
    }

    mapStateToThis(state) {
        return {
            languages: state.languages.languages
        };
    }

    $onInit = () => {
        if (this.certId) {
            this.loadCert({ id: this.certId });
        } else {
            this.createNewCerts();
        }
    };

    loadCert = async () => {
        fetchEntity.call(this,{
            id: this.certId,
            name: 'cert',
            loading: 'inProcess',
            urlName: 'certs',
            error: 'errorCert'
        },schema);
    };

    setForm = (form) => {
        this.form = form;
    };

    createNewCerts = () => {
        let locales = getLocales([], schema, this.languages);
        this.cert = {};
        let defaultLanguage = checkDefaultLanguage(locales, this.languages);
        this.locales = setDefaultLanguage(defaultLanguage, locales);
        this.active = defaultLanguage.id;
        this.defaultLanguage = defaultLanguage.id;
        this.languages.forEach((item, index) => {
            if (item.id === this.defaultLanguage) {
                this.activeIndex = index;
            }
        });
        this.setBtnConfig();
    }

    dropzoneChanged = (file) => {
        if (!this.locales[this.activeIndex].name) {
            this.locales[this.activeIndex].name = file.name;
        }
    };

    setDefaultLanguage = (languageId) => {
        this.defaultLanguage = languageId;
        this.locales.forEach((item, index) => {
            if (index === this.activeIndex) {
                item.isDefault = 1;
            } else {
                item.isDefault = 0;
            }
        });
        this.locales[this.activeIndex].isDefault = 1;
    };

    setActiveLanguage = (active) => {
        let findIndex = false;
        this.locales.forEach((item, index) => {
            if (item && item.languageId === active) {
                this.activeIndex = index;
                findIndex = true;
            }
        });
        if (!findIndex) {
            this.activeIndex = this.locales.length;
            this.locales[this.activeIndex] = {};
            this.locales[this.activeIndex].languageId = active;
        }
        this.active = active;
    }

    clear = () => {
        this.locales[this.activeIndex].dropzone = null;
        this.locales[this.activeIndex].filename = null;
    }

    checkTranslations = () => {
        let keys = [];
        let locales = [];
        let changeDefault = true;
        for (let i = 0; i < this.locales.length; i++) {
            if ((!this.locales[i].dropzone &&
                    !this.locales[i].filename) ||
                !this.locales[i].name) {
                keys.push(i);
            }
        }
        locales = this.locales.filter((item, index) => keys.indexOf(index) === -1);
        locales.forEach(item => {
            if (item.isDefault === undefined) {
                item.isDefault = 0;
            }
            if (item.isDefault) {
                changeDefault = false;
            }
        });
        if (changeDefault) {
            locales[0].isDefault = 1;
        }
        return locales;
    }

    setFormData = async () => {
        try {
            this.saveInProcess = true;
            let locales = this.checkTranslations();
            let value = await setLocales(locales, this.languages, schema);
            this.save(value);
        } catch (err) {
            this.saveInProcess = false;
            this.errorCert = true;
            this.errorText = this.gettextCatalog.getString('Что-то пошло не так :-(');
        }
    }

    save = async (formData) => {
        try {
            this.errorCert = false;
            this.errorText = null;
            let cert = await contentFetch({ body: formData, content: 'certs' }, this.certId);
            if (cert.error) {
                throw cert.error;
            }
            this.fetchCerts();
            this.saveInProcess = false;
            this.$dismiss();
        } catch (err) {
            this.saveInProcess = false;
            this.errorCert = true;
            this.errorText = this.gettextCatalog.getString('Что-то пошло не так :-(');
        }
    };

    modalClose = () => {
        if (this.saveInProcess) return;
        this.$close();
    }

    disable = () => {
        return this.saveInProcess ||
            (!this.locales[this.activeIndex].dropzone &&
                !this.locales[this.activeIndex].filename);
    }

    isLoading = () => {
        return this.saveInProcess;
    }

    setBtnConfig = () => {
        this.btnConf = defaultConfig.call(this,[this.modalClose, this.setFormData], [this.isLoading, this.disable]);
    }
}

CertsModalController.$inject = [
    '$ngRedux',
    'certId',
    'gettextCatalog',
    '$scope'
];

export {
    CertsModalController
};
