import style from './style.modules.css';
import getNounPluralForm from '../../helpers/getNonPluralForm';
import { setSearchData } from '../../toolkit/actions';

class SearchFilterItemsController {
    constructor($ngRedux, gettextCatalog, $localStorage) {
        this.gettextCatalog = gettextCatalog;
        this.$localStorage = $localStorage;
        this.style = style;

        this.runToAnchor = gettextCatalog.getString('перешел по ссылке');
        this.openFile = gettextCatalog.getString('открыл вложение');
        // this.statusEmailInfected = gettextCatalog.getString('открыл письмо');
        // this.statusLinkInfected = gettextCatalog.getString('перешел по ссылке');
        this.runToQrCode = gettextCatalog.getString('перешел по ссылке в QR');
        this.statusOpenedFileFromLink = gettextCatalog.getString('открыл файл по ссылке');
        this.newEmployee = gettextCatalog.getString('новый сотрудник');
        this.noChecked = gettextCatalog.getString('не проверялся');
        this.withstoodAttack = gettextCatalog.getString('выдержал атаку');
        this.openMail = gettextCatalog.getString('открыл письмо');
        this.inputDate = gettextCatalog.getString('ввел данные в форму');
        this.reportedAttack = gettextCatalog.getString('сообщил об атаке');

        this.endLearning = gettextCatalog.getString('прошел обучение');
        this.startLearning = gettextCatalog.getString('на обучении');
        this.notLearning = gettextCatalog.getString('не обучался');
        this.lateLearning = gettextCatalog.getString('не прошел обучение вовремя');

        this.positiveRank = gettextCatalog.getString('рейтинг положительный');
        this.negativeRank = gettextCatalog.getString('рейтинг отрицательный');
        this.upRank = gettextCatalog.getString('рейтинг улучшился');
        this.downRank = gettextCatalog.getString('рейтинг ухудшился');
        this.noChangeRank = gettextCatalog.getString('рейтинг не изменился');

        this.hasVulnerabilities = gettextCatalog.getString('есть уязвимости');
        this.notVulnerabilities = gettextCatalog.getString('нет уязвимостей');

        this.noMarks = gettextCatalog.getString('без меток');

        this.selectedTargets = gettextCatalog.getString('выбранные сотрудники');

        this.inLastAttack = gettextCatalog.getString('в последней атаке:');

        this.daysAsNew = {
            one: gettextCatalog.getString('дня'),
            few: gettextCatalog.getString('дней'),
            many: gettextCatalog.getString('дней'),
        };

        this.renderFields = [
            'attackField1',
            'attackField2',
            'educationField',
            'ratingField1',
            'ratingField2',
            'vulnerabilitiesField',
            'marksField',
        ];
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { setSearchData })(this);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    mapStateToThis(state) {
        return {
            selectedData: state.selectedData.selectedData,
        };
    }

    $onInit() {
        const ctrl = this;
        this.attackField1 = [
            {
                name: ctrl.selectedTargets,
                class: 'search-filter-checkbox',
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1 && ctrl.selectedData.selectedCount.targets > 0;
                },
            },
            {
                name: ctrl.newEmployee,
                description: '< ' + ctrl.$localStorage.userInfo.daysAsNew + ' ' + ctrl.daysAsNew[getNounPluralForm(ctrl.$localStorage.userInfo.daysAsNew)],
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
            {
                name: 'divider',
                get condition() {
                    let names = [
                        ctrl.selectedTargets,
                        ctrl.newEmployee,
                    ];
                    return names.filter(function(obj) { return ctrl.selectedData.search.indexOf(obj) === -1; }).length > 0;
                },
            },
            {
                name: ctrl.noChecked,
                class: 'schema-icon new-target-ico',
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
        ];
        this.attackField2 = [
            {
                name: 'group',
                description: ctrl.inLastAttack,
                get condition() {
                    let names = [
                        ctrl.withstoodAttack,
                        ctrl.reportedAttack,
                        ctrl.openMail,
                        ctrl.runToAnchor,
                        ctrl.runToQrCode,
                        ctrl.openFile,
                        // ctrl.statusEmailInfected,
                        // ctrl.statusLinkInfected,
                        ctrl.statusOpenedFileFromLink,
                        ctrl.inputDate,
                    ];
                    return names.filter(function(obj) { return ctrl.selectedData.search.indexOf(obj) == -1; }).length > 0;
                },
            },
            {
                name: ctrl.withstoodAttack,
                class: 'schema-icon succsess-ico',
                deep: 1,
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
            {
                name: ctrl.reportedAttack,
                class: 'schema-icon new-target-ico report-mail-ico',
                deep: 1,
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
            {
                name: ctrl.openMail,
                class: 'schema-icon warning-ico',
                deep: 1,
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
            {
                name: ctrl.runToAnchor,
                class: 'schema-icon danger-ico',
                deep: 1,
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
            {
                name: ctrl.runToQrCode,
                class: 'schema-icon danger-ico',
                deep: 1,
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
            {
                name: ctrl.openFile,
                class: 'schema-icon open-file',
                deep: 1,
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
            // {
            //     name: ctrl.statusLinkInfected,
            //     class: 'schema-icon danger-ico',
            //     deep: 1,
            //     onClick() {
            //         ctrl.findBy(this.name, ctrl.searchKey);
            //     },
            //     get condition() {
            //         return ctrl.selectedData.search.indexOf(this.name) === -1;
            //     },
            // },
            {
                name: ctrl.statusOpenedFileFromLink,
                class: 'schema-icon danger-ico',
                deep: 1,
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
            {
                name: ctrl.inputDate,
                class: 'schema-icon time-out-ico',
                deep: 1,
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
        ];
        this.educationField = [
            {
                name: 'divider',
                get condition() {
                    let names = [
                        ctrl.selectedTargets,
                        ctrl.newEmployee,
                        ctrl.noChecked,
                        ctrl.withstoodAttack,
                        ctrl.reportedAttack,
                        ctrl.openMail,
                        // ctrl.statusEmailInfected,
                        // ctrl.statusLinkInfected,
                        ctrl.statusOpenedFileFromLink,
                        ctrl.inputDate,
                    ];
                    return names.filter(function(obj) { return ctrl.selectedData.search.indexOf(obj) === -1; }).length > 0;
                },
            },
            {
                name: ctrl.notLearning,
                class: 'schema-icon learning-ico no-learning',
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
            {
                name: ctrl.endLearning,
                class: 'schema-icon learning-ico complete-learning',
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
            {
                name: ctrl.startLearning,
                class: 'schema-icon learning-ico learning-proccess',
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
            {
                name: ctrl.lateLearning,
                class: 'schema-icon learning-ico miss-learning',
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
        ];
        this.ratingField1 = [
            {
                name: 'divider',
                get condition() {
                    let names = [
                        ctrl.notLearning,
                        ctrl.endLearning,
                        ctrl.startLearning,
                        ctrl.lateLearning,
                    ];
                    return names.filter(function(obj) { return ctrl.selectedData.search.indexOf(obj) === -1; }).length > 0;
                },
            },
            {
                name: ctrl.positiveRank,
                class: 'schema-icon succsess-ico',
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
            {
                name: ctrl.negativeRank,
                class: 'schema-icon danger-ico',
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
        ];
        this.ratingField2 = [
            {
                name: 'group',
                description: ctrl.inLastAttack,
                get condition() {
                    let names = [
                        ctrl.upRank,
                        ctrl.downRank,
                        ctrl.noChangeRank,
                    ];
                    return names.filter(function(obj) { return ctrl.selectedData.search.indexOf(obj) == -1; }).length > 0;
                },
            },
            {
                name: ctrl.upRank,
                class: 'sortorder reverse',
                deep: 1,
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
            {
                name: ctrl.downRank,
                class: 'sortorder',
                deep: 1,
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
            {
                name: ctrl.noChangeRank,
                class: '',
                deep: 1,
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
        ];
        this.vulnerabilitiesField = [
            {
                name: 'divider',
                get condition() {
                    let names = [
                        ctrl.positiveRank,
                        ctrl.negativeRank,
                        ctrl.upRank,
                        ctrl.downRank,
                        ctrl.noChangeRank,
                    ];
                    return names.filter(function(obj) { return ctrl.selectedData.search.indexOf(obj) === -1; }).length > 0;
                },
            },
            {
                name: ctrl.hasVulnerabilities,
                class: 'schema-icon danger-ico vuln-ico',
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
            {
                name: ctrl.notVulnerabilities,
                class: 'schema-icon succsess-ico',
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
        ];
        this.marksField = [
            {
                name: 'divider',
                get condition() {
                    let names = [
                        ctrl.hasVulnerabilities,
                        ctrl.notVulnerabilities,
                    ];
                    return names.filter(function(obj) { return ctrl.selectedData.search.indexOf(obj) === -1; }).length > 0;
                },
            },
            {
                name: ctrl.noMarks,
                class: '',
                onClick() {
                    ctrl.findBy(this.name, ctrl.searchKey);
                },
                get condition() {
                    return ctrl.selectedData.search.indexOf(this.name) === -1;
                },
            },
        ];
    }

    findBy = (search, key) => {
        this.search[key] = search;
        this.setSearchData(this.search);
    };

    blockVisible = (name) => {
        let visible = false;
        for (let i = 0; i < this[name].length; i++) {
            if (['divider', 'group'].indexOf(this[name][i].name) > -1) {
                continue;
            }
            if (this[name][i].condition) {
                visible = true;
                return visible;
            }
        }
        return visible;
    };
}

SearchFilterItemsController.$inject = [
    '$ngRedux',
    'gettextCatalog',
    '$localStorage',
];

export {
    SearchFilterItemsController,
};
