export default (index) => {

    let fickleConf = {};

    fickleConf.override1 = [
        {
            borderWidth: 0,
            backgroundColor: [
                '#c3c3c3',
                '#f6a622',
                '#5db75c',
                '#bd0ce0',
            ],
            hoverBackgroundColor: [
                '#c3c3c3',
                '#f6a622',
                '#5db75c',
                '#bd0ce0'
            ],
        },
        {
            borderWidth: 0,
            backgroundColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff'],
            hoverBackgroundColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff'],
        },
    ];

    fickleConf.override2 = [
        {
            borderWidth: 0,
            backgroundColor: [
                '#c3c3c3',
                '#5db75c',
                '#f6a622',
                '#d95250',
                '#B3514B',
                '#bd0ce0',
            ],
            hoverBackgroundColor: ['#c3c3c3', '#5db75c', '#f6a622', '#d95250', '#B3514B', '#bd0ce0'],
        },
        {
            borderWidth: 0,
            backgroundColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
            hoverBackgroundColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
        },
    ];

    fickleConf.override4 = [
        {
            color: '#bd0ce0',
            borderWidth: 0,
            backgroundColor: [
                '#c3c3c3',
                '#5db75c',
                '#bd0ce0',
            ],
            hoverBackgroundColor: ['#c3c3c3', '#5db75c', '#bd0ce0'],
        },
        {
            borderWidth: 0,
            backgroundColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
            hoverBackgroundColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
        },
    ];

    let series = ['1', '2'];

    let options = {
        cutoutPercentage: 70,
        tooltips: {
            enabled: true,
            mode: 'point',
            displayColors: false,
            callbacks: {
                label: function(tooltipItems, data) {
                    return data.labels[tooltipItems.index];
                },
            },
        },
    };

    let override = fickleConf[`override${index}`];

    return { override, series, options };
};
