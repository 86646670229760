import { Controller } from '../../controllers/controller';
import style from './style.modules.css';
import setOptions from '../../helpers/setOptions';

class EducationMessageController extends Controller {

    constructor (gettextCatalog, scope) {
        super();
        this.style = style;
        this.scope = scope;
        this.gettextCatalog = gettextCatalog;
        this.appCtrl = window.appCtrl;
        this.notifications = [];
        this.senderComplite = false;
        this.courses = [];
        this.notificationsKey = [
            'certs',
            'messageCourses',
            'notifications'
        ];
        this.notificationName = {
            'Registration': this.gettextCatalog.getString('Отправка уведомления о регистрации в системе обучения'),
            'Update': this.gettextCatalog.getString('Отправка уведомления об изменении учетной записи'),
            'CoursesNew': this.gettextCatalog.getString('Отправка уведомления о новом обучении'),
            'CoursesMiss': this.gettextCatalog.getString('Отправка уведомления о пропуске обучения')
        };
        this.hidden = false;
        this.limitTo = 1;
    }

    $onInit () {
        this.scope.$watch(scope => {
            return scope.$.messageNotifications;
        }, () => {
            this.updateNotifications(this.messageNotifications, this.notifications);
        });
        this.scope.$watch(scope => {
            return scope.$.messageCourses;
        }, () => {
            this.updateNotifications(this.messageCourses, this.courses, true);
        });
    }

    retry = async (action, type) => {
        try {
            const URL =`${window.config.API_URL}/learning/queue?retryType=${action}&retryValue=${type}`;
            let opt = setOptions();
            let response = await fetch(URL, opt);
            if (!response.ok) {
                throw response.error();
            }
        } catch (error) {
            window.console.error(error);
        }
    };

    hide = () => {
        this.hidden = 1;
    };

    setLimitTo = (limitTo) => {
        this.limitTo = limitTo;
    };

    updateNotifications = (message, selfarr, external = false) => {
        if (!message.length) {
            if (!external) {
                this.senderComplite = false;
            }
            return;
        }
        if (!selfarr.count) {
            selfarr = message.map(item => item);
        }
        message.forEach((item, index) => {
            if (item.count > selfarr[index].count) {
                selfarr[index].count = item.count;
                selfarr[index].sent = 0;
            }
            if (item.count < selfarr[index].count) {
                selfarr[index].sent = selfarr[index].count - item.count;
            }
            if (item.done === selfarr[index].count) {
                this.senderComplite = true;
            }
        });
    }

    toggleLimitTo = () => {
        if (this.limitTo == 1) {
            this.limitTo = 999;
        } else {
            this.limitTo = 1;
        }
    };

    $onDestroy () {
        // эта штука не работает
        if (this.$timeout) {
            this.$timeout.cancel(this.updateGrowl);
        }
    }
}

EducationMessageController.$inject = [
    'gettextCatalog',
    '$scope'
];

export {
    EducationMessageController
};
