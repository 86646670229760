import { RISKS_SERVICE } from '../../const';
import style from './style.modules.css';
import { clearSelectedData } from '../../toolkit/actions';
import { fetchDashboardParams } from '../../toolkit/think';

class RiskSoftwareController {
    constructor($ngRedux, RisksService) {
        this.style = style;
        this.RisksService = RisksService;
        this.dataMode = 0;
        this.viewMode = 0;
        this.departmentsLoading = false;
        this.targetsLoading = false;
        this.ignoreSetLoading = {
            departmentsLoading: false,
            targetsLoading: false,
        };
        this.reloading = false;
        this.departmentCount = 0;
        this.departmentCheckedCache = [];
        this.departmentPartialCache = [];
        this.appCtrl = window.appCtrl;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { clearSelectedData, fetchDashboardParams })(this);
    }

    $onInit = () => {
        this.clearSelectedData();
    };

    $onDestroy() {
        this.unsubscribe();
    }

    mapStateToThis(state) {
        return {
            selectedData: state.selectedData.selectedData,
        };
    }

    fixVulnerabilities = () => {
        if (this.appCtrl && this.appCtrl.hasAccess(3, 'vulnerability_fix')) {
            if (this.selectedData && this.selectedData.selectedCount && this.selectedData.selectedCount.targets > 0) {
                this.fixEmployeesError = false;
                this.fixEmployeesLoading = true;
                let params = { ...this.selectedData };
                params.risk = 1;
                params.softwareId = this.software.id;
                params.vendorId = this.software.vendorId;
                this.RisksService.bulkFix(params, this, (ctrl) => {
                    if (ctrl.risksCtrl) {
                        ctrl.risksCtrl.expandedType = null;
                        ctrl.risksCtrl.expanded = null;
                        ctrl.clearSelectedData();
                        ctrl.fetchDashboardParams();
                    }
                    ctrl.fixEmployeesLoading = false;
                }, (ctrl) => {
                    ctrl.fixEmployeesLoading = false;
                    ctrl.fixEmployeesError = true;
                });
            }
        }
    };

    isAnyLoading = () => {
        return this.departmentsLoading ||
            this.targetsLoading;
    };

    setViewMode = (viewMode) => {
        this.viewMode = viewMode;
    };

    setLoading = (loadingType = 'departmentsLoading', value = false) => {
        value = !!value;
        if (!this.ignoreSetLoading[loadingType]) {
            if (value != this[loadingType]) {
                this[loadingType] = value;
            }
        } else {
            this.ignoreSetLoading[loadingType] = false;
        }
    };

    setReloading = (reloading) => {
        this.reloading = reloading;
    };

    setDepartmentCount = (count) => {
        this.departmentCount = +count;
    };

    subFunctions = () => {
        const {
            setLoading,
        } = this;
        return {
            setLoading,
        };
    };

}

RiskSoftwareController.$inject = [
    '$ngRedux',
    RISKS_SERVICE,
];

export {
    RiskSoftwareController,
};
