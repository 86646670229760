import style from './style.modules.css';
import { hasAccess } from '../../reselect';
import { RoleModal } from '../roleModal';
import { DEFAULT_PER_PAGE_SIZE, ROLES_SERVICE } from '../../const';
import { plural } from '../../filters';

class SettingsRoleController {
    constructor (gettextCatalog, injector, $ngRedux, $scope, RolesService) {
        this.style = style;
        this.injector = injector;
        this.gettextCatalog = gettextCatalog;
        this.ldapSyncRefs = [];
        this.syncParameters = [];
        this.RolesService = RolesService;
        this.loading = false;
        this.$scope = $scope;
        this.endingHours = (hours) => [
            this.gettextCatalog.getString('Каждый {{count}} час', { count: hours }),
            this.gettextCatalog.getString('Каждые {{count}} часа', { count: hours }),
            this.gettextCatalog.getString('Каждые {{count}} часов', { count: hours }),
        ];
        this.endingDays = (days) => [
            this.gettextCatalog.getString('Каждый {{count}} день', { count: days }),
            this.gettextCatalog.getString('Каждые {{count}} дня', { count: days }),
            this.gettextCatalog.getString('Каждые {{count}} дней', { count: days }),
        ];
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, null)(this);
        this.syncRoles = {};
    }

    mapStateToThis = (state) => {
        return {
            licenseValid: state.auth.auth.license.isValid,
            roleSaveAccess: hasAccess(state, { sectionId: 102, rightName: 'role_save' }),
            roleDeleteAccess: hasAccess(state, { sectionId: 102, rightName: 'role_delete' }),
            roleViewAccess: hasAccess(state, { sectionId: 102, rightName: 'view' }),
            userInfo: state.auth.auth
        };
    }

    $onInit = () => {};

    saveRole = (role) => {
        this.modal = this.injector.instantiate(RoleModal);
        this.modal.open({
            roleId: role && role.id ? role.id : 0,
            ldapProfilesList: this.ldapProfiles
        }).then((roleData) => {
            if (roleData) {
                this.loadRoles();
            }
        }, () => {});
    };

    getProfileNames = (ldapSyncRefs) => {
        if (!ldapSyncRefs.length) return;
        let profileIds = [];
        let profileNames = [];
        ldapSyncRefs.forEach(ldapItem => {
            profileIds.push(ldapItem.profileId);
        });
        this.ldapProfiles.forEach(item => {
            if (~profileIds.indexOf(item.id)) {
                profileNames.push(item.title);
            }
        });
        return profileNames.join(', ');
    }

    getGroupNames = (ldapSyncRefs) => {
        if (!ldapSyncRefs.length) return;
        let groupNames = [];
        ldapSyncRefs.forEach(item => {
            groupNames = [...item.groupNames, ...groupNames];
        });
        let uniq = Array.from(new Set(groupNames));
        return uniq.join(', ');
    }

    getSyncParams = (syncParams) => {
        if (!syncParams) return;
        if (syncParams.mode === 2) {
            return this.gettextCatalog.getString('Вручную');
        }
        let hours = syncParams.synchronizeInterval / 60 / 60;
        if (hours / 24 === (hours / 24 ^ 0)) {
            return plural()(hours/24, this.endingDays(hours/24));
        }
        return plural()(hours, this.endingHours(hours));
    }

    deleteRole = (role) => {
        if (this.roleDeleteAccess && confirm(this.gettextCatalog.getString('Удалить роль?'))) {
            this.RolesService.one(role.id).then((role) => {
                role.$delete().then(() => {
                    this.loadRoles();
                });
            });
        }
        return false;
    };

    loadRoles = () => {
        if (!this.roleViewAccess) return;
        this.loading = true;
        this.roleList.$page = 1;
        this.roleList.$load(DEFAULT_PER_PAGE_SIZE).then((data) => {
            this.setRoles(data.$data, data);
            this.loading = false;
        });
    };

    loadMoreRoles = () => {
        this.loading.roles = true;
        if (this.roleList.$existNext()) {
            const prevData = this.roleList.$data;
            this.roleList.$nextPage().then((data) => {
                data.$data = [...prevData, ...data.$data];
                this.setRoles(data.$data, data);
                this.loading.roles = false;
            });
        }
    };

    startSync = (event, role) => {
        this.onAfterSync = this.onAfterSync || function (){};
        role.loading = true;
        role.error = false;
        event.stopPropagation();
        fetch(window.config.SYSTEM_URL + window.config.API_URL + '/roles/sync', {
            headers: {
                'X-Csrf-Token': window.csrfToken,
                'Enable-Session': 1,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({ id: role.id }),
        }).then(resp => {
            return resp.json();
        }).then(resp => {
            if (resp.success === true) {
                this.onAfterSync();
                role.loading = false;
            } else {
                role.loading = false;
                role.error = true;
            }
        });
    };
}

SettingsRoleController.$inject = [
    'gettextCatalog',
    '$injector',
    '$ngRedux',
    '$scope',
    ROLES_SERVICE
];

export {
    SettingsRoleController,
};
