export default (nextLink) => {
    let nextPage = '';
    for (let i = 0; i < nextLink.length; i++) {
        if (nextLink[i].indexOf('next') !== -1) {
            nextPage = nextLink[i];
        }
    }
    let pageStart = nextPage.indexOf('&page');
    let pageEnd = nextPage.indexOf('&', pageStart + 1);
    return nextPage.slice(pageStart + 6, pageEnd);
};
