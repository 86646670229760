import { GettextContext } from 'rootReact/context';
import { useContext } from 'react';
import { usePlural } from 'rootReact/hook';
import Translate from 'rootReact/Translate.jsx';

export const ldapSchema = ({ handleRow, iconHandle, disabled }, syncLdap, syncStatus, targetImport) => {
    const gettext = useContext(GettextContext);
    return {
        row: { handleRow, disabled: () => false },
        cell: [
            {
                text: 'title',
            },
            {
                text: 'url',
            },
            {
                text: 'baseDn',
            },
            {
                text: (profile) => {
                    let period = profile.timePeriod === 'day' ?
                            ['каждый {{count}} день', 'каждые {{count}} дня', 'каждые {{count}} дней'] :
                            ['каждый {{count}} час', 'каждые {{count}} часа', 'каждые {{count}} часов'];
                    return !profile.timeValue ?
                            gettext.getString('Вручную') :
                            gettext.getString(usePlural(profile.timeValue, period), {count: profile.timeValue});
                },
            },
            {
                text: (profile) => {
                    if (!profile.canSync || !targetImport) return;
                    if (syncStatus[profile.id] && syncStatus[profile.id].loading) {
                        return <>
                            <span className="fa fa-circle-o-notch fa-spin"></span>&nbsp;
                            <span><Translate>Синхронизация профиля</Translate></span>...</>
                    }
                    if (syncStatus[profile.id] && syncStatus[profile.id].error) {
                        return <>
                            <span className="fa fa-exclamation-triangle"></span>&nbsp;
                            <span><Translate>Что-то пошло не так :-(</Translate></span>
                        </>
                    }
                    return <a onClick={(e) => syncLdap(e, profile.id)}><Translate>Запустить сейчас</Translate></a>
                }
            }
        ],
        icon: [
            {
                isIcon: true,
                icon: disabled ? 'noIcon' : 'delete',
                handle: [
                    ...iconHandle
                ]
            }
        ]
    };
};

