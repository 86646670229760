import settingsRoleTemplate from './view.html';

import { SettingsRoleController } from './controller';

const SettingsRoleComponent = {
    template: settingsRoleTemplate,
    controller: SettingsRoleController,
    controllerAs: '$',
    bindings: {
        ldapProfiles: '<',
        roles: '=',
        setRoles: '=',
        roleList: '<',
        onAfterSync: '='
    }
};

export {
    SettingsRoleComponent
};
