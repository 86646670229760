
class FinalPagesService {
    constructor ($resource) {
        this.resource = $resource(window.config.SYSTEM_URL + window.config.API_URL + '/final-pages');
    }

    getAll = () => {
        return this.resource.query();
    };
}

export {
    FinalPagesService
};
