import modalTemplate from './view.html';

import { RoleModalController } from './controller';

import { ROLE_MODAL_CONTROLLER } from '../../const';

class RoleModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    open = ({ roleId, ldapProfilesList }) => {
        this.modalInstance = this.$uibModal.open({
            template: modalTemplate,
            bindToController: true,
            controller: ROLE_MODAL_CONTROLLER,
            controllerAs: '$',
            size: 'lg',
            windowClass: 'center-modal',
            resolve: {
                roleId: roleId,
                ldapProfilesList: () => ldapProfilesList
            },
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}

export {
    RoleModalController,
    RoleModal,
};
