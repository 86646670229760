import daemonMessageTemplate from './view.html';

import { DaemonMessageViewController } from './controller';

const DaemonMessageViewComponent = {
    template: daemonMessageTemplate,
    controller: DaemonMessageViewController,
    controllerAs: '$',
    scope: true,
    bindings: {
        messageStatus: '<',
        messageKey: '<',
        messageBody: '<',
        msMessage: '<',
        daemonId: '<',
        setHidden: '=',
        arrHidden: '<',
        loadCancel: '<',
        oneTimeMessage: '<'
    },
};

export {
    DaemonMessageViewComponent,
};
