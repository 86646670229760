import style from './style.modules.css';

/* eslint-disable */

class UrlInputController {
    constructor() {
        this.style = style;
        this.urlMethod = 'https://';
        this.outInfectedUrl = '';
        this.domainString = /^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i;
        this.noPatternDomainString = /^[\w\._\/:&\?-]+$/i;
        this.appCtrl = window.appCtrl;
    }

    $onInit = () => {
        if (!this.urlRequired) {
            this.urlMethod = '';
        }
        if (this.urlName) {
            const parseString = this.urlName.split(/:\/\//);
            this.urlMethod = parseString[0] + '://';
            this.outInfectedUrl = parseString[1];
        }
        if (this.httpsOnly) {
            this.urlMethod = 'https://';
        }
    };

    setOutInfectedUrl = (method = null) => {
        if (method) this.urlMethod = method;
        if (!this.urlRequired && this.outInfectedUrl === '') {
            this.urlName = '';
            this.urlOnBlur ? this.urlOnBlur(this.urlName, this.fieldName) : null;
        } else {
            this.urlName = this.urlMethod + this.outInfectedUrl;
            this.urlOnBlur ? this.urlOnBlur(this.urlName, this.fieldName) : null;
        }
    };
}

UrlInputController.$inject = [];

export {
    UrlInputController,
};
