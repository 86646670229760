import React, { useContext } from 'react';
import { GettextContext } from 'rootReact/context';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import deleteIcon from '../../../../images/delete-icon.svg';

function WifiDeleteModal({ onModalClose, attack }) {
    const gettext = useContext(GettextContext);

    return (
        <>
            <ModalHeader
                styleText={{ color: '#000', fontWeight: '600', fontSize: '20px' }}
                stylePadding={{
                    paddig: '0',
                    paddigTop: '20px',
                    paddigLeft: '20px',
                    paddigRight: '20px',
                    borderBottom: 'none',
                }}
                title={gettext.getString('Удаление устройства')}
                closeModal={() => onModalClose()}
            />
            <div className='device-delete-modal'>
                <img src={deleteIcon} />
                <div className='device-delete-modal_content'>
                    <span className='device-delete-modal_top'>{`${gettext.getString('Выбранное беспроводное устройство используется в запущенной атаке')} ${attack.title}.`}</span>
                    <span className='device-delete-modal_bottom'>{gettext.getString('Остановите атаку и удалите устройство.')}</span>
                </div>
            </div>
            <ModalFooter titleBtnClose={gettext.getString('Ок')} closeModal={() => onModalClose()}
                styleBtnOk={{ display: 'none' }} />
        </>
    );
}

export default WifiDeleteModal;
