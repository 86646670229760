import style from './style.modules.css';
import { hasAccess } from '../../reselect';

class MaturityLevelController {
    constructor ($ngRedux, $scope, gettextCatalog, $localStorage) {
        this.style = style;
        this.targetCount = 0;
        this.opened = false;
        this.scope = $scope;
        this.gettextCatalog = gettextCatalog;
        this.$localStorage = $localStorage;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, null)(this);
        this.strings = {
            eduComplete: gettextCatalog.getString('прошел обучение'),
            eduProcess: gettextCatalog.getString('на обучении'),
            eduMiss: gettextCatalog.getString('не прошел обучение вовремя')
        };
    }

    mapStateToThis = (state) => {
        return {
            linkNames: state.breadcrumbLinks.link,
            accessCoursesDepartments: hasAccess(state, { sectionId: 1, rightName: 'view' })
        };
    };

    $onInit () {
        if (!this.accessCoursesDepartments) {
            return;
        }
        this.sortCourses();
    }

    getAdress = (fullList, page) => {
        return `${window.config.SYSTEM_URL}${window.config.API_URL}/courses?layout=targets&fullList=${fullList}&page=${page}&per-page=10&sortBy=${this.sortBy}&sortReverse=${this.reverse}`;
    };

    sortCourses = () => {
        this.courses = this.courses.filter(item => item.id !== 0);
        if (this.courses && this.courses.length > 0) {
            this.targetCount = this.courses[0].complete + this.courses[0].miss + this.courses[0].none + this.courses[0].process;
        }
    };
}

MaturityLevelController.$inject = [
    '$ngRedux',
    '$scope',
    'gettextCatalog',
    '$localStorage'
];

export {
    MaturityLevelController
};
