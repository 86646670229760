
class LdapProfilesService {
    constructor (gettextCatalog, YiiModel, $resource) {
        this.gettextCatalog = gettextCatalog;
        this.YiiModel = YiiModel;
        this.all = YiiModel.all('ldap-profiles');
        this.one = (id) => {
            return YiiModel.one('ldap-profiles').$find(id);
        };
        this.action = (profile, action, ctrl, success, error) => {
            return $resource(`${window.config.SYSTEM_URL}${window.config.API_URL}/ldap-profiles/${profile.id}/${action}`, null, {
                save: {
                    method: 'GET'
                }
            }).save({}, {}, function (response) {
                if (typeof (success) == 'function' && profile && ctrl) {
                    success(profile, ctrl, response);
                }
            }, function (response) {
                if (typeof (error) == 'function' && profile && ctrl) {
                    error(profile, ctrl, response);
                }
            });
        };
        this.fields = [
            'buildingname',
            'c',
            'cn',
            'co',
            'comment',
            'commonname',
            'company',
            'description',
            'distinguishedname',
            'dn',
            'department',
            'displayname',
            'facsimiletelephonenumber',
            'fax',
            'friendlycountryname',
            'givenname',
            'homephone',
            'homepostaladdress',
            'info',
            'initials',
            'ipphone',
            'l',
            'mail',
            'mailnickname',
            'rfc822mailbox',
            'mobile',
            'mobiletelephonenumber',
            'name',
            'othertelephone',
            'ou',
            'pager',
            'pagertelephonenumber',
            'physicaldeliveryofficename',
            'postaladdress',
            'postalcode',
            'postofficebox',
            'samaccountname',
            'serialnumber',
            'sn',
            'surname',
            'st',
            'stateorprovincename',
            'street',
            'streetaddress',
            'telephonenumber',
            'title',
            'uid',
            'url',
            'userprincipalname',
            'wwwhomepage',
            'employeeid',
            'othername',
            'middlename',
            'canonicalname'
        ];
    }
}

export {
    LdapProfilesService
};
