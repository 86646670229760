import ldapDepartmentTemplate from './view.html';

import { LdapDepartmentController } from './controller';

const LdapDepartmentComponent = () => ({
    template: ldapDepartmentTemplate,
    controller: LdapDepartmentController,
    controllerAs: 'ldapDepartmentCtrl',
    bindToController: {
        ldapDepartment: '<',
        ldapDepartmentsCtrl: '=',
        selectedData: '<',
    },
});

export {
    LdapDepartmentComponent,
};
