import React, { useState } from 'react';
import DropdownSelect from '../DropdownSelect';

function InputTimeZone({ inputAttr, label, inputId, timeZones, error, Style, handleClick, placeholder, disabled }) {
    const gmtStyle = 'react-input_field react-input_field__left time-zone_label';
    const seTime = (value) => {
        if (disabled) return;
        handleClick(value);
    };

    return (
        <div className={'settings_item'}>
            <div className={'react-input'}>
                <label style={{ marginLeft: 0, marginRight: 45 }}
                    className={error ? 'react-input_label__error react-input_label' : 'react-input_label'} htmlFor={inputId}>{label}</label>
                <div className={'react-input_wrapper'}>
                    <div className={gmtStyle + ' react-input_field__untouched'}>{'GMT' + inputAttr.value.gmt}</div>
                    <DropdownSelect
                        inputAttr={{ ...inputAttr, value: inputAttr.value.name }}
                        list={timeZones}
                        disabled={disabled}
                        idClass={inputId}
                        placeholder={placeholder}
                        Style={Style}
                        handleClick={seTime}
                        optionsName='displayValue'
                    />
                </div>
            </div>
        </div>
    );
}

export default InputTimeZone;
