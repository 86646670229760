
class TemplatesService {
    constructor ($resource) {
        this.$resource = $resource;
        this.one = (id) => {
            return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/templates/' + id).get();
        };
        this.getAll = () => {
            return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/templates').query();
        };
        this.loadTags = (name) => {
            return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/vectors').query({ name: name });
        };
    }
}

export {
    TemplatesService
};
