// import angular from 'angular';
import style from './style.modules.css';
import moment from 'moment';

class AttackGraphController {
    constructor(gettextCatalog, $cookies) {
        this.gettextCatalog = gettextCatalog;
        this.style = style;
        this.moment = moment;
        this.langCode = $cookies.get('langCode');
        this.moment.updateLocale('ru', {
            longDateFormat : {
                LL: 'DD MMMM YYYY года',
            }
        });
        this.chart = {
            outCnvBaseFont: 'sans-serif',
            outCnvBaseFontSize: '12',
            outCnvBaseFontColor: '#5a5a5a',
            'baseFontSize': '12',
            showvalues: '0',
            numvisibleplot: '24',
            theme: 'gammel',
            formatNumber: '0',
            legendItemFontBold: 0,
            legendPosition: 'bottom-left',
            legendNumRows: 1,
            showLegend: '0',
            legendNumColumns: 5,
            labelDisplay: 'none',
            formatNumberScale: 0,
            enableChartMouseMoveEvent: '1',
            toolbarHAlign: 'Left'
        };
        this.colors = [
            '#5CB75C',
            '#f5a623',
            '#BD10E0',
            '#d95350',
            '#c04b4c',
            '#dddddd'
        ];
        this.seriesname = [
            gettextCatalog.getString('Выдержали атаку'),
            gettextCatalog.getString('Открыли письмо'),
            gettextCatalog.getString('Ввели данные в форму'),
            gettextCatalog.getString('Перешли по ссылке или по ссылке в QR'),
            gettextCatalog.getString('Открыли вложение или файл по ссылке'),
            gettextCatalog.getString('Не проверялись')
        ];
        this.month = [
            this.gettextCatalog.getString('Январь'),
            this.gettextCatalog.getString('Февраль'),
            this.gettextCatalog.getString('Март'),
            this.gettextCatalog.getString('Апрель'),
            this.gettextCatalog.getString('Май'),
            this.gettextCatalog.getString('Июнь'),
            this.gettextCatalog.getString('Июль'),
            this.gettextCatalog.getString('Август'),
            this.gettextCatalog.getString('Сентябрь'),
            this.gettextCatalog.getString('Октябрь'),
            this.gettextCatalog.getString('Ноябрь'),
            this.gettextCatalog.getString('Декабрь')
        ];
        this.myDataCampaing = {};
    }

    $onInit = () => {
        const that = this;
        if (!this.attackHistory.length) {
            this.chart['yAxisMaxValue'] = 5;
            this.myDataCampaing.chart = this.chart;
            this.myDataCampaing.categories = [{ category: [''] }];
            this.myDataCampaing.dataset = [{}];
            return;
        }
        this.chart['yAxisMaxValue'] = this.targetsInSystem > 5 ? this.targetsInSystem : 5;
        let monthCount = this.getMonthCount();
        let startMonth = new Date(this.attackHistory[0].date).getMonth();
        this.myDataCampaing.chart = this.chart;
        this.myDataCampaing.categories = [
            { category: this.setCategory(startMonth, monthCount) }
        ];
        this.myDataCampaing.dataset = this.setDate();
        // eslint-disable-next-line
        FusionCharts.ready((FusionCharts) => {
            FusionCharts.addEventListener('chartRollOver', that.setEvent);
            FusionCharts.addEventListener('chartMouseMove', that.setEvent);
        });
    };

    getMonthCount = () => {
        return Math.floor((this.attackHistory[this.attackHistory.length - 1].date - this.attackHistory[0].date) /60/60/24/30);
    }

    getMonth = (n) => {
        let month = new Date(this.attackHistory[n].date).getMonth();
        return month;
    }

    setDate = () => {
        let dataset = [];
        let mailReport = [];
        let openMail = [];
        let submitForm = [];
        let openLinkOrQr = [];
        let openAttachmentOrFileFromLink = [];
        let noAttack = [];
        for (let i = 0; i < this.attackHistory.length; i++) {
            mailReport.push({
                value: `${this.attackHistory[i].mailReport + this.attackHistory[i].proofAttack}.${this.attackHistory[i].date}`
            });
            openMail.push({
                value: this.attackHistory[i].openMail
            });
            submitForm.push({
                value: this.attackHistory[i].submitForm
            });
            openLinkOrQr.push({
                value: this.attackHistory[i].openLinkOrQr
            });
            openAttachmentOrFileFromLink.push({
                value: this.attackHistory[i].openAttachmentOrFileFromLink
            });
            noAttack.push({
                value: this.attackHistory[i].noAttack
            });
        }
        dataset.push({
            seriesname: this.seriesname[0],
            color: this.colors[0],
            data: mailReport
        });
        dataset.push({
            seriesname: this.seriesname[1],
            color: this.colors[1],
            data: openMail
        });
        dataset.push({
            seriesname: this.seriesname[2],
            color: this.colors[2],
            data: submitForm
        });
        dataset.push({
            seriesname: this.seriesname[3],
            color: this.colors[3],
            data: openLinkOrQr
        });
        dataset.push({
            seriesname: this.seriesname[4],
            color: this.colors[4],
            data: openAttachmentOrFileFromLink
        });
        dataset.push({
            seriesname: this.seriesname[5],
            color: this.colors[5],
            data: noAttack
        });
        return dataset;
    }

    setCategory = () => {
        let category = [];
        let choosed = [];
        for (let i = 0; i < this.attackHistory.length; i++) {
            let month = this.month[this.getMonth(i)];
            if (month === choosed[choosed.length - 1]) {
                category.push({
                    label: ''
                });
            } else {
                category.push({
                    label: month
                });
                choosed.push(month);
            }
        }
        return category;
    }

    setEvent = (event) => {
        const chart = event.sender;
        if (chart.args.type !== 'stackedarea2d') {
            return;
        }
        let reg = /[\d.]+/g;
        let children = event.data.container.children[0].children[2] ? event.data.container.children[0].children[2].children : null;
        let data = event.data.container.children[0].children[2] ? event.data.container.children[0].children[2].innerText : null;
        if (!data || children.length === 2) {
            return;
        }
        let arr = data.match(reg);
        const num = +arr[0];
        arr[0] = parseInt(arr[0]).toString();
        let date = num.toFixed(13).match(/\d+/g);
        let title = chart.ref.querySelector('.fc__tooltip');
        let div = document.createElement('section');
        div.classList.add('fc__tooltip_label');
        let ul = document.createElement('ul');
        ul.classList.add('fc__tooltip_list-wrapper');
        if (!arr || !arr.length) return;
        let targetsInSystem = +arr[0] + +arr[1] + +arr[2] + +arr[3] + +arr[4] + +arr[5];
        let minValue = 0;
        let maxValue = 0;
        minValue = maxValue = arr[0] * 100/targetsInSystem ? arr[0] * 100/targetsInSystem : 0;
        let persent = [];
        for (let i = 0; i < arr.length; i++) {
            persent.push(arr[i] * 100/targetsInSystem ? arr[i] * 100/targetsInSystem : 0);
            if (!minValue) {
                minValue = persent[i];
            }
            if (minValue > persent[i] && persent[i]) {
                minValue = persent[i];
            }
            if (maxValue < persent[i]) {
                maxValue = persent[i];
            }
        }
        for (let i = 0; i < arr.length; i++) {
            let li = document.createElement('li');
            let cellPersent = document.createElement('span');
            let bollWrapper = document.createElement('span');
            let boll = document.createElement('span');
            let cellNum = document.createElement('span');
            let cellText = document.createElement('span');
            boll.classList.add('fc__tooltip_boll');
            bollWrapper.classList.add('fc__tooltip_bollwrapper');
            cellNum.classList.add('fc__tooltip_num');
            cellText.classList.add('fc__tooltip_text');
            cellPersent.classList.add('fc__tooltip_num');
            boll.style['background-color'] = this.colors[i];
            li.classList.add('fc__tooltip_item');
            if (persent[i] > 0 && persent[i] < 1) {
                persent[i] = '< 1%';
            } else if (persent[i] > 99 && persent[i] < 100) {
                persent[i] = '> 99%';
            } else if (persent[i] > Math.floor(persent[i])) {
                persent[i] = `> ${Math.floor(persent[i])}%`;
            } else {
                persent[i] = Math.round(persent[i]) + '%';
            }
            cellNum.insertAdjacentText('afterbegin', persent[i]);
            cellText.insertAdjacentText('afterbegin', this.seriesname[i]);
            cellPersent.insertAdjacentText('afterbegin', (+arr[i]).toLocaleString());
            bollWrapper.appendChild(boll);
            li.appendChild(bollWrapper);
            li.appendChild(cellNum);
            li.appendChild(cellText);
            li.appendChild(cellPersent);
            ul.appendChild(li);
        }
        div.insertAdjacentText('afterbegin', this.moment(+date[1]).locale(this.langCode).format('LL'));
        setTimeout(() => {
            while (title.firstChild) {
                title.removeChild(title.firstChild);
            }
            title.appendChild(div);
            title.appendChild(ul);
        }, 1);
    }

    $onDestroy() {
        const that = this;
        // eslint-disable-next-line
        FusionCharts.removeEventListener('chartMouseMove', that.setEvent);
        // eslint-disable-next-line
        FusionCharts.removeEventListener('chartRollOver', that.setEvent);
    }
}

AttackGraphController.$inject = [
    'gettextCatalog',
    '$cookies'
];

export {
    AttackGraphController,
};
