import { POLICY_SERVICE } from '../../const';
import angular from 'angular';

class UserPolicyModalController {
    constructor ($scope, $localStorage, PolicyService) {
        this.$scope = $scope;
        this.$localStorage = $localStorage;
        this.PolicyService = PolicyService;
        this.inProcess = false;
        this.policy = null;
        this.policyOrigin = null;
        this.min = {
            'length': 0,
            'digit_min': 0
        };
        this.max = {
            'length': 999,
            'digit_min': 996
        };
        this.gettextCatalog = window.appCtrl.gettextCatalog;
    }

    loadPolicy = () => {
        this.loadingPolicy = true;
        this.errorPolicy = false;
        this.PolicyService.getPolicy().$promise.then(response => {
            this.policy = angular.copy(response);
            this.policyOrigin = angular.copy(response);
            this.setExtremums(this.policy);
            this.loadingPolicy = false;
        }, () => {
            this.loadingPolicy = false;
            this.errorPolicy = true;
        }).catch(() => {
            this.loadingPolicy = false;
            this.errorPolicy = true;
        });
        this.errorText = null;
    };

    $onInit = () => {
        this.loadPolicy();
        this.$scope.$watch('$.policy', this.setExtremums, true);
    };

    setForm = (form) => {
        this.form = form;
    };

    setExtremums = (policy) => {
        if (policy && this.policy) {
            if (this.policy.char_big || this.policy.char_small || this.policy.digit || this.policy.char_special) {
                let diffMin = [!!this.policy.char_big, !!this.policy.char_small, !!this.policy.digit, !!this.policy.char_special].filter(i => i).length;
                if (+this.policy.length < (+diffMin + +this.policy.digit_min)) {
                    this.policy.length = +diffMin + +this.policy.digit_min;
                }
                if (+this.policy.length < +this.min.length) {
                    this.policy.length = +this.min.length;
                }
                if (+this.policy.length > +this.max.length) {
                    this.policy.length = +this.max.length;
                }
                if (+this.policy.digit_min && +this.policy.length < (+diffMin + +this.policy.digit_min)) {
                    this.policy.digit_min = +this.policy.length - +diffMin;
                }
                if (+this.policy.digit_min > +this.max.digit_min) {
                    this.policy.digit_min = +this.max.digit_min;
                }
                if (!+this.policy.digit) {
                    this.policy.digit_min = 0;
                } else if (+this.policy.digit_min == 0) {
                    this.policy.digit_min = 1;
                }
            } else {
                this.policy.length = 0;
            }
            return this.policy;
        }
    };

    savePolicy = () => {
        this.errorText = null;
        this.form.$setSubmitted();
        if (this.isPolicyChanged()) {
            this.inProcess = true;
            this.PolicyService.savePolicy(this.policy, this,
                (ctrl, response) => {
                    if (response) {
                        ctrl.policy.local = response;
                        ctrl.policyIsSaved = true;
                        ctrl.$close(response);
                    } else {
                        ctrl.errorText = this.gettextCatalog.getString('Ошибка при сохранении требований к паролю');
                    }
                    ctrl.inProcess = false;
                }, (ctrl, response) => {
                    if (response && response.data && response.data.error) {
                        ctrl.errorText = response.data.error;
                        if (response.data.field) {
                            ctrl.form.$setValidity(response.data.field, false);
                        }
                    }
                    if (response && response.data && response.data.message) {
                        ctrl.errorText = response.data.message;
                    }
                    ctrl.inProcess = false;
                });
        }
    };

    validConfig = () => {
        return this.policy && (this.policy.char_small || this.policy.char_big || this.policy.char_special || this.policy.digit);
    };

    isPolicyChanged = () => {
        let changed = false;
        angular.forEach(this.policy, (value, key) => {
            if (['$promise', 'global'].indexOf(key) == -1 && this.policyOrigin[key] != value) {
                changed = true;
            }
        });
        if (!changed) {
            this.errorText = null;
        }
        return changed;
    };
}

UserPolicyModalController.$inject = [
    '$scope',
    '$localStorage',
    POLICY_SERVICE
];

export {
    UserPolicyModalController
};
