import React, { useState, useContext,useEffect, useRef } from 'react';
import { GettextContext } from 'rootReact/context';
import { useClipboard } from 'use-clipboard-copy';

function ButtonsGroup({ deleteDevice, editDevice, id, name, attack, setCloseMenu, setPopup, deviceList, opened }) {
    const gettext = useContext(GettextContext);
    const clipboard = useClipboard();
    const menuRef = useRef(null);

    useEffect(() => {
        if (!opened) return;
        const handleClick = (e) => {
            if (!menuRef.current) return;
            if (!menuRef.current.contains(e.target)) {
                setCloseMenu(false);
            }
        };
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [opened, setCloseMenu]);

    const handleEdit = () => {
        editDevice(id, attack);
        setCloseMenu(false);
    };

    const handleDelete = () => {
        deleteDevice(id, name, attack);
        setCloseMenu(false);
    };

    const handleToken = () => {
        const token = deviceList.find((item) => item.id === id).token;
        clipboard.copy(token);
        setPopup(true);
        setCloseMenu(false);
    };

    return (
        <div className={'wifi-device_button-sector'} ref={menuRef}>
            <button
                data-testid='device-token'
                onClick={handleToken}
                className='wifi-device_btn'
            >
                <svg className={'wifi-device_btn-token'}>
                    <use xlinkHref={'/image/icoSprite.svg#wifi-token'}></use>
                </svg>
                <span>{gettext.getString('Скопировать токен')}</span>
            </button>
            <button
                data-testid='device-edit'
                onClick={handleEdit}
                className={'wifi-device_btn'}
            >
                <svg className={'wifi-device_btn-edit'}>
                    <use xlinkHref={'/image/icoSprite.svg#wifi-edit'}></use>
                </svg>
                <span>{gettext.getString('Редактировать')}</span>
            </button>
            <button
                data-testid='device-delete'
                onClick={handleDelete}
                className={'wifi-device_btn'}
            >
                <svg className={'wifi-device_btn-delete'}>
                    <use xlinkHref={'/image/icoSprite.svg#wifi-delete'}></use>
                </svg>
                <span>{gettext.getString('Удалить')}</span>
            </button>
        </div>
    );
}

export default ButtonsGroup;
