import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';
import { defaultDashboardParams } from 'root/assets/webpack/src/js/const';

const verifyData = (data) => {
    if (!data) return defaultDashboardParams;
    let keys = Object.keys(defaultDashboardParams);
    let value = {};
    keys.forEach(item => {
        if (Number.isInteger(data[item])) {
            value[item] = data[item]
        } else {
            value[item] = defaultDashboardParams[item];
        }
    });
    return value;
};

const initialState = {
    status: 'idle',
    firstLoading: true,
    dashboard: verifyData(JSON.parse(window.localStorage.getItem('dashboardParams'))),
    error: false
};

export const fetchDashboardParams = createAsyncThunk(
    'dashboardParams/status',
    async () => {
        const fetchApi = new FetchApi('/dashboard?noActionSeconds=' + window.noActionSeconds);
        const response = await fetchApi.get();
        if (response.isError) throw response.err;
        return response;
    }
);

const dashboardParamsSlice = createSlice({
    name: 'dashboardParams',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchDashboardParams.fulfilled]: (state, { payload }) => {
            state.dashboard = verifyData(payload);
            window.localStorage.setItem('dashboardParams', JSON.stringify(verifyData(payload)));
            state.firstLoading = false;
            state.status = 'load';
        },
        [fetchDashboardParams.pending]: (state) => {
            state.status = 'pending';
        },
        [fetchDashboardParams.rejected]: (state) => {
            state.status = 'rejected';
        },
    }
});

export default dashboardParamsSlice.reducer;

