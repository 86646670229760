const domainPattern = /^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i; // eslint-disable-line
const noPatternDomain = /^[\w\._\/:&\?-]+$/i; // eslint-disable-line

export const validRules = (rule, fields) => {
    let validate = fields.map(item => {
        const { name, value } = item;
        if (!rule[name]) return;
        if (!rule[name].required && !value) {
            return {
                name,
                valid: true,
                error: false
            };
        }

        if (rule[name].required && !value) {
            return {
                name,
                valid:false,
                error: true,
                errorType: 'required'
            };
        }

        if (rule[name].url) {
            let url = value.split('://');
            let val = validRegExp(url[1], domainPattern);
            if (!val) {
                return {
                    name,
                    valid: false,
                    error: true,
                    errorType: 'uncorrectedUrl'
                };
            }
        }

        if (rule[name].urlNoPattern) {
            let url = value.split('://');
            let val = validRegExp(url[1], noPatternDomain);
            if (!val) {
                return {
                    name,
                    valid: false,
                    error: true,
                    errorType: 'uncorrectedUrlNP'
                };
            }
        }

        if (rule[name].maxLength && value.trim().length > rule[name].maxLength) {
            return {
                name,
                valid: false,
                error: true,
                errorType: 'maxSizeError'
            };
        }

        if (rule[name].httpsOnly) {
            let url = value.split('://');
            if (url[0] !== 'https') {
                return {
                    name,
                    valid: false,
                    error: true,
                    errorType: 'httpsOnly'
                };
            }
        }

        return {
            name,
            valid: true,
            error: false
        };
    }).filter(item => item.error);
    if (!validate.length) return false;
    return validate.reduce((prevVal, item) => {
        if (item.valid) return prevVal;
        prevVal[item.name] = item;
        return prevVal;
    }, {});
};

const validRegExp = (value, pattern) => {
    return value.match(pattern);
};
