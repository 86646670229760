export const bossKadrovikSchema = ({ handleRow, iconHandle, disabled }) => {
    return {
        row: { handleRow, disabled: () => false },
        cell: [
            {
                text: 'title',
            },
            {
                text: 'host',
            },
            {
                text: 'database',
            }
        ],
        icon: [
            {
                isIcon: true,
                icon: disabled ? 'noIcon' : 'delete',
                handle: [
                    ...iconHandle
                ]
            }
        ]
    };
};

