import { DateTime } from 'luxon';

class Trigger {
    constructor (trigger_value, trigger_mode, day_value, type, startDate, timeZone, dateTimeString, courses) {
        this.trigger_value = trigger_value || 0;
        this.trigger_mode = trigger_mode || null;
        this.day_value = day_value || 0;
        this.type = type || '';
        this.repeat = false;
        // TimeZone
        this.time_zone = timeZone.name || 'UTC';
        this.time_zone_name = timeZone.name || 'UTC';
        this.time_zone_val = timeZone.gmt || '+0';
        this.courses = courses || [];
        // Unix
        this.start_date = startDate || DateTime.local().toSeconds();
        this.minValue = this.start_date;
        this.error = false;
        this.dateTimeString = dateTimeString || DateTime.fromSeconds(this.start_date, { zone: this.time_zone_name }).toFormat('yyyy-MM-dd HH:mm:ss');
    }

    get range () {
        const range = [];
        for (let i = this.trigger_value - 10; i < this.trigger_value + 11; i++) {
            range.push(i);
        }
        return range;
    }

    get isTimed() {
        return this.type.indexOf('time') > -1;
    }
    get dayRepeatModeName() {
        return 'repeat_day';
    }
    get weekRepeatModeName() {
        return 'repeat_week';
    }
    get monthRepeatModeName() {
        return 'repeat_month';
    }

    get isDailyRepeat() {
        return this.trigger_mode === this.dayRepeatModeName;
    }
    get isWeeklyRepeat() {
        return this.trigger_mode === this.weekRepeatModeName;
    }
    get isMonthlyRepeat() {
        return this.trigger_mode === this.monthRepeatModeName;
    }
    setNoRepeat = () => {
        this.trigger_mode = '';
    }
    setRepeatDaily = () => {
        this.trigger_mode = this.dayRepeatModeName;
    }
    setRepeatWeekly = () => {
        this.trigger_mode = this.weekRepeatModeName;
    }
    setRepeatMonthly = () => {
        this.trigger_mode = this.monthRepeatModeName;
    }

    get isRepeated () {
        return this.isDailyRepeat || this.isMonthlyRepeat || this.isWeeklyRepeat;
    }

    setStart = () => {
        this.startDate = this.dateTimeString;
    };

    set startDate(sd) {
        this.start_date = DateTime.fromSQL(sd, { zone: this.time_zone_name }).toSeconds();
    }

    get startDate() {
        return this.dateTimeString;
    }

    /**
     * триггер эксклюзивен: т.е. его нельзя комбинировать с другими через логические и/или
     */
    get isExclusive() {
        return this.isTimed; // пока только для "время пришло"
    }

    setTimeZone = (tz) => {
        tz = tz || { 'name': 'UTC', 'gmt': '+0' };
        this.time_zone = tz.name;
        this.time_zone_name = tz.name;
        this.time_zone_val = tz.gmt;
        this.dateTimeString = DateTime.fromSQL(this.dateTimeString, { zone: this.time_zone_name }).toFormat('yyyy-MM-dd HH:mm:ss');
    }

    set timeZone(tz) {
        this.time_zone = tz;
    }

    get timeZone() {
        return this.time_zone;
    }

    changeSchedulerTriggerMode = () => {
        if (!this.isRepeated) {
            this.setRepeatDaily();
            this.trigger_value = 1;
        } else {
            this.setNoRepeat();
            this.trigger_value = 0;
        }
    };
}

export {
    Trigger
};
