import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    ssoLoading: false,
    modalLoading: false,
    modalString: 'noLoading',
    deleteLoading: false,
};

const loaderSsoSlice = createSlice({
    name: 'loaderSso',
    initialState,
    reducers: {
        setSSOLoading (state, {payload}) {
            for (let key in state) {
                if (payload.hasOwnProperty(key)) {
                    state[key] = payload[key];
                } else {
                    state[key] = state[key];
                }
            }
        }
    },
    extraReducers: {}
});

export const {setSSOLoading} = loaderSsoSlice.actions;
export default loaderSsoSlice.reducer;

