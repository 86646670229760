class TargetsService {
    constructor (YiiModel, $resource) {
        return {
            YiiModel: YiiModel,
            all: () => (YiiModel.all('targets')),
            new: () => {
                return YiiModel.one('targets');
            },
            one: (id) => {
                return YiiModel.one('targets').$find(id);
            },
            count: (data, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/targets/count').save({}, data, function (response) {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, function (response) {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            },
            bulkUpdate: (data, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/targets/bulk-update').save({}, data, function (response) {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, function (response) {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            },
            bulkDelete: (data, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/targets/bulk-delete').save({}, data, function (response) {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, function (response) {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            },
            getLdapGroups: (profileId, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/targets/import/' + profileId + '/ldap-groups/').query().$promise.then((response) => {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, (response) => {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            },
            ldapTargets: YiiModel.all('targets/import/ldap-targets'),
            ldapDepartments: YiiModel.all('targets/import/ldap-departments'),
            ldapImport: (data, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/targets/import/' + data.profile + '/ldap-import').save({}, data, function (response) {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, function (response) {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            },
            searchLdap: (data, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/targets/import/ldap-targets/1').save({}, data, function (response) {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, function (response) {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            },
            getLdapUsersCount: (data, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/targets/import/' + (data.profile || 0) + '/ldap-count').save({}, { filter: data.filter || '' }, (response) => {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, (response) => {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            },
            hasData: (data, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/targets/has-data').save({}, data, function (response) {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, function (response) {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            },
            similarTags: (data, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/targets/similar-tags').save({}, data, function (response) {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, (response) => {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            },
            similarLanguage: (data, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/targets/similar-language').save({}, data, function(response) {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, function (response) {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            },
            rebuildSelectedData: (data, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/targets/rebuild').save({}, data, function (response) {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, function (response) {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            },
            rebuildLdapSelectedData: (data, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/targets/rebuild-ldap').save({}, data, function (response) {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, function (response) {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            },
            reverseSelectedData: (data, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/targets/reverse').save({}, data, function (response) {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, function (response) {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            },
            getTransliterate: (data, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/targets/transliterate').save({}, data, function (response) {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, function (response) {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            },
            attacks: (targetId, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/targets/' + +targetId + '/attacks').query().$promise.then((response) => {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, (response) => {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            },
            getSelected: (data, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/targets/selected-data', null, {
                    save: {
                        method: 'POST',
                        isArray: true
                    }
                }).save({}, data, function (response) {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, function (response) {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            }
        };
    }
}

export {
    TargetsService
};
