const dashboardChart = {
    scrollColor: '#FFFFFF',
    'baseFont': 'sans-serif',
    'baseFontSize': '12',
    'baseFontColor': '#5a5a5a',
    numVDivLines: '3',
    numDivLines: '2',
    yAxisNameFontSize: '13',
    labelDisplay: 'NONE',
    yAxisMaxValue: '100',
    yAxisMinValue: '0',
    showvalues: '0',
    numvisibleplot: '180',
    lineColor: '#f5a623',
    yAxisValueAlpha: '50',
    theme: 'fusion',
    scrollToEnd: 1,
    scrollHeight: 0,
    legendBgColor: '#ccc',
    drawAnchors: '0',
    showToolTip: 1,
    seriesNameInToolTip: 1,
    enableChartMouseMoveEvent: '1',
};

export const getMySourseDashboard = (
    history /* данные obj */,
    maincount /* в отношении чего высчитываем str */,
    targetInSystem /* люди в системе num */,
    tooltext /* arr */,
    licenseCount,
    isTarget = false,
    gettextCatalog,
) => {
    const month = [
        gettextCatalog.getString('Январь'),
        gettextCatalog.getString('Февраль'),
        gettextCatalog.getString('Март'),
        gettextCatalog.getString('Апрель'),
        gettextCatalog.getString('Май'),
        gettextCatalog.getString('Июнь'),
        gettextCatalog.getString('Июль'),
        gettextCatalog.getString('Август'),
        gettextCatalog.getString('Сентябрь'),
        gettextCatalog.getString('Октябрь'),
        gettextCatalog.getString('Ноябрь'),
        gettextCatalog.getString('Декабрь')
    ];
    let myDataSource = {};
    myDataSource.chart = dashboardChart;
    if (!history.length) {
        myDataSource.dataset = [{}];
        myDataSource.categories = [{ category: [''] }];
        return myDataSource;
    }
    let peoplePercent = Math.round(history[history.length-1][maincount] * 100 / (licenseCount ? licenseCount: targetInSystem));
    if (!isTarget) {
        peoplePercent = 100 - peoplePercent;
    }
    myDataSource.dataset = [
        { color: peoplePercent > 84 ? '#5CB75C' : (peoplePercent < 30 ? '#d95350' : '#f5a623'), data: setDate(history, tooltext, maincount) }
    ];
    myDataSource.categories = [
        { category: setCategory(history, month) }
    ];
    return myDataSource;
};

const setDate = (history, toolText, maincount) => {
    let data = [];
    for (let i = 0; i < history.length; i++) {
        let targetsInSystem = 0;
        if (maincount !== 'count') {
            for (let key in history[i]) {
                if (key !== 'id' && key !== 'date') {
                    targetsInSystem += history[i][key];
                }
            }
        } else {
            targetsInSystem = history[i].companyCount;
        }
        let count = maincount === 'count' ? history[i][maincount] : targetsInSystem - history[i][maincount];
        data.push({
            value: count * 100/targetsInSystem,
            toolText: toolText(history[i])
        });
    }
    return data;
};

const getMonth = (history, n) => {
    let month = new Date(history[n].date).getMonth();
    return month;
};

const setCategory = (history, month) => {
    let category = [];
    let choosed = [];
    for (let i = 0; i < history.length; i++) {
        let thismonth = month[getMonth(history, i)];
        if (thismonth === choosed[choosed.length - 1]) {
            category.push({
                label: ''
            });
        } else {
            category.push({
                label: thismonth
            });
            choosed.push(thismonth);
        }
    }
    return category;
};

export const getDashGraphItem = (fields, data) => {
    let arr = data.split(' ');
    let item = {};
    for (let i = 0; i < fields.length; i++) {
        if (!arr.filter(item => item.indexOf(fields[i]) !== -1).length) continue;
        item[fields[i]] = arr.filter(item => item.indexOf(fields[i]) !== -1)[0].split(':')[1];
    }
    return item;
};
