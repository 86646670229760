import style from './style.modules.css';

class LdapSearchPanelController {
    /* props
    *   clearAll: fun
    *   searchString: string
    *   clearSearch: fun
    *   search: fun
    *   disabled: bool
    */
    constructor(scope) {
        this.scope = scope;
        this.style = style;
        this.searchString = '';
    }

    $onInit = () => {
        this.scope.$watch(scope => {
            return scope.$.searchString;
        }, (value, oldValue) => {
            if (oldValue === value) return;
            this.setSearch(value);
        });
    }

    setSearch = (value) => {
        this.search(value);
    }

    clearSearch = () => {
        this.searchString = '';
    }

    doBlur = (event) => {
        event.target.blur();
    };
}

LdapSearchPanelController.$inject = [
    '$scope'
];

export {
    LdapSearchPanelController,
};
