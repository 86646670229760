class PhishingPagesService {
    constructor ($resource) {
        this.resource = $resource(window.config.SYSTEM_URL + window.config.API_URL + '/phishing-pages');
    }

    getAll = () => {
        return this.resource.query();
    };
}

export {
    PhishingPagesService
};
