import angular from 'angular';
import { EMPTY_SELECTED_DATA, EMPTY_SELECTED_PREV_DATA } from '../const';

export default (selectedData, response, options) => {
    selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(selectedData));
    let newSelectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(response));
    newSelectedData.search = selectedData.search;
    let selectedCount = newSelectedData.selectedCount;
    if (selectedCount && (selectedCount.departments > 0 || selectedCount.targets > 0)) {
        newSelectedData.prevData = angular.extend({}, EMPTY_SELECTED_PREV_DATA);
        newSelectedData.prevData.targets = angular.extend({}, response.targets);
        newSelectedData.prevData.departments = angular.copy(response.departments);
        newSelectedData.prevData.departmentsUsed = angular.copy(response.departmentsUsed);
        newSelectedData.prevData.risk = options && options.risk;
        newSelectedData.prevData.selectedCount = angular.copy(response.selectedCount);
    }
    if (response.selectedCount) {
        newSelectedData.selectedCount = {
            targets: +response.selectedCount.targets || 0,
            departments: +response.selectedCount.departments || 0,
            groups: +response.selectedCount.groups || 0,
            notSelectedTargets: +response.selectedCount.notSelectedTargets || 0,
            allSearchTargets: +response.selectedCount.allSearchTargets || 0,
        };
    }
    if (options && options.setDepartmentCheckedFunction && typeof (options.setDepartmentCheckedFunction) == 'function') {
        options.setDepartmentCheckedFunction(response.departments);
    }
    if (options && options.setDepartmentPartialFunction && typeof (options.setDepartmentPartialFunction) == 'function') {
        options.setDepartmentPartialFunction(response.departmentsUsed);
    }
    if (options && options.setDepartmentCheckedAndPartialFunction && typeof (options.setDepartmentCheckedAndPartialFunction) == 'function') {
        options.setDepartmentCheckedAndPartialFunction(angular.copy(response.departments), angular.copy(response.departmentsUsed));
    }
    if (newSelectedData && newSelectedData.$promise) {
        delete newSelectedData.$promise;
    }
    if (newSelectedData && newSelectedData.$resolved) {
        delete newSelectedData.$resolved;
    }
    return newSelectedData;
};
