
class GroupsService {
    constructor(YiiModel, $resource) {
        return {
            all: () => (YiiModel.all('groups')),
            new: () => {
                return YiiModel.one('groups');
            },
            one: (id) => {
                return YiiModel.one('groups').$find(id);
            },
            simple: $resource(window.config.SYSTEM_URL + window.config.API_URL + '/groups'),
            count: (data, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/groups/count').save({}, data, function (response) {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, function (response) {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            },
            bulkDelete: (data, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/groups/bulk-delete').save({}, data, function (response) {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, function (response) {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            },
            delete: (data, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/groups/delete').delete(data, {}, function (response) {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, function (response) {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            },
        };
    }

}

export {
    GroupsService
};
