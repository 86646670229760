import departmentBreadcrumbTemplate from './view.html';

import { DepartmentBreadcrumbController } from './controller';

const DepartmentBreadcrumbComponent = {
    template: departmentBreadcrumbTemplate,
    controller: DepartmentBreadcrumbController,
    controllerAs: '$',
    scope: true,
    bindings: {
        breadcrumb: '<',
        prefix: '<',
        search: '<',
        withHighlight: '<',
    },
};

export {
    DepartmentBreadcrumbComponent,
};
