import { createSelector } from 'reselect';

const notification = (state) => {
    return state.systemNotification.notification;
};

const certsFeature = (state) => {
    return state.auth.auth.license.features.cert;
};

const learningConfig = (state) => {
    return state.settings.settings.learning;
};

const notifications = (state) => {
    return state.notificationsList.notification;
};

export const targetNotification = createSelector([notification, certsFeature, learningConfig], (notification, certsFeature, learningConfig) => {
    let returnedValue = notification.filter(item => {
        return (item.type !== 'Cert' || certsFeature)
            && ((!learningConfig.location && ['Registration', 'RegistrationLdap', 'Update', 'UpdateLdap'].indexOf(item.type) === -1)
            || (learningConfig.location && ['AuthLink'].indexOf(item.type) == -1)
            && (!learningConfig.type || (learningConfig.type === 1 && ['Registration', 'RegistrationLdap', 'Update', 'UpdateLdap'].indexOf(item.type) == -1)));
    });
    return returnedValue;
});

export const defaultNotification = createSelector([notifications], (notifications) => {
    return notifications.filter(item => !item.custom);
});

export const customNotification = createSelector([notifications], (notifications) => {
    return notifications.filter(item => item.custom);
});
