import React, { useState, forwardRef } from 'react';
import style from './Calendar.module.less';
import { useSelector } from 'react-redux';
import { format, subDays } from 'date-fns';
import Translate from 'rootReact/Translate.jsx';
import DatePicker from 'react-datepicker';

export const Calendar = (props) => {
    const {wrapperClass, minDate, popupText, children, handleClick, ...all } = props;
    const [showPopup, setShowPopup] = useState(false);
    const [period, setPeriod] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [startDatePrev, setStartDatePrev] = useState(subDays(new Date(), 7));
    const MinDate = useSelector(state => minDate || state.auth.auth.createdAt);

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
            <button className='savefile-popup_date' onClick={onClick} ref={ref}>
                {format(new Date(value), 'yyyy-MM-dd')}
            </button>
    ));

    const onClickPeriod = () => {
        setPeriod((prev) => !prev);
    };

    const onChildrenClick = () => {
        setShowPopup((prev) => !prev);
    }

    const clickSave = () => {
        handleClick({
            dateFrom: period ? startDatePrev : MinDate,
            dateTo:  period ? startDate : Date.now(),
        });
    }

    return (
        <div className={wrapperClass} style={{position: 'relative'}}>
            {showPopup &&
                <div className={style.wrapper}>
                    <div className={style.headerText}>
                        <span className={style.text}><Translate>{popupText}</Translate></span>
                        {period ? <a className={style.text}
                                     onClick={onClickPeriod}><Translate>за период</Translate></a>
                                : <a className={style.text}
                                     onClick={onClickPeriod}><Translate>за все время</Translate></a>}
                        <span>:</span>
                    </div>
                    {period ? <span style={{display: 'flex'}}>
                            <span className={style.text}><Translate>с</Translate></span>
                        <div className={'savefile-popup_calendar-start'}>
                                    <DatePicker
                                            positionFixed='true'
                                            popperPlacement='top'
                                            selected={startDatePrev}
                                            onChange={(date) => setStartDatePrev(date)}
                                            customInput={<ExampleCustomInput />}
                                            maxDate={new Date()}
                                            minDate={new Date(MinDate)}
                                    />
                                </div>
                            <span className={style.text}><Translate>по</Translate></span>
                        <div className={'savefile-popup_calendar-end'}>
                                    <DatePicker
                                            positionFixed='true'
                                            popperPlacement='top'
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            customInput={<ExampleCustomInput />}
                                            maxDate={new Date()}
                                            minDate={startDatePrev}
                                    />
                                </div>
                            <span>.</span>
                            </span> : '' }
                    <a className={style.save}
                       onClick={clickSave}
                    ><Translate>Сохранить</Translate></a>
                </div>
            }
            {React.Children.map(children, child => (
                    React.cloneElement(child, {handleClick: onChildrenClick, ...all})
            ))}
        </div>
    );
};
