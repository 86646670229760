import React, { useEffect, useState, useContext } from 'react';
import { GettextContext } from 'rootReact/context';
import { useDispatch } from 'react-redux';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import loader from '../../../../images/loading.svg';
import FaIcon from 'rootReact/components/FaIcon';
import FetchApi from '../../REST';
import { setWifiDevice, updateWifiDevice } from '../../../toolkit/actions';
import { useInput } from '../../hook/useValidModal.jsx';

function WifiDeviceModal({ id, onModalClose, attack }) {
    const gettext = useContext(GettextContext);
    const dispatch = useDispatch();
    const [formError, setFormError] = useState([]);
    const [formEmptyError, setFormEmptyError] = useState(false);
    const [save, setSave] = useState(false);
    const [loading, setLoading] = useState(false);
    const name = useInput('', { isEmpty: true });
    const ip = useInput('', { isEmpty: true, ipError: false });
    const ssid = useInput('', { isEmpty: true });
    const login = useInput('', { isEmpty: true });
    const password = useInput('', { isEmpty: true });
    const [responseBody, setResponseBody] = useState({
        name: '',
        ip: '',
        ssid: '',
        login: '',
        password: ''
    });

    const bodyWithPassword = {
        name: name.value,
        ip: ip.value,
        ssid: ssid.value,
        login: login.value,
        password: password.value,
    };

    const body = {
        name: name.value,
        ip: ip.value,
        ssid: ssid.value,
        login: login.value,
    };

    useEffect(() => {
        async function fetchApi() {
            let response = await FetchApi('/wifi-devices').get(id);
            if (response && response.isError) {
                throw response;
            }
            name.onChange(response.name);
            ip.onChange(response.ip);
            ssid.onChange(response.ssid);
            login.onChange(response.login);
            setResponseBody({
                name: response.name,
                ip: response.ip,
                ssid: response.ssid,
                login: response.login,
                password: ''
            });
            setLoading(false);
        }
        try {
            if (id) {
                setLoading(true);
                fetchApi();
            }
        } catch (e) {
            setFormError('Что-то пошло не так :-(');
        }
    }, [id]);

    const saveSettings = async () => {
        if (
            !name.value.length ||
            (!id && !password.value.length) ||
            !login.value.length ||
            !ssid.value.length ||
            !ip.value.length
        ) {
            setFormEmptyError(true);
            return;
        }
        try {
            setSave(true);
            setFormError('');
            if (id) {
                const response = await FetchApi('/wifi-devices').put(id, {
                    body: !password.value ? body : bodyWithPassword,
                });
                if (response && response.isError) {
                    throw response;
                }
                dispatch(updateWifiDevice(response));
            } else {
                const response = await FetchApi('/wifi-devices').post({
                    body: bodyWithPassword,
                });
                if (response && response.isError) {
                    throw response;
                }
                dispatch(setWifiDevice(response));
            }
            setSave(false);
            onModalClose();
        } catch (e) {
            setSave(false);
            const error = e.err.map((item) => {
                const arr = [];
                arr.push(item.message);
                return arr;
            });
            setFormError(error.join(' '));
        }
    };

    const closeModalPopup = () => {
        if (JSON.stringify(responseBody) !== JSON.stringify(bodyWithPassword)) {
            const value = confirm(gettext.getString('Устройство не сохранено, и все изменения будут потеряны. Уверены?'));
            if (value) {
                onModalClose();
            }
        } else {
            onModalClose();
        }
    };

    return (
        <>
            <ModalHeader
                styleText={{ color: '#000', fontWeight: '600', fontSize: '20px' }}
                stylePadding={{
                    paddig: '0',
                    paddigTop: '20px',
                    paddigLeft: '20px',
                    paddigRight: '20px',
                    borderBottom: 'none',
                }}
                title={gettext.getString('Устройство')}
                closeModal={closeModalPopup}
                disabled={save || loading ? true : false}
            />
            {loading ? (
                <img className='modal-loader' src={loader} />
            ) : (
                <div className='device-modal'>
                    <div className='device-modal_block'>
                        <label
                            className={
                                (name.isDirty && name.isEmpty) || (formEmptyError && !name.value.length) ? 'device-modal_label text-error' : 'device-modal_label'
                            }
                            htmlFor='name'
                        >
                            {gettext.getString('Название')}
                        </label>
                        <input
                            onChange={(e) => name.onChange(e.target.value)}
                            onBlur={(e) => name.onBlur(e)}
                            value={name.value}
                            id='name'
                            className={
                                (name.isDirty && name.isEmpty) || (formEmptyError && !name.value.length) ? 'device-modal_input input-error' : 'device-modal_input'
                            }
                            type='text'
                            data-testid='wifi-inputName'
                            disabled={attack ? true : false}
                        />
                    </div>
                    <div className='device-modal_block'>
                        <label
                            className={
                                (ip.isDirty && ip.isEmpty) || (ip.isDirty && ip.ipError) || (formEmptyError && !ip.value.length)
                                    ? 'device-modal_label text-error'
                                    : 'device-modal_label'
                            }
                            htmlFor='ip'
                        >
                            {gettext.getString('IP')}
                        </label>
                        <input
                            onChange={(e) => ip.onChange(e.target.value)}
                            onBlur={(e) => ip.onBlur(e)}
                            value={ip.value}
                            id='ip'
                            className={
                                (ip.isDirty && ip.isEmpty) || (ip.isDirty && ip.ipError) || (formEmptyError && !ip.value.length)
                                    ? 'device-modal_input input-error'
                                    : 'device-modal_input'
                            }
                            type='text'
                            data-testid='wifi-inputIp'
                            disabled={attack ? true : false}
                        />
                        <span
                            className={
                                ip.isDirty && ip.ipError ? 'device-modal_ip-error-show' : 'device-modal_ip-error-none'
                            }
                        >
                            {gettext.getString('В IP допускаются символы: 0.0.0.0 – 255.255.255.255')}
                        </span>
                    </div>
                    <div className='device-modal_block'>
                        <label
                            className={
                                (ssid.isDirty && ssid.isEmpty) || (formEmptyError && !ssid.value.length) ? 'device-modal_label text-error' : 'device-modal_label'
                            }
                            htmlFor='ssid'
                        >
                            {gettext.getString('Имя сети')}: SSID
                        </label>
                        <input
                            onChange={(e) => ssid.onChange(e.target.value)}
                            onBlur={(e) => ssid.onBlur(e)}
                            value={ssid.value}
                            id='ssid'
                            className={
                                (ssid.isDirty && ssid.isEmpty) || (formEmptyError && !ssid.value.length)
                                    ? 'device-modal_input mb input-error'
                                    : 'device-modal_input mb'
                            }
                            data-testid='wifi-inputSsid'
                            disabled={attack ? true : false}
                        />
                        <span>
                            {gettext.getString('Сотрудник увидит название в списке доступных беспроводных сетей')}
                        </span>
                    </div>
                    <div className='device-modal_bottom'>
                        <div className='device-modal_bottom-wrapper'>
                            <div className='device-modal_bottom-block'>
                                <label
                                    className={
                                        (login.isDirty && login.isEmpty) || (formEmptyError && !login.value.length)
                                            ? 'device-modal_label text-error'
                                            : 'device-modal_label'
                                    }
                                    htmlFor='login'
                                >
                                    {gettext.getString('Логин')}
                                </label>
                                <input
                                    onChange={(e) => login.onChange(e.target.value)}
                                    onBlur={(e) => login.onBlur(e)}
                                    value={login.value}
                                    id='login'
                                    className={
                                        (login.isDirty && login.isEmpty) || (formEmptyError && !login.value.length)
                                            ? 'device-modal_login input-error'
                                            : 'device-modal_login'
                                    }
                                    type='text'
                                    data-testid='wifi-inputLogin'
                                    disabled={attack ? true : false}
                                />
                            </div>
                            <div className='device-modal_bottom-block'>
                                <label
                                    className={
                                        (!id && password.isDirty && password.isEmpty) || (formEmptyError && !password.value.length)
                                            ? 'device-modal_label text-error'
                                            : 'device-modal_label'
                                    }
                                    htmlFor='password'
                                >
                                    {gettext.getString('Пароль')}
                                </label>
                                <input
                                    onChange={(e) => password.onChange(e.target.value)}
                                    onBlur={(e) => password.onBlur(e)}
                                    value={password.value}
                                    id='password'
                                    className={
                                        (!id && password.isDirty && password.isEmpty) || (formEmptyError && !password.value.length)
                                            ? 'device-modal_pass input-error'
                                            : 'device-modal_pass'
                                    }
                                    type='text'
                                    data-testid='wifi-inputPassword'
                                    disabled={attack ? true : false}
                                />
                            </div>
                        </div>
                        <span>{gettext.getString('Совпадают с настройками роутера')}</span>
                    </div>
                </div>
            )}
            <div className='device-modal_process'>
                {formError.length !== 0 ? <FaIcon type={'error'} text={formError} /> : ''}
                {save ? <FaIcon type={'pending'} text={gettext.getString('Сохранение...')} /> : ''}
                <ModalFooter
                    stylePosition={{ justifyContent: 'flex-end' }}
                    styleBtnOk={{ backgroundColor: '#37397F' }}
                    titleBtnOk={gettext.getString('Сохранить')}
                    titleBtnClose={gettext.getString('Отменить')}
                    closeModal={closeModalPopup}
                    disabledCancel={loading || save ? true : false}
                    disabled={
                        loading ||
                            save ||
                            (!id && password.isDirty && password.isEmpty) ||
                            (login.isDirty && login.isEmpty) ||
                            (ssid.isDirty && ssid.isEmpty) ||
                            (ip.isDirty && ip.isEmpty) ||
                            (ip.isDirty && ip.ipError) ||
                            (name.isDirty && name.isEmpty) ||
                            attack
                            ? true
                            : false
                    }
                    onEnter={saveSettings}
                />
            </div>
        </>
    );
}

export default WifiDeviceModal;
