import style from './style.modules.css';

class PageFooterController {
    constructor() {
        this.supportEmailSubject = '';
        this.style = style;
        this.dateNow = new Date;
    }

    $onInit() {
        this.supportEmailAddress = `mailto:${this.supportEmail}?subject=${this.supportEmailSubject}`;
    }

    $onDestroy() {
    }
}

PageFooterController.$inject = [];

export {
    PageFooterController,
};
