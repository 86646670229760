class ReactModalController {
    constructor(params, $ngRedux, gettextCatalog) {
        this.params = params;
        this.gettextCatalog = gettextCatalog;
        this.redux = $ngRedux;
    }

    $onInit = () => {
        if (!this.params.props) {
            this.params.props = {};
        }
        this.params.props.onModalClose = this.$close;
    }
}

ReactModalController.$inject = [
    'params',
    '$ngRedux',
    'gettextCatalog'
];

export {
    ReactModalController,
};
