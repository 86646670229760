import style from './style.modules.css';
import { fetchAttackHistory } from '../../../toolkit/think';
import { attackHistoryDefault } from '../../../toolkit/actions';
import { getAttackHistoryDashboard, hasAccess } from '../../../reselect';
import moment from 'moment';
import { getDashGraphItem } from '../../../helpers/graphs';

class DashboardAttack {
    constructor(gettextCatalog, $ngRedux, $cookies) {
        this.gettextCatalog = gettextCatalog;
        this.style = style;
        this.fields = ['date', 'noAttack', 'openLink', 'openAttachment', 'openAttachmentOrFileFromLink', 'openMail', 'proofAttack', 'submitForm'];
        this.moment = moment;
        this.langCode = $cookies.get('langCode');
        this.moment.updateLocale('ru', {
            longDateFormat : {
                LL: 'DD MMMM YYYY года',
            }
        });
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { fetchAttackHistory, attackHistoryDefault })(this);
        this.strings = {
            attackNew: gettextCatalog.getString('не был проверен')
        };
    }

    mapStateToThis = (state) => {
        const that = this;
        return {
            targetInsystem: state.auth.auth.targetCount,
            myDataSource: getAttackHistoryDashboard(state, { scope: that, gettextCatalog: that.gettextCatalog, apply: 'attack', tooltext: (data) => {
                let string = '';
                for (let key in data) {
                    string += ` ${key}:${data[key]}`;
                }
                return string;
            } }),
            attackLoad: state.attackHistory.load,
            attackHistory: state.attackHistory.attackHistory,
            employeesAddAccess: hasAccess(state, { sectionId: 1, rightName: 'target_save' }),
            accessDashboardAttack: hasAccess(state, { sectionId: 1, rightName: 'target_attack' }),
        };
    }

    $onInit() {
        const that = this;
        this.fetchAttackHistory();
        // eslint-disable-next-line
        FusionCharts.ready((FusionCharts) => {
            // eslint-disable-next-line
            FusionCharts.addEventListener('chartRollOver', that.setEvent);
            // eslint-disable-next-line
            FusionCharts.addEventListener('chartMouseMove', that.setEvent);
        });
    }

    setEvent = (event) => {
        const chart = event.sender;
        if (chart.args.type !== 'scrollline2d') {
            return;
        }
        let children = event.data.container.children[0].children[2] ? event.data.container.children[0].children[2].children : null;
        let data = event.data.container.children[0].children[2] ? event.data.container.children[0].children[2].innerText : null;
        if (!data || children.length === 2) {
            return;
        }
        let item = getDashGraphItem(this.fields, data);
        let title = chart.ref.querySelector('.fc__tooltip');
        let div = document.createElement('section');
        div.classList.add('fc__tooltip_label');
        let ul = document.createElement('ul');
        ul.classList.add('fc__tooltip_list-wrapper');
        let li = document.createElement('li');
        let cellNum = document.createElement('span');
        let cellText = document.createElement('span');
        cellNum.classList.add('fc__tooltip_num');
        cellText.classList.add('fc__tooltip_text');
        li.classList.add('fc__tooltip_item');
        let persent = '0%';
        let peopleComplete = (+item.openLink + +item.openAttachment + item.openAttachmentOrFileFromLink + +item.openMail + +item.proofAttack + +item.submitForm);
        let peopleInSystem = (+peopleComplete + +item.noAttack);
        if (+peopleComplete) {
            persent = +peopleComplete < +peopleInSystem ? +peopleComplete*100/+peopleInSystem : 100;
            if (persent < 1 && persent > 0) {
                persent = '< 1%';
            } else if (persent > 99 && persent < 100) {
                persent = '> 99%';
            } else {
                persent = Math.round(persent) + '%';
            }
        }
        cellNum.insertAdjacentText('afterbegin', persent);
        cellText.insertAdjacentText('afterbegin', this.gettextCatalog.getString('{{count}} из {{total}} сотрудников участвовали хотя бы в одной имитированной атаке.',
            {
                count: (+peopleComplete).toLocaleString(),
                total: +peopleComplete < +peopleInSystem ? (+peopleInSystem).toLocaleString() : (+peopleComplete).toLocaleString()
            }));
        li.appendChild(cellNum);
        li.appendChild(cellText);
        ul.appendChild(li);
        div.insertAdjacentText('afterbegin', this.moment(+item.date).locale(this.langCode).format('LL'));
        setTimeout(() => {
            while (title.firstChild) {
                title.removeChild(title.firstChild);
            }
            title.appendChild(div);
            title.appendChild(ul);
        }, 1);
    }

    $onDestroy() {
        const that = this;
        this.attackHistoryDefault();
        this.unsubscribe();
        // eslint-disable-next-line
        FusionCharts.removeEventListener('chartMouseMove', that.setEvent);
        // eslint-disable-next-line
        FusionCharts.removeEventListener('chartRollOver', that.setEvent);
    }
}

DashboardAttack.$inject = [
    'gettextCatalog',
    '$ngRedux',
    '$cookies'
];

export {
    DashboardAttack,
};
