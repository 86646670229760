import React from 'react';
import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GettextContext, InjectorContext } from 'rootReact/context';
import { setOneTimeMessage, setAuthProps } from '../../../toolkit/actions';
import {
    TwoFactorDisableModal,
    TwoFactorPassModal,
    TwoFactorQRModal
} from 'root/assets/webpack/src/js/components/twoFactorAuthModal';
import { setDaemonsMessage } from 'root/assets/webpack/src/js/helpers/setDaemons';

function Settings2Fa({ licenseIsValid }) {
    const gettext = useContext(GettextContext);
    const injector = useContext(InjectorContext);
    const dispatch = useDispatch();
    const is2FAEnabled = useSelector(state => state.auth.auth.usesSecondFactor);

    const open2FactorModal = async (enabled) => {
        if (is2FAEnabled === enabled) return;
        let pass = injector.instantiate(TwoFactorPassModal);
        let disable = injector.instantiate(TwoFactorDisableModal);
        let code = injector.instantiate(TwoFactorQRModal);
        let next = true;
        if (!enabled) {
            next = await disable.open();
        }
        if (!next) return;
        next = await pass.open(enabled);
        if (!next) return;
        if (!enabled) {
            dispatch(setOneTimeMessage(setDaemonsMessage(gettext.getString('Двухфакторная аутентификация выключена'))));
            dispatch(setAuthProps({ usesSecondFactor: 0 }));
        }
        if (enabled) {
            next = await code.open(next);
            if (next) {
                dispatch(setOneTimeMessage(setDaemonsMessage(gettext.getString('Двухфакторная аутентификация включена'))));
                dispatch(setAuthProps({ usesSecondFactor: 1 }));
            }
        }
    };

    return (
        <div>
            <div className={'settings_item'}>
                <span style={{ whiteSpace: 'nowrap' }}>{gettext.getString('Двухфакторная аутентификация:')}</span>
                <div className={'react-button'}>
                    <button className={`${!licenseIsValid ? 'react-button__disabled' : ''} react-button_element react-button_element__sm react-button_element__left ${is2FAEnabled ? 'react-button_element__active' : ''}`}
                        disabled={!licenseIsValid}
                        onClick={() => {open2FactorModal(1);}}
                    >{gettext.getString('включена')}</button>
                    <button className={`${!licenseIsValid ? 'react-button__disabled' : ''} react-button_element react-button_element__sm react-button_element__right ${!is2FAEnabled ? 'react-button_element__active' : ''}`}
                        disabled={!licenseIsValid}
                        onClick={() => {open2FactorModal(0);}}
                    >{gettext.getString('выключена')}</button>
                </div>
            </div>
        </div>
    );
}

export default Settings2Fa;
