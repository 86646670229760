import { DEFAULT_PER_PAGE_SIZE, USB_SERVICE } from '../../const';
import style from './style.modules.css';

const ENTER = 13;

class UsbAttackTargetsController {
    constructor(UsbService) {
        this.UsbService = UsbService;
        this.sortBy = 'date';
        this.sortReverse = 0;
        this.firstLoading = true;
        this.style = style;
        this.totalCount = null;
        this.updatingNow = {};
    }

    $onInit() {
        this.targetsList = this.UsbService.targets(this.attack.id);
        this.loadTargets();
    }

    loadTargets = (reLoad) => {
        this.targetsError = null;
        this.targetsLoading = true;
        const { sortBy, sortReverse } = this;
        this.targetsList.$filters = {
            sortBy,
            sortReverse,
        };
        this.targetsList.$page = 1;
        this.targetsList.$load(reLoad ? this.targetsList.$data.length : DEFAULT_PER_PAGE_SIZE).then((data) => {
            this.targetsList = data;
            this.totalCount = data.$meta.totalCount;
            this.firstLoading = false;
            this.targetsLoading = false;
        }, () => {
            this.targetsLoading = false;
            this.targetsError = true;
        });
    };

    loadMoreTargets = (all) => {
        if (this.targetsList.$data.length < this.totalCount) {
            this.targetsMoreLoading = true;
            this.targetsMoreError = false;
            if (all) {
                this.targetsList.$page = 1;
                this.targetsList.$load(this.targetsList.$meta.totalCount).then((data) => {
                    this.targetsList = data;
                    this.targetsMoreLoading = false;
                }, () => {
                    this.targetsMoreLoading = false;
                    this.targetsMoreError = true;
                });
            } else {
                const prevData = this.targetsList.$data;
                this.targetsList.$nextPage().then((data) => {
                    this.targetsList.$meta = data.$meta;
                    this.targetsList.$data = [...prevData, ...data.$data];
                    this.targetsMoreLoading = false;
                }, () => {
                    this.targetsMoreLoading = false;
                    this.targetsMoreError = true;
                });
            }
        }
    };

    setSortBy = (sortBy) => {
        if (this.sortBy !== sortBy) {
            this.sortBy = sortBy;
        } else {
            this.sortReverse = !this.sortReverse;
        }
        this.loadTargets();
    };

    updateIpName = (id, el) => {
        this.UsbService.updateName({ 'id': id, 'ip_name': el.value }).$promise.then(() => {
            this.updatingNow = {};
            this.loadTargets(true);
        }, () => {
            el.focus();
        });
    };

    setUpdating = id => {
        let el = window.document.getElementById('input-' + id);
        this.updatingNow[id] = true;
        el.addEventListener('keydown', event => {
            if (event.keyCode === ENTER) {
                this.updateIpName(id, el);
            }
        });
        el.addEventListener('blur', () => this.updateIpName(id, el));
    };
}

UsbAttackTargetsController.$inject = [
    USB_SERVICE,
];

export {
    UsbAttackTargetsController,
};
