import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';

const initialState = {
    loading: false,
    itemLoad: null,
    notification: [],
    error: false,
    errorText: '',
};

export const fetchListNotification = createAsyncThunk('notificationList/status', async () => {
    const notificationApi = FetchApi('/notifications');
    let notifications = await notificationApi.get();
    const customApi = FetchApi('/custom-notifications');
    let customNotifications = await customApi.get();
    customNotifications = customNotifications.length ? customNotifications.map(item => {
        item.custom = true;
        return item;
    }) : [];
    return [...notifications, ...customNotifications];
});

export const deleteNotification = createAsyncThunk('notificationList/delete',
    async ({id, uuid}, thunkApi) => {
        const api = FetchApi(uuid ? '/custom-notifications' : '/notifications');
        let response = await api.delete(id || uuid);
        if (response.isError) {
            return thunkApi.rejectWithValue(response.err[0].message);
        }
        return id || uuid;
});

export const toDefaultOneNotify = createAsyncThunk(
    'notificationList/toDefault',
    async ({id, uuid}) => {
        const api = FetchApi(uuid ? `/custom-notifications/rollback/${uuid}` : `/notifications/rollback/${id}`);
        let response = await api.post();
        return response;
    });

const notificationListSlice = createSlice({
    name: 'notificationList',
    initialState,
    reducers: {
        setListNotification(state, { payload }) {
            state.notification = payload;
        },
        setOneNotification(state, { payload }) {
            state.notification.push(payload);
        },
        updateOneNotification(state, { payload }) {
            state.notification = state.notification.map((item) => {
                if (item.uuid !== payload.uuid) return item;
                return payload;
            });
        },
    },
    extraReducers: {
        [fetchListNotification.pending]: (state) => {
            state = initialState;
        },
        [fetchListNotification.fulfilled]: (state, { payload }) => {
            state.loading = true;
            state.error = false;
            (state.errorText = ''), (state.notification = payload);
        },
        [fetchListNotification.rejected]: (state, { payload }) => {
            state.loading = true;
            state.error = true;
            state.errorText = payload;
        },
        [deleteNotification.pending]: (state) => {
            state = state;
        },
        [deleteNotification.fulfilled]: (state, { payload }) => {
            state.error = false;
            state.notification = state.notification.filter(item => item.uuid !== payload);
        },
        [deleteNotification.rejected]: (state,  payload) => {
            state.loading = true;
            state.error = true;
            state.errorText = payload;
        },
        [toDefaultOneNotify.pending]: (state) => {
            state = state;
        },
        [toDefaultOneNotify.fulfilled]: (state, { payload }) => {
            state.error = false;
            state.notification = state.notification.filter(item => item.uuid !== payload.uuid);
        },
        [toDefaultOneNotify.rejected]: (state, { payload }) => {
            state.loading = true;
            state.error = true;
            state.errorText = payload;
        },
    },
});

export const { setListNotification, setOneNotification, updateOneNotification, updateDefault } = notificationListSlice.actions;
export default notificationListSlice.reducer;
