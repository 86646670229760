import icoRoundTemplate from './view.html';

import { IcoRoundController } from './controller.js';

const IcoRoundComponent = {
    template: icoRoundTemplate,
    controller: IcoRoundController,
    controllerAs: '$',
    scope: true,
    bindings: {
        targetStatus: '<',
        reportMail: '<',
        hasVuln: '<',
    },
};

export {
    IcoRoundComponent,
};
