import React from 'react';

function StatusIcon({ status, reportMail, hasVuln }) {

    const getStyle = () => {
        let style = '';
        switch (status) {
            case 0:
            case 12:
            case -16:
                style = 'new-target-ico';
                break;
            case 1:
                style = 'succsess-ico';
                break;
            case 2:
                style = 'warning-ico';
                break;
            case 4:
                style = 'learning-ico learning-proccess';
                break;
            case 5:
                style = 'learning-ico complete-learning';
                break;
            case 7:
                style = 'learning-ico miss-learning';
                break;
            case -4:
                style = 'learning-ico no-learning';
                break;
            case 9:
                style += ' open-file';
                break;
        }
        if (reportMail) {
            style += ' report-mail-ico';
        }
        if (hasVuln) {
            style += ' vuln-ico';
        }
        if (status === 3 || status === 10 || status === 15) {
            style += ' danger-ico';
        }
        if (status === 8 || status === 11) {
            style += ' time-out-ico';
        }
        return style;
    };

    return (
        <div className={`schema-icon ${getStyle()}`} />);
}

export default StatusIcon;
