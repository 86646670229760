import campaignTemplate from './view.html';

import { CampaignController } from './controller';

const CampaignComponent = {
    template: campaignTemplate,
    controller: CampaignController,
    scope: true,
    bindings: {
        campaign: '=',
        campaignsCtrl: '=',
        licenseValid: '=',
        filterCampaign: '=',
        isReport: '<',
    },
};

export {
    CampaignComponent,
};
