
class UsersService {
    constructor (YiiModel, $resource) {
        this.resource = $resource(`${window.config.SYSTEM_URL}${window.config.API_URL}/users`);
        this.YiiModel = YiiModel;
        this.all = YiiModel.all('users?fullList=1');
        this.one = (id) => {
            return YiiModel.one('users').$find(id);
        };
        this.loadAdministrators = () => {
            return this.resource.query({ mode: 'select' });
        };
    }
}

export {
    UsersService
};
