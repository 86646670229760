import style from '../../styles/layout.modules.css';
import { hasAccess, getSystemAlert } from '../reselect';
import headerPage from '../React/containers/HeaderPage';
import footerPage from '../React/containers/FooterPage';

class LayoutController {
    constructor($location, $ngRedux, gettextCatalog) {
        this.$location = $location;
        this.info = {};
        this.style = style;
        this.headerPage = headerPage;
        this.footerPage = footerPage;
        this.gettextCatalog = gettextCatalog;
        this.menuOpen = false;
        this.$ngRedux = $ngRedux;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis)(this);
        window.ctrl = this;
    }

    mapStateToThis(state) {
        return {
            accessDashboard: hasAccess(state, { sectionId: 0, rightName: 'view' }),
            systemAlerts: getSystemAlert(state)
        };
    }

    onMenuOpen = () => {
        this.menuOpen = !this.menuOpen;
    };

    isDataPickerClass = (target) => {
        let isContain = false;
        let classList = target.classList;
        classList.forEach(item => {
            if (item.indexOf('datepicker') > -1) {
                isContain = true;
            }
        });
        return isContain;
    }

    closeMenu = (event) => {
        if (!this.menuOpen) {
            return;
        }
        let target = event.target;
        let close = true;
        while (target && !target.classList.contains('js-layout')) {
            if (target.classList.contains('js-menu') || this.isDataPickerClass(target)) {
                close = false;
                break;
            }
            target = target.parentNode;
        }
        if (close) {
            this.menuOpen = false;
        }
    };

    go = (path) => {
        this.$location.path(path);
        this.$location.hash('');
    };

}

LayoutController.$inject = [
    '$location',
    '$ngRedux',
    'gettextCatalog'
];

export {
    LayoutController,
};
