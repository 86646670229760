import modalTemplate from './view.html';
import { UsbAttackTokenModalController } from './controller';

import { USB_ATTACK_TOKEN_MODAL_CONTROLLER } from '../../const';

class UsbAttackTokenModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    $onInit = () => {
        this.$uibModal;
    };

    open = (attack = null) => {
        this.modalInstance = this.$uibModal.open({
            template: modalTemplate,
            bindToController: true,
            controller: USB_ATTACK_TOKEN_MODAL_CONTROLLER,
            controllerAs: '$',
            size: 'nm',
            resolve: {
                attack: attack,
            },
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}

export {
    UsbAttackTokenModal,
    UsbAttackTokenModalController,
};
