import React, { useState, useEffect } from 'react';
import FetchApi from '../../REST';
import SaveFilePopup from '../SaveFilePopup';

function SaveFile({ files, disabled }) {
    const [downloadFiles] = useState(files);
    const [activeFile, setActiveFile] = useState(files[0]);
    const [idClass] = useState(Math.floor(Math.random() * 100));
    const [popupOpen, setPopupOpen] = useState(false);
    const [tooltip, setTooltip] = useState(true);

    const fileSave = () => {
        if(disabled) return;
        let fileName = '';
        const reg = /\[.*?]/g;
        let name = (fileName = activeFile[2]);
        let mask = name.match(reg)[0];
        if (mask === '[data]') {
            let dataNow = new Date().toISOString().split('T')[0];
            fileName = name.replace('[data]', '-' + dataNow);
        }
        const fetchApi = FetchApi(activeFile[1]);
        fetchApi.download(activeFile[1], fileName);
    };

    const searchPopup = (e) => {
        let target = e.target;
        let parent = target.parentNode || null;
        if (!target || !parent) {
            setPopupOpen(false);
        }
        if (target.classList.contains(idClass.toString()) || parent && parent.classList.contains(idClass.toString())) {
            return;
        }
        setPopupOpen(false);
    };

    const searchTooltip = (target) => {
        if (target === document.bodyElem) return setTooltip(() => true);
        if (!target || !target.classList) return setTooltip(() => true);
        if (target.classList.contains('popup-active') ||
            target.classList.contains('file-save_link') ||
            target.classList.contains('react-datepicker__navigation') ||
            target.classList.contains('react-datepicker')) return;
        searchTooltip(target.parentNode);
    };

    useEffect(() => {
        if (disabled) return;
        let body = document.body;
        body.addEventListener('click', searchPopup);
        return () => body.removeEventListener('click', searchPopup);
    }, []);

    useEffect(() => {
        if (disabled) return;
        const start = (e) => {
            searchTooltip(e.target)
        }
        let body = document.body;
        body.addEventListener('click', start);
        return () => body.removeEventListener('click', start);
    }, []);

    const setFilesItems = () => {
        return downloadFiles.map((item) => {
            return (
                <li
                    className={'file-save_list-item'}
                    key={item[0]}
                    onClick={() => {
                        setActiveFile(item);
                        setPopupOpen(false);
                    }}
                >
                    {item[0]}
                </li>
            );
        });
    };

    const openTooltip = () => {
        if(disabled) return;
        setTooltip((prev) => !prev);
    };

    const openPopup = () => {
        if (disabled) return;
        setPopupOpen(!popupOpen);
    };

    return (
        <div className={'file-save'}>
            <SaveFilePopup visible={tooltip} fileSave={fileSave} downloadFile={files[0]} />
            <a
                onClick={!disabled && (activeFile[0].includes('журнал действий') || activeFile[0].includes('журнал синхронизаций')) ? openTooltip : fileSave}
                className={`file-save_link ${disabled ? 'nolink' : ''}`}
            >
                {activeFile[0]}
            </a>
            {downloadFiles.length > 1 && <span onClick={openPopup} className={`caret ${idClass}`} />}
            {popupOpen && <ul className={`file-save_items ${idClass}`}>{setFilesItems()}</ul>}
        </div>
    );
}

export default SaveFile;
