export function useInputStyle (style ,touched, focused, error) {
    const getClass = () => {
        let cssClass = [...style];
        if (!touched) {
            cssClass.push('react-input_field__untouched');
        }
        if (!error && focused) {
            cssClass.push('react-input_field__focused');
        }
        if (error) {
            cssClass.push('react-input_field__error');
        }
        return cssClass.join(' ');
    };
    return getClass();
}
