export const PRELOADER_SHOW_TIMEOUT = 500;
export const DEFAULT_PER_PAGE_SIZE = 10;

export const DASHBOARD_RELOAD_TIME = 5000;

export const AUTH_SERVICE = 'AUTH_SERVICE';
export const CAMPAIGNS_SERVICE = 'CAMPAIGNS_SERVICE';
export const DASHBOARD_SERVICE = 'DASHBOARD_SERVICE';
export const DEPARTMENTS_SERVICE = 'DEPARTMENTS_SERVICE';
export const STATUS_SERVICE = 'STATUS_SERVICE';
export const TARGETS_SERVICE = 'TARGETS_SERVICE';
export const MARKS_SERVICE = 'MARKS_SERVICE';
export const TEMPLATES_SERVICE = 'TEMPLATES_SERVICE';
export const SETTINGS_SERVICE = 'SETTINGS_SERVICE';
export const LEARNING_SERVICE = 'LEARNING_SERVICE';
export const POLICY_SERVICE = 'POLICY_SERVICE';
export const LDAP_PROFILES_SERVICE = 'LDAP_PROFILES_SERVICE';
export const BOSS_KADROVIK_PROFILES_SERVICE = 'BOSS_KADROVIK_PROFILES_SERVICE';
export const SCHEDULER_TASKS_SERVICE = 'SCHEDULER_TASKS_SERVICE';
export const SENDER_DOMAINS_SERVICE = 'SENDER_DOMAINS_SERVICE';
export const CAMPAIGN_TARGETS_SERVICE = 'CAMPAIGN_TARGETS_SERVICE';
export const ATTACHMENTS_SERVICE = 'ATTACHMENTS_SERVICE';
export const PHISHING_PAGES_SERVICE = 'PHISHING_PAGES_SERVICE';
export const FINAL_PAGES_SERVICE = 'FINAL_PAGES_SERVICE';
export const REPORTS_SERVICE = 'REPORTS_SERVICE';
export const RISKS_SERVICE = 'RISKS_SERVICE';
export const USB_SERVICE = 'USB_SERVICE';
export const NOTIFICATIONS_SERVICE = 'NOTIFICATIONS_SERVICE';
export const PATCHERS_SERVICE = 'PATCHERS_SERVICE';
export const VARIABLES_SERVICE = 'VARIABLES_SERVICE';
export const USERS_SERVICE = 'USERS_SERVICE';
export const TIME_ZONES_SERVICE = 'TIME_ZONES_SERVICE';
export const COURSES_SERVICE = 'COURSES_SERVICE';
export const ROLES_SERVICE = 'ROLES_SERVICE';
export const MAIL_SERVERS_SERVICE = 'MAIL_SERVERS_SERVICE';
export const BOSS_KADROVIK_SERVICE = 'BOSS_KADROVIK_SERVICE';
export const LDAP_IMPORTS_SERVICE = 'LDAP_IMPORTS_SERVICE';
export const GROUPS_SERVICE = 'GROUPS_SERVICE';
export const ENTITY_SERVICE = 'ENTITY_SERVICE';
export const DIRECTORIES_SERVICE = 'DIRECTORIES_SERVICE';
export const CERT_MODAL = 'CERT_MODAL';

export const DEPARTMENT_MODAL_CONTROLLER = 'DEPARTMENT_MODAL_CONTROLLER';
export const EMPLOYEES_MODAL_CONTROLLER = 'EMPLOYEES_MODAL_CONTROLLER';
export const CAMPAIGN_MODAL_CONTROLLER = 'CAMPAIGN_MODAL_CONTROLLER';
export const CAMPAIGN_DELETE_MODAL_CONTROLLER = 'CAMPAIGN_DELETE_MODAL_CONTROLLER';
export const CAMPAIGN_SCHEDULE_MODAL_CONTROLLER = 'CAMPAIGN_SCHEDULE_MODAL_CONTROLLER';
export const CAMPAIGN_START_MODAL_CONTROLLER = 'CAMPAIGN_START_MODAL_CONTROLLER';
export const USB_ATTACK_MODAL_CONTROLLER = 'USB_ATTACK_MODAL_CONTROLLER';
export const USB_ATTACK_DELETE_MODAL_CONTROLLER = 'USB_ATTACK_DELETE_MODAL_CONTROLLER';
export const USB_ATTACK_TOKEN_MODAL_CONTROLLER = 'USB_ATTACK_TOKEN_MODAL_CONTROLLER';
export const REPORT_MODAL_CONTROLLER = 'REPORT_MODAL_CONTROLLER';
export const REPORT_TARGETS_MODAL_CONTROLLER = 'REPORT_TARGETS_MODAL_CONTROLLER';
export const LDAP_PROFILE_MODAL_CONTROLLER = 'LDAP_PROFILE_MODAL_CONTROLLER';
export const SCHEDULER_TASK_MODAL_CONTROLLER = 'SCHEDULER_TASK_MODAL_CONTROLLER';
export const EDIT_SELECTED_MODAL_CONTROLLER = 'EDIT_SELECTED_MODAL_CONTROLLER';
export const EMPLOYEES_DELETE_MODAL_CONTROLLER = 'EMPLOYEES_DELETE_MODAL_CONTROLLER';
export const NOTIFICATION_MODAL_CONTROLLER = 'NOTIFICATION_MODAL_CONTROLLER';
export const ERROR_MODAL_CONTROLLER = 'ERROR_MODAL_CONTROLLER';
export const VARIABLE_MODAL_CONTROLLER = 'VARIABLE_MODAL_CONTROLLER';
export const USER_MODAL_CONTROLLER = 'USER_MODAL_CONTROLLER';
export const USER_POLICY_MODAL_CONTROLLER = 'USER_POLICY_MODAL_CONTROLLER';
export const MAIL_SERVER_MODAL_CONTROLLER = 'MAIL_SERVER_MODAL_CONTROLLER';
export const MAIL_SERVER_DETAILS_MODAL_CONTROLLER = 'MAIL_SERVER_DETAILS_MODAL_CONTROLLER';
export const IMAGE_MODAL_CONTROLLER = 'IMAGE_MODAL_CONTROLLER';
export const COURSES_MODAL_CONTROLLER = 'COURSES_MODAL_CONTROLLER';
export const COURSE_MODAL_CONTROLLER = 'COURSE_MODAL_CONTROLLER';
export const ROLE_MODAL_CONTROLLER = 'ROLE_MODAL_CONTROLLER';
export const USER_AUTH_SETTINGS_MODAL_CONTROLLER = 'USER_AUTH_SETTINGS_MODAL_CONTROLLER';
export const BOSS_KADROVIK_PROFILE_MODAL_CONTROLLER = 'BOSS_KADROVIK_PROFILE_MODAL_CONTROLLER';
export const LOGS_MODAL_CONTROLLER = 'LOGS_MODAL_CONTROLLER';
export const GROUP_MODAL_CONTROLLER = 'GROUP_MODAL_CONTROLLER';
export const BUlK_DELETE_MODAL = 'BUlK_DELETE_MODAL';

export const EMPLOYEE_ADD_CONTROLLER = 'EMPLOYEE_ADD_CONTROLLER';
export const EMPLOYEE_IMPORT_FILE_CONTROLLER = 'EMPLOYEE_IMPORT_FILE_CONTROLLER';
export const EMPLOYEE_IMPORT_LDAP_CONTROLLER = 'EMPLOYEE_IMPORT_LDAP_CONTROLLER';

export const SEPARATOR = '+$+';

export const LOGIN_CONTROLLER = 'LOGIN_CONTROLLER';

export const DEFAULT_COURSES = {};
export const DEFAULT_REPORTS = {
    viewMode: 0,
};

export const TWO_FACTOR_PASS_MODAL = 'TWO_FACTOR_PASS_MODAL';

export const CREATE_ATTACK_DIRECTORIES = [
    'templates',
    'attachments',
    'patchers',
    'phishingPages',
    'finalPages',
    'senderDomains',
    'vectors'
];

export const CREATE_ATTACK_FIELDS = [
    'subject',
    'name',
    'fromName',
    'from',
    'domain',
    'text',
    'attachment',
    'attachmentFakeName',
    'phishingPage',
    'finalPage',
    'domainInLinks',
    'sendAttachment',
    'showRedirectPage',
    'checkLdapPassword',
    'vectors',
    'sources',
    'attachmentActions',
    'qrCode'
];

export const CREATE_ATTACK_BOOL_FIELDS = [
    'domainInLinks',
    'sendAttachment'
];

export const DEFAULT_RISKS = {
    viewMode: 0,
};
export const DEFAULT_CAMPAIGNS = {
    viewMode: 0,
};
export const DEFAULT_DEPARTMENTS = {
    sortBy: '',
    viewMode: 0,
    dataMode: 0,
    sortReverse: false,
};

export const EMPTY_SELECTED_DATA = {
    search: [''],
    all: 0,
    departments: [],
    groups: [],
    departmentsUsed: [],
    groupsUsed: [],
    targets: {},
    unselectedDepartments: [],
    unselectedGroups: [],
    unselectedTargets: {},
    selectedCount: {
        departments: 0,
        groups: 0,
        targets: 0,
        notSelectedTargets: 0,
        allSearchTargets: 0,
    },
};

export const EMPTY_SELECTED_PREV_DATA = {
    search: [''],
    departments: [],
    groups: [],
    departmentsUsed: [],
    groupsUsed: [],
    targets: {},
    unselectedDepartments: [],
    unselectedGroups: [],
    unselectedTargets: {},
    selectedCount: {
        departments: 0,
        targets: 0,
        groups: 0
    },
};

export const EMPTY_LDAP_SELECTED_DATA = {
    search: '',
    targets: [],
    selectedCount: {
        targets: 0,
    },
};

export const DOWNLOAD_TYPE = {
    1: 1,
    2: 'select',
    3: 'education',
    4: 'general',
    5: 'log',
    6: 'anonym',
    7: 'risks',
    8: 'attack',
    9: 'department',
    10: 'feedback_outlook',
    11: 'wifi'
};

export const NOTIFICATIONS_TYPE = [
    {
        type: 'CoursesMissMany',
        value: 'Не прошел несколько курсов вовремя'
    },
    {
        type: 'CoursesMissOne',
        value: 'Не прошел курс вовремя'
    },
    {
        type: 'Cert',
        value: 'Сертификат об окончании курса'
    },
    {
        type: 'CoursesNewOne',
        value: 'Назначен на курс'
    },
    {
        type: 'CoursesNewMany',
        value: 'Назначен на несколько курсов'
    },
    {
        type: 'AuthLink',
        value: 'Ссылка для входа'
    }
];

export const NODE = 'node';
export const LIST = 'list';

export const defaultDashboardParams = {
    targets: 0,
    systemAlerts: [],
    onCourse: 0,
    onCoursePercent: 0,
    missCourse: 0,
    missCoursePercent: 0,
    completeCourse: 0,
    completeCoursePercent: 0,
    noCourse: 0,
    noCoursePercent: 100,
    campaigns: 0,
    noCampaign: 0,
    noCampaignPercent: 100,
    completeCampaign: 0,
    completeCampaignPercent: 0,
    openCampaign: 0,
    openCampaignPercent: 0,
    linkCampaign: 0,
    linkCampaignPercent: 0,
    openAttachment: 0,
    openAttachmentPercent: 0,
    openAttachmentFromLink: 0,
    openAttachmentFromLinkPercent: 0,
    formCampaign: 0,
    formCampaignPercent: 0,
    rating: 0,
    lastChange: 0,
    ratingImprove: 0,
    ratingImprovePercent: 0,
    ratingWorse: 0,
    ratingWorsePercent: 0,
    vulnCount: 0,
    vulnPercent: 0,
    noVulnerability: 0,
    noVulnerabilityPercent: 0,
    hasVulnerability: 0,
    hasVulnerabilityPercent: 0
};
