import variableModalTemplate from './view.html';

import { VariableModalController } from './controller';

import { VARIABLE_MODAL_CONTROLLER } from '../../const';

class VariableModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    open = (variableId) => {
        this.modalInstance = this.$uibModal.open({
            template: variableModalTemplate,
            bindToController: true,
            controller: VARIABLE_MODAL_CONTROLLER,
            controllerAs: '$',
            size: 'md',
            resolve: {
                variableId: variableId,
            },
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}

export {
    VariableModal,
    VariableModalController,
};
