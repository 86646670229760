import React, { useContext } from 'react';
import { GettextContext } from 'rootReact/context';
import { usePlural } from 'rootReact/hook';
import StatusIcon from 'rootReact/components/StatusIcon';
import Style from '../style.module.less';

const triggersIcon = {
    target_just_added: 0,
    target_no_attack: 0,
    target_proof_attack: 1,
    target_open_mail: 2,
    target_click_link: 3,
    target_course: 4,
    target_complete_course: 5,
    target_software: 6,
    target_fill_form: 6,
    target_miss_course: 7,
    target_open_attachment: 9,
    target_no_course: -4
};

function TriggerCell({logicMode, trigger, triggerTypes, courses }) {
    const gettext = useContext(GettextContext);
    const itemTriggers = trigger.map(item => item.type);


    const value = itemTriggers.map(item => {
        for (let i = 0; i < triggerTypes.length; i++) {
            if (triggerTypes[i].id === item) {
                return triggerTypes[i];
            }
        }
        return { name: gettext.getString('Неизвестное событие') };
    });

    const getTargetActivity = (index) => {
        return (
            <>{trigger[index].trigger_mode !== 'less' && trigger[index].trigger_mode !== 'more'
                ? <span className={'scheduler_text'}>{gettext.getString(`{{value}} ${usePlural(trigger[index].day_value, ['день', 'дня', 'дней'])}`, { value: trigger[index].day_value })}</span>
                : trigger[index].trigger_mode === 'less'
                    ? <span className={'scheduler_text'}>{gettext.getString(`меньше {{value}} ${usePlural(trigger[index].day_value, ['дня', 'дней', 'дней'])}`, { value: trigger[index].day_value })}</span>
                    : <span className={'scheduler_text'}>{gettext.getString(`дольше {{value}} ${usePlural(trigger[index].day_value, ['дня', 'дней', 'дней'])}`, { value: trigger[index].day_value })}</span>}</>
        );
    };

    const getCourse = (index) => {
        if (!trigger[index].trigger_mode || trigger[index].trigger_mode === 'any') {
            return courses.length
                ? <span className={'scheduler_text'}>{gettext.getString('Любой курс')}</span>
                : <span className={'scheduler_text text-danger'}>{gettext.getString('Нет доступных курсов')}</span>;
        }
        if (trigger[index].trigger_mode === 'many') {
            return trigger[index].courses.map(item => {
                return <span className={'scheduler_text'}>{item.name}</span>;
            });
        }
    };

    const getRating = (index) => {
        if (!trigger[index].trigger_mode || trigger[index].trigger_mode === 'down') {
            return <span className={'scheduler_text'}>{gettext.getString('ухудшился')}</span>;
        } else if (trigger[index].trigger_mode == 'up') {
            return <span className={'scheduler_text'}>{gettext.getString('улучшился')}</span>;
        } else if (trigger[index].trigger_mode == 'more') {
            return <span className={'scheduler_text'}>{gettext.getString('больше {{value}}', { value: trigger[index].trigger_value })}</span>;
        } else {
            return <span className={'scheduler_text'}>{gettext.getString('меньше {{value}}', { value: trigger[index].trigger_value })}</span>;
        }
    };

    const getProof = (index) => {
        if (!trigger[index].trigger_mode || trigger[index].trigger_mode === 'once' || !trigger[index].trigger_value || trigger[index].trigger_value === 1) {
            return <span className={'scheduler_text'}>{gettext.getString('однажды')}</span>;
        }
        if (trigger[index].trigger_mode !== 'once' && trigger[index].trigger_value > 1) {
            return <span className={'scheduler_text'}>{trigger[index].trigger_value + ' ' + gettext.getString(usePlural(trigger[index].day_value, ['раз подряд', 'раза подряд', 'раз подряд']))}</span>;
        }
    };

    const returnedValue = value.reduce((reduce, item, index, arr) => {
        let name = item.name.replace(/\.\.\./g, '').trim();
        return (
            <>{reduce}<div className={`row-center ${Style.actionWrapper}`}>
                <span style={{display: 'flex', alignItems: 'end'}}>{reduce ? logicMode === 'or' ?
                        <span style={{ marginRight: '5px' }}>{gettext.getString('или')}</span> : <span style={{ marginRight: '5px' }}>{gettext.getString('и')}</span> : ''}{name}
                    {Number.isInteger(triggersIcon[item.id])
                            ? <div style={{ marginLeft: '5px' }}><StatusIcon status={triggersIcon[item.id]} /></div>
                            : ''}
                </span>
                {trigger[index].type === 'target_activity'
                    && getTargetActivity(index)
                }
                {trigger[index].type === 'target_no_attack'
                    && (trigger[index].day_value
                        ? <span className={'scheduler_text'}>{gettext.getString(`дольше {{value}} ${usePlural(trigger[index].day_value, ['дня', 'дней', 'дней'])}`, { value: trigger[index].day_value })}</span>
                        : <span className={'scheduler_text'}>{gettext.getString('никогда')}</span>)
                }
                {~trigger[index].type.indexOf('course') ? getCourse(index) : ''}
                {~trigger[index].type.indexOf('rating') ? getRating(index) : ''}
                {~trigger[index].type.indexOf('proof') ? getProof(index) : ''}
            </div></>);
    }, '');
    return returnedValue?<div style={{ display: 'flex', flexDirection: 'column' }}>{returnedValue}</div> : gettext.getString('Неизвестное событие');
}

export default TriggerCell;
