import { useState, useEffect } from 'react';

function ProgressBar() {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (progress < 100) {
                setProgress(prev => prev + 12.5);
            }
        }, 1000); // 1 second intervals for each eighth of the circle

        return () => clearInterval(interval);
    }, [progress]);

    const radius = 22;
    const stroke = 1.5;
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (progress / 100) * circumference;

    return (
        <svg width={radius * 2} height={radius * 2} viewBox={`0 0 ${radius * 2} ${radius * 2}`}>
            <circle
                stroke='transparent'
                strokeWidth={stroke}
                fill='transparent'
                r={normalizedRadius}
                cx={radius}
                cy={radius}
            />
            <circle
                stroke='#fff'
                strokeWidth={stroke}
                strokeDasharray={`${circumference} ${circumference}`}
                style={{ strokeDashoffset }}
                fill='transparent'
                r={normalizedRadius}
                cx={radius}
                cy={radius}
                transform={`rotate(-90 ${radius} ${radius})`}
            />
        </svg>
    );
}

export default ProgressBar;