import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';

const initialState = {
    targetHistory: {},
    load: true,
    error: false
};

export const fetchTargetHistory = createAsyncThunk(
    'targetHistory/status',
    async () => {
        const fetchApi = new FetchApi('/history/targets');
        let response = await fetchApi.get();
        if (response.length) {
            response = response.map(item => {
                item.date = item.date * 1000;
                return item;
            });
            let arrUnshift = [];
            let targetHistory = [];
            let firstDate = new Date(response[0].date).getDate();
            let firstDay = new Date(new Date(response[0].date).getFullYear(), new Date(response[0].date).getMonth(), 1).valueOf();
            for (let i = 1; i < firstDate; i++) {
                arrUnshift.push({
                    companyCount: 0,
                    count: 0,
                    licenseCount: 0,
                    date: firstDay + 1000 * 60 * 60 * 24 * i
                });
            }
            targetHistory = [...arrUnshift, ...response];
            return targetHistory;
        }
        return [];
    }
);

const targetHistorySlice = createSlice({
    name: 'targetHistory',
    initialState,
    reducers: {
        targetHistoryDefault (state) {
            state = initialState;
        }
    },
    extraReducers: {
        [fetchTargetHistory.fulfilled]: (state, { payload }) => {
            state.targetHistory = payload;
            state.load = false;
            state.error = false;
        },
        [fetchTargetHistory.pending]: (state) => {
            state.load = true;
            state.error = false;
        },
        [fetchTargetHistory.rejected]: (state) => {
            state.load = false;
            state.error = true;
        },
    }
});

export const {targetHistoryDefault} = targetHistorySlice.actions;
export default targetHistorySlice.reducer;

