import style from './style.modules.css';
import getNextPage from '../../helpers/getNextPage';
import { hasAccess } from '../../reselect';
import { setBreadcrumbLink } from '../../toolkit/actions';

class ManagerTableController {
    constructor ($localStorage, $scope, gettextCatalog, $sessionStorage, $ngRedux, $filter) {
        this.style = style;
        this.$localStorage = $localStorage;
        this.gettextCatalog = gettextCatalog;
        this.$sessionStorage = $sessionStorage;
        this.scope = $scope;
        this.filter = $filter;
        this.sortReverse = false;
        this.nextPage = 0;
        this.sortBy = '';
        this.sortReverse = false;
        this.setManagersLoading(true);
        this.departmentChoosed = false;
        this.sortableFields = [
            'name',
            'email'
        ];
        this.managers = [];
        this.managerCount = 0;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { setBreadcrumbLink })(this);
        this.strings = {
            newEmployee: gettextCatalog.getString('новый сотрудник')
        };
    }

    mapStateToThis (state) {
        return {
            selectedData: state.selectedData.selectedData,
            linkNames: state.breadcrumbLinks.link,
            accessManagersDepartments: hasAccess(state, { sectionId: 1, rightName: 'view' })
        };
    }

    $onInit () {
        if (!this.accessManagersDepartments) {
            return;
        }
        this.loadManagers(0, 1);
        this.scope.$watch(scope => {
            return scope.$.linkNames;
        }, (links, oldLinks) => {
            if (links.length === oldLinks.length || !oldLinks.length) {
                return;
            }
            if (links.length === 1 && links[0] && {}.hasOwnProperty.call(links[0], 'isTitle')) {
                return;
            }
            if (!links.length) {
                this.managers = [];
                this.loadManagers(false, 1);
            }
        }, true);
        this.scope.$watch(scope => {
            return scope.$.reloading;
        }, (newVal, oldVal) => {
            if (newVal && newVal !== oldVal) {
                if (this.linkNames.length === 1 && this.linkNames[0] && {}.hasOwnProperty.call(this.linkNames[0], 'isTitle')) {
                    return;
                }
                this.managers = [];
                this.loadManagers(false, 1);
            }
            return;
        });
    }

    loadManagers = (fullList, page) => {
        this.setManagersLoading(true);
        this.departmentChoosed = false;
        fetch(this.getAdress(fullList, page), {
            headers: {
                'X-Csrf-Token': window.csrfToken,
                'Enable-Session': 1
            }
        }).then(response => {
            if (response.ok) {
                // eslint-disable-next-line
                let nextLink = response.headers.get('link').split(/\,/g);
                this.managerCount = response.headers.get('X-Pagination-Total-Count');
                this.nextPage = getNextPage(nextLink);
            }
            return response.json();
        }).then(json => {
            if (json.message) {
                this.setManagersLoading(false);
                this.error = json.message;
                this.scope.$apply();
                return;
            }
            if (json && json.length > 0) {
                if (fullList || page == 1) {
                    this.managers = json;
                } else {
                    this.managers = [...this.managers, ...json];
                }
            }
            if (this.setReloading) {
                this.setReloading(false);
            }
            this.setManagersLoading(false);
            this.sortManagers();
            this.scope.$apply();
        }).catch(() => {
            this.error = true;
            this.setManagersLoading(false);
            this.scope.$apply();
        });
    };

    loadDepartments = (id) => {
        this.setBreadcrumbLink([
            ...this.linkNames, ...this.managers.filter(item => {
                if (item.id === id) {
                    item.isTitle = true;
                    item.isManager = true;
                    return true;
                } else {
                    return false;
                }
            })]);
        this.departmentChoosed = id;
        this.$localStorage.openedManager = id;
    };

    setManagersLoading = (loading) => {
        this.loading = loading;
        if (typeof (this.setLoading) == 'function') {
            this.setLoading('managersLoading', loading);
        }
    };

    setSortBy = (str) => {
        if (this.sortBy === str) {
            this.sortReverse = !this.sortReverse;
        } else if (this.sortBy != str) {
            this.sortReverse = false;
        }
        this.sortBy = str;
        if (this.sortableFields.indexOf(this.sortBy) > -1) {
            this.managers = [];
            this.loadManagers(0, this.nextPage > 1 ? this.nextPage - 1 : 1);
        } else {
            this.sortManagers();
        }
    };

    sortManagers = () => {
        let str = this.sortBy;
        if (str && this.sortableFields.indexOf(str) === -1) {
            let sorting = !this.loading;
            if (sorting) {
                this.setManagersLoading(true);
            }
            this.managers = this.filter('orderBy')(this.managers, str);
            if (this.sortReverse) {
                this.managers.reverse();
            }
            if (sorting) {
                this.setManagersLoading(false);
            }
        }
    };

    getAdress = (fullList, page) => {
        return `${window.config.SYSTEM_URL}${window.config.API_URL}/managers?managerId=0&fullList=${+fullList}&page=${page}&per-page=10&sortBy=${this.sortBy}&sortReverse=${this.sortReverse}`;
    };

    $onDestroy () {
        this.unsubscribe();
    }
}

ManagerTableController.$inject = [
    '$localStorage',
    '$scope',
    'gettextCatalog',
    '$sessionStorage',
    '$ngRedux',
    '$filter'
];

export {
    ManagerTableController
};
