import daemonMessageTemplate from './view.html';

import { DaemonMessageController } from './controller';

const DaemonMessageComponent = {
    template: daemonMessageTemplate,
    controller: DaemonMessageController,
    controllerAs: '$',
    scope: true,
    bindings: {},
};

export {
    DaemonMessageComponent,
};
