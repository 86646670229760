import { saveAs } from 'file-saver';
import style from './style.modules.css';
import moment from 'moment';
import { EMPTY_SELECTED_DATA } from '../../const';
import angular from 'angular';

class SaveFileController {
    constructor ($ngRedux, $scope, $localStorage, $document, $filter, gettextCatalog) {
        this.gettextCatalog = gettextCatalog;
        this.$scope = $scope;
        this.$localStorage = $localStorage;
        this.$filter = $filter;
        this.$document = $document;
        this.setFileLoading(false);
        this.setFileError(false);
        this.style = style;
        this.isOpenCalend = false;
        this.downloadStatFromDate = new Date();
        this.downloadStatFromDate.setDate(this.downloadStatFromDate.getDate() - 7);
        this.downloadStatToDate = new Date();
        this.minDate = this.$filter('dateFormat')(this.downloadStatFromDate, 'yyyy-MM-dd');
        this.nowDate = new Date();
        this.prepareDates();
        this.allDates = false;
        if (this.accessSection && this.accessRight) {
            this.disabled = !$scope.appCtrl.hasAccess(+this.accessSection, this.accessRight);
        }
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, null)(this);
    }

    $onDestroy () {
        this.unsubscribe();
    }

    mapStateToThis (state) {
        return {
            createdAt: state.auth.auth.createdAt,
            selectedData: state.selectedData
        };
    }

    $onInit () {
        const ctrl = this;
        if (!this.isMinDate) {
            this.minDate = this.createdAt;
        }
        this.$document.ready(() => {
            this.$document[0].addEventListener('click', (event) => {
                let target = event.target;
                if (!target) {
                    return;
                }
                if (target.classList.contains('anonym-open')) {
                    return;
                }
                while (target && target !== ctrl.$document[0]) {
                    if (!ctrl.isOpenCalend) {
                        break;
                    }
                    if (target.classList.contains('anonym-text-wrapper')) {
                        let oldOpened = ctrl.isOpenCalendFrom || ctrl.isOpenCalendTo;
                        ctrl.isOpenCalendFrom = false;
                        ctrl.isOpenCalendTo = false;
                        if (oldOpened) {
                            ctrl.$scope.$apply();
                        }
                        break;
                    }
                    if (target.classList.contains('main-page-sections')) {
                        let oldOpened = ctrl.isOpenCalend;
                        ctrl.isOpenCalend = !ctrl.isOpenCalend;
                        if (!ctrl.isOpenCalend) {
                            ctrl.isOpenCalendFrom = false;
                            ctrl.isOpenCalendTo = false;
                        }
                        if (oldOpened) {
                            ctrl.$scope.$apply();
                        }
                        break;
                    }
                    target = target.parentNode;
                    if (target && target.classList.contains('anonym-downloader-panel')) {
                        break;
                    }
                }
            });
        });
    }

    getCsrfToken = () => {
        return window.csrfToken;
    };

    getNowDate = () => {
        return moment(new Date()).format('YYYY-MM-DD');
    };

    downloadReport = (download = true, event = null) => {
        if (event && !this.isOpenCalendFrom && !this.isOpenCalendTo) {
            event.stopPropagation();
        }
        if (this.onClickLink) {
            this.onClickLink();
        }
        const ctrl = this;
        if (!ctrl.loading && ['report', 'log', 'anonym', 'education', 'educationAll', 'attack', 'department', 'employee', 'employeeAll', 'risks', 'scheduler', 'debug', 'user', 'users', 'sessions', 'ldap', 'certs'].indexOf(ctrl.fileType) > -1) {
            if (ctrl.filterDates) {
                if (!download) {
                    ctrl.isOpenCalend = true;
                    ctrl.isOpenCalendFrom = false;
                    ctrl.isOpenCalendTo = false;
                    return;
                }
                ctrl.prepareDates();
            }
            ctrl.setFileError(null);
            ctrl.setFileLoading(true);
            let urls = {
                report: `${window.config.SYSTEM_URL}${window.config.API_URL}/reports/download/general`,
                log: `${window.config.SYSTEM_URL}${window.config.API_URL}/reports/download/log`,
                education: `${window.config.SYSTEM_URL}${window.config.API_URL}/reports/download/education`,
                educationAll: `${window.config.SYSTEM_URL}${window.config.API_URL}/reports/download/education`,
                attack: `${window.config.SYSTEM_URL}${window.config.API_URL}/reports/download/attack/${ctrl.id}`,
                department: `${window.config.SYSTEM_URL}${window.config.API_URL}/reports/download/department/${ctrl.id}`,
                employee: `${window.config.SYSTEM_URL}${window.config.API_URL}/reports/download/select`,
                employeeAll: `${window.config.SYSTEM_URL}${window.config.API_URL}/reports/download/select`,
                risks: `${window.config.SYSTEM_URL}${window.config.API_URL}/reports/download/risks`,
                scheduler: `${window.config.SYSTEM_URL}${window.config.API_URL}/scheduler-tasks/report`,
                debug: `${window.config.SYSTEM_URL}${window.config.API_URL}/settings/debug-archive`,
                user: `${window.config.SYSTEM_URL}${window.config.API_URL}/reports/download/user`,
                users: `${window.config.SYSTEM_URL}${window.config.API_URL}/stats/users`,
                sessions: `${window.config.SYSTEM_URL}${window.config.API_URL}/stats/sessions`,
                anonym: `${window.config.SYSTEM_URL}${window.config.API_URL}/stats/attack`,
                ldap: `${window.config.SYSTEM_URL}${window.config.API_URL}/ldap-imports/report`,
                certs: window.config.SYSTEM_URL + window.config.API_URL + '/settings/download-all-certs',

            };
            let exts = {
                risks: 'xlsx',
                employee: 'xlsx',
                employeeAll: 'xlsx',
                attack: 'xlsx',
                department: 'xlsx',
                education: 'xlsx',
                educationAll: 'xlsx',
                report: 'xlsx',
                log: 'csv',
                scheduler: 'csv',
                debug: 'zip',
                user: 'csv',
                users: 'csv',
                sessions: 'csv',
                ldap: 'csv',
                anonym: 'zip',
                certs: 'zip'
            };
            let contentTypes = {
                xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                csv: 'text/csv',
                zip: 'application/zip'
            };
            let reportUrl = urls[ctrl.fileType];
            let bodyParams = null;
            if (['education', 'employee'].indexOf(ctrl.fileType) > -1) {
                bodyParams = {
                    selectedData: angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(ctrl.selectedData))
                };
            }
            if (['educationAll', 'employeeAll'].indexOf(ctrl.fileType) > -1) {
                bodyParams = {
                    selectedData: angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy({ all: 1 }))
                };
            }
            if (ctrl.filterDates && !ctrl.allDates) {
                if (!bodyParams) {
                    bodyParams = {};
                }
                bodyParams.fromDate = ctrl.downloadStatFromDate;
                bodyParams.toDate = ctrl.downloadStatToDate;
            }
            fetch(reportUrl, {
                headers: {
                    'X-Csrf-Token': ctrl.getCsrfToken(),
                    'Enable-Session': 1,
                    'Content-Type': contentTypes[exts[ctrl.fileType]]
                },
                method: 'POST',
                body: bodyParams ? JSON.stringify(bodyParams) : null
            }).then(response => {
                if (response.ok) {
                    return response.blob();
                }
                return response.json();
            }).then((blobContent) => {
                ctrl.setFileLoading(false);
                let message = blobContent.message || blobContent.error || null;
                if (message) {
                    ctrl.setFileError(ctrl.errorText ? ctrl.errorText : ctrl.gettextCatalog.getString('Ошибка при подготовке отчета'));
                    ctrl.$scope.$apply();
                    return;
                }
                ctrl.$scope.$apply();
                let date = ctrl.getNowDate();
                if (ctrl.filterDates && !ctrl.allDates) {
                    date = ctrl.downloadStatFromDate + '-' + ctrl.downloadStatToDate;
                }
                let fileName = ctrl.fileType.replace('All', '-all');
                if (ctrl.id) {
                    fileName = fileName + ctrl.id;
                }
                fileName = fileName + '-' + date + '.' + exts[ctrl.fileType];
                saveAs(blobContent, fileName);
            }).catch(() => {
                ctrl.setFileLoading(false);
                ctrl.setFileError(ctrl.errorText ? ctrl.errorText : ctrl.gettextCatalog.getString('Ошибка при подготовке отчета'));
                ctrl.$scope.$apply();
            });
        }
    };

    setFileLoading = (loading) => {
        this.loading = loading;
        if (this.setLoading) {
            this.setLoading(this.fileType, loading);
        }
    };

    setFileError = (error) => {
        this.error = error;
        if (this.setError) {
            this.setError(this.fileType, error);
        }
    };

    getIsOpenCalend = () => {
        return this.isOpenCalend;
    };

    prepareDates = () => {
        this.minDate = angular.copy(this.downloadStatFromDate);
        this.maxDate = angular.copy(this.downloadStatToDate);
        this.downloadStatFromDate = this.$filter('dateFormat')(this.downloadStatFromDate, 'yyyy-MM-dd');
        this.downloadStatToDate = this.$filter('dateFormat')(this.downloadStatToDate, 'yyyy-MM-dd');
        if (this.minDate > this.maxDate) {
            this.maxDate = this.minDate;
            this.downloadStatToDate = this.$filter('dateFormat')(this.downloadStatFromDate, 'yyyy-MM-dd');
        }
        if (!this.isMinDate) {
            this.minDate = this.createdAt;
        }
        this.isOpenCalendFrom = false;
        this.isOpenCalendTo = false;
    };

    openCalendFrom = (event) => {
        if (event) {
            event.stopPropagation();
        }
        this.isOpenCalendTo = false;
        this.isOpenCalendFrom = !this.isOpenCalendFrom;
    };

    openCalendTo = (event) => {
        if (event) {
            event.stopPropagation();
        }
        this.isOpenCalendFrom = false;
        this.isOpenCalendTo = !this.isOpenCalendTo;
    };

    switchAllDates = (event) => {
        if (event) {
            event.stopPropagation();
        }
        this.allDates = !this.allDates;
        this.isOpenCalendFrom = false;
        this.isOpenCalendTo = false;
    };
}

SaveFileController.$inject = [
    '$ngRedux',
    '$scope',
    '$localStorage',
    '$document',
    '$filter',
    'gettextCatalog'
];

export {
    SaveFileController
};
