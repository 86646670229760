import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FetchApi from "../React/REST";

const initialState = {
    loading: false,
    deleteLoading: false,
    itemLoad: null,
    wifi: [],
    error: false,
    errorText: "",
};

export const fetchListWifi = createAsyncThunk("wifi/status", async (pagination) => {
    const wifiApi = FetchApi(`/wifi-attacks?fullList=1?per-page=${pagination}`);
    let wifi = await wifiApi.get();
    wifi = wifi.length
        ? wifi.map((item) => {
              item.checked = false;
              return item;
          })
        : [];
    return [...wifi];
});

export const deleteWifi = createAsyncThunk("wifi/delete", async (ids) => {
    const wifi = FetchApi(`/wifi-attacks/delete`);
    const response = await wifi.post({
        body: {
            ids,
        },
    });
    return response.success;
});

const wifiSlice = createSlice({
    name: "wifi",
    initialState,
    reducers: {
        setWifiList(state, { payload }) {
            state.wifi = payload;
        },
        setWifi(state, { payload }) {
            state.wifi.unshift(payload);
        },
        updateWifi(state, { payload }) {
            state.wifi = state.wifi.map((item) => {
                if (item.id !== payload.id) return item;
                return payload;
            });
        },
        updateWifiChecked(state, { payload }) {
            state.wifi = state.wifi.map((item) => {
                if (item.id === payload) {
                    return {
                        ...item,
                        checked: !item.checked,
                    };
                } else {
                    return item;
                }
            });
        },
    },
    extraReducers: {
        [fetchListWifi.pending]: (state) => {
            state.loading = true;
        },
        [fetchListWifi.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.error = false;
            (state.errorText = ""), (state.wifi = payload);
        },
        [fetchListWifi.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = true;
            state.errorText = payload;
        },
        [deleteWifi.pending]: (state) => {
            state.deleteLoading = true;
        },
        [deleteWifi.fulfilled]: (state, { payload }) => {
            state.deleteLoading = false;
            state.error = false;
            const ids = Object.keys(payload).map(id => (Number(id)))
            state.wifi = state.wifi.filter((v) => {
                return !ids.includes(v.id);
            });
        },
        [deleteWifi.rejected]: (state, { payload }) => {
            state.deleteLoading = false;
            state.error = true;
            state.errorText = payload;
        },
    },
});

export const { setWifiList, setWifi, updateWifi, updateWifiChecked } = wifiSlice.actions;
export default wifiSlice.reducer;
