import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSettings } from 'root/assets/webpack/src/js/toolkit/settings';

const initialState = {
    status: 'idle',
    templates: [],
    error: false
};

export const fetchTemplates = createAsyncThunk(
    'templates/status',
    async () => {}
);

const templatesSlice = createSlice({
    name: 'templates',
    initialState,
    reducers: {
        setTemplates (state, {payload}) {
            state.templates = payload;
        }
    },
    extraReducers: {
        [fetchSettings.fulfilled]: (state, { payload }) => {
            state.templates = payload.templates;
        }
    }
});

export const {setTemplates} = templatesSlice.actions;
export default templatesSlice.reducer;

