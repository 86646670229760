class SchedulerTasksService {
    constructor (YiiModel, $resource) {
        this.YiiModel = YiiModel;
        this.all = YiiModel.all('scheduler-tasks');
        this.one = (id) => {
            return YiiModel.one('scheduler-tasks').$find(id);
        };
        this.typesFactory = $resource(window.config.SYSTEM_URL + window.config.API_URL + '/scheduler-tasks/:action', {}, {
            triggerTypes: {
                method: 'GET',
                params: {
                    action: 'trigger-types'
                },
                isArray: true
            },
            actionTypes: {
                method: 'GET',
                params: {
                    action: 'action-types'
                },
                isArray: true
            },
            reportTypes: {
                method: 'GET',
                params: {
                    action: 'report-types'
                },
                isArray: true
            }
        });
        this.coursesFactory = $resource(window.config.SYSTEM_URL + window.config.API_URL + '/courses/:id', null, {});
        this.templatesFactory = $resource(window.config.SYSTEM_URL + window.config.API_URL + '/templates/:id', null, {});
    }
}

export {
    SchedulerTasksService
};
