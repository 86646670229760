import targetsTableTemplate from './view.html';

import { TargetsTableController } from './controller';

const TargetsTableComponent = {
    template: targetsTableTemplate,
    controller: TargetsTableController,
    controllerAs: '$',
    scope: true,
    bindings: {
        setLoading: '<',
        isSelectable: '<',
        isEditable: '<',
        departmentId: '<',
        groupId: '<',
        search: '<',
        setSearch: '=',
        courseId: '<',
        isVuln: '<',
        isVulnModal: '<',
        isReport: '<',
        loadDepartments: '<',
        reloading: '<',
        managerId: '<',
        isAnyLoading: '<',
        rebuildLoading: '<',
        setTargetCount: '<',
        departmentCheckedCache: '=',
        departmentPartialCache: '=',
        getPartial: '=',
        getReportParams: '=',
        departmentIsParent: '<',
        software: '<',
        getFirstLoading: '=',
        isErrorHidden: '<',
        isGroup: '<',
        viewMode: '<'
    },
};

export {
    TargetsTableComponent,
};
