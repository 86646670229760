import React from 'react';
import Translate from 'rootReact/Translate.jsx';

export function CertLink({handleClick}) {
    return (
            <a onClick={handleClick}
            >
                <Translate>Архив сертификатов сотрудников</Translate>
            </a>
    );
}
