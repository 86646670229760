class EntityService {
    constructor($resource) {
        this.service = null;
        this.$resource = $resource;
        return {
            bulkDelete: (data, ctrl, success, error) => {
                error(ctrl, {
                    'data': { 'error': 'the service is not delegated' }
                });
            },
            delete: (data, ctrl, success, error) => {
                if (!this.service) {
                    error(ctrl, {
                        'data': { 'error': 'the service is not delegated' }
                    });
                    return;
                }
                return this.service.delete(data, ctrl, success, error);
            },
        };
    }
}

EntityService.$inject = [
    '$resource'
];

export {
    EntityService
};
