class VariablesService {
    constructor (YiiModel, $resource) {
        this.YiiModel = YiiModel;
        this.$resource = $resource(window.config.SYSTEM_URL + window.config.API_URL + '/variables?all=1');
        this.all = YiiModel.all('variables');
        this.one = (id) => {
            return YiiModel.one('variables').$find(id);
        };
    }

    getAll = () => {
        return this.$resource.query();
    };
}

export {
    VariablesService
};
