import ldapTargetsTemplate from './view.html';

import { LdapTargetsController } from './controller';

const LdapTargetsComponent = {
    template: ldapTargetsTemplate,
    controller: LdapTargetsController,
    controllerAs: '$',
    bindings: {
        departmentName: '<',
        targets: '<',
        viewMode: '<',
        selectedData: '<',
        externalIdEnabled: '<',
        toggleTargetsLdap: '=',
    },
};

export {
    LdapTargetsComponent,
};
