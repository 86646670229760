import courseTableTemplate from './view.html';

import { CourseTableController } from './controller';

const CourseTableComponent = {
    template: courseTableTemplate,
    controller: CourseTableController,
    controllerAs: '$',
    scope: true,
    bindings: {
        departmentChoosed: '<',
        loadCourses: '<',
        courses: '=',
        loadDepartments: '=',
        viewMode: '<',
        sortBy: '<',
        sortReverse: '<',
        setSortBy: '<',
        error: '<',
        graphMode: '<',
        loading: '<',
        modeData: '<',
        isAnyLoading: '<',
        reloading: '<',
        setReloading: '<',
        setDepartmentCount: '<',
        setTargetCount: '<',
        departmentCheckedCache: '=',
        departmentPartialCache: '=',
        setLoading: '<',
        rebuildLoading: '<',
        setSearch: '<',
    },
};

export {
    CourseTableComponent,
};
