import daemonDownloadTemplate from './view.html';

import { DaemonDownloadController } from './controller';

const DaemonDownloadComponent = {
    template: daemonDownloadTemplate,
    controller: DaemonDownloadController,
    controllerAs: '$',
    scope: true,
    bindings: {
        type: '<',
        icon: '<',
        useSelect: '<',
        newString: '<',
        viewPopUp: '<',
        reportAccess: '<',
        itemId: '<',
        useItemId: '<'
    },
};

export {
    DaemonDownloadComponent,
};
