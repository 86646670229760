import alertMessagesTemplate from './view.html';

import { AlertMessagesController } from './controller';

const AlertMessagesComponent = {
    template: alertMessagesTemplate,
    controller: AlertMessagesController,
    controllerAs: '$',
    scope: true,
    bindings: {
        messages: '<',
    },
};

export {
    AlertMessagesComponent,
};
