import modalTemplate from './view.html';
import { CampaignScheduleModalController } from './controller';

import { CAMPAIGN_SCHEDULE_MODAL_CONTROLLER } from '../../const';

class CampaignScheduleModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    $onInit = () => {
        this.$uibModal;
    };

    open = (campaign = null) => {
        this.modalInstance = this.$uibModal.open({
            template: modalTemplate,
            bindToController: true,
            controller: CAMPAIGN_SCHEDULE_MODAL_CONTROLLER,
            controllerAs: '$',
            size: 'nm',
            resolve: {
                campaign: campaign,
            },
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}

export {
    CampaignScheduleModal,
    CampaignScheduleModalController,
};
