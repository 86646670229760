import { createSelector } from 'reselect';
import { getMySourseDashboard } from '../helpers/graphs';
// import {getMySourse} from '../helpers/mainGrapth';

const targetHistory = (state, props = null) => {
    let history = Array.isArray(state.targetHistory.targetHistory) ? [...state.targetHistory.targetHistory]:null;

    return {
        targetHistory: history ? history : state.targetHistory.targetHistory,
        targetInSystem: state.auth.auth.license.employeeCount,
        companyTargetCount: state.auth.auth.companyTargetCount,
        targetCount: state.auth.auth.targetCount,
        props: props
    };
};

const attackHistory = (state, props = null) => {
    let history = Array.isArray(state.attackHistory.attackHistory) ? [...state.attackHistory.attackHistory]:null;

    return {
        attackHistory: history ? history : state.attackHistory.attackHistory,
        targetInSystem: state.auth.auth.targetCount,
        props: props,
        licenseCount: state.auth.auth.license.employeeCount
    };
};

const educationHistory = (state, props = null) => {
    let history = Array.isArray(state.educationHistory.educationHistory) ? [...state.educationHistory.educationHistory]:null;

    return {
        educationHistory: history ? history : state.educationHistory.educationHistory,
        targetInSystem: state.auth.auth.targetCount,
        props: props,
        licenseCount: state.auth.auth.license.employeeCount,
    };
};

const motivationHistory = (state, props = null) => {
    return { motivationHistory: state.motivationHistory.motivationHistory, props: props };
};

export const getAttackHistoryDashboard = createSelector([attackHistory], state => {
    let myDataSourse = getMySourseDashboard(state.attackHistory, 'noAttack', state.targetInSystem, state.props.tooltext, state.targetInSystem, false, state.props.gettextCatalog);
    return myDataSourse;
});

export const getTargetHistoryDashboard = createSelector([targetHistory], state => {
    let myDataSourse = getMySourseDashboard(state.targetHistory, 'count', state.companyTargetCount, state.props.tooltext, null, true, state.props.gettextCatalog);
    return myDataSourse;
});

export const getEducationHistoryDashboard = createSelector([educationHistory], state => {
    let myDataSourse = getMySourseDashboard(state.educationHistory, 'noEducation', state.targetInSystem, state.props.tooltext, state.targetInSystem, false, state.props.gettextCatalog);
    return myDataSourse;
});

export const getMotivationHistoryDashboard = createSelector([motivationHistory], motivationHistory => {
    return motivationHistory;
});
