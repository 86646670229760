import { LDAP_PROFILES_SERVICE } from '../../const';
import style from './style.modules.css';

class LdapProfileModalController {
    constructor(LdapProfilesService, profileId, moodleIsVisible) {
        this.LdapProfilesService = LdapProfilesService;
        this.profileId = profileId;
        this.moodleIsVisible = moodleIsVisible;
        this.style = style;
        this.profile = null;
        this.changePassword = true;
        this.appCtrl = window.appCtrl;
        this.gettextCatalog = this.appCtrl.gettextCatalog;
        this.loading = false;
        this.errorText = null;
        this.useTimeValue = false;
        this.menuOpen = false;
        this.ldapLoginType = [];
        this.openLdapSettings = false;
        this.defaultTypesLogin = [
            {
                value: 'ldapLoginData@ldapDomainData',
                name: 'ldapLoginData@ldapDomainData'
            },
            {
                value: 'email',
                name: this.gettextCatalog.getString('Использовать email')
            },
            {
                value: 'ldapDomainData\\ldapLoginData',
                name: 'ldapDomainData\\ldapLoginData'
            }
        ];
    }

    setForm = (form) => {
        this.form = form;
    };

    setOpenLdapSettings = () => {
        this.openLdapSettings = !this.openLdapSettings;
    }

    setMenuOpen = () => {
        this.menuOpen = !this.menuOpen;
    }

    setLoginType = (type) => {
        this.ldapLoginType.push(type);
    }

    removeLoginType = (type) => {
        this.ldapLoginType = this.ldapLoginType.filter(item => item !== type);
    }

    $onInit = () => {
        this.loading = true;
        this.errorText = null;
        if (this.profileId > 0) {
            this.LdapProfilesService.one(this.profileId).then((profile) => {
                this.profile = profile;
                if (profile && profile.timeValue > 0) {
                    this.useTimeValue = true;
                }
                this.fillType(this.profile.loginMethod);
                this.changePassword = false;
                this.loading = false;
            }, () => {
                this.loading = false;
                this.errorText = this.gettextCatalog.getString('Ошибка при загрузке ldap-профиля');
            });
        } else {
            this.profile = this.LdapProfilesService.YiiModel.one('ldap-profiles');
            this.profile.timeout = 60;
            this.ldapLoginType = this.defaultTypesLogin;
            this.loading = false;
        }
        this.editable = this.appCtrl && this.appCtrl.hasAccess(10, 'ldap_save');
        document.addEventListener('click', this.setWatcher);
    };

    checkSelected = (type) => {
        let result = this.ldapLoginType.find(item => item.value === type.value);
        return result;
    }

    fillType = (type) => {
        this.ldapLoginType = this.defaultTypesLogin.filter(item => {
            return ~type.indexOf(item.value);
        });
    }

    save = () => {
        if (this.editable) {
            this.errorText = null;
            this.form.$setSubmitted();
            if (this.form.$valid) {
                this.inProcess = true;
                this.profile.loginMethod = this.ldapLoginType.map(item => item.value);
                if (!this.useTimeValue) {
                    this.profile.timeValue = null;
                    this.profile.timePeriod = null;
                }
                this.profile.$save().then((profile) => {
                    if (profile) {
                        this.onDestroy(profile);
                    }
                    this.inProcess = false;
                }).catch(() => {
                    this.errorText = this.gettextCatalog.getString('Ошибка при сохранении ldap-профиля');
                    this.inProcess = false;
                });
            }
        }
    };

    setWatcher = (e) => {
        this.watcher(e.target, 0);
    }

    watcher = (element, i) => {
        if (!element) return;
        let id = element.getAttribute('id');
        if (i > 4) {
            this.menuOpen = false;
            return;
        }
        if (id === 'menu-awr') return;
        this.watcher(element.parentElement, i + 1);
    }

    setProfileTimeout = (value) => {
        this.profile.timeout = value;
    }

    setSchema = (schema) => {
        if (this.editable) {
            this.profile.schema = schema || 'ActiveDirectory';
        }
    };

    setEncryption = (encryption) => {
        if (this.editable) {
            this.profile.encryption = encryption || 0;
        }
    };

    enableTimeValue = (enabled) => {
        this.useTimeValue = !!enabled;
        if (this.useTimeValue && !this.profile.timeValue) {
            this.profile.timeValue = 1;
        }
    };

    onDestroy(val) {
        document.removeEventListener('click', this.setWatcher);
        this.$close(val);
    }
}

LdapProfileModalController.$inject = [
    LDAP_PROFILES_SERVICE,
    'profileId',
    'moodleIsVisible',
];

export {
    LdapProfileModalController,
};
