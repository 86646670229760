import groupsTableTemplate from './view.html';

import { GroupsTableController } from './controller';

const GroupsTableComponent = {
    template: groupsTableTemplate,
    controller: GroupsTableController,
    controllerAs: '$',
    scope: true,
    bindings: {
        setLoading: '<',
        setGroupCount: '<',
        isSelectable: '<',
        isEditable: '<',
        isDisclosed: '<',
        isAnyLoading: '<',
        courseId: '<',
        isReport: '<',
        isReportModal: '<',
        isVuln: '<',
        isVulnModal: '<',
        isRating: '<',
        groupCheckedCache: '=',
        groupPartialCache: '=',
        reloading: '<',
        search: '<',
        managerId: '<',
        rebuildLoading: '<',
        setReloading: '=',
        getReportParams: '=',
        graphMode: '<',
        setSearch: '=',
        software: '<',
        trainingOpen: '=',
        isErrorHidden: '<',
        setViewMode: '='
    },
};

export {
    GroupsTableComponent,
};
