import style from './style.modules.css';
import moment from 'moment';
import { DOWNLOAD_TYPE, EMPTY_SELECTED_DATA } from '../../const';
import setOptions from '../../helpers/setOptions';
import { daemonsCancel } from '../../helpers/daemonsCancel';
import angular from 'angular';
import { daemonsMessageDefault } from '../../toolkit/actions';
import { fetchDaemonsMessage } from '../../toolkit/think';
import TimeSelect from 'rootReact/components/TimeSelect';

class DaemonDownloadController {
    constructor ($ngRedux, scope, gettextCatalog, $timeout, $location, $injector) {
        this.style = style;
        this.scope = scope;
        this.timeout = $timeout;
        this.$injector = $injector;
        this.$location = $location;
        this.statusParams = {
            readyToStart: 'readyToStart',
            inProgress: 'inProgress',
            readyToDownload: 'readyToDownload'
        };
        this.loadLink = [
            gettextCatalog.getString('Архив сертификатов сотрудников'),
            gettextCatalog.getString('Подготовить отчет по действиям'),
            gettextCatalog.getString('Подготовить отчет по обучению'),
            gettextCatalog.getString('общий отчет'),
            gettextCatalog.getString('с полной статистикой'),
            gettextCatalog.getString('с анонимной статистикой'),
            gettextCatalog.getString('Подготовить отчет по уязвимостям'),
            gettextCatalog.getString('Подготовить отчет по атаке'),
            gettextCatalog.getString('Подготовить отчет по отделу'),
            gettextCatalog.getString('по обратной связи от сотрудников'),
        ];
        this.prepareMessage = [
            gettextCatalog.getString('Готовим архив сертификатов...'),
            gettextCatalog.getString('Готовим отчет по действиям...'),
            gettextCatalog.getString('Готовим отчет по обучению...'),
            gettextCatalog.getString('Готовим общий отчет...'),
            gettextCatalog.getString('Готовим отчет (полная статистика)...'),
            gettextCatalog.getString('Готовим отчет (анонимная статистика)...'),
            gettextCatalog.getString('Готовим отчет по уязвимостям...'),
            gettextCatalog.getString('Готовим отчет по атаке...'),
            gettextCatalog.getString('Готовим отчет по отделу...'),
            gettextCatalog.getString('Готовим отчет по обратной связи от сотрудников...'),
        ];
        this.downloadLinkText = [
            gettextCatalog.getString('Скачать сертификаты сотрудников архивом'),
            gettextCatalog.getString('Скачать отчет по действиям'),
            gettextCatalog.getString('Скачать отчет по обучению'),
            gettextCatalog.getString('Скачать общий отчет'),
            gettextCatalog.getString('Скачать отчет (полная статистика)'),
            gettextCatalog.getString('Скачать отчет (анонимная статистика)'),
            gettextCatalog.getString('Скачать отчет по уязвимостям'),
            gettextCatalog.getString('Скачать отчет по атаке'),
            gettextCatalog.getString('Скачать отчет по отделу'),
            gettextCatalog.getString('Скачать отчет по обратной связи от сотрудников'),
            gettextCatalog.getString('Скачать отчет по wifi-атаке'),
        ];
        this.isOpenCalend = false;
        this.downloadFromDate = moment(Date.now() - 1000*60*60*24*7).format('YYYY-MM-DD HH:mm:ss');
        this.downloadToDate = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');
        this.fromDateOptions = {};
        this.gettextCatalog = gettextCatalog;
        this.period = false;
        this.messageStatus = this.statusParams.readyToStart;
        this.toDateOptions = {};
        this.TimeSelect = TimeSelect;
        this.redux = $ngRedux;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { daemonsMessageDefault, fetchDaemonsMessage })(this);
    }

    mapStateToThis = (state) => {
        return {
            message: state.daemonsMessage.daemons,
            userInfo: state.auth.auth,
            selectedData: state.selectedData
        };
    }

    $onInit () {
        this.prepareDates();
        if (this.useSelect) {
            this.setFielName();
        }
        if (this.newString) {
            this.loadLink[this.type - 1] = this.newString.loadLink || this.loadLink[this.type - 1];
            this.prepareMessage[this.type - 1] = this.newString.prepareMessage || this.prepareMessage[this.type - 1];
            this.downloadLinkText[this.type - 1] = this.newString.downloadLinkText || this.downloadLinkText[this.type - 1];
        }
        let body = document.body;
        body.addEventListener('click', (e) => this.closeDownload(e.target));
        this.scope.$watch(scope => {
            return scope.$.message;
        }, () => {
            this.checkStatus();
        });
        this.scope.$watch(scope => {
            return scope.$.type;
        }, () => {
            this.checkStatus();
        });
    }

    checkStatus = () => {
        let selected = {};
        if (!this.message.length) {
            this.messageStatus = this.statusParams.readyToStart;
            return;
        }
        selected = this.message.filter(item => {
            if (this.useItemId) {
                return item.type === this.type && item.details.id === this.itemId;
            }
            return item.type === this.type;
        })[0];
        if (selected) {
            this.daemonId = selected.id;
        }
        this.messageStatus = !selected
            ? this.statusParams.readyToStart
            : (selected.isReady
                ? this.statusParams.readyToDownload :
                this.statusParams.inProgress);
    }

    cancelBuild = async () => {
        this.cancelLoading = true;
        let response = await daemonsCancel(this.daemonId);
        if (response.status === 'success') {
            this.daemonsMessageDefault(this.daemonId);
        }
        this.cancelLoading = false;
    }

    closeDownload = (target) => {
        if (!this.isOpenCalend) return;
        if (target === document.body) {
            this.isOpenCalend = false;
            this.scope.$apply();
            return;
        }
        if (target.classList.contains('download-cert-wrapper') && +target.dataset.type === this.type) {
            return;
        }
        if (target.parentNode) {
            this.closeDownload(target.parentNode);
            return;
        }
    }

    setPeriod = () => {
        this.period = !this.period;
    }

    closeWindow = () => {
        this.isOpenCalend = false;
    }

    onOpenCalend = () => {
        if (!this.reportAccess) return;
        if (this.viewPopUp) {
            this.isOpenCalend = !this.isOpenCalend;
        } else {
            this.prepareReport();
        }
    }

    openCalendFrom = (event) => {
        if (event) {
            event.stopPropagation();
        }
        this.isOpenCalendTo = false;
        this.isOpenCalendFrom = !this.isOpenCalendFrom;
    };

    setFielName = () => {
        this.loadLink[2] = this.gettextCatalog.getString('Подготовить отчет');
        this.prepareMessage[2] = this.gettextCatalog.getString('Готовим отчет...');
        this.downloadLinkText[2] = this.gettextCatalog.getString('Скачать отчет');
    }

    openCalendTo = (event) => {
        if (event) {
            event.stopPropagation();
        }
        this.isOpenCalendFrom = false;
        this.isOpenCalendTo = !this.isOpenCalendTo;
    };

    prepareDates = () => {
        if (moment(this.downloadFromDate).format('X') > moment(this.downloadToDate).format('X')) {
            this.downloadFromDate = moment(this.downloadToDate).add(-7, 'days').format('YYYY-MM-DD HH:mm:ss');
        }
        this.minDate = moment(this.downloadFromDate).format('YYYY-MM-DD HH:mm:ss');
        this.maxDate = moment(this.downloadToDate).format('YYYY-MM-DD HH:mm:ss');
        this.fromDateOptions = {
            minDate: moment('0000-00-01 00:00:00').format('YYYY-MM-DD HH:mm:ss'),
            maxDate: this.maxDate
        };
        this.toDateOptions = { minDate: this.minDate, maxDate: Date.now() };
        this.isOpenCalendFrom = false;
        this.isOpenCalendTo = false;
    };

    setBody = (date) => {
        let body = {
            type: this.type > 1 ? 2 : 1,
            data: {
                dateFrom: this.period ? this.downloadFromDate : this.userInfo.createdAt,
                dateTo: this.period ? this.downloadToDate : moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
                itemId: this.itemId ? this.itemId : null
            }
        };
        if (date) {
            body.data.dateFrom = date.from;
            body.data.dateTo = date.to;
        }
        if (this.type > 1) {
            body.data.reportType = DOWNLOAD_TYPE[this.type];
        }
        if (this.individuality) {
            body.data.id = this.individuality;
        }
        if (this.useSelect) {
            body.data.selectedData = this.selectedData;
        } else {
            body.data.selectedData = angular.extend({}, EMPTY_SELECTED_DATA, { all: 1 });
        }
        return body;
    }

    setDownloadCert = () => {
        const that = this;
        this.timeout(() => {
            that.noneCetificate = false;
        }, 2000);
    };

    prepareReport = (date) => {
        this.isOpenCalend = false;
        let opt = setOptions();
        this.messageStatus = this.statusParams.inProgress;
        opt.headers['Content-Type'] = 'application/json;charset=UTF-8';
        opt.method = 'POST';
        opt.body = JSON.stringify(this.setBody(date));
        fetch(`${window.config.SYSTEM_URL}${window.config.API_URL}/daemon/start`, opt).then(res => {
            return res.json();
        }).then((response) => {
            if (!response.success) {
                this.noneCetificate = true;
                this.messageStatus = this.statusParams.readyToStart;
                this.scope.$apply();
            } else {
                this.fetchDaemonsMessage();
            }
        });
    }
}

DaemonDownloadController.$inject = [
    '$ngRedux',
    '$scope',
    'gettextCatalog',
    '$timeout',
    '$location',
    '$injector',
];

export {
    DaemonDownloadController
};
