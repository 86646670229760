import style from './style.modules.css';

class LdapDepartmentController {
    constructor(scope) {
        this.scope = scope;
        this.style = style;
    }

    $onInit = () => {
        this.scope.$watch(scope => {
            return scope.ldapDepartmentCtrl.ldapDepartmentsCtrl.searchString;
        }, (newValue, oldValue) => {
            if (newValue === oldValue) return;
            this.targets = this.setTargets();
        });
        this.scope.$watch(scope => {
            return scope.ldapDepartmentCtrl.ldapDepartment.showTargets;
        }, (newValue, oldValue) => {
            if (newValue === oldValue) return;
            this.targets = this.setTargets();
        });
        this.targets = this.setTargets();
    }

    setTargets = () => {
        return this.ldapDepartment.children.filter(item => item.type === 'list' && item.show);
    }
}

LdapDepartmentController.$inject = [
    '$scope'
];

export {
    LdapDepartmentController,
};
