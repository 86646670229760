import { EditSelectedModal } from '../editSelectedModal';
import { ReportTargetsModal } from '../reportTargetsModal';
import { CampaignModal } from '../campaignModal';
import { CampaignScheduleModal } from '../campaignScheduleModal';
import { EmployeesDeleteModal } from '../employeesDeleteModal';
import { CampaignStartModal } from '../campaignStartModal';
import style from './style.modules.css';
import { hasAccess } from '../../reselect';
import angular from 'angular';
import { EMPTY_SELECTED_DATA } from '../../const';
import { setLinkings, clearSelectedData, setSelectedData } from '../../toolkit/actions';
import { fetchDashboardParams } from '../../toolkit/think';

class BottomPanelController {
    constructor($localStorage, $ngRedux, $injector, $state, $location, $scope) {
        this.$localStorage = $localStorage;
        this.state = $state;
        this.$location = $location;
        this.$injector = $injector;
        this.scope = $scope;
        this.trainingTemplate = 'send-to-training.html';
        this.style = style;
        this.firstLoading = true;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { setLinkings, clearSelectedData, setSelectedData, fetchDashboardParams })(this);
    }

    mapStateToThis = (state) => {
        return {
            selectedData: state.selectedData.selectedData,
            linkNames: state.breadcrumbLinks.link,
            targetAttackAccess: hasAccess(state, { sectionId: 1, rightName: 'target_attack' }),
            targetEducationAccess: hasAccess(state, { sectionId: 1, rightName: 'target_education' }),
            reportEducationAccess: hasAccess(state, { sectionId: 1, rightName: 'report_education' }),
            targetHistoryAccess: hasAccess(state, { sectionId: 1, rightName: 'target_history' }),
            targetDeleteAccess: hasAccess(state, { sectionId: 1, rightName: 'target_delete' }),
            targetSaveAccess: hasAccess(state, { sectionId: 1, rightName: 'target_save' }),
            vulnerabiltyFixAccess: hasAccess(state, { sectionId: 3, rightName: 'vulnerability_fix' }),
            hashPath: state.linking.link,
            anonymization: state.auth.auth.license.features.anonymization,
        };
    };

    $onInit() {
        this.scope.$watch(scope => {
            return scope.$.hashPath;
        }, (newVal, oldVal) => {
            if (newVal === oldVal && !this.firstLoading) {
                return;
            }
            this.firstLoading = false;
            if (newVal === 'createAttack') {
                this.createAttack();
                this.setLinkings('');
            }
        });
    }

    backToSchedulerTask = (saveSelectedData = false) => {
        const { $localStorage } = this;
        if ($localStorage.schedulerTaskData) {
            if (saveSelectedData) {
                $localStorage.schedulerTaskData.selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
            }
            this.clearSelectedData();
        }
        if ($localStorage.schedulerTaskSelectedData) {
            if (saveSelectedData) {
                $localStorage.schedulerTaskSelectedData.schedulerTask.selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
            }
            this.clearSelectedData();
        }
        this.state.go('app.index.settings');
    };

    backHistory = () => {
        const { $localStorage } = this;
        if ($localStorage.reportData && $localStorage.reportData.selectedData) {
            let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy($localStorage.reportData.selectedData));
            this.setSelectedData(selectedData);
        }
        this.showHistory();
    };

    showHistory = () => {
        if (this.targetHistoryAccess) {
            const { $localStorage } = this;
            this.modal = this.$injector.instantiate(ReportTargetsModal);
            let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
            selectedData.courses = [$localStorage.openedCourse];
            this.modal.open(selectedData).then((selectedData) => {
                if (selectedData) {
                    if ($localStorage.reportData && $localStorage.reportData.selectedData) {
                        selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy($localStorage.reportData.selectedData));
                        this.setSelectedData(selectedData);
                    }
                } else {
                    $localStorage.campaignData = null;
                }
            }, () => {
                $localStorage.campaignData = null;
            });
        }
    };

    createAttack = () => {
        if (this.targetAttackAccess) {
            const { $localStorage } = this;
            if (!$localStorage.lastPage) {
                $localStorage.lastPage = this.$location.path();
            }
            this.modal = this.$injector.instantiate(CampaignModal);
            this.modal.open(null).then((campaignData) => {
                if (campaignData) {
                    if (!$localStorage.campaignData) {
                        // this.state.go('app.index.attacks');
                    } else if ($localStorage.campaignData && $localStorage.campaignData.selectedData) {
                        let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy($localStorage.campaignData.selectedData));
                        this.setSelectedData(selectedData);
                    }
                } else {
                    this.backToStart($localStorage.campaignData);
                    $localStorage.campaignData = null;
                    this.setReloading(false);
                    this.setReloading(true);
                }
            }, (some) => {
                some();
                $localStorage.campaignData = null;
                this.setReloading(false);
                this.setReloading(true);
            });
        }
    };

    backToStart = (campaignData) => {
        const { $localStorage } = this;
        if (campaignData && campaignData.id) {
            this.state.go('app.index.attacks');
            if ($localStorage.campaignStart) {
                if (campaignData && campaignData.name) {
                    $localStorage.campaignStart.name = campaignData.name;
                }
                this.modal = this.$injector.instantiate(CampaignStartModal);
                this.modal.open($localStorage.campaignStart).then((senderData) => {
                    if (senderData && window.campaignsCtrl) {
                        window.campaignsCtrl.load(true);
                    }
                }, () => {
                });
            } else if ($localStorage.campaignSchedule) {
                if (campaignData && campaignData.name) {
                    $localStorage.campaignSchedule.name = campaignData.name;
                }
                this.modal = this.$injector.instantiate(CampaignScheduleModal);
                this.modal.open($localStorage.campaignSchedule).then((senderData) => {
                    if (senderData && window.campaignsCtrl) {
                        window.campaignsCtrl.load(true);
                    }
                }, () => {
                });
            } else {
                if (typeof (this.clearAll) == 'function') {
                    this.clearAll();
                }
            }
        } else {
            if (typeof (this.clearAll) == 'function') {
                this.clearAll();
            }
        }
    };

    backAttack = () => {
        const { $localStorage } = this;
        if ($localStorage.campaignData) {
            let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy($localStorage.campaignData.selectedData));
            this.setSelectedData(selectedData);
        }
        this.createAttack();
    };

    deleteSelected = () => {
        if (this.targetDeleteAccess) {
            const { $localStorage } = this;
            this.modal = this.$injector.instantiate(EmployeesDeleteModal);
            let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), this.selectedData);
            selectedData.courses = [$localStorage.openedCourse];
            selectedData.managerId = $localStorage.openedManager;
            this.modal.open(selectedData).then((selectedData) => {
                if (selectedData) {
                    if (typeof (this.clearAll) == 'function') {
                        this.clearAll();
                    }
                    this.fetchDashboardParams();
                    this.setReloading(false);
                    this.setReloading(true);
                }
            }, () => {
                this.setReloading(false);
                this.setReloading(true);
            });
        }
    };

    editSelected = () => {
        if (this.targetSaveAccess) {
            const { $localStorage } = this;
            this.modal = this.$injector.instantiate(EditSelectedModal);
            let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
            selectedData.courses = [$localStorage.openedCourse];
            this.modal.open(selectedData).then((response) => {
                if (response) {
                    if (typeof (this.clearAll) == 'function') {
                        this.clearAll();
                    }
                    if (response.departmentId) {
                        let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
                        selectedData.departmentsUsed.push(response.departmentId);
                        selectedData.targets[response.departmentId] = [];
                        angular.forEach(response.targetIds, (targetId) => {
                            selectedData.targets[response.departmentId].push(+targetId);
                        });
                        this.setSelectedData(selectedData);
                    }
                }
                this.setReloading(false);
                this.setReloading(true);
            }, () => {
                this.setReloading(false);
                this.setReloading(true);
            });
        }
    };

    $onDestroy() {
        this.unsubscribe();
    }
}

BottomPanelController.$inject = [
    '$localStorage',
    '$ngRedux',
    '$injector',
    '$state',
    '$location',
    '$scope',
];

export {
    BottomPanelController,
};
