import inputNumberTemplate from './view.html';
import style from './style.modules.css';

class InputNumberController {
    constructor () {
        this.style = style;
        this.waitValue = null;
        this.minValue = 1;
        this.number = null;
        this.maxValue = 999;
    }

    $onInit = () => {
        this.value = this.currentValue;
    }

    set value (value) {
        if (!value) {
            this.number = null;
            return;
        }
        let num = value.toString().match(/\d{0,3}/)[0];
        let input = value.toString().match(/\d{0,3}/)['input'];
        if (+input > this.maxValue) {
            this.number = this.maxValue;
        } else if (+input < this.minValue) {
            this.number = this.minValue;
        } else {
            this.number = +num;
        }
        this.setValue(this.number);
    }

    onHandleFocus = () => {
        this.waitValue = this.number;
        this.number = null;
    }

    onHandleBlur = () => {
        if (!this.number) {
            this.number = this.waitValue;
        }
        this.waitValue = null;
    }

    get value () {
        return this.number;
    }

    setvalue = (event, position) => {
        event.stopPropagation();
        if (position === 'increment' && this.value < this.maxValue) {
            ++this.value;
        }
        if (position === 'decrement' && this.value>this.minValue) {
            --this.value;
        }
    }

    $onDestroy () {}
}

InputNumberController.$inject = [];

const InputNumberComponent = {
    template: inputNumberTemplate,
    controller: InputNumberController,
    controllerAs: '$',
    scope: true,
    bindings: {
        setValue: '=',
        currentValue: '<'
    },
};

export {
    InputNumberComponent,
};
