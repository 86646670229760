export default (payload, options) => {
    if (payload && payload.options) {
        options = payload.options;
    }
    if (!options || !options.headers) {
        options = {};
        options.headers = {};
    }
    options.headers['X-Csrf-Token'] = window.csrfToken;
    options.headers['Enable-Session'] = 1;
    return options;
};
