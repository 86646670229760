import modalTemplate from './view.html';
import { BulkDeleteModalController } from './controller';
import { BUlK_DELETE_MODAL } from '../../const';

class BulkDeleteModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    open = (entity) => {
        this.modalInstance = this.$uibModal.open({
            template: modalTemplate,
            bindToController: true,
            controller: BUlK_DELETE_MODAL,
            controllerAs: '$',
            size: 'nm',
            resolve: {
                entity: entity,
            },
            backdrop: 'static',
            keyboard: false,
        });
        return this.modalInstance.result;
    };
}

BulkDeleteModal.$inject = [
    '$uibModal'
];

export {
    BulkDeleteModal,
    BulkDeleteModalController,
};
