import React from 'react';

function ModalFooter({ btnDelete, titleBtnClose, titleBtnOk, closeModal, onEnter, disabled, style, styleBtnOk, disabledCancel }) {
    return (
        <div className='react-modal-footer_content' data-testid='react-modal-footer'>
            <button data-testid='cancel' disabled={disabledCancel} onClick={closeModal} className={`btn btn-default btn-left ${style}`}>
                {titleBtnClose}
            </button>
            <button data-testid={!btnDelete ? 'save' : 'delete'}
                style={styleBtnOk}
                onClick={onEnter}
                disabled={disabled}
                className={`btn btn-primary btn-left ${style}`}>
                {titleBtnOk}
            </button>
        </div>
    );
}

export default ModalFooter;
