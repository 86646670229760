import style from './style.modules.css';

class CampaignAttachmentController {
    constructor(gettextCatalog, $scope) {
        this.style = style;
        this.$scope = $scope;
        this.gettextCatalog = gettextCatalog;
        this.showAttachment = false;
        this.zipSelectOpen = false;
        this.zipPass = false;
        this.zip = false;
        this.archiveType = 1;
    }

    $onInit() {
        this.showAttachment = this.sendAttachment;
        this.setSendAttachment(this.showAttachment);
        if (this.attachment) {
            this.patcher = this.attachment.patcher;
        }
        this.initZipAction();
        window.addEventListener('click', this.zipSelect);
    }

    getLinkText () {
        if (this.showAttachment && this.activeFileLink) return this.gettextCatalog.getString('Файл по ссылке и вложение');
        if (!this.showAttachment && this.activeFileLink) {
            return this.gettextCatalog.getString('Файл по ссылке');
        }
        return this.gettextCatalog.getString('Вложение');
    }

    toggleAttachment (click = false) {
        if (click) {
            this.setSendAttachment(!this.showAttachment);
            this.showAttachment = !this.showAttachment;
        }
        if (!this.attachment && (this.showAttachment || this.activeFileLink)) {
            this.chooseDefaultAttachment();
        }
        if (this.attachment && (!this.showAttachment && !this.activeFileLink)) {
            this.clearAttachment();
        }
    }

    setZip = () => {
        if (this.zip) {
            this.setZipAction(null);
            return;
        }
        this.setZipAction(this.archiveType);
    }

    setArchiveType = (archive) => {
        this.archiveType = archive;
        if (this.zip) {
            this.setZipAction(archive);
        }
    }

    zipSelect = (e) => {
        const target = e.target;
        if (!this.zipSelectOpen && (target.id === 'zip' || target.parentNode.id === 'zip')) {
            this.zipSelectOpen = true;
            this.$scope.$apply();
        }
        if (this.zipSelectOpen && target.id !== 'zip' && target.parentNode.id !== 'zip') {
            this.zipSelectOpen = false;
            this.$scope.$apply();
        }
    }

    initZipAction = () => {
        if (!this.zip) return;
        this.archiveType = this.zip;
    }

    clearAttachment = () => {
        this.setAttachmentFakeName(null);
        this.setAttachment(null);
        this.setAttachmentActions(null);
        this.setZipAction(null);
    }

    chooseDefaultAttachment = () => {
        this.setAttachment(this.attachments[0]);
        this.patcher = this.attachments[0].patcher;
    }

    choosePatcher = (patcher) => {
        if (this.state) return;
        this.patcher = patcher;
        this.setAttachment(this.filterAttachments(patcher)[0]);
    };

    filterAttachments = (patcher = this.patcher) => {
        if (!this.patcher) {
            this.chooseDefaultAttachment();
            patcher = this.patcher;
        }
        let array = this.attachments.filter(item => item.patcher.id === patcher.id);
        return array;
    }

    $onDestroy () {
        window.removeEventListener('click', this.zipSelect);
    }
}

CampaignAttachmentController.$inject = [
    'gettextCatalog',
    '$scope'
];

export {
    CampaignAttachmentController,
};
