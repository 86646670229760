class LogsModalController {
    constructor(modalData) {
        this.modalData = modalData;
        this.logs = modalData.logs;
        this.title = modalData.title;
    }
}

LogsModalController.$inject = [
    'modalData',
];

export {
    LogsModalController,
};
