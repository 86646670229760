import settingsSsoProfileTemplate from './view.html';

import { SettingsSsoProfileController } from './controller';

const SettingsSsoProfileComponent = {
    template: settingsSsoProfileTemplate,
    controller: SettingsSsoProfileController,
    controllerAs: '$',
    bindings: {
        ssoProfiles: '<'
    }
};

export {
    SettingsSsoProfileComponent
};
