import style from './style.modules.css';

class ModalHeaderController {
    constructor (gettextCatalog) {
        this.style = style;
        this.gettextCatalog = gettextCatalog;
    }

    $onInit () {}
}

ModalHeaderController.$inject = [
    'gettextCatalog',
];

export {
    ModalHeaderController
};
