import ldapDepartmentsTemplate from './view.html';

import { LdapDepartmentsController } from './controller';

const LdapDepartmentsComponent = {
    template: ldapDepartmentsTemplate,
    controller: LdapDepartmentsController,
    controllerAs: '$',
    bindings: {
        employeesModalCtrl: '=',
        importFromLdapCtrl: '=',
        loadDashboard: '=',
        setLanguageId: '=',
        selectedData: '<',
        setSelectedData: '<',
        setLoading: '<',
        setError: '<',
        getSelectedData: '=',
        groups: '<'
    },
};

export {
    LdapDepartmentsComponent,
};
