import React, { useContext, useEffect, useState, useRef } from 'react';
import { GettextContext, InjectorContext } from 'rootReact/context';
import { SchedulerTaskModal } from 'root/assets/webpack/src/js/components/schedulerTaskModal';
import SettingsHeader from 'rootReact/components/SettingsSectionHeader';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'rootReact/UI/Table';
import Translate from 'rootReact/Translate.jsx';
import TriggerCell from 'rootReact/components/Scheduler/TriggerCell';
import ActionCell from 'rootReact/components/Scheduler/ActionCell';
import { usePlural } from 'rootReact/hook';
import ReactIcon from 'rootReact/components/ReactIcons';
import style from './style.module.less';
import { deleteSchedulerTasks, toggleSchedulerTask, setNewSchedulerTask, editSchedulerTask } from 'root/assets/webpack/src/js/toolkit/schedulerTasks';
import { setLinkings } from 'root/assets/webpack/src/js/toolkit/actions';
import SaveFile from 'rootReact/components/SaveFile';

const headerNames = [
    'Название', 'Что случилось', 'Что сделать', 'Выполнить', ''
]

function Scheduler({ licenseIsValid }) {
    const gettext = useContext(GettextContext);
    const myRef = useRef(null);
    const hashPath = useSelector(state => state.linking.link);
    const injector = useContext(InjectorContext);
    const [disabledItem, setDisabled] = useState([]);
    const schedulerTasks = useSelector(state => state.schedulerTasks.task);
    const triggerTypes = useSelector(state => state.settings.settings.triggerTypes);
    const actionTypes = useSelector(state => state.settings.settings.actionTypes);
    const templates = useSelector(state => state.templates.templates);
    const reportTypes = useSelector(state => state.settings.settings.reportTypes);
    const taskLoad = useSelector(state => state.schedulerTasks.taskLoading);
    const courses = useSelector(state => state.courses.courses);
    const userName = useSelector(state => state.auth.auth.fullName);
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem('ngStorage-schedulerTaskData') || localStorage.getItem('ngStorage-schedulerTaskSelectedData')) {
            openModal();
            const executeScroll = () => myRef.current.scrollIntoView(false);
            executeScroll();
        }
    }, []);

    useEffect(() => {
        let disabled = schedulerTasks.filter(item => !item.task_status).map(item => item.id);
        setDisabled(disabled);
    }, [schedulerTasks]);

    useEffect(() => {
        if (hashPath === 'scheduler') {
            const executeScroll = () => myRef.current.scrollIntoView(false);
            executeScroll();
            openModal();
            dispatch(setLinkings(''));
        }
        if (hashPath === 'schedulerField') {
            const executeScroll = () => myRef.current.scrollIntoView(false);
            executeScroll();
            dispatch(setLinkings(''));
        }
    }, [hashPath]);

    const files = [
        [gettext.getString('журнал действий'), '/v2/scheduler-tasks/report', 'scheduler[data].csv']
    ];
    const getHeaderLink = () => {
        return (
            <div style={{display: 'flex'}}>
                <a onClick={() => licenseIsValid && openModal()} className={`settings_path-right ${!licenseIsValid ? 'nolink' : ''}`}>{gettext.getString('добавить')}</a>
                <SaveFile files={files}/>
            </div>
        );
    };

    const setPluralTimeStart = (item) => {
        if (!item.delay_period || item.delay_period == 'minute') {
            return usePlural(item.delay_value, ['минута', 'минуты', 'минут']);
        } else if (item.delay_period == 'hour') {
            return usePlural(item.delay_value, ['час', 'часа', 'часов']);
        } else if (item.delay_period == 'day') {
            return usePlural(item.delay_value, ['день', 'дня', 'дней']);
        } else if (item.delay_period == 'week') {
            return usePlural(item.delay_value, ['неделя', 'недели', 'недель']);
        }
    };

    const setPluralTimeDelay = (task) => {
        if (!task.action_period || task.action_period === 'hour') {
            return usePlural(task.delay_value, ['раз в час', 'раза в час', 'раз в час']);
        } else if (task.action_period === 'day') {
            return usePlural(task.delay_value, ['раз в день', 'раза в день', 'раз в день']);
        } else if (task.action_period === 'week') {
            return usePlural(task.delay_value, ['раз в неделю', 'раза в неделю', 'раз в неделю']);
        } else if (task.action_period === 'month') {
            return usePlural(task.delay_value, ['раз в месяц', 'раза в месяц', 'раз в месяц']);
        }
    };

    const setStartName = (item) => {
        if (!item.delay_value && !item.action_value) {
            return `${gettext.getString('сразу')}`;
        }
        if (item.delay_value && !item.action_value) {
            return `${gettext.getString('с задержкой')} ${item.delay_value} ${gettext.getString(setPluralTimeStart(item))}`;
        }
        if (item.action_value) {
            return `${gettext.getString('не чаще, чем')} ${item.action_value} ${gettext.getString(setPluralTimeDelay(item))}`;
        }
    };

    const openModal = (id = 0) => {
        let modal = injector.instantiate(SchedulerTaskModal);
        modal.open(id, window.settingsCtrl).then(item => {
            if (!item) return;
            id ? editTusk(item) : setTusk(item);
        });
    };

    const editTusk = (item) => {
        dispatch(editSchedulerTask(item));
    }

    const handleRow = (id) => {
        if (taskLoad) return;
        openModal(id);
    };

    const iconDelete = (e, id) => {
        e.stopPropagation();
        if (taskLoad || !confirm(gettext.getString('Удалить действие?'))) return;
        dispatch(deleteSchedulerTasks(id));
    };

    const setTusk = (tusk) => {
        dispatch(setNewSchedulerTask(tusk))
    }

    const toggleScript = (e, id, status) => {
        e.stopPropagation();
        if (taskLoad) return;
        dispatch(toggleSchedulerTask({id, status}));
    }

    return (
        <div id={'scheduler-field'} className={style.wrapper} ref={myRef}>
            <SettingsHeader title={gettext.getString('Планировщик')} right>{getHeaderLink()}</SettingsHeader>
            <div className={'settings_item'}>
                <Table>
                    <TableHead>
                        {headerNames.map(name => (
                            <TableCell key={name} Style={'react-table_header-cell'}>
                                <Translate>{name}</Translate>
                            </TableCell>
                        ))}
                    </TableHead>
                    <TableBody>
                        {schedulerTasks.map(task => (
                            <TableRow key={task.id}
                                      handleClick={() => handleRow(task.id)}
                            >
                                <TableCell Style={!task.task_status && style.notActive}>{task.name}</TableCell>
                                <TableCell Style={!task.task_status && style.notActive}>
                                    <TriggerCell trigger={task.triggers}
                                                 triggerTypes={triggerTypes}
                                                 courses={courses}
                                                 logicMode={task.logic_mode}
                                    />
                                </TableCell>
                                <TableCell Style={!task.task_status && style.notActive}>
                                    <ActionCell action={task.actions}
                                                reportTypes={reportTypes}
                                                actionTypes={actionTypes}
                                                templates={templates}
                                                courses={courses}
                                                userName={userName}
                                    />
                                </TableCell>
                                <TableCell Style={!task.task_status && style.notActive}>{setStartName(task)}</TableCell>
                                <TableCell>
                                    {task.task_status ?
                                            <ReactIcon icoClass={'pause'}
                                                       disabled={!taskLoad}
                                                       style={{fill: 'black'}}
                                                       handleClick={(e) => toggleScript(e, task.id, +!task.task_status)}
                                            />
                                            : <ReactIcon icoClass={'play'}
                                                         tooltip={<Translate>включить</Translate>}
                                                         disabled={!taskLoad}
                                                         style={{fill: 'black'}}
                                                         handleClick={(e) => toggleScript(e, task.id, +!task.task_status)}
                                            />}
                                    <ReactIcon handleClick={(e) => iconDelete(e, task.id)}
                                               icoClass={'delete'}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
}

export default Scheduler;
