import { DepartmentModal } from '../departmentModal';
import { GroupModal } from '../groupModal';
import { EmployeesModal } from '../employeesModal';
import style from './style.modules.css';
import { hasAccess } from '../../reselect';
import angular from 'angular';
import buildSearchString from '../../helpers/buildSearchString';
import { setSearchData, setLinkings } from '../../toolkit/actions';

class ToolbarController {
    constructor ($ngRedux, $localStorage, $injector, gettextCatalog, $stateParams, $scope) {
        this.style = style;
        this.$injector = $injector;
        this.$localStorage = $localStorage;
        this.gettextCatalog = gettextCatalog;
        this.$stateParams = $stateParams;
        this.allVisible = false;
        this.unAllVisible = false;
        this.noLink = false;
        this.selectLinkName = '';
        this.titleLink = false;
        this.departmentVisible = false;
        this.unDepartmentVisible = false;
        this.selectLinkName = '';
        this.$scope = $scope;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { setSearchData, setLinkings })(this);
        this.strings = {
            searchPlaceholder: gettextCatalog.getString('ФИО, почта или рейтинг')
        };
    }

    mapStateToThis = (state) => {
        return {
            hashPath: state.linking.link,
            selectedData: state.selectedData.selectedData,
            targetSaveAccess: hasAccess(state, { sectionId: 1, rightName: 'target_save' }),
            linkNames: state.breadcrumbLinks.link,
        };
    };

    $onInit () {
        this.search = angular.copy(this.selectedData.search) || [''];
        // this.setSearchData(this.search);
        this.setSelectedAnchor();
        this.$scope.$watch(scope => {
            return scope.$.selectedData.search;
        }, (newSearch, oldSearch) => {
            if (newSearch === oldSearch) {
                return;
            }
            if (newSearch && angular.isArray(newSearch)) {
                this.search = [...newSearch];
            } else if (newSearch && !angular.isArray(newSearch)) {
                this.search = [newSearch];
            } else {
                this.search = [''];
            }
            this.setSelectedAnchor();
        });
        this.$scope.$watch(scope => {
            return scope.$.selectedData;
        }, (newVal, oldVal) => {
            if (newVal === oldVal) {
                return;
            }
            this.setSelectedAnchor();
        });
        this.$scope.$watch(scope => {
            return scope.$.linkNames;
        }, (newVal, oldVal) => {
            if (newVal === oldVal) {
                return;
            }
            this.setSelectedAnchor();
        });
        this.$scope.$watch(scope => {
            return scope.$.departmentCount;
        }, (newVal, oldVal) => {
            if (newVal === oldVal) {
                return;
            }
            this.setSelectedAnchor();
        });
        this.$scope.$watch(scope => {
            return scope.$.hashPath;
        }, (newVal, oldVal) => {
            if (oldVal === newVal) {
                return;
            }
            if (newVal.indexOf('updateToolbar') > -1) {
                this.setSelectedAnchor();
                this.setLinkings('');
            }
        });
    }

    setSelectedAnchor = () => {
        this.isNoLinks();
        this.isTitleLink();
        this.unSelectDepartmentVisible();
        this.selectDepartmentVisible();
        this.unSelectAllVisible();
        this.selectAllVisible();
    };

    addDepartment = () => {
        if (this.targetSaveAccess) {
            let departmentId = 0;
            if (this.linkNames && this.linkNames.length > 0 && this.linkNames[this.linkNames.length - 1]) {
                departmentId = this.linkNames[this.linkNames.length - 1].childs ? this.linkNames[this.linkNames.length - 1].id : 0;
            }
            this.modal = this.$injector.instantiate(DepartmentModal);
            this.modal.open(null, departmentId).then(() => {
                if (this.setReloading) {
                    this.setReloading(true);
                }
            }, () => {
            });
        }
    };

    addGroup = () => {
        if (this.targetSaveAccess) {
            let groupId = 0;
            if (this.linkNames && this.linkNames.length > 0 && this.linkNames[this.linkNames.length - 1]) {
                groupId = this.linkNames[this.linkNames.length - 1].id;
            }
            this.modal = this.$injector.instantiate(GroupModal);
            this.modal.open(null, groupId).then(() => {
                if (this.setReloading) {
                    this.setReloading(true);
                }
            }, () => {
            });
        }
    };

    clearSearch = (searchKey) => {
        searchKey = +searchKey;
        if (searchKey == -1) {
            this.search = [''];
        } else {
            this.search = this.search.filter((item, i) => {
                return searchKey !== i;
            });
            if (this.search.length == 0) {
                this.search = [''];
            }
        }
        this.setSearchData(this.search);
        if (this.setReloading) {
            this.setReloading(true);
        }
    };

    toggleDepartment = (choice) => {
        let all = 'Toggle';
        if (choice) {
            if (this.allVisible) all = 'All';
            if (this.departmentVisible) all = this.modeData === 3 ? 'GroupSelect' : 'DepartmentSelect';
        } else {
            if (this.unAllVisible) all = 'Clear';
            if (this.unDepartmentVisible) all = 'DepartmentUnselect';
        }
        if (all === 'Clear') {
            this.departmentCheckedCache = [];
            this.departmentPartialCache = [];
        }
        this.setSelectedAnchor();
        this.setLinkings(`toolBar${all}`);
    };

    reverseSelection = () => {
        this.setSelectedAnchor();
        this.setLinkings('toolBarReverse');
    };

    setSearch = () => {
        this.setSearchData(this.search.filter(() => true));
    };

    addSearch = () => {
        if (!angular.isArray(this.search)) {
            this.search = [];
        }
        if (this.search.indexOf(null) === -1 && this.search.indexOf('') === -1) {
            this.search.unshift('');
        }
    };

    addEmployee = () => {
        if (this.targetSaveAccess) {
            let departmentId = 0;
            if (this.linkNames && this.linkNames.length > 0 && this.linkNames[this.linkNames.length - 1]) {
                departmentId = this.linkNames[this.linkNames.length - 1].id;
            }
            this.modal = this.$injector.instantiate(EmployeesModal, 'ui.bootstrap');
            this.modal.open(0, departmentId).then((employee) => {
                if (this.setReloading) {
                    this.setReloading(true);
                }
                if (typeof (this.addEmployeeCallback) == 'function') {
                    this.addEmployeeCallback(employee);
                }
            }, () => {
                if (this.setReloading) {
                    this.setReloading(true);
                }
                if (typeof (this.addEmployeeCallback) == 'function') {
                    this.addEmployeeCallback();
                }
            });
        }
    };

    $onDestroy () {
        this.unsubscribe();
    }

    isNoLinks = () => {
        this.noLink = this.linkNames && !this.linkNames.length;
        return this.noLink;
    };

    isTitleLink = () => {
        this.titleLink = this.linkNames && this.linkNames[this.linkNames.length - 1] && this.linkNames[this.linkNames.length - 1].isTitle;
        return this.titleLink;
    };

    selectAllVisible = () => {
        if (!this.linkNames)return;
        let departmentId = this.linkNames[this.linkNames.length - 1] && this.linkNames[this.linkNames.length - 1].fullName ? this.linkNames[this.linkNames.length - 1].id : null;
        if (this.modeData === 2 && this.linkNames.length === 1) {
            departmentId = null;
        }
        if ((!this.departmentCount && !this.targetCount && !this.groupCount) || this.departmentVisible || departmentId) {
            this.allVisible = false;
        } else if (!this.unAllVisible) {
            this.allVisible = true;
        } else {
            this.allVisible = (this.noLink || this.titleLink) &&
                ((this.selectedData && this.selectedData.selectedCount &&
                    this.selectedData.selectedCount.notSelectedTargets > 0 &&
                    this.selectedData.selectedCount.notSelectedTargets <= this.selectedData.selectedCount.allSearchTargets) ||
                    (this.targetCount > 0 && this.selectedData.selectedCount.allSearchTargets < this.targetCount));
        }
        return this.allVisible;
    };

    unSelectAllVisible = () => {
        if (!this.linkNames)return;
        let departmentId = this.linkNames[this.linkNames.length - 1] && this.linkNames[this.linkNames.length - 1].fullName ? this.linkNames[this.linkNames.length - 1].id : null;
        if (this.modeData === 2 && this.linkNames.length === 1) {
            departmentId = null;
        }
        if ((!this.departmentCount && !this.targetCount && !this.groupCount) || this.unDepartmentVisible || departmentId) {
            this.unAllVisible = false;
        } else {
            this.unAllVisible = (this.noLink || this.titleLink) &&
                (this.selectedData && this.selectedData.selectedCount &&
                    (this.selectedData.selectedCount.allSearchTargets != this.selectedData.selectedCount.notSelectedTargets) &&
                    (this.selectedData.selectedCount.targets > 0 || this.selectedData.selectedCount.departments > 0) &&
                    (this.selectedData.selectedCount.allSearchTargets > this.selectedData.selectedCount.notSelectedTargets || this.departmentCheckedCache.length > 0 || this.departmentPartialCache.length > 0));
        }
        return this.unAllVisible;
    };

    selectDepartmentVisible = () => {
        if (!this.linkNames)return;
        if (!this.noLink && !this.titleLink && !this.unDepartmentVisible) {
            this.departmentVisible = true;
        } else {
            let departmentId = this.linkNames[this.linkNames.length - 1] && this.linkNames[this.linkNames.length - 1].fullName ? this.linkNames[this.linkNames.length - 1].id : null;
            this.departmentVisible = departmentId && (!this.noLink && !this.titleLink) && (this.departmentCheckedCache.indexOf(departmentId) == -1 || this.departmentPartialCache.indexOf(departmentId) > -1);
        }
        return this.departmentVisible;
    };

    unSelectDepartmentVisible = () => {
        if (!this.linkNames)return;
        let departmentId = this.linkNames[this.linkNames.length - 1] && !this.linkNames[this.linkNames.length - 1].isTitle ? this.linkNames[this.linkNames.length - 1].id : null;
        this.unDepartmentVisible = departmentId && (!this.noLink && !this.titleLink) && (this.departmentCheckedCache.indexOf(departmentId) > -1 || this.departmentPartialCache.indexOf(departmentId) > -1);
        return this.unDepartmentVisible;
    };

    buildSearchString = () => {
        return buildSearchString(this.selectedData.search);
    };

    getSelectCondition = (choice) => {
        if (!this.isSelectable) return false;
        if (this.isRisk && this.viewMode) {
            this.selectLinkName = this.gettextCatalog.getString('Выбрать всех');
            return true;
        }
        if ((!~[0, 3].indexOf(this.modeData)
            && !(this.linkNames[0] && !!this.linkNames[0].isTitle))) return false;
        let visible = this.allVisible || this.departmentVisible;
        let unVisible = this.unDepartmentVisible || this.unAllVisible;
        if (choice && visible) {
            this.selectLinkName = this.allVisible ? this.gettextCatalog.getString('Выбрать всех')
                : (~[0, 1, 2].indexOf(this.modeData) ? this.gettextCatalog.getString('Выбрать отдел')
                    : this.gettextCatalog.getString('Выбрать группу'));
            return true;
        }
        if (!choice && unVisible) return true;
    }
}

ToolbarController.$inject = [
    '$ngRedux',
    '$localStorage',
    '$injector',
    'gettextCatalog',
    '$stateParams',
    '$scope'
];

export {
    ToolbarController
};
