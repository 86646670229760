
class LearningService {

    constructor ($resource) {
        this.$resource = $resource;
    }

    getLearningConfig = () => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/learning').get();
    };

    saveLearningConfig = (data, ctrl, success, error) => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/learning').save({}, data, function (response) {
            if (typeof (success) == 'function' && ctrl) {
                success(ctrl, response);
            }
        }, function (response) {
            if (typeof (error) == 'function' && ctrl) {
                error(ctrl, response);
            }
        });
    };

    courses = (data, ctrl, success, error) => {
        if (data) {
            return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/courses/config', null, {
                save: {
                    method: 'POST',
                    isArray: true
                }
            }).save({}, data, function (response) {
                if (typeof (success) == 'function' && ctrl) {
                    success(ctrl, response);
                }
            }, function (response) {
                if (typeof (error) == 'function' && ctrl) {
                    error(ctrl, response);
                }
            });
        } else {
            return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/courses').query();
        }
    };

    sendToTraining = (data, ctrl, success, error) => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/learning/sendToTraining').save({}, data, function (response) {
            if (typeof (success) == 'function' && ctrl) {
                success(ctrl, response);
            }
        }, function (response) {
            if (typeof (error) == 'function' && ctrl) {
                error(ctrl, response);
            }
        });
    };
    cancelTraining = (data, ctrl, success, error) => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/learning/cancelTraining').save({}, data, function (response) {
            if (typeof (success) == 'function' && ctrl) {
                success(ctrl, response);
            }
        }, function (response) {
            if (typeof (error) == 'function' && ctrl) {
                error(ctrl, response);
            }
        });
    };

    getQueue = (query) => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/learning/queue').get(query);
    };

    deleteLogo = (data, ctrl, success, error) => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/learning/logotype').delete({}, data, function (response) {
            if (typeof (success) == 'function' && ctrl) {
                success(ctrl, response);
            }
        }, function (response) {
            if (typeof (error) == 'function' && ctrl) {
                error(ctrl, response);
            }
        });
    };
}

export {
    LearningService
};
