import reportSideBarTemplate from './view.html';

import { ReportSideBarController } from './controller';

const ReportSideBarComponent = {
    template: reportSideBarTemplate,
    controller: ReportSideBarController,
    controllerAs: '$',
    bindings: {},
};

export {
    ReportSideBarComponent,
};
