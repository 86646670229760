import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';

const initialState = {
    settings: {},
    load: true,
    error: false
};

export const fetchSettings = createAsyncThunk(
    'settings/status',
    async () => {
        const fetchApi = new FetchApi('/settings');
        let response = await fetchApi.get();
        if (response.isError) {
            throw response;
        }
        return response;
    }
);

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setLearningConfig(state, { payload }) {
            state.settings.learning = {...state.settings.learning, ...payload}
        },
        settingsDefault(state) {
            state = initialState;
        }
    },
    extraReducers: {
        [fetchSettings.fulfilled]: (state, { payload }) => {
            state.settings = payload;
            state.load = false;
            state.error = false;
        },
        [fetchSettings.pending]: (state) => {
            state.load = true;
            state.error = false;
        },
        [fetchSettings.rejected]: (state) => {
            state.load = false;
            state.error = true;
        },
    }
});

export const {settingsDefault, setLearningConfig} = settingsSlice.actions;
export default settingsSlice.reducer;

