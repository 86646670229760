
class DashboardService {
    constructor($resource) {
        this.resource = $resource(window.config.SYSTEM_URL + window.config.API_URL + '/dashboard');
    }

    getInfo = () => {
        return this.resource.get();
    };
}

export {
    DashboardService
};
