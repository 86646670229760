import { SENDER_DOMAINS_SERVICE } from '../../const';
import style from './style.modules.css';

class CampaignModalSenderAddressController {
    constructor(SenderDomainsService) {
        this.SenderDomainsService = SenderDomainsService;
        this.domainRe = /^((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,10}(?:\.[a-z]{2})?)$/i;
        this.style = style;
        this.inProcess = null;
        this.errors = null;
        this.timeout = null;
        this.startCheckDomain = false;
    }

    $onInit () {
        if (this.campaign.domainInLinks) {
            this.validateSenderDomain(true);
        }
    }

    isDefaultSenderDomain = () => {
        let domain = null;
        if (this.senderDomains) {
            domain = this.senderDomains.find((domain) => {
                if (domain.name == this.campaign.domain) {
                    return true;
                }
            });
        }
        return !domain || +domain.isDefault > 0;
    };

    isSelectedSenderDomain = () => {
        let domain = this.senderDomains.find((domain) => {
            if (domain.name == this.campaign.domain) {
                return true;
            }
        });
        return domain ? true : false;
    };

    removeSenderDomain = () => {
        let domain = this.senderDomains.find((domain) => {
            if (domain.name == this.campaign.domain) {
                return true;
            }
        });
        if (domain && domain.id) {
            this.SenderDomainsService.one(domain.id).then((domain) => {
                domain.$delete().then(() => {
                    this.senderDomains = this.senderDomains.filter((item) => {
                        if (item.id != domain.id) {
                            return true;
                        }
                    });
                    this.campaign.domain = '';
                    this.campaign.domainInLinks = null;
                    this.errors = null;
                });
            });
        } else {
            this.senderDomains = this.senderDomains.filter((item) => {
                if (item.name != this.campaign.domain) {
                    return true;
                }
            });
            this.campaign.domain = '';
            this.campaign.domainInLinks = null;
            this.errors = null;
        }
    };

    onChangeFrom = function() {
        if (this.ctrl && this.campaign && this.campaign.template && this.campaign.template.from && this.campaign.template.from != this.campaign.from) {
            this.campaign.template = null;
            this.ctrl.lastTemplate = null;
        }
    };

    onChangeDomain() {
        if (this.fieldChanged) {
            this.fieldChanged('domain');
        }
        this.errors = null;
        this.inProcess = false;
        this.campaign.domainInLinks = false;
    }

    onChangeFrom = () => {
    };

    changeDomainInLinks = function() {
        if (+this.campaign.state == 0) {
            this.errors = null;
            this.campaign.domainInLinks = !this.campaign.domainInLinks;
            if (this.campaign.domainInLinks) {
                this.validateSenderDomain();
            }
        }
    };

    validateSenderDomain = (isFirst = false) => {
        this.errors = null;
        this.inProcess = true;
        if (this.campaign.domain && this.campaign.domain.name) {
            this.campaign.domain = this.campaign.domain.name;
        }
        this.SenderDomainsService.factory.checkDomain(
            { domain: this.campaign.domain },
            (response) => {
                this.errors = null;
                this.inProcess = null;
                if (response.result) {
                    this.campaign.domainInLinks = true;
                    if (!this.isSelectedSenderDomain() && this.campaign.domain) {
                        this.senderDomains.push({
                            id: response.senderDomainId,
                            name: this.campaign.domain,
                            isDefault: 0,
                        });
                    }
                } else {
                    this.errors = true;
                    this.campaign.domainInLinks = null;
                    if (!this.isSelectedSenderDomain() && this.campaign.domain) {
                        this.senderDomains.push({
                            id: response.senderDomainId,
                            name: this.campaign.domain,
                            isDefault: 0,
                        });
                    }
                }
            },
            () => {
                this.errors = !isFirst;
                this.inProcess = null;
                this.campaign.domainInLinks = null;
            },
        );
    };
}

CampaignModalSenderAddressController.$inject = [
    SENDER_DOMAINS_SERVICE,
];

export {
    CampaignModalSenderAddressController,
};
