import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';

const initialState = {
    status: 'idle',
    variables: {},
    error: false
};

export const fetchVariables = createAsyncThunk(
    'variables/fetchVariablesStatus',
    async () => {
        const fetchApi = new FetchApi('/variables');
        const response = await fetchApi.get();
        return response;
    }
);

const variablesSlice = createSlice({
    name: 'variables',
    initialState,
    extraReducers: {
        [fetchVariables.fulfilled]: (state, { payload }) => {
            state.variables = payload;
            state.status = 'load';
        },
        [fetchVariables.pending]: (state, { payload }) => {
            state.variables = payload;
            state.status = 'pending';
        },
        [fetchVariables.rejected]: (state, { payload }) => {
            state.variables = payload;
            state.status = 'rejected';
        },
    }
});

export default variablesSlice.reducer;

