import { AUTH_SERVICE, GROUPS_SERVICE, MARKS_SERVICE } from '../../const';
import angular from 'angular';
import { fetchDashboardParams } from '../../toolkit/think';

class EmployeesModalController {
    //ВОПРОС зачем тут departmentId ??? ни где не используется
    //ОТВЕТ он передается во вьюхе в компонент
    constructor($uibModalInstance, AuthService, MarksService, GroupsService, targetId, departmentId, viewMode, $ngRedux) {
        this.AuthService = AuthService;
        this.MarksService = MarksService;
        this.GroupsService = GroupsService;
        this.inProcess = false;
        this.$uibModalInstance = $uibModalInstance;
        this.tab = 1;
        this.targetId = targetId;
        this.departmentId = departmentId;
        this.viewMode = viewMode;
        this.appCtrl = window.appCtrl;
        window.employeesModalCtrl = this;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { fetchDashboardParams })(this);
    }

    $onInit = () => {
        this.userInfo = this.AuthService.getUserInfo('licenseFeatures,licenseDomain,targetImportXlsxLimit,targetImportLdapLimit,targetImportLdapExternalIdEnabled,bossKadrovikEnabled');
        if (angular.isNumber(this.viewMode)) {
            this.tab = this.viewMode;
        }
    };

    setTab = (tab) => {
        if (tab == 1 || (tab == 2 && window.appCtrl.hasAccess(1, 'target_file')) || (tab == 3 && window.appCtrl.hasAccess(1, 'target_ldap'))) {
            this.tab = tab;
        }
    };

    getCsrfToken = () => {
        return window.csrfToken;
    };

    setInProcess = (inProcess) => {
        this.inProcess = inProcess;
    };

    loadTags = (query) => (
        this.MarksService.loadTags(query).$promise.then((response) => {
            return response;
        })
    );

    loadGroups = () => [];

    mapStateToThis = (state) => {
        return {
            anonymization: state.auth.auth.license.features.anonymization,
        };
    };

}


EmployeesModalController.$inject = [
    '$uibModalInstance',
    AUTH_SERVICE,
    MARKS_SERVICE,
    GROUPS_SERVICE,
    'targetId',
    'departmentId',
    'viewMode',
    '$ngRedux',
];

export {
    EmployeesModalController,
};
