import { DEPARTMENTS_SERVICE, EMPTY_SELECTED_DATA, TARGETS_SERVICE } from '../../const';
import angular from 'angular';

class EmployeesDeleteModalController {
    constructor(TargetsService, DepartmentsService, $localStorage, selectedData) {
        this.TargetsService = TargetsService;
        this.DepartmentsService = DepartmentsService;
        this.$localStorage = $localStorage;
        this.deleteStatistics = true;
        this.selectedData = selectedData;
    }

    delete = () => {
        let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
        if (selectedData) {
            this.deleteEmployeesError = false;
            this.deleteEmployeesLoading = true;
            selectedData.deleteStatistics = this.deleteStatistics ? 1 : 0;
            if (window.EducationCtrl) {
                window.EducationCtrl.updateEducation();
            }
            this.TargetsService.bulkDelete(selectedData, this, (ctrl) => {
                ctrl.$close(selectedData);
                ctrl.deleteEmployeesLoading = false;
                if (window.EducationCtrl) {
                    window.EducationCtrl.retry(null, null);
                }
            }, (ctrl, response) => {
                ctrl.deleteEmployeesLoading = false;
                if (response && response.data && response.data.error) {
                    ctrl.deleteEmployeesError = response.data.error;
                } else {
                    ctrl.deleteEmployeesError = true;
                }
                if (window.EducationCtrl) {
                    window.EducationCtrl.retry(null, null);
                }
            });
        }
    };
}

EmployeesDeleteModalController.$inject = [
    TARGETS_SERVICE,
    DEPARTMENTS_SERVICE,
    '$localStorage',
    'selectedData',
];

export {
    EmployeesDeleteModalController,
};
