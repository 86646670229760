import ldapTemplate from './view.html';

import { LdapMessageController } from './controller';

const LdapMessageComponent = {
    template: ldapTemplate,
    controller: LdapMessageController,
    controllerAs: '$',
    scope: true,
    bindings: {
        import: '<',
    },
};

export {
    LdapMessageComponent,
};
