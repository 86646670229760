import toolbarTemplate from './view.html';

import { ToolbarController } from './controller';

const ToolbarComponent = {
    template: toolbarTemplate,
    controller: ToolbarController,
    controllerAs: '$',
    scope: true,
    bindings: {
        isAddable: '<',
        isFilterable: '<',
        isSingleSearch: '<',
        isSelectable: '<',
        isReversible: '<',
        subFunctions: '<',
        isAnyLoading: '<',
        departmentCheckedCache: '=',
        departmentPartialCache: '=',
        modeData: '<',
        isRisk: '<',
        departmentCount: '<',
        groupCount: '<',
        targetCount: '<',
        setLoading: '<',
        setReloading: '<',
        isDisabled: '<',
        viewMode: '<',
    }
};

export {
    ToolbarComponent
};
