import style from './style.modules.css';
import modalTemplate from './twoFactorQR.html';
import setOptions from '../../helpers/setOptions';

export class TwoFactorQRController {
    constructor(qrCode, gettextCatalog, scope) {
        this.gettextCatalog = gettextCatalog;
        this.qrCode = qrCode;
        this.style = style;
        this.scope = scope;
        this.key = '';
        this.errorText = '';
        this.headerText = gettextCatalog.getString('Включение двухфакторной аутентификации');
    }

    $onInit () {
        const that = this;
        this.btnConfig = [
            {
                title: that.gettextCatalog.getString('Отменить'),
                action: () => {
                    that.$close();
                },
                selenium: 'cancel-btn',
                get disabled() {
                    return false;
                },
                class: 'default'
            },
            {
                title: that.gettextCatalog.getString('Сохранить'),
                action: () => {
                    that.oneTimePassEnter();
                },
                selenium: 'save-btn',
                get disabled() {
                    return false;
                },
                class: 'primary'
            }
        ];
    }

    oneTimePassEnter = async () => {
        try {
            this.errorText = '';
            let options = setOptions();
            options.body = JSON.stringify({ oneTimePassword: this.key });
            options.method = 'POST';
            options.headers['Content-Type'] = 'application/json';
            let response = await fetch(window.config.SYSTEM_URL + window.config.API_URL + '/users/enable-second-factor', options);
            if (!response.ok) {
                throw await response.json();
            }
            this.$close(true);
        } catch (e) {
            this.errorText = e.error ? [e.error] : [this.gettextCatalog.getString('Что-то пошло не так :-(')];
            this.scope.$apply();
        }
    }

    get oneTimePass () {
        let arr = this.key.match( /\d{1,3}/g ) || [];
        return arr.join(' ');
    }

    set oneTimePass (key) {
        this.key = key.split(' ').join('');
    }

    modalClose = () => {
        this.$close();
    }
}

TwoFactorQRController.$inject = [
    'qrCode',
    'gettextCatalog',
    '$scope'
];

export class TwoFactorQRModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    $onInit = () => {
        this.$uibModal;
    };

    open = (qrCode = null) => {
        this.modalInstance = this.$uibModal.open({
            template: modalTemplate,
            bindToController: true,
            controller: TwoFactorQRController,
            controllerAs: '$',
            size: 'qr',
            resolve: {
                qrCode,
            },
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}
