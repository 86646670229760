import angular from 'angular';

export const textEditorNotificationOptions = () => {
    return {
        $get: function () {
            let langCode = 'ru';
            if (window.appCtrl && window.appCtrl.langCode && window.appCtrl.langCode != 'ru') {
                langCode = window.appCtrl.langCode;
            }
            return {
                height: 500,
                language: langCode,
                invalid_elements: 'iframe',
                plugins: 'code preview lists paste Codemirror',
                toolbar1: 'undo redo | paste | styleselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | code | preview',
                toolbar2: `firstNameTemplate secondNameTemplate ${window.appCtrl.langCode !== 'ru' && 'thirdNameTemplate'} | dateTemplate | detectImage fishLink | tags`,
                theme_advanced_buttons3_add : 'fullpage',
                fullpage_fontsizes : '13px,14px,15px,18pt,xx-large',
                fullpage_default_xml_pi : false,
                fullpage_default_langcode : 'en',
                fullpage_default_title : 'My document title',
                menubar: false,
                visual: false,
                relative_urls: true,
                remove_script_host: false,
                paste_data_images: true,
                theme: 'silver',
                content_style: '.mce-content-body {font-family:Verdana,Arial,Helvetica,sans-serif;font-size:11px;}',
                images_dataimg_filter: function (img) {
                    return img.hasAttribute('internal-blob');
                },
                selector: 'textarea',
                ui_container: '.modal-dialog',
                content_css: false,
                skin: false,
                codemirror: {
                    indentOnInit: true, // Whether or not to indent code on init.
                    fullscreen: false,   // Default setting is false
                    path: 'CodeMirror', // Path to CodeMirror distribution
                    config: {           // CodeMirror config object
                        lineNumbers: true
                    },
                    width: 800,         // Default value is 800
                    height: 600,        // Default value is 550
                    saveCursorPosition: true    // Insert caret marker
                },
                force_br_newlines : false,
                force_p_newlines : false,
                forced_root_block : '',
                setup: function (editor) {
                    editor.ui.registry.addButton('dateTemplate', {
                        icon: 'insert-time',
                        tooltip: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Дата') : 'Дата',
                        onAction: function () {
                            editor.insertContent('{dmy}');
                        }
                    });
                    editor.ui.registry.addButton('firstNameTemplate', {
                        text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Фамилия') : 'Фамилия',
                        tooltip: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Фамилия') : 'Фамилия',
                        onAction: function () {
                            editor.insertContent('{last_name}');
                        }
                    });
                    editor.ui.registry.addButton('secondNameTemplate', {
                        text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Имя') : 'Имя',
                        tooltip: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Имя') : 'Имя',
                        onAction: function () {
                            editor.insertContent('{name}');
                        }
                    });
                    if (langCode === 'ru') {
                        editor.ui.registry.addButton('thirdNameTemplate', {
                            text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Отчество') : 'Отчество',
                            tooltip: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Отчество') : 'Отчество',
                            onAction: function () {
                                editor.insertContent('{middle_name}');
                            }
                        });
                    }
                    editor.ui.registry.addMenuButton('tags', {
                        type: 'menubutton',
                        text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Переменные') : 'Переменные',
                        fetch: (callback) => {
                            let tagList = [
                                {
                                    type: 'nestedmenuitem',
                                    text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Общие') : 'Общие',
                                    getSubmenuItems: () => {
                                        return [
                                            {
                                                type: 'menuitem',
                                                text: (window.appCtrl ? window.appCtrl.gettextCatalog.getString('Дата') : 'Дата') + ' d.m.Y',
                                                onAction: function () {
                                                    editor.insertContent('{dmy}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: (window.appCtrl ? window.appCtrl.gettextCatalog.getString('Дата d.m.Y [+/-]N дней') : 'Дата d.m.Y [+/-]N дней'),
                                                onAction: function () {
                                                    editor.insertContent('{dmy+N}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Дата с форматированием') : 'Дата с форматированием',
                                                onAction: function () {
                                                    editor.insertContent('{date}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Дата с форматированием [+/-]N дней') : 'Дата с форматированием [+/-]N дней',
                                                onAction: function () {
                                                    editor.insertContent('{date+N}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('День') : 'День',
                                                onAction: function () {
                                                    editor.insertContent('{day}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Месяц') : 'Месяц',
                                                onAction: function () {
                                                    editor.insertContent('{month}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Год') : 'Год',
                                                onAction: function () {
                                                    editor.insertContent('{year}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Случайное число из N цифр') : 'Случайное число из N цифр',
                                                onAction: function () {
                                                    editor.insertContent('{rand:N}');
                                                }
                                            }
                                        ];
                                    }
                                },
                                {
                                    type: 'nestedmenuitem',
                                    text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Администратор') : 'Администратор',
                                    getSubmenuItems: () => {
                                        return [
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Фамилия') : 'Фамилия',
                                                onAction: function () {
                                                    editor.insertContent('{admin_last_name}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Имя') : 'Имя',
                                                onAction: function () {
                                                    editor.insertContent('{admin_name}');
                                                }
                                            },
                                            {
                                                type: window.appCtrl.langCode === 'ru' && 'menuitem',
                                                text: window.appCtrl && window.appCtrl.langCode === 'ru' ? window.appCtrl.gettextCatalog.getString('Отчество') : 'Отчество',
                                                onAction: window.appCtrl.langCode === 'ru' && function () {
                                                    editor.insertContent('{admin_middle_name}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Электронная почта') : 'Электронная почта',
                                                onAction: function () {
                                                    editor.insertContent('{admin_email}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Номер телефона') : 'Номер телефона',
                                                onAction: function () {
                                                    editor.insertContent('{admin_phone}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Компания') : 'Компания',
                                                onAction: function () {
                                                    editor.insertContent('{admin_company}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Отдел') : 'Отдел',
                                                onAction: function () {
                                                    editor.insertContent('{admin_department}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Должность') : 'Должность',
                                                onAction: function () {
                                                    editor.insertContent('{admin_position}');
                                                }
                                            }
                                        ];
                                    }
                                },
                                {
                                    type: 'nestedmenuitem',
                                    text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Сотрудник') : 'Сотрудник',
                                    getSubmenuItems: () => {
                                        return [
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Фамилия') : 'Фамилия',
                                                onAction: function () {
                                                    editor.insertContent('{last_name}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Имя') : 'Имя',
                                                onAction: function () {
                                                    editor.insertContent('{name}');
                                                }
                                            },
                                            {
                                                type: window.appCtrl.langCode === 'ru' && 'menuitem',
                                                text: window.appCtrl && window.appCtrl.langCode === 'ru' ? window.appCtrl.gettextCatalog.getString('Отчество') : 'Отчество',
                                                onAction: window.appCtrl.langCode === 'ru' && function () {
                                                    editor.insertContent('{middle_name}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Электронная почта') : 'Электронная почта',
                                                onAction: function () {
                                                    editor.insertContent('{email}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Отдел') : 'Отдел',
                                                onAction: function () {
                                                    editor.insertContent('{department}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Должность') : 'Должность',
                                                onAction: function () {
                                                    editor.insertContent('{position}');
                                                }
                                            }
                                        ];
                                    }
                                },
                                {
                                    type: 'nestedmenuitem',
                                    text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Обучение') : 'Обучение',
                                    getSubmenuItems: () => {
                                        return [
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Домен') : 'Домен',
                                                onAction: function () {
                                                    editor.insertContent('{domain}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Url системы обучения') : 'Url системы обучения',
                                                onAction: function () {
                                                    editor.insertContent('{education_url}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Логин') : 'Логин',
                                                onAction: function () {
                                                    editor.insertContent('{education_login}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Пароль') : 'Пароль',
                                                onAction: function () {
                                                    editor.insertContent('{education_password}');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: window.appCtrl ? window.appCtrl.gettextCatalog.getString('Курсы') : 'Курсы',
                                                onAction: function () {
                                                    editor.insertContent('{education_courses:\'' + (window.appCtrl ? window.appCtrl.gettextCatalog.getString('пройдите до') : 'пройдите до') + '\'}');
                                                }
                                            }
                                        ];
                                    }
                                }
                            ];
                            let tagsByGroup = {};
                            let otherTags = [];
                            if (window.mceTags && window.mceTags.length) {
                                tagList.push({
                                    text: '|'
                                });
                                angular.forEach(window.mceTags, (tag) => {
                                    if (tag.group) {
                                        if (!tagsByGroup[tag.group]) {
                                            tagsByGroup[tag.group] = [];
                                        }
                                        tagsByGroup[tag.group].push({
                                            type: 'menuitem',
                                            text: tag.name,
                                            onAction: function () {
                                                editor.insertContent(tag.tag);
                                            }
                                        });
                                    } else {
                                        otherTags.push({
                                            type: 'menuitem',
                                            text: tag.name,
                                            onAction: function () {
                                                editor.insertContent(tag.tag);
                                            }
                                        });
                                    }
                                });
                            }
                            angular.forEach(tagsByGroup, (tags, groupName) => {
                                tagList.push({
                                    type: 'nestedmenuitem',
                                    text: groupName,
                                    getSubmenuItems: () => {
                                        return tags;
                                    }
                                });
                            });
                            angular.forEach(otherTags, (tag) => {
                                tagList.push(tag);
                            });
                            callback(tagList);
                        }
                    });
                }
            };
        }
    };
};
