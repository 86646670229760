import style from './style.modules.css';
import { Controller } from '../../controllers/controller';
import { EmployeesDeleteModal } from '../employeesDeleteModal';
import { EMPTY_LDAP_SELECTED_DATA, LDAP_IMPORTS_SERVICE } from '../../const';
import angular from 'angular';
import { fetchDashboardParams } from '../../toolkit/think';
import { setLinkings } from '../../toolkit/actions';

class LdapMessageController extends Controller {

    constructor($injector, $scope, $interval, gettextCatalog, LdapImportsService, $ngRedux) {
        super();
        this.style = style;
        this.appCtrl = window.appCtrl;
        this.$injector = $injector;
        this.$scope = $scope;
        this.$interval = $interval;
        this.LdapImportsService = LdapImportsService;
        this.ldapImports = LdapImportsService.all;
        this.gettextCatalog = gettextCatalog;
        this.imports = [];
        this.gettextCatalog = window.appCtrl.gettextCatalog;
        this.updateGrowl = null;
        this.limitTo = 1;
        this.hidden = false;
        this.showLogs = {};
        this.loading = {};
        this.importLoading = {};
        this.importDone = {};
        this.importError = {};
        this.importErrorText = {};
        this.error = {};
        this.logs = {};
        this.messages = {
            userCreate: gettextCatalog.getString('будут добавлены'),
            userModify: gettextCatalog.getString('будут обновлены'),
            userSkip: gettextCatalog.getString('будут оставлены без изменений'),
            userNotFound: gettextCatalog.getString('не найдены в профиле'),
            userError: gettextCatalog.getString('не могут быть импортированы'),
        };
        this.toRemoveImports = [];
        this.unsubscribe = $ngRedux.connect(null, { fetchDashboardParams, setLinkings })(this);
        window.LdapCtrl = this;
    }

    $onInit() {
        if (this.import) {
            this.import.show = true;
            this.imports.push(this.import);
        } else {
            this.getImports();
        }
    }

    updateImports = () => {
        let self = this;
        this.ldapImports.$filters.time = parseInt(new Date().getTime() / 1000);
        this.ldapImports.$load().then((result) => {
            let data = result.$data;
            let updatedImports = [];
            let hasInProcess = false;
            if (data && data.length > 0) {
                data.forEach(function(dataImport) {
                    if (dataImport) {
                        let ldapImport = self.imports.find(function(scopeImport) {
                            return scopeImport.id == dataImport.id;
                        });
                        if (dataImport.status > 0 && !dataImport.error) {
                            hasInProcess = true;
                        }
                        if (ldapImport && ldapImport.id) {
                            if (ldapImport.completed && !ldapImport.userError) {
                                ldapImport.hidden = true;
                            }
                            if (ldapImport.status != dataImport.status || ldapImport.completed != dataImport.completed || ldapImport.error != dataImport.error) {
                                ldapImport.hidden = false;
                            }
                            ldapImport.status = dataImport.status;
                            ldapImport.completed = dataImport.completed;
                            ldapImport.error = dataImport.error;
                            ldapImport.userAll = dataImport.userAll;
                            ldapImport.userCreate = dataImport.userCreate;
                            ldapImport.userModify = dataImport.userModify;
                            ldapImport.userError = dataImport.userError;
                            ldapImport.userNotFound = dataImport.userNotFound;
                            ldapImport.userDelete = dataImport.userDelete;
                            ldapImport.userSkip = dataImport.userSkip;
                        } else {
                            dataImport.show = false;
                            self.imports.push(dataImport);
                        }
                        if (ldapImport.completed) {
                            self.importDone[ldapImport.id] = true;
                        } else {
                            if (self.importDone[ldapImport.id]) {
                                self.importDone[ldapImport.id] = false;
                            }
                        }
                        updatedImports.push(dataImport.id);
                    }
                });
            }
            angular.forEach(self.toRemoveImports, (removeId, removeKey) => {
                let selfKey = -1;
                angular.forEach(self.imports, (item, itemKey) => {
                    if (item.id == removeId) {
                        selfKey = itemKey;
                    }
                });
                if (selfKey > -1 && removeKey > -1) {
                    self.toRemoveImports.splice(removeKey, 1);
                    self.imports.splice(selfKey, 1);
                    if (typeof (self.logs[removeId]) != 'undefined') {
                        self.logs[removeId + '_userCreate'] = {};
                        self.logs[removeId + '_userModify'] = {};
                        self.logs[removeId + '_userNotFound'] = {};
                        self.logs[removeId + '_userSkip'] = {};
                    }
                }
            });
            angular.forEach(self.imports, (selfImport) => {
                if (updatedImports.indexOf(selfImport.id) == -1 && self.toRemoveImports.indexOf(selfImport.id) == -1) {
                    self.toRemoveImports.push(selfImport.id);
                    selfImport.completed = 1;
                    self.importDone[selfImport.id] = true;
                }
            });
            self.retryType = null;
            self.retryValue = null;
            if (!hasInProcess && !self.toRemoveImports.length) {
                self.$interval.cancel(self.updateGrowl);
                self.updateGrowl = self.$interval(self.updateImports, 3600000);
                if (!self.imports.length) {
                    self.hidden = true;
                }
            } else {
                self.$interval.cancel(self.updateGrowl);
                self.updateGrowl = self.$interval(self.updateImports, 5000);
                self.hidden = false;
            }
            if (!self.imports.length && !hasInProcess && !self.toRemoveImports.length) {
                self.$interval.cancel(self.updateGrowl);
                self.updateGrowl = self.$interval(self.updateImports, 3600000);
                self.reloadDepartments();
            }
        });
    };

    reloadDepartments = () => {
        this.fetchDashboardParams();
        this.setLinkings('reload');
    };

    getImports = () => {
        let self = this;
        self.$interval.cancel(self.updateGrowl);
        self.updateGrowl = self.$interval(self.updateImports, 3600000);
        self.ldapImports.$filters = {
            time: parseInt(new Date().getTime() / 1000),
        };
        self.ldapImports.$load().then((result) => {
            let data = result.$data;
            let startInterval = false;
            if (data && data.length > 0) {
                self.imports = data;
                angular.forEach(data, (item) => {
                    if (item && item.status > 0) {
                        startInterval = true;
                    }
                });
            } else {
                self.imports = [];
            }
            if (startInterval) {
                self.$interval.cancel(self.updateGrowl);
                self.updateGrowl = self.$interval(self.updateImports, 5000);
            } else {
                self.$interval.cancel(self.updateGrowl);
                self.updateGrowl = self.$interval(self.updateImports, 3600000);
            }
            if (!self.imports.length) {
                self.$interval.cancel(self.updateGrowl);
                self.updateGrowl = self.$interval(self.updateImports, 3600000);
            }
        });
    };

    retry = () => {
        this.getImports();
    };

    hide = (data) => {
        data.hidden = 1;
        if ((!data.status && !this.importLoading[data.id] && !this.importError[data.id] && !this.importDone[data.id]) || data.userError || data.error) {
            this.decline(data);
        }
    };

    showUsers = (importId, dataType) => {
        if (this.showLogs[importId + '_' + dataType]) {
            if (!this.logs[importId + '_' + dataType] || !this.logs[importId + '_' + dataType].length) {
                this.loading[importId + '_' + dataType] = true;
                this.error[importId + '_' + dataType] = false;
                let logs = this.LdapImportsService.logs(importId, dataType);
                logs.$promise.then((logs) => {
                    this.logs[importId + '_' + dataType] = logs;
                    this.loading[importId + '_' + dataType] = false;
                }, () => {
                    this.loading[importId + '_' + dataType] = false;
                    this.error[importId + '_' + dataType] = this.gettextCatalog.getString('Ошибка при загрузке списка сотрудников');
                });
            }
        }
    };

    apply = (data) => {
        this.importLoading[data.id] = true;
        this.importError[data.id] = null;
        this.importDone[data.id] = null;
        data.error = null;
        delete data.lastImport;
        data.completed = 0;
        this.LdapImportsService.action(data.id, 'apply', this, (ctrl) => {
            ctrl.importLoading[data.id] = false;
            ctrl.importDone[data.id] = false;
            data.status = 1;
            ctrl.$interval.cancel(ctrl.updateGrowl);
            ctrl.updateGrowl = ctrl.$interval(ctrl.updateImports, 5000);
        }, (ctrl, response) => {
            ctrl.importLoading[data.id] = false;
            ctrl.importDone[data.id] = false;
            ctrl.importError[data.id] = true;
            if (response.data) {
                if (response.data.error) {
                    ctrl.importErrorText[data.id] = response.data.error;
                }
                if (response.data.message) {
                    ctrl.importErrorText[data.id] = response.data.message;
                }
                if (response.status && response.status == 504) {
                    ctrl.importErrorText[data.id] = ctrl.gettextCatalog.getString('Превышен лимит ожидания импорта сотрудников');
                }
            }
        });
    };

    decline = (data) => {
        this.LdapImportsService.action(data.id, 'decline', this, (ctrl) => {
            data.hidden = 1;
            data.completed = 1;
            ctrl.$interval.cancel(ctrl.updateGrowl);
            ctrl.updateGrowl = ctrl.$interval(ctrl.updateImports, 5000);
            let selfKey = -1;
            angular.forEach(ctrl.imports, (item, itemKey) => {
                if (item.ud == data.id) {
                    selfKey = itemKey;
                }
            });
            if (selfKey > -1) {
                ctrl.imports.splice(selfKey, 1);
            }
        });
    };

    deleteNotFound = (data) => {
        let deleteModal = this.$injector.instantiate(EmployeesDeleteModal);
        let selectedData = angular.extend({}, EMPTY_LDAP_SELECTED_DATA);
        selectedData.selectedCount.targets = data.userNotFound;
        selectedData.importId = this.import ? this.import.id : data.id;
        deleteModal.open(selectedData, selectedData.importId).then((selectedData) => {
            if (selectedData) {
                data.userDelete = data.userNotFound;
            }
        }, () => {
        });
    };

    $onDestroy() {
        this.$interval.cancel(this.updateGrowl);
        this.unsubscribe();
    }
}

LdapMessageController.$inject = [
    '$injector',
    '$scope',
    '$interval',
    'gettextCatalog',
    LDAP_IMPORTS_SERVICE,
    '$ngRedux',
];

export {
    LdapMessageController,
};
