
class CampaignsService {
    constructor (YiiModel, $resource) {
        this.YiiModel = YiiModel;
        this.all = YiiModel.all('attacks');
        this.new = () => {
            return YiiModel.one('attacks');
        };
        this.one = (id) => {
            return YiiModel.one('attacks').$find(id);
        };
        this.tasks = (id) => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/attacks/' + id + '/tasks').get();
        };
        this.restoreInfo = () => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/attacks/restore').get();
        };
        this.restoreHiddenCampaigns = (ctrl, success, error) => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/attacks/restore').save({}, {}, function (response) {
                if (typeof (success) == 'function' && ctrl) {
                    success(ctrl, response);
                }
            }, function (response) {
                if (typeof (error) == 'function' && ctrl) {
                    error(ctrl, response);
                }
            });
        };
        this.deleteHiddenCampaigns = (ctrl, success, error) => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/attacks/restore').delete({}, {}, function (response) {
                if (typeof (success) == 'function' && ctrl) {
                    success(ctrl, response);
                }
            }, function (response) {
                if (typeof (error) == 'function' && ctrl) {
                    error(ctrl, response);
                }
            });
        };
        this.deleteCampaign = (id, params, ctrl, success, error) => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/attacks/' + id).delete(params, {}, function (response) {
                if (typeof (success) == 'function' && ctrl) {
                    success(ctrl, response);
                }
            }, function (response) {
                if (typeof (error) == 'function' && ctrl) {
                    error(ctrl, response);
                }
            });
        };
        this.saveCampaign = (params, ctrl, success, error) => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/attacks').save({}, params, function (response) {
                if (typeof (success) == 'function' && ctrl) {
                    success(ctrl, response);
                }
            }, function (response) {
                if (typeof (error) == 'function' && ctrl) {
                    error(ctrl, response);
                }
            });
        };
        this.getSchedule = (attackId) => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/attacks/' + attackId + '/schedule').get();
        };
        this.saveSchedule = (attackId, params, ctrl, success, error) => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/attacks/' + attackId + '/schedule').save({}, params, function (response) {
                if (typeof (success) == 'function' && ctrl) {
                    success(ctrl, response);
                }
            }, function (response) {
                if (typeof (error) == 'function' && ctrl) {
                    error(ctrl, response);
                }
            });
        };
        this.saveTemplate = (params) => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/templates' + ('id' in params && params.allowed ? '/' + params.id : '') + '/form').save({}, params);
        };
        this.deleteTemplate = (id) => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/templates/' + id).delete();
        };
        this.deleteSchedule = (attackId, params, ctrl, success, error) => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/attacks/' + attackId + '/schedule').delete({}, {}, function (response) {
                if (typeof (success) == 'function' && ctrl) {
                    success(ctrl, response);
                }
            }, function (response) {
                if (typeof (error) == 'function' && ctrl) {
                    error(ctrl, response);
                }
            });
        };
        this.sender = (attackId, action, ctrl, success, error) => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/attacks/' + attackId + '/' + action).save({}, {}, function (response) {
                if (typeof (success) == 'function' && ctrl) {
                    success(ctrl, response);
                }
            }, function (response) {
                if (typeof (error) == 'function' && ctrl) {
                    error(ctrl, response);
                }
            });
        };
    }
}

export {
    CampaignsService
};
