import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mode: 1
};

const graphModeSlice = createSlice({
    name: 'graphMode',
    initialState,
    reducers: {
        setGraphMode (state, {payload}) {
            state.mode = payload;
        }
    },
    extraReducers: {}
});

export const {setGraphMode} = graphModeSlice.actions;
export default graphModeSlice.reducer;

