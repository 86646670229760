import { ENTITY_SERVICE, GROUPS_SERVICE } from '../../const';

class BulkDeleteModalController {
    constructor(entity, EntityService, GroupsService) {
        this.entity = entity.entity || {};
        this.entityType = entity.type || '';
        this.withTargets = false;
        this.proccessing = false;
        this.error = false;
        this.service = this.entityType === 'department' ? EntityService : GroupsService;
    }

    cancel = () => {
        this.$close();
    }
    confirm = () => {
        this.service.delete({
            'id': this.entity.id,
            'type': this.entityType,
            'deleteTargets': +this.withTargets
        }, this, (ctrl) => {
            ctrl.$close({ 'entity': this.entity, 'withTargets': this.withTargets });
            ctrl.proccessing = false;
        }, (ctrl, response) => {
            ctrl.proccessing = false;
            if (response && response.data && response.data.error) {
                ctrl.error = response.data.error;
            } else {
                ctrl.error = true;
            }
        });
    }
}

BulkDeleteModalController.$inject = [
    'entity',
    ENTITY_SERVICE,
    GROUPS_SERVICE
];

export {
    BulkDeleteModalController,
};
