import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';

const initialState = {
    status: 'idle',
    languages: [],
    langCode: '',
    error: false
};

export const fetchLang = createAsyncThunk(
    'languages/status',
    async () => {
        const fetchApi = new FetchApi('/languages');
        const response = await fetchApi.get();
        return response;
    }
);

const languagesSlice = createSlice({
    name: 'languages',
    initialState,
    reducers: {
        setLangCode(state, { payload }) {
            state.langCode = payload;
        },
    },
    extraReducers: {
        [fetchLang.fulfilled]: (state, { payload }) => {
            state.languages = payload;
            state.status = 'load';
        },
        [fetchLang.pending]: (state) => {
            state.status = 'pending';
        },
        [fetchLang.rejected]: (state) => {
            state.status = 'rejected';
        },
    }
});

export const {setLangCode} = languagesSlice.actions;
export default languagesSlice.reducer;

