import style from './style.modules.css';

class SectionHeaderController {
    constructor(
        gettextCatalog,
        $localStorage) {
        this.gettextCatalog = gettextCatalog;
        this.$localStorage = $localStorage;
        this.style = style;
    }

    $onInit() {}

    $onDestroy() {}
}

SectionHeaderController.$inject = [
    'gettextCatalog',
    '$localStorage',
];

export {
    SectionHeaderController,
};
