import React, { useContext, useState } from 'react';
import ReactIcon from 'rootReact/components/ReactIcons';
import CourseItem from 'rootReact/components/NotificationTable/CourseItem.jsx';
import { GettextContext } from 'rootReact/context';
import { NOTIFICATIONS_TYPE } from '../../../const.js';

const SHOW_LIMIT = 7;

function CustomList({
    notificationsList,
    toggleNotification,
    editNotification,
    copyNotification,
    deleteNotification,
    toDefault,
    editAccess,
    playAccess
}) {
    const gettext = useContext(GettextContext);
    const [listShow, setListShow] = useState(false);
    if (!notificationsList.length) return <div>{gettext.getString('Нет уведомлений')}</div>;
    const getType = (item) => {
        if (!item.type) return '—';
        let value = '—';
        NOTIFICATIONS_TYPE.forEach(notifyItem => {
            if (notifyItem.type === item.type) {
                value = gettext.getString(notifyItem.value);
            }
        });
        return value;
    };

    const showPlayButton = (item) => {
        if (item.type === 'AuthLink') {
            return true;
        }
        if (item.type && item.courses && item.courses.length) {
            return true;
        }
        return false;
    };

    const setList = (list, hidden = false) => {
        let value = list.map((item) => {
            return (
                <div className={'list-item_wrapper'} key={item.uuid}>
                    <div className={'list_row list_row__custom'}>
                        <div className={'list_label'}>{item.name}</div>
                        <div className={'list_label'}>{item.courses && item.courses.length ? <CourseItem courses={item.courses}/> : '—'}</div>
                        <div className={'list_label'}>{getType(item)}</div>
                        <div className={'list_button-sector'}>
                            {playAccess && showPlayButton(item) ?
                                <div className={'list_icon-wrapper'}>
                                    {item.status ? <ReactIcon icoClass={'pause-new'}
                                        listIcon={true}
                                        handleClick={() => toggleNotification(item.uuid)}
                                    /> : <ReactIcon icoClass={'play-new'}
                                        listIcon={true}
                                        handleClick={() => toggleNotification(item.uuid)}
                                    />}
                                </div> : null
                            }
                            <div className={'list_icon-wrapper'}>
                                <ReactIcon icoClass={'edit-new'}
                                    listIcon={true}
                                    handleClick={() => editNotification(item.uuid)}
                                />
                            </div>
                            {editAccess ? <div className={'list_icon-wrapper'}>
                                <ReactIcon icoClass={'copy-new'}
                                    listIcon={true}
                                    handleClick={ () => copyNotification(null, item.uuid)}
                                />
                            </div> : ''}
                            {!item.isDefault && editAccess &&
                                <div className={'list_icon-wrapper'}>
                                    <ReactIcon icoClass={'default'}
                                        listIcon={true}
                                        handleClick={() => toDefault(null, item.uuid)}
                                    />
                                </div>
                            }
                            {editAccess &&
                                <div className={'list_icon-wrapper'}>
                                    <ReactIcon icoClass={'delete-new'}
                                        listIcon={true}
                                        handleClick={() => {
                                            deleteNotification(null, item.uuid);
                                        }}/>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            );
        });
        if (hidden) {
            value.push(
                <div style={{ padding: '5px' }} key={'link'}>
                    <a onClick={() => setListShow(!listShow)}>{gettext.getString('Показать все')} <span
                        className='show-yet show-yet-react'> ↓</span></a>
                </div>
            );
        }
        if (listShow) {
            value.push(
                <div style={{ padding: '5px' }} key={'link'}>
                    <a onClick={() => setListShow(!listShow)}>{gettext.getString('Свернуть')}</a>
                </div>
            );
        }
        return value;
    };

    if (!listShow && notificationsList.length > SHOW_LIMIT) {
        return setList(notificationsList.filter((item, i) => i < SHOW_LIMIT), true);
    } else {
        return setList(notificationsList);
    }
}

export default CustomList;
