import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FetchApi from "../React/REST";

const initialState = {
    attackHistory: {},
    load: true,
    isError: false,
    error: "",
};

export const fetchAttackHistory = createAsyncThunk("attackHistory/status", async (data) => {
    const fetchApi = new FetchApi("/history/attacks");
    let response = await fetchApi.post(data);
    if (response.length) {
        response = response.map((item) => {
            item.date = item.date * 1000;
            return item;
        });
        let arrUnshift = [];
        let attackHistory = [];
        let firstDate = new Date(response[0].date).getDate();
        let firstDay = new Date(new Date(response[0].date).getFullYear(), new Date(response[0].date).getMonth(), 1).valueOf();
        for (let i = 0; i < firstDate; i++) {
            arrUnshift.push({
                hasVulnerability: 0,
                mailReport: 0,
                noAttack: 0,
                openLink: 0,
                openAttachmentOrFileFromLink: 0,
                openMail: 0,
                proofAttack: 0,
                submitForm: 0,
                date: firstDay + 1000 * 60 * 60 * 24 * i,
            });
        }
        attackHistory = [...arrUnshift, ...response];
        return attackHistory;
    }
    return [];
});

const attackHistorySlice = createSlice({
    name: "attackHistory",
    initialState,
    reducers: {
        attackHistoryDefault(state) {
            state = initialState;
        },
    },
    extraReducers: {
        [fetchAttackHistory.fulfilled]: (state, { payload }) => {
            state.attackHistory = payload;
            state.load = false;
        },
        [fetchAttackHistory.pending]: (state) => {
            state.load = true;
            state.isError = false;
            state.error = "";
        },
        [fetchAttackHistory.rejected]: (state, { payload }) => {
            state.load = false;
            state.isError = true;
            state.error = payload;
        },
    },
});

export const { attackHistoryDefault } = attackHistorySlice.actions;
export default attackHistorySlice.reducer;
