import navTemplate from './view.html';

import { NavBarController } from './controller.js';

const NavBarComponent = {
    template: navTemplate,
    controller: NavBarController,
    controllerAs: '$',
    scope: true,
    bindings: {
        onMenuOpen: '=',
        menuOpen: '<',
        logoMode: '<',
        logoModeTop: '<',
        logoModeHeight: '<'
    }
};

export {
    NavBarComponent
};
