import style from './style.modules.css';

class AlertMessagesController {
    constructor() {
        this.style = style;
    }

    $onInit() {
    }
}

AlertMessagesController.$inject = [];

export {
    AlertMessagesController,
};
