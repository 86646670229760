import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';

const initialState = {
    status: 'idle',
    firstLoading: false,
    outlook: {},
    error: false,
    cardLoad: false,
    cardError: '',
    activeCard: 0,
    savedCard: false,
};

export const fetchOutlookSettings = createAsyncThunk(
    'outlookSettings/status',
    async () => {
        const fetchApi = new FetchApi('/outlook/settings');
        const response = await fetchApi.get();
        return response;
    }
);

export const fetchOutlookPost = createAsyncThunk(
    'outlookSettings/post',
    async (params) => {
        const fetchApi = new FetchApi('/outlook/settings/create');
        const response = await fetchApi.post({body: params});
        return response;
    }
);

export const saveCardSettings = createAsyncThunk(
    'outlookSettings/save',
    async (params, thunkAPI) => {
        let state = thunkAPI.getState().outlook.outlook.card.filter(item => item.settingId === params)[0]
        const fetchApi = new FetchApi(`/outlook/card-settings${params < 0 ? '/create' : ''}`);
        if (params < 0) {
            const card = {}
            for (let key in state) {
                if (key === 'settingId') continue
                card[key] = state[key]
            }
            return await fetchApi.post({body:card})
        } else {
            return await fetchApi.put(params, {body:state})
        }
    }
)

const outlookSettingsSlice = createSlice({
    name: 'outlookSettings',
    initialState,
    reducers: {
        setCardDownload (state, {payload}) {
           state.cardLoad = payload;
        },
        setLoadOutlook (state) {
            state.status = 'load';
        },
        setActive (state, {payload}) {
            state.activeCard = payload;
        },
        setDefaultOutlook (state) {
            state = initialState;
        },
        cancelSaved (state) {
            state.savedCard = false
        },
        setNewCard (state, { payload }) {
            state.outlook.card.push(payload);
        },
        deleteCard (state, {payload}) {
            state.outlook.card = state.outlook.card.filter(item => item.settingId !== payload);
        },
        setCardParams (state, {payload}) {
            state.outlook.card = state.outlook.card.map(item => {
                if (item.settingId !== payload.id) return item;
                item = {...item, ...payload.params};
                return item;
            });
        },
        setFeedbackLink (state, {payload}) {
            state.outlook.feedbackLink = payload;
        }
    },
    extraReducers: {
        [fetchOutlookSettings.fulfilled]: (state, { payload }) => {
            payload.card.forEach(item => {
                if (!item.settingId) {
                    item.settingId = ~Math.floor((Math.random() * 1000))
                }
            })
            state.outlook = payload;
            state.status = 'load';
            state.activeCard = payload.card[0].settingId
            state.firstLoading = true;
        },
        [fetchOutlookSettings.pending]: (state) => {
            state.status = 'pending';
        },
        [fetchOutlookSettings.rejected]: (state) => {
            state.status = 'rejected';
        },

        [fetchOutlookPost.fulfilled]: (state) => {
            state.status = 'success';
        },
        [fetchOutlookPost.pending]: (state) => {
            state.status = 'pending';
        },
        [fetchOutlookPost.rejected]: (state) => {
            state.status = 'rejected';
        },

        [saveCardSettings.fulfilled]: (state, { payload, meta }) => {
            if (payload.isError) {
                state.cardError = true
                state.cardLoad = false
                return
            }
            const id = meta.arg
            state.outlook.card = state.outlook.card.map(item => {
                if (item.settingId === id) {
                    return payload.added
                }
                return item
            })
            state.activeCard = payload.added.settingId
            state.cardLoad = false;
            state.savedCard = true
        },
        [saveCardSettings.pending]: (state) => {
            state.cardError = false;
            state.cardLoad = true;
        },
        [saveCardSettings.rejected]: (state) => {
            state.cardError = true;
            state.cardLoad = false;
        },
    }
});

export const {
    setLoadOutlook,
    setDefaultOutlook,
    setNewCard,
    deleteCard,
    setCardParams,
    setCardDownload,
    setActive,
    cancelSaved,
} = outlookSettingsSlice.actions;
export default outlookSettingsSlice.reducer;

