import style from './style.modules.css';
import modalTemplate from './twoFactorDisable.html';

export class TwoFactorDisableController {
    constructor(gettextCatalog) {
        this.gettextCatalog = gettextCatalog;
        this.style = style;
        this.password = '';
        this.headerText = gettextCatalog.getString('Отключение двухфакторной аутентификации');
    }

    $onInit () {
        const that = this;
        this.btnConfig = [
            {
                title: that.gettextCatalog.getString('да'),
                action: () => {
                    that.$close(true);
                },
                selenium: 'confirm-btn',
                get disabled() {
                    return false;
                },
                class: 'default',
                classCustom: style['disabled-button'] + ' ' + 'first-uppercase'
            },
            {
                title: that.gettextCatalog.getString('нет'),
                action: () => {
                    that.$close();
                },
                selenium: 'cancel-btn',
                get disabled() {
                    return false;
                },
                class: 'primary',
                classCustom: style['disabled-button'] + ' ' + 'first-uppercase'
            }
        ];
    }

    modalClose = () => {
        this.$close();
    }
}

TwoFactorDisableController.$inject = [
    'gettextCatalog'
];

export class TwoFactorDisableModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    $onInit = () => {
        this.$uibModal;
    };

    open = () => {
        this.modalInstance = this.$uibModal.open({
            template: modalTemplate,
            bindToController: true,
            controller: TwoFactorDisableController,
            controllerAs: '$',
            size: 'qr',
            resolve: {},
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}
