import style from './style.modules.css';

class DashboardMotivation {
    constructor(gettextCatalog, $ngRedux, scope) {
        this.gettextCatalog = gettextCatalog;
        this.style = style;
        this.scope = scope;
        this.chart = {
            'baseFont': 'sans-serif',
            'baseFontSize': '12',
            'baseFontColor': '#5a5a5a',
            stack100percent: '0',
            theme: 'fusion',
            yAxisMaxValue: '100',
            yAxisMinValue: '0',
            decimals: '0',
            drawcrossline: '0',
            showvalues: '0',
            numvisibleplot: '24',
            showLegend: 0,
            showXAxisPercentValues: 0,
            showSum: 0,
            labelDisplay: 'none',
        };
        this.myDataSource = {};
        this.motivationValue = {
            D: gettextCatalog.getString('Низкая'),
            C: gettextCatalog.getString('Средняя'),
            B: gettextCatalog.getString('Хорошая'),
            A: gettextCatalog.getString('Отличная')
        };
        this.month = [
            this.gettextCatalog.getString('Январь'),
            this.gettextCatalog.getString('Февраль'),
            this.gettextCatalog.getString('Март'),
            this.gettextCatalog.getString('Апрель'),
            this.gettextCatalog.getString('Май'),
            this.gettextCatalog.getString('Июнь'),
            this.gettextCatalog.getString('Июль'),
            this.gettextCatalog.getString('Август'),
            this.gettextCatalog.getString('Сентябрь'),
            this.gettextCatalog.getString('Октябрь'),
            this.gettextCatalog.getString('Ноябрь'),
            this.gettextCatalog.getString('Декабрь')
        ];
    }

    $onInit() {
        this.myDataSource.chart = this.chart;
        this.myDataSource.categories = [{ category: this.setCategory() }];
        this.myDataSource.dataset = [...this.setDate()];
        this.render = true;
        this.scope.$watch(scope => {
            return scope.$.motivationHistory;
        }, () => {
            this.myDataSource.chart = this.chart;
            this.myDataSource.categories = [{ category: this.setCategory() }];
            this.myDataSource.dataset = [...this.setDate()];
        });

    }

    setDate = () => {
        let dataSet =[];
        let veryBad = [];
        let bad = [];
        let good = [];
        let veryGood = [];
        for (let i = 0; i < this.motivationHistory.length; i++) {
            let item = this.motivationHistory[i].motivation;
            veryBad.push(item === 'D' ? { value: '25', toolText: this.gettextCatalog.getString('Низкий') }:  null);
            bad.push(item ===  'C' ? { value:'50', toolText: this.gettextCatalog.getString('Средний') }: null);
            good.push(item === 'B' ? { value:'75', toolText: this.gettextCatalog.getString('Хороший') }: null);
            veryGood.push(item === 'A' ? { value:'100', toolText: this.gettextCatalog.getString('Отличный') }: null);
        }
        dataSet.push({ seriesname: '', data: veryBad, color: '#DA5855' });
        dataSet.push({ seriesname: '', data: bad, color: '#F5A829' });
        dataSet.push({ seriesname: '', data: good, color: '#A8D8A8' });
        dataSet.push({ seriesname: '', data: veryGood, color: '#5EB75D' });
        return dataSet;
    }

    getMonthCount = () => {
        if (!this.motivationHistory.length){
            return;
        }
        return Math.floor((this.motivationHistory[this.motivationHistory.length - 1].date - this.motivationHistory[0].date) /60/60/24/30);
    }

    getMonth = (n) => {
        let month = new Date(this.motivationHistory[n].date).getMonth();
        return month;
    }

    setCategory = () => {
        let category = [];
        let choosed = [];
        for (let i = 0; i < this.motivationHistory.length; i++) {
            let month = this.month[this.getMonth(i)];
            if (month === choosed[choosed.length - 1]) {
                category.push({
                    label: ' '
                });
            } else {
                category.push({
                    label: month
                });
                choosed.push(month);
            }
        }
        return category.length ? category : [{ label: '' }];
    }
}

DashboardMotivation.$inject = [
    'gettextCatalog',
    '$ngRedux',
    '$scope'
];

export {
    DashboardMotivation,
};
