export const educationRule = {
    name: {
        required: false
    },
    description: {
        required: false
    },
    internalUrl: {
        required: true,
        url: true,
    },
    systemUrl: {
        required: false,
        url: true,
    },
    systemPhone: {
        required: false,
        phone: true
    },
    systemLanguage: {
        required: false
    }
};
