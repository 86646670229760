import React, { useState, useContext, useEffect } from 'react';
import Input from 'rootReact/components/Input';
import { GettextContext, InjectorContext } from 'rootReact/context';
import { useAutoWidth, useInputStyle, usePlural, useValidForm } from 'rootReact/hook';
import { educationRule } from './EducationSystemAfRule';
import UrlInput from 'rootReact/components/UrlInput';
import DropdownSelect from 'rootReact/components/DropdownSelect';
import { ImageModal } from 'root/assets/webpack/src/js/components/imageModal';
import FaIcon from 'rootReact/components/FaIcon';
import { validRules } from '../../../helpers';

const noColumn = {
    marginLeft: 0,
    marginRight: 30
};

const READY = 'READY';
const LOADING = 'LOADING';
const SUCCESS = 'SUCCESS';

const linkModeOneTime = 1;
const linkModeUnlimited = 2;

function EducationSystemAf({ learningConfig, languages, licenseIsValid, saveSettings, error }) {
    const gettext = useContext(GettextContext);
    const injector = useContext(InjectorContext);
    const [name, setName] = useState(learningConfig.systemName || '');
    const [description, setDescription] = useState(learningConfig.systemDescription || '');
    const [internalUrl, setInternalUrl] = useState(learningConfig.internalUrl || 'http://');
    const [systemLogo, setSystemLogo] = useState(learningConfig.systemLogo || null);
    const [systemLogoExt, setSystemLogoExt] = useState(learningConfig.systemLogoExt || null);
    const [systemUrl, setSystemUrl] = useState(learningConfig.systemUrl || '');
    const [systemPhone, setSystemPhone] = useState(learningConfig.systemPhone || '');
    const [systemLanguage, setSystemLanguage] = useState(languages.find(item => item.code === learningConfig.systemLanguage)?.label);
    const [learningValue, setLearningValue] = useState(learningConfig.timeValue || 1);
    const [learningPeriod, setLearningPeriod] = useState(learningConfig.timePeriod || 'day');
    const [linkMode, setLinkMode] = useState(learningConfig.linkMode || linkModeUnlimited);
    const [learningStatus, setLearningStatus] = useState(READY);
    const values = useValidForm({
        name: { value: name, setValue: setName },
        description: { value: description, setValue: setDescription },
        internalUrl: { value: internalUrl, setValue: setInternalUrl },
        systemUrl: { value: systemUrl, setValue: setSystemUrl },
        systemPhone: { value: systemPhone, setValue: setSystemPhone },
        systemLanguage: { value: systemLanguage, setValue: setSystemLanguage }
    }, educationRule);
    const nameStyle = useInputStyle(['react-input_field'], values['name'].touched, values['name'].focused, values['name'].error);
    const internalUrlStyle = useInputStyle(['react-input_field react-input_field__right'], values['internalUrl'].touched, values['internalUrl'].focused, values['internalUrl'].error);
    const descriptionStyle = useInputStyle(['react-input_field'], values['description'].touched, values['description'].focused, values['description'].error);
    const systemUrlStyle = useInputStyle(['react-input_field react-input_field__right'], values['systemUrl'].touched, values['systemUrl'].focused, values['systemUrl'].error);
    const systemPhoneStyle = useInputStyle(['react-input_field'], values['systemPhone'].touched, values['systemPhone'].focused, values['systemPhone'].error);
    const systemLanguageStyle = useInputStyle(['react-input_field'], true, values['systemLanguage'].focused, values['systemLanguage'].error);
    const widthLearningCount = useAutoWidth(learningValue, { minChars: 2 });

    const handleClickLanguage = (lang) => {
        values['systemLanguage'].inputAttr.onChange({ target: { value: lang.label } });
    };

    const handleLearningValue = (value) => {
        if (value < 1) return setLearningValue(1);
        setLearningValue(value);
    };

    const cleanLogo = () => {
        setSystemLogoExt(null);
        setSystemLogo(null);
    };

    const addLogo = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setSystemLogoExt(file.type);
        reader.readAsDataURL(file);
        reader.onload = function () {
            setSystemLogo(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const viewLogotype = () => {
        let logoModal = injector.instantiate(ImageModal);
        logoModal.open({ data: systemLogo, ext: systemLogoExt }).then(() => {}, () => {});
    };

    const getLogoBase = () => {
        if (systemLogo && systemLogo.indexOf('base64,') > -1) {
            let logo = systemLogo.split('base64,')[1];
            return logo;
        }
        return systemLogo;
    };

    const handleSave = async () => {
        const errors = validRules(educationRule, [
            { name: 'internalUrl', value: internalUrl },
            { name: 'systemUrl', value: systemUrl }
        ]);
        if (errors && learningConfig.isVisible) {
            values.setError(errors);
            return;
        }
        setLearningStatus(LOADING);
        if (!learningConfig.isVisible) {
            await saveSettings({
                timePeriod: learningPeriod,
                timeValue: learningValue,
            });
        } else {
            await saveSettings({
                systemDescription: description,
                systemName: name,
                internalUrl,
                systemLogo: getLogoBase(),
                systemLogoExt,
                systemUrl,
                systemPhone,
                systemLanguage: languages.filter(item => item.label === systemLanguage)[0].code,
                timePeriod: learningPeriod,
                timeValue: learningValue,
                linkMode,
                type: 2
            });
        }

        setLearningStatus(SUCCESS);
        setTimeout(() => setLearningStatus(READY), 2000);
    };

    useEffect(() => {
        if (learningStatus === SUCCESS) {
            setTimeout(() => setLearningStatus(READY), 2000);
        }
    }, [learningStatus]);

    return (
        <div>
            {!!learningConfig.isVisible && <><div className={'settings_item'}>
                <div className={'settings_path-left'}>
                    {gettext.getString('Адрес')}
                </div>
                <div className={'settings_path-right'} style={noColumn}>
                    <UrlInput
                        value={values['internalUrl'].inputAttr.value}
                        setValue={values['internalUrl'].inputAttr.onChange}
                        handleBlur={values['internalUrl'].inputAttr.onBlur}
                        handleFocus={values['internalUrl'].inputAttr.onFocus}
                        error={values['internalUrl'].error}
                        Style={internalUrlStyle}
                    />
                </div>
            </div>
            <div className={'settings_item'}>
                <div className={'settings_path-left'}>
                    {gettext.getString('Логотип')}
                </div>
                <div className={'settings_path-right'} style={{ display: 'flex' }}>
                    <label style={{ ...noColumn, width: 'fit-content', marginRight: 0 }}
                        className={`react-button_element react-button_element__db ${systemLogo && systemLogoExt ? 'react-button_element__left' : ''}`}
                    >{gettext.getString('Выбрать')}
                        <input
                            type='file'
                            style={{ display: 'none' }}
                            onChange={addLogo}
                        />
                    </label>
                    {systemLogo && systemLogoExt &&
                    <>
                        <button
                            onClick={viewLogotype}
                            style={{ marginRight: 0 }}
                            className={'react-button_element react-button_element__db react-button_element__right'}
                        >
                            {gettext.getString('Просмотр')}
                        </button>
                        <div className={'setting-ico'}
                            onClick={cleanLogo}
                        >
                            <span className='ico ico-delete'></span>
                        </div>
                    </>}
                </div>
            </div>
            <div className={'settings_item'}>
                <Input
                    inputId={'edu-name'}
                    labelStyle={'settings-auth_label'}
                    label={gettext.getString('Название')}
                    inputAttr={values['name'].inputAttr}
                    error={values['name'].error}
                    Style={nameStyle}
                />
            </div>
            <div className={'settings_item'}>
                <Input
                    inputId={'description'}
                    labelStyle={'settings-auth_label'}
                    label={gettext.getString('Описание')}
                    inputAttr={values['description'].inputAttr}
                    error={values['description'].error}
                    Style={descriptionStyle}
                    textarea={'auto'}
                />
            </div>
            <div className={'settings_item'}>
                <div className={'settings_path-left'}>
                    {gettext.getString('Ссылка на копирайте')}
                </div>
                <div className={'settings_path-right'} style={noColumn}>
                    <UrlInput
                        value={values['systemUrl'].inputAttr.value}
                        setValue={values['systemUrl'].inputAttr.onChange}
                        handleBlur={values['systemUrl'].inputAttr.onBlur}
                        handleFocus={values['systemUrl'].inputAttr.onFocus}
                        error={values['systemUrl'].error}
                        Style={systemUrlStyle}
                    />
                </div>
            </div>
            <div className={'settings_item'}>
                <Input
                    inputId={'system-phone'}
                    label={gettext.getString('Телефон поддержки')}
                    labelStyle={'settings-auth_label'}
                    inputAttr={values['systemPhone'].inputAttr}
                    error={values['systemPhone'].error}
                    Style={systemPhoneStyle}
                />
            </div>
            <div className={'settings_item'}>
                <div className={'settings_path-left'} style={{ marginRight: '15px' }}>
                    {gettext.getString('Язык')}
                </div>
                <div className={'settings_path-right'}>
                    <DropdownSelect
                        inputAttr={{ ...values['systemLanguage'].inputAttr, value: values['systemLanguage'].inputAttr.value }}
                        labelStyle={'settings-auth_label'}
                        Style={systemLanguageStyle}
                        list={languages}
                        idClass={'system-language'}
                        optionsName={'label'}
                        handleClick={handleClickLanguage}
                    />
                </div>
            </div></>}
            <div className={'settings_item'}>
                <div className={'settings_path-left settings-auth_label'} style={{ wordBreak: 'break-word', marginRight: '15px' }}>
                    {gettext.getString('На прохождение курсов')}
                </div>
                <div className={'settings_path-right'}>
                    <div className={'row-wrapper'}>
                        <input type={'number'}
                            className={'react-input_field react-input_field__sm'}
                            style={{ width: widthLearningCount, marginRight: 0 }}
                            value={learningValue}
                            onChange={(e) => handleLearningValue(e.target.value)}/>
                        <button className={`${learningPeriod === 'day' ? 'react-button_element__active' : ''} react-button_element react-button_element__sm react-button_element__left`}
                            onClick={() => {setLearningPeriod('day');}}
                        >{gettext.getString(usePlural(learningValue, ['день', 'дня', 'дней']))}</button>
                        <button onClick={() => {setLearningPeriod('week');}}
                            className={`${learningPeriod !== 'day' ? 'react-button_element__active' : ''} react-button_element react-button_element__sm react-button_element__right`}>{gettext.getString(usePlural(learningValue, ['неделя', 'недели', 'недель']))}</button>
                    </div>
                </div>
            </div>
            {!!learningConfig.isVisible && <div className={'settings_item'}>
                <div className={'settings_path-left'}>
                    {gettext.getString('Ссылка на обучение')}
                </div>
                <div className={'settings_path-right'}>
                    <div className={'row-wrapper'}>
                        <button className={`${linkMode === linkModeUnlimited ? 'react-button_element__active' : ''} react-button_element react-button_element__sm react-button_element__left`}
                            onClick={() => {setLinkMode(linkModeUnlimited);}}
                        >{gettext.getString('бессрочная')}</button>
                        <button className={`${linkMode === linkModeOneTime ? 'react-button_element__active' : ''} react-button_element react-button_element__sm react-button_element__right`}
                            onClick={() => {setLinkMode(linkModeOneTime);}}
                        >{gettext.getString('одноразовая')}</button>
                    </div>
                </div>
            </div>}
            <div className={'settings_item'}>
                <div className={'settings_path-left'}></div>
                <div className={'settings_path-right'}>
                    {error && <div className={'text-danger'}>
                        <span className={'fa fa-exclamation-triangle'}></span>
                        {error}
                    </div>}
                    {learningStatus === LOADING && <div style={{ display: 'flex' }}><FaIcon type={'pending'} /> <a className={'nolink'}>{gettext.getString('Сохранить настройки')}</a></div>}
                    {learningStatus === READY && <a onClick={handleSave} className={!licenseIsValid ? 'nolink' : ''}>{gettext.getString('Сохранить настройки')}</a>}
                    {learningStatus === SUCCESS && <div style={{ display: 'flex' }}><FaIcon type={'success'} /> <a className={'nolink'}>{gettext.getString('Изменения сохранены')}</a></div>}
                </div>
            </div>
        </div>
    );
}

export default EducationSystemAf;
