import style from './style.modules.css';
// import angular from 'angular';

class SelfProgressBarController {
    constructor() {
        this.style = style;
    }

    $onInit() {}
}

SelfProgressBarController.$inject = [];

export {
    SelfProgressBarController,
};
