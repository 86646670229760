import style from './style.modules.css';

class ModalFooterController {
    constructor (gettextCatalog) {
        this.style = style;
        this.gettextCatalog = gettextCatalog;
    }

    $onInit() {}

    onClick = (index) => {
        this.buttonConf[index].action();
    }
}

ModalFooterController.$inject = [
    'gettextCatalog'
];

export {
    ModalFooterController
};
