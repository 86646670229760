import { hasAccess, getDashboardInfo } from '../../reselect';
import style from './style.modules.css';

class ReportSideBarController {
    constructor ($ngRedux) {
        this.reportType = 5;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis)(this);
        this.style = style;
    }

    mapStateToThis (state) {
        return {
            accessCommonReport: hasAccess(state, { sectionId: 4, rightName: 'report_common' }),
            accessReportFull: hasAccess(state, { sectionId: 4, rightName: 'report_full' }),
            accessAnonymReport: hasAccess(state, { sectionId: 4, rightName: 'report_anonym_stat' }),
            accessFeedbackReport: hasAccess(state, { sectionId: 4, rightName: 'report_feedback_outlook' }),
            vulnCount: getDashboardInfo(state).vulnCount,
            vulnPercent: getDashboardInfo(state).vulnPercent,
        };
    }

    $onInit = () => {
        this.checkAccess();
    }

    selectReport = (reportType) => {
        this.reportType = reportType;
    }

    checkAccess = () => {
        if (!this.accessReportFull) {
            this.reportType = 6;
        }
    }

    $onDestroy () {
        this.unsubscribe();
    }
}

ReportSideBarController.$inject = [
    '$ngRedux'
];

export {
    ReportSideBarController
};
