import loginFormTemplate from './view.html';

import { LoginFormController } from './controller';

const LoginFormComponent = {
    template: loginFormTemplate,
    controller: LoginFormController,
    controllerAs: '$',
    bindings: {
        signIn: '=',
        user: '<',
        showPasswordForm: '<',
        inProcess: '<',
        errorText: '<',
        signInBackUrl: '<',
        isSSO: '<',
        changePasswordText: '<',
        setSSO: '=',
        twoFactorEnable: '<',
        setTwoFactor: '='
    }
};

export {
    LoginFormComponent
};
