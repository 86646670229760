import style from './style.modules.css';

class GroupsGraphItemController {
    constructor ($scope, gettextCatalog) {
        this.scope = $scope;
        this.style = style;
        this.gettextCatalog = gettextCatalog;
    }

    $onInit () {
        this.textStyle = `${this.parentStyle['header-link']} ${this.parentStyle['group-num']} ${!this.groupsItem && this.parentStyle['no-interactive']}`;
    }
}

GroupsGraphItemController.$inject = [
    '$scope',
    'gettextCatalog'
];

export {
    GroupsGraphItemController
};
