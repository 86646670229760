import { GettextContext } from 'rootReact/context';
import { useContext } from 'react';

export const accountSchema = ({ handleRow, iconHandle, disabled }) => {
    const gettext = useContext(GettextContext);
    return {
        row: { handleRow, disabled: () => false },
        cell: [
            {
                text: 'fullName',
            },
            {
                text: 'login',
            },
            {
                text: 'phone',
            },
            {
                text: (item) => item.department && item.department.name,
            },
            {
                text: 'position',
            },
            {
                text: (item) => item.usesSecondFactor ? gettext.getString('Включена') : gettext.getString('Выключена')
            },
        ],
        icon: [
            {
                isIcon: true,
                icon: disabled ? 'noIcon' : 'delete',
                handle: [
                    ...iconHandle
                ]
            }
        ]
    };
};

