export default ($location, path) => {
    if (window.errorMessage) {
        if ($location.path() != '/error') {
            $location.path('error');
            $location.hash('');
        }
    } else {
        $location.path(path);
        $location.hash('');
    }
};
