import UserModal from 'rootReact/components/UserModal';

class UserModalController {
    constructor(userId, userInfo, $ngRedux, gettextCatalog) {
        this.redux = $ngRedux;
        this.UserModal = UserModal;
        this.userId = userId;
        this.userInfo = userInfo;
        this.gettextCatalog = gettextCatalog;
    }
}

UserModalController.$inject = [
    'userId',
    'userInfo',
    '$ngRedux',
    'gettextCatalog'
];

export {
    UserModalController
};
