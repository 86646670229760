// import angular from 'angular';
import { hasAccess } from '../../reselect';

class SettingsEducationController {
    constructor($ngRedux, gettextCatalog, $localStorage, scope) {
        this.gettextCatalog = gettextCatalog;
        this.$localStorage = $localStorage;
        this.type = 0;
        this.scope = scope;
        this.baseUrl = '';
        this.userSyncUrl = '';
        this.login = '';
        this.password = '';
        this.token = '';
        this.errors = {};
        this.fields = [
            'type',
            'baseUrl',
            'employeesUrl',
            'userSyncUrl',
            'login',
            'password',
            'token',
            'ntlmLogin',
            'ntlmPassword',
            'isNtlm',
        ];
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis)(this);
    }

    mapStateToThis = (state) => {
        return {
            educationSave: hasAccess(state, { sectionId: 6, rightName: 'education_save' }),
        };
    };

    $onInit() {
        this.type = this.learningConfig.type;
        this.baseUrl = this.learningConfig.baseUrl;
        this.employeesUrl = this.learningConfig.employeesUrl;
        this.userSyncUrl = this.learningConfig.userSyncUrl;
        this.login = this.learningConfig.login;
        this.password = this.learningConfig.password;
        this.token = this.learningConfig.token;
        this.isNtlm = this.learningConfig.isNtlm;
        this.ntlmLogin = this.learningConfig.ntlmLogin;
    }

    setLearningType = (type) => {
        if (this.educationSave) {
            this.type = type || 0;
            this.recalcInactiveNotifications();
            this.setLearningParams();
        }
    };

    setLearningParams = (urlName, fieldName) => {
        if (fieldName === 'baseUrl') {
            this.baseUrl = urlName ? urlName : this.baseUrl;
        }
        if (fieldName === 'userSyncUrl') {
            this.userSyncUrl = urlName ? urlName : this.userSyncUrl;
        }
        this.sendParams();
    }

    sendParams = () => {
        const {
            type,
            baseUrl,
            employeesUrl,
            userSyncUrl,
            login,
            password,
            token,
            ntlmLogin,
            ntlmPassword,
            isNtlm
        } = this;
        this.getLearningParams({
            type,
            baseUrl,
            employeesUrl,
            userSyncUrl,
            login,
            password,
            token,
            ntlmLogin,
            ntlmPassword,
            isNtlm: +isNtlm
        });
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

SettingsEducationController.$inject = [
    '$ngRedux',
    'gettextCatalog',
    '$localStorage',
    '$scope'
];

export {
    SettingsEducationController,
};
