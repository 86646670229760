import angular from 'angular';
import buildSelectedDataFromResponse from './buildSelectedDataFromResponse';
import { EMPTY_SELECTED_DATA } from '../const';

export default (selectedData, options) => {
    if (options && options.setLoadingFunction && typeof (options.setLoadingFunction) == 'function') {
        options.setLoadingFunction(true);
    }
    if (options && options.courseId) {
        selectedData.courses = [options.courseId];
    }
    if (options && options.risk) {
        selectedData.risk = options.risk;
    }
    if (options && options.software) {
        if (options.software.vendorId) {
            selectedData.vendorId = options.software.vendorId;
        }
        if (options.software.id) {
            selectedData.softwareId = options.software.id;
        }
    }
    if (options && options.managerId > 0) {
        selectedData.managerId = options.managerId;
    }
    selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(selectedData));
    let targets = selectedData.targets;
    let unselectedTargets = selectedData.unselectedTargets;
    selectedData.targets = {};
    selectedData.unselectedTargets = {};
    angular.forEach(targets, (ids, departmentId) => {
        if (departmentId > 0) {
            selectedData.targets[departmentId] = ids;
        }
    });
    angular.forEach(unselectedTargets, (ids, departmentId) => {
        if (departmentId > 0) {
            selectedData.unselectedTargets[departmentId] = ids;
        }
    });
    let sort = options && options.reverse ? 'reverse' : 'rebuild';
    fetch(`${window.config.SYSTEM_URL}${window.config.API_URL}/targets/${sort}`, {
        headers: {
            'X-Csrf-Token': window.csrfToken,
            'Enable-Session': 1,
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(selectedData)
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            if (options && options.setLoadingFunction && typeof (options.setLoadingFunction) == 'function') {
                options.setLoadingFunction(false);
            }
            if (options && options.scope) {
                options.scope.$apply();
            }
        }
    }).then(selectedData => {
        let newSelectedData = buildSelectedDataFromResponse(selectedData, selectedData, {
            risk: options && options.risk ? 1 : 0,
            setDepartmentCheckedFunction: options ? options.setDepartmentCheckedFunction : null,
            setDepartmentPartialFunction: options ? options.setDepartmentPartialFunction : null,
            setDepartmentCheckedAndPartialFunction: options ? options.setDepartmentCheckedAndPartialFunction : null
        });
        if (options && options.setSelectedDataFunction && typeof (options.setSelectedDataFunction) == 'function') {
            options.setSelectedDataFunction(newSelectedData);
        }
        if (options && options.callbackFunction && typeof (options.callbackFunction) == 'function') {
            options.callbackFunction(options && options.callbackParams && options.callbackParams[0] ? options.callbackParams[0] : null, options && options.callbackParams && options.callbackParams[1] ? options.callbackParams[1] : null,
                options && options.callbackParams && options.callbackParams[2] ? options.callbackParams[2] : null, options && options.callbackParams && options.callbackParams[3] ? options.callbackParams[3] : null);
        }
        if (options && options.setLoadingFunction && typeof (options.setLoadingFunction) == 'function') {
            options.setLoadingFunction(false);
        }
        if (options && options.scope) {
            options.scope.$apply();
        }
    }).catch((error) => {
        window.console.log(error);
        if (options && options.setLoadingFunction && typeof (options.setLoadingFunction) == 'function') {
            options.setLoadingFunction(false);
        }
        if (options && options.scope) {
            options.scope.$apply();
        }
    });
};
