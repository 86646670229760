import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';

const initialState = {
    motivationHistory: [],
    load: true,
    error: false
};

export const fetchMotivationHistory = createAsyncThunk(
    'motivationHistory/status',
    async (data) => {
        const fetchApi = new FetchApi('/history/motivation');
        let response = await fetchApi.post(data);
        if (response.length) {
            response = response.map(item => {
                item.date = item.date * 1000;
                return item;
            });
            return response;
        }
        return [];
    }
);

const motivationHistorySlice = createSlice({
    name: 'motivationHistory',
    initialState,
    reducers: {
        motivationHistoryDefault(state) {
            state = initialState;
        }
    },
    extraReducers: {
        [fetchMotivationHistory.fulfilled]: (state, { payload }) => {
            state.motivationHistory = payload;
            state.load = false;
            state.error = false;
        },
        [fetchMotivationHistory.pending]: (state) => {
            state.load = true;
            state.error = false;
        },
        [fetchMotivationHistory.rejected]: (state) => {
            state.load = false;
            state.error = true;
        },
    }
});

export const {motivationHistoryDefault} = motivationHistorySlice.actions;
export default motivationHistorySlice.reducer;

