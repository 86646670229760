import reportTemplate from './view.html';

import { ReportDiagramsController } from './controller';

const ReportDiagramsComponent = {
    template: reportTemplate,
    controller: ReportDiagramsController,
    controllerAs: '$',
    bindings: {
        data: '<',
        skill: '<',
        name: '<',
        showTargetList: '=',
        cacheTargets: '=',
    },
};

export {
    ReportDiagramsComponent,
};
