import courseFunctionTemplate from './view.html';

import { CourseFunctionController } from './controller';

const CourseFunctionComponent = {
    template: courseFunctionTemplate,
    controller: CourseFunctionController,
    controllerAs: '$',
    scope: true,
    bindings: {
        graphMode: '<',
        viewMode: '<',
        modeData: '<',
        isAnyLoading: '<',
        reloading: '<',
        setReloading: '<',
        setDepartmentCount: '<',
        departmentCheckedCache: '=',
        departmentPartialCache: '=',
        setLoading: '<',
        rebuildLoading: '<',
        setSearch: '<',
    },
};

export {
    CourseFunctionComponent,
};
