import style from './style.modules.css';

class DashboardOldController {
    constructor(gettextCatalog, scope, location, timeout, $state) {
        this.style = style;
        this.scope = scope;
        this.$state = $state;
        this.location = location;
        this.timeout = timeout;
        this.gettextCatalog = gettextCatalog;
    }

    $onInit () {}

    $onDestroy() {}
}

DashboardOldController.$inject = [
    'gettextCatalog',
    '$scope',
    '$location',
    '$interval',
    '$state'
];

export {
    DashboardOldController,
};
