class ImageModalController {
    constructor(image) {
        this.imageData = image.data;
        this.imageExt = image.ext;
    }
}

ImageModalController.$inject = [
    'image',
];

export {
    ImageModalController,
};
