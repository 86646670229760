import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    motivation: null
};

const motivationSlice = createSlice({
    name: 'motivation',
    initialState,
    reducers: {
        setMotivation (state, {payload}) {
            state.motivation = payload;
        }
    },
    extraReducers: {}
});

export const {setMotivation} = motivationSlice.actions;
export default motivationSlice.reducer;

