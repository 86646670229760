import React from 'react';

export function TableRow({ children, handleClick, Style, className }) {
    return (
        <tr data-testid={'ui-table-table_row'}
            className={`react-table_row ${className}`}
            style={Style}
            onClick={() => handleClick()}
        >
            {children}
        </tr>
    );
}
