import { createSelector } from 'reselect';

const outlookSettings = (state) => {
    return state.outlook.outlook;
};

export const getOutlookCard = createSelector([outlookSettings], outlookSettings => {
    const card = [];
    for (let key in outlookSettings) {
        if (outlookSettings[key].type) {
            card.push(outlookSettings[key]);
        }
    }
    return card;
});

export const getFeedbackLink = createSelector([outlookSettings], outlookSettings => {
    console.log(outlookSettings);
});
