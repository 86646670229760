import { createSelector } from 'reselect';

const departmentsInfo = (state, props = null) => {
    return { state: state.departments, props: props };
};

export const getDepartments = createSelector([departmentsInfo], departmentsInfo => {
    let departments = departmentsInfo.state.departments;
    return departments;
});

export const getDepartmentsStatus = createSelector([departmentsInfo], departmentsInfo => {
    return departmentsInfo.state.load;
});
