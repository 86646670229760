import { createSelector } from 'reselect';

const dashboardNew = (state) => {
    return state.newDashboard.dashboard;
};

export const getDashboardLoading = (state) => {
    return state.newDashboard.firstLoading;
};

export const getDashboardNew = createSelector([dashboardNew], dashboardNew => {
    return dashboardNew;
});

export const getDashboardInfo = createSelector([dashboardNew], dashboardNew => {
    return dashboardNew;
});

export const getKnowledge = createSelector([dashboardNew], dashboardNew => {
    const {
        noCoursePercent, noCourse,
        onCoursePercent, onCourse,
        completeCoursePercent, completeCourse,
        missCoursePercent, missCourse,
        targets
    } = dashboardNew;
    const dataset = getData([noCoursePercent, onCoursePercent, completeCoursePercent, missCoursePercent],
        [noCourse, onCourse, completeCourse, missCourse]);
    const noCourses = getSubValue(targets, noCoursePercent, noCourse, '0');
    const onCourses = getSubValue(targets, onCoursePercent, onCourse, '1');
    const completeCourses = getSubValue(targets, completeCoursePercent, completeCourse, '2');
    const missCourses = getSubValue(targets, missCoursePercent, missCourse, '3');
    return {
        noCourses,
        onCourses,
        completeCourses,
        missCourses,
        dataset
    };
});

export const getCampaign = createSelector([dashboardNew], dashboardNew => {
    const {
        noCampaignPercent, noCampaign,
        completeCampaignPercent, completeCampaign,
        openCampaignPercent, openCampaign,
        linkCampaignPercent, linkCampaign,
        openAttachmentPercent, openAttachment,
        openAttachmentFromLinkPercent, openAttachmentFromLink,
        formCampaignPercent, formCampaign,
        targets
    } = dashboardNew;
    const dataset = getData([noCampaignPercent, completeCampaignPercent, openCampaignPercent, linkCampaignPercent, openAttachmentPercent + openAttachmentFromLinkPercent, formCampaignPercent],
        [noCampaign, completeCampaign, openCampaign, linkCampaign, openAttachment + openAttachmentFromLink, formCampaign]);
    const noCampaigns = getSubValue(targets, noCampaignPercent, noCampaign, '0');
    const completeCampaigns = getSubValue(targets, completeCampaignPercent, completeCampaign, '1');
    const openCampaigns = getSubValue(targets, openCampaignPercent, openCampaign, '2');
    const linkCampaigns = getSubValue(targets, linkCampaignPercent, linkCampaign, '3');
    const openAttachments = getSubValue(targets, openAttachmentPercent + openAttachmentFromLinkPercent, openAttachment + openAttachmentFromLink,'4');
    const formCampaigns = getSubValue(targets, formCampaignPercent, formCampaign, '5');
    return {
        noCampaigns,
        completeCampaigns,
        openCampaigns,
        linkCampaigns,
        formCampaigns,
        openAttachments,
        dataset
    };
});

export const getRating = createSelector([dashboardNew], dashboardNew => {
    const { rating, lastChange, ratingImprove, ratingWorse, campaigns } = dashboardNew;
    return { rating, lastChange, ratingImprove, ratingWorse, campaigns };
});

export const getVulnerability = createSelector([dashboardNew], dashboardNew => {
    const {
        noCampaignPercent, noCampaign,
        noVulnerabilityPercent, noVulnerability,
        hasVulnerabilityPercent, hasVulnerability,
        targets
    } = dashboardNew;
    const dataset = getData([noCampaignPercent, noVulnerabilityPercent, hasVulnerabilityPercent],
        [noCampaign, noVulnerability, hasVulnerability]);
    const noChecked = getSubValue(targets, noCampaignPercent, noCampaign, '0');
    const noVulnerabilitys = getSubValue(targets, noVulnerabilityPercent, noVulnerability, '1');
    const hasVulnerabilitys = getSubValue(targets, hasVulnerabilityPercent, hasVulnerability, '2');
    return {
        noChecked,
        noVulnerabilitys,
        hasVulnerabilitys,
        dataset
    };
});

function getSubValue (all, percent, target, index) {
    let subValue = '';
    let value = 0;
    let item = '';
    if (!percent && target > 0) {
        subValue = '<';
    }
    if (subValue) {
        value = 1;
        item = {
            text: `
                <div class="dashboard-less">&lt;</div>
                <div>${value}</div>
                <div class="dashboard-percent">%</div>`,
            target,
            value,
            index
        };
        return item;
    }
    value = percent;
    if (percent === 100 && target !== all) {
        value = 99;
    }
    item = {
        text: `
            <div>${value}</div>
            <div class="dashboard-percent">%</div>`,
        target,
        value,
        index
    };
    return value ? item : false;
}


const getData = (percent, value) => {
    const data = percent.map((item, index) => {
        return item < 5 && value[index] > 0 ? 5 : item;
    });
    const dataset = [];
    dataset.push(data);
    dataset.push(data);
    return dataset;
};
