import { getDashboardVersion, getDashboardRound } from './dashboard.js';

import { getCompanyName, getUserName, getUserTimeZone, hasAccess, getAuthType, isSSO, getDomains } from './auth.js';

import { getDepartments, getDepartmentsStatus } from './departments';

import { getLerningConfig } from './learningConfig';

import { getDashboardInfo } from './newDasboard';

import { getSystemAlert } from './extensibleData';

import { getOutlookCard } from './outlook';

import { defaultNotification, customNotification, targetNotification } from './systemNotification';

import { notExtensibleWifiAttack } from './wifiAttack';

import { notExtensibleCoursesList } from './courses';

import { getCertDaemon } from './daemons';

import {
    notExtensibleTemplate,
    notExtensibleCourses,
    notExtensibleTrigger,
    notExtensibleReport,
    notExtensibleAction,
} from './scheduler';

import {
    getAttackHistoryDashboard,
    getTargetHistoryDashboard,
    getEducationHistoryDashboard,
    getMotivationHistoryDashboard,
} from './history';

export {
    getDashboardVersion,
    getDashboardInfo,
    hasAccess,
    getUserName,
    getCompanyName,
    getUserTimeZone,
    getDepartments,
    getDepartmentsStatus,
    getLerningConfig,
    getAttackHistoryDashboard,
    getTargetHistoryDashboard,
    getEducationHistoryDashboard,
    getMotivationHistoryDashboard,
    getDashboardRound,
    getAuthType,
    isSSO,
    getDomains,
    notExtensibleTemplate,
    notExtensibleCourses,
    notExtensibleTrigger,
    notExtensibleReport,
    notExtensibleAction,
    getSystemAlert,
    getOutlookCard,
    defaultNotification,
    customNotification,
    targetNotification,
    notExtensibleCoursesList,
    notExtensibleWifiAttack,
    getCertDaemon,
};
