import { VARIABLES_SERVICE } from '../../const';

class VariableModalController {
    constructor(VariablesService, variableId) {
        this.VariablesService = VariablesService;
        this.variableId = variableId;
        this.variable = null;
        this.errorVariable = null;
        this.loadingVariable = null;
        this.errorText = null;
        this.gettextCatalog = window.appCtrl.gettextCatalog;
        this.appCtrl = window.appCtrl;
    }

    setForm = (form) => {
        this.form = form;
    };

    loadVariable = () => {
        this.errorVariable = false;
        this.loadingVariable = true;
        this.VariablesService.one(this.variableId).then((variable) => {
            this.variable = variable;
            this.loadingVariable = false;
        }, () => {
            this.loadingVariable = false;
            this.errorVariable = true;
        }).catch(() => {
            this.loadingVariable = false;
            this.errorVariable = true;
        });
    };

    $onInit = () => {
        this.loadVariable();
        this.editable = this.appCtrl && this.appCtrl.hasAccess(11, 'variable_save');
    };

    save = () => {
        if (this.editable) {
            this.errorText = null;
            this.form.$setSubmitted();
            if (this.form.$valid) {
                this.inProcess = true;
                this.variable.$save().then((variable) => {
                    if (variable) {
                        this.$close(variable);
                    }
                    this.inProcess = false;
                }).catch(() => {
                    this.errorText = this.gettextCatalog.getString('Ошибка при сохранении переменной');
                });
            }
        }
    };
}

VariableModalController.$inject = [
    VARIABLES_SERVICE,
    'variableId',
];

export {
    VariableModalController,
};
