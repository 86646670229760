import style from './style.modules.css';

class GroupsGraphController {
    constructor ($scope, gettextCatalog) {
        this.style = style;
        this.scope = $scope;
        this.view = 'group';
        this.ratingSheme = [];
        this.gettextCatalog = gettextCatalog;
    }

    $onInit () {
        this.getRatingShema();
        this.scope.$watch(scope => {
            return scope.$.groupsList;
        }, (newVal, oldVal) => {
            if (newVal !== oldVal) {
                this.getRatingShema();
            }
        });
        this.setSortBy('targetsCount', true);
    }

    getRatingShema = () => {
        this.ratingSheme = [];
        for (let i = 0; i < this.groupsList.length; i++) {
            if (this.groupsList[i].id === -1) {
                return;
            }
            this.ratingSheme.push(this.getRatingBg(this.groupsList[i]));
        }
    }

    getRatingBg = (item) => {
        if (+item.rating === 0 && +item.lastChange === 0) {
            return 'noRatingChanges';
        }
        if (+item.rating > 0 && +item.lastChange >= 0) {
            return 'positiveRatingUp';
        }
        if (+item.rating > 0 && +item.lastChange < 0) {
            return 'positiveRatingDown';
        }
        if (+item.rating < 0 && +item.lastChange >= 0) {
            return 'negativeRatingUp';
        }
        if (+item.rating < 0 && +item.lastChange < 0) {
            return 'negativeRatingDown';
        }
    }

    setView = (view) => {
        if (this.isAnyLoading()) return;
        if (view === this.view) return;
        if ((view === 'group' || view === 'rating') && (this.view === 'group' || this.view === 'rating')) {
            this.view = view;
            return;
        }
        this.view = view;
        this.setSortBy(view === 'priority' ? 'priority' : 'targetsCount', true);
    }
}

GroupsGraphController.$inject = [
    '$scope',
    'gettextCatalog'
];

export {
    GroupsGraphController
};
