import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    search: '',
    callback: null,
    reload: false
};

const departmentsFilterSlice = createSlice({
    name: 'departmentsFilter',
    initialState,
    reducers: {
        applyFilterDepartment (state, {payload}) {
            state.search = payload.search;
            state.callback = payload.callback;
            state.reload = payload.reload;
        }
    },
    extraReducers: {}
});

export const {applyFilterDepartment} = departmentsFilterSlice.actions;
export default departmentsFilterSlice.reducer;

