import settingsEducationTemplate from './view.html';

import { SettingsEducationController } from './controller';

const SettingsEducationSystemComponent = {
    template: settingsEducationTemplate,
    controller: SettingsEducationController,
    controllerAs: '$',
    scope: true,
    bindings: {
        getLearningParams: '=',
        learningConfig: '<',
        notificationsLoading: '<',
        recalcInactiveNotifications: '='
    },
};

export {
    SettingsEducationSystemComponent,
};
