import styles from './style.modules.css';
import angular from 'angular';

class ArrowsFilterController {
    constructor($scope, gettextCatalog) {
        this.$scope = $scope;
        this.forseSelect = false;
        this.loadedTemplate = false;
        this.arrowsType = 'all';
        this.templateTypes = [];
        this.chooseArrows = [];
        this.chooseArrowsId = [];
        this.arrowsDangerous = [];
        this.arrowsDangerousId = [];
        this.gettextCatalog = gettextCatalog;
        this.langCode = window.appCtrl.langCode;
        this.classificationLinks = {
            en: window.config.CLASSIFICATION_URL_EN,
            ru: window.config.CLASSIFICATION_URL_RU,
            it: window.config.CLASSIFICATION_URL_IT,
        };
        this.classificationLink = this.classificationLinks[this.langCode];
        this.addVectorText = gettextCatalog.getString('Добавить вектор');
        this.appCtrl = window.appCtrl;
    }

    $onInit() {
        this.arrowsDangerous = this.allArrows.filter(arrow => arrow.dangerous);
        this.styles = styles;
        this.$scope.$watch((scope) => {
            return scope.$.fieldsChange;
        }, (fieldsChange) => {
            if (fieldsChange) {
                if (this.arrowsType === 'dangerous') {
                    this.chooseArrows = angular.copy(this.arrowsDangerous);
                }
                if (this.arrowsType === 'choosen') {
                    this.vectors = [...this.chooseArrows];
                }
                if (this.templateCampaign && this.templateCampaign.source) {
                    this.sources = [{ id: this.templateCampaign.source }];
                    this.templateTypes = [this.templateCampaign.source];
                }
                if (!(this.templateCampaign && this.templateCampaign.source) && this.templateTypes.length > 1) {
                    this.templateTypes = [];
                }
                this.arrowsType = 'choosen';
            }
        });
        if (this.sources && this.sources.length) {
            angular.forEach(this.sources, (source) => {
                this.templateTypes.push(source.id);
            });
        }
        if (this.vectors && this.vectors.length) {
            this.chooseArrows = this.vectors;
            angular.forEach(this.vectors, (vector) => {
                this.chooseArrowsId.push(vector.id);
            });
            this.arrowsType = 'choosen';
        }

        this.$scope.$watch((scope) => {
            return scope.$.clearArrows;
        }, (clearArrows) => {
            if (clearArrows) {
                this.arrowsType = 'all';
                this.templateTypes = [];
                this.chooseArrows = [];
                this.clearArrows = false;
                this.filterTemplates();
            }
        });
    }

    pushType = (type) => {
        this.sources = [];
        if (!this.templateCampaign) {
            if (this.templateTypes.includes(type)) {
                this.templateTypes.splice(this.templateTypes.indexOf(type), 1);
            } else {
                this.templateTypes.push(type);
            }
            this.sources.push({ id: type });
            this.filterTemplates();
        } else {
            this.setFieldChange(true);
            if (this.templateTypes && this.templateTypes.indexOf(type) > -1) {
                this.templateTypes = [];
            } else {
                this.templateTypes = [];
                this.templateTypes.push(type);
                this.sources.push({ id: type });
            }
        }
    };

    filterByArrows = (template) => {
        if (!template.vectors.length && this.arrowsType === 'all') {
            return true;
        }
        return template.vectors.map(item => {
            if (this.arrowsType === 'all') {
                return true;
            }
            if (this.arrowsType === 'choosen') {
                return this.chooseArrowsId.includes(item.id);
            }
            if (this.arrowsType === 'dangerous') {
                return this.arrowsDangerousid.includes(item.id);
            }
        }).includes(true);
    };

    filterByTypes = (template) => {
        return this.templateTypes.includes(template.source);
    };

    filterTemplates = () => {
        this.chooseArrowsId = [];
        this.chooseArrowsId.push(...this.chooseArrows.map(item => item.id));
        this.arrowsDangerousid = [];
        this.arrowsDangerousid.push(...this.arrowsDangerous.map(item => item.id));
        this.vectors = [...this.chooseArrows];
        if (!this.templateTypes || !this.templateTypes.length) {
            this.templatesShow = this.templatesAll.filter(template => {
                return this.filterByArrows(template);
            });
        }
        if (this.templateTypes && this.templateTypes.length) {
            this.templatesShow = this.templatesAll.filter(template => {
                return this.filterByArrows(template) && this.filterByTypes(template);
            });
        }
    };
}

ArrowsFilterController.$inject = [
    '$scope',
    'gettextCatalog',
];

export {
    ArrowsFilterController,
};
