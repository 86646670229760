import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';

const initialState = {
    state: 'people'
};

const dashboardStateSlice = createSlice({
    name: 'dashboardState',
    initialState,
    reducers: {
        setDashboardState (state, {payload}) {
            state.state = payload;
        }
    },
    extraReducers: {}
});

export const {setDashboardState} = dashboardStateSlice.actions;
export default dashboardStateSlice.reducer;

