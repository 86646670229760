class ReportsService {
    constructor ($resource) {
        this.$resource = $resource;
        this.campaignCounters = (id, dateTime) => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/reports/counters/campaign/' + id + '/' + dateTime).get();
        };
        this.departmentCounters = (id) => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/reports/counters/department/' + id).get();
        };
    }

    targetsHistory = (data, ctrl, success, error) => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/reports/targets-history', null, {
            save: {
                method: 'POST',
                isArray: false
            }
        }).save({}, data, function (response) {
            if (typeof (success) == 'function' && ctrl) {
                success(ctrl, response);
            }
        }, function (response) {
            if (typeof (error) == 'function' && ctrl) {
                error(ctrl, response);
            }
        });
    };
}

export {
    ReportsService
};
