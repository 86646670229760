import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';

const initialState = {
    status: 'idle',
    roles: [],
    error: false,
    loading: false,
};

export const fetchRoles = createAsyncThunk(
    'roleUsers/fetchRolesStatus',
    async (userId) => {
        const fetchApi = FetchApi('/roles?fullList=1');
        const response = await fetchApi.get(userId, {params: 'fullList=1'});
        if(response.isError) {
            throw response;
        }
        return response;
    }
);

export const deleteRole = createAsyncThunk(
    'roleUsers/deleteRole',
    async (id) => {
        const fetchApi = FetchApi('/roles');
        const response = await fetchApi.delete(id);
        return response;
    }
);

const roleUsersSlice = createSlice({
    name: 'roleUsers',
    initialState,
    reducers: {
        setRoles(state, { payload }) {
            state.roles = payload;
        },
        setNewRole(state, {payload}) {
            state.roles.push(payload);
        },
        editRole(state, {payload}) {
            state.roles = state.roles.map(item => {
                if (item.id === payload.id) {
                    return payload;
                } else {
                    return  item;
                }
            });
        }
    },
    extraReducers: {
        [fetchRoles.fulfilled]: (state, { payload }) => {
            state.roles = payload;
            state.status = 'load';
            state.loading = false;
        },
        [fetchRoles.pending]: (state) => {
            state.status = 'pending';
            state.loading = true;
        },
        [fetchRoles.rejected]: (state) => {
            state.status = 'rejected';
            state.loading = false;
        },
        [deleteRole.fulfilled]: (state, { meta }) => {
            let id = meta.arg;
            state.roles = state.roles.filter(item => item.id !== id);
            state.targetCountStatus = 'idle';
        },
        [deleteRole.pending]: (state) => {
            state.targetCountStatus = 'pending';
        },
        [deleteRole.rejected]: (state) => {
            state.targetCountStatus = 'rejected';
        },
    }
});

export const { setRoles, editRole, setNewRole } = roleUsersSlice.actions;
export default roleUsersSlice.reducer;
