import style from './style.modules.css';
import modalTemplate from './twoFactorPass.html';
import setOptions from '../../helpers/setOptions';
import { TWO_FACTOR_PASS_MODAL } from '../../const';

export class TwoFactorPassController {
    constructor(enabled, gettextCatalog, scope) {
        this.gettextCatalog = gettextCatalog;
        this.enabled = enabled;
        this.style = style;
        this.scope = scope;
        this.password = '';
        this.errorText = '';
        this.headerText = enabled ? gettextCatalog.getString('Включение двухфакторной аутентификации') :
            gettextCatalog.getString('Отключение двухфакторной аутентификации');
    }

    $onInit () {
        const that = this;
        this.btnConfig = [
            {
                title: that.gettextCatalog.getString('Отменить'),
                action: () => {
                    that.$close();
                },
                selenium: 'cancel-btn',
                get disabled() {
                    return false;
                },
                class: 'default'
            },
            {
                title: that.gettextCatalog.getString('Сохранить'),
                action: () => {
                    that.passwordEnter();
                },
                selenium: 'save-btn',
                get disabled() {
                    return false;
                },
                class: 'primary'
            }
        ];
    }

    passwordEnter = async () => {
        try {
            this.errorText = '';
            let url = this.enabled ? '/users/create-otp-secret' : '/users/disable-second-factor';
            let options = setOptions();
            options.body = JSON.stringify({ password: this.password });
            options.method = 'POST';
            options.headers['Content-Type'] = 'application/json';
            let response = await fetch(window.config.SYSTEM_URL + window.config.API_URL + url, options);
            if (!response.ok) {
                throw await response.json();
            }
            let opt = await response.json();
            this.$close(this.enabled ? opt : true);
        } catch (e) {
            this.errorText = e.error ? [e.error] : [this.gettextCatalog.getString('Что-то пошло не так :-(')];
            this.scope.$apply();
            window.console.log(this.errorText);
        }
    }

    modalClose = () => {
        this.$close();
    }
}

TwoFactorPassController.$inject = [
    'enabled',
    'gettextCatalog',
    '$scope'
];

export class TwoFactorPassModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    $onInit = () => {
        this.$uibModal;
    };

    open = (enabled = null) => {
        this.modalInstance = this.$uibModal.open({
            template: modalTemplate,
            bindToController: true,
            controller: TWO_FACTOR_PASS_MODAL,
            controllerAs: '$',
            size: 'qr',
            resolve: {
                enabled,
            },
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}
