import managerTableTemplate from './view.html';

import { ManagerTableController } from './controller';

const ManagerTableComponent = {
    template: managerTableTemplate,
    controller: ManagerTableController,
    controllerAs: '$',
    scope: true,
    bindings: {
        viewMode: '<',

        isAnyLoading: '<',
        reloading: '<',
        setReloading: '<',
        setDepartmentCount: '<',
        setTargetCount: '<',
        departmentCheckedCache: '=',
        departmentPartialCache: '=',
        setLoading: '<',
        rebuildLoading: '<',
        setSearch: '<',
    },
};

export {
    ManagerTableComponent,
};
