import React from 'react';
import ReactIcon from '../ReactIcons';

export function ReactTableCell({ viewItem, rowId, Style }) {
    const setView = () => {
        if (!viewItem.isIcon) {
            return viewItem.text;
        }
        if (viewItem.isIcon) {
            let nameIcon = viewItem.icon.split(' ');
            return nameIcon.map((item, index) => {
                return <ReactIcon
                    key={`${index} + ${rowId}`}
                    icoClass={item}
                    handleClick={viewItem.handle[index]}
                    handleParams={rowId}
                />;
            });
        }
    };
    return (
        <td className={`react-table_cell ${Style} ${viewItem.class}`}>
            {setView()}
        </td>
    );
}
