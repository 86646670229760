import certsTableTemplate from './view.html';
import style from '../campaignView/style.modules.css';
import { CertsModal } from '../certModal';
import { fetchLang } from '../../toolkit/think';

class CertsTableController {
    get LOADING () {return 'loading';}
    get ERROR () {return 'error';}
    get SUCCESS () {return 'success';}

    constructor($ngRedux, $injector, gettextCatalog) {
        this.gettextCatalog = gettextCatalog;
        this.style = style;
        this.$injector = $injector;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { fetchLang })(this);
    }

    mapStateToThis(state) {
        return {
            licenseValid: state.auth.auth.license.isValid,
            status: state.certificate.status
        };
    }

    $onInit() {
        this.fetchLang();
    }

    saveCerts = (cert) => {
        if (!this.licenseValid) return;
        let modal = this.$injector.instantiate(CertsModal);
        modal.open(cert && cert.id).catch(() => {});
    }

    deleteCerts = (cert, event) => {
        event.stopPropagation();
        if (!this.licenseValid) return;
    }

    $onDestroy = () => {};
}

CertsTableController.$inject = [
    '$ngRedux',
    '$injector',
    'gettextCatalog'
];

const CertsTableComponent = {
    template: certsTableTemplate,
    controller: CertsTableController,
    controllerAs: '$',
    scope: true,
    bindings: {
        certs: '<'
    },
};

export {
    CertsTableComponent,
};
