import { createSlice } from '@reduxjs/toolkit';
import {EMPTY_SELECTED_DATA} from '../const';

const initialState = {
    status: 'idle',
    selectedData: {...EMPTY_SELECTED_DATA},
    error: false
};

const selectedDataSlice = createSlice({
    name: 'selectedData',
    initialState,
    reducers: {
        setSelectedData (state, {payload}) {
            state.selectedData = payload;
        },
        clearSelectedData (state) {
            state.selectedData = {...EMPTY_SELECTED_DATA};
        },
        setSearchData (state, {payload}) {
            state.selectedData.search = payload || [''];
        }
    }
});

export const { setSelectedData, clearSelectedData, setSearchData } = selectedDataSlice.actions;
export default selectedDataSlice.reducer;
