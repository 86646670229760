import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';

const initialState = {
    config: {}
};

export const fetchLearningConfig = createAsyncThunk(
    'learningConfig/status',
    async () => {
        const fetchApi = new FetchApi('/learning');
        const response = await fetchApi.get();
        return response;
    }
);

const learningConfigSlice = createSlice({
    name: 'learningConfig',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchLearningConfig.fulfilled]: (state, { payload }) => {
            state.config = payload;
        },
        [fetchLearningConfig.pending]: (state) => {
            state.config = {};
        },
        [fetchLearningConfig.rejected]: (state) => {
            state.config = {};
        },
    }
});

export default learningConfigSlice.reducer;

