import breadcrumbLinksTemplate from './view.html';

import { breadcrumbLinksController } from './controller';

const breadcrumbLinksComponent = {
    template: breadcrumbLinksTemplate,
    controller: breadcrumbLinksController,
    controllerAs: '$',
    scope: true,
    bindings: {
        subFunctions: '<',
        titleName: '<',
        isAnyLoading: '=',
    },
};

export {
    breadcrumbLinksComponent,
};
