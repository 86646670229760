import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FetchApi from "../React/REST";

const initialState = {
    loading: false,
    deleteLoading: false,
    itemLoad: null,
    wifiDevice: [],
    error: false,
    errorText: "",
};

export const fetchListWifiDevice = createAsyncThunk("wifiDevice/status", async () => {
    const wifiDeviceApi = FetchApi("/wifi-devices?fullList=1");
    let wifiDevice = await wifiDeviceApi.get();
    wifiDevice = wifiDevice.length
        ? wifiDevice.map((item) => {
              item.wifi = true;
              return item;
          })
        : [];
    return [...wifiDevice];
});

export const deleteWifiDevice = createAsyncThunk("wifiDevice/delete", async ({ id }) => {
    const wifiDeviceApi = FetchApi("/wifi-devices");
    const response = await wifiDeviceApi.delete(id);
    return id;
});

const wifiDeviceSlice = createSlice({
    name: "wifiDeviceList",
    initialState,
    reducers: {
        setListWifiDevice(state, { payload }) {
            state.wifiDevice = payload;
        },
        setWifiDevice(state, { payload }) {
            state.wifiDevice.push(payload);
        },
        updateWifiDevice(state, { payload }) {
            state.wifiDevice = state.wifiDevice.map((item) => {
                if (item.id !== payload.id) return item;
                return payload;
            });
        },
    },
    extraReducers: {
        [fetchListWifiDevice.pending]: (state) => {
            state.loading = true;
        },
        [fetchListWifiDevice.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.error = false;
            (state.errorText = ""), (state.wifiDevice = payload);
        },
        [fetchListWifiDevice.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = true;
            state.errorText = payload;
        },
        [deleteWifiDevice.pending]: (state) => {
            state.deleteLoading = true;
        },
        [deleteWifiDevice.fulfilled]: (state, { payload }) => {
            state.deleteLoading = false;
            state.error = false;
            state.wifiDevice = state.wifiDevice.filter((item) => item.id !== payload);
        },
        [deleteWifiDevice.rejected]: (state, { payload }) => {
            state.deleteLoading = false;
            state.error = true;
            state.errorText = payload;
        },
    },
});

export const { setListWifiDevice, setWifiDevice, updateWifiDevice } = wifiDeviceSlice.actions;
export default wifiDeviceSlice.reducer;
