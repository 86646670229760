class BossKadrovikProfilesService {
    constructor(YiiModel) {
        this.YiiModel = YiiModel;
        this.all = YiiModel.all('boss-kadrovik-profiles');
        this.one = (id) => {
            return YiiModel.one('boss-kadrovik-profiles').$find(id);
        };
    }
}

export {
    BossKadrovikProfilesService
};
