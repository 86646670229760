import React, { useContext } from 'react';
import StatusIcon from 'rootReact/components/StatusIcon';
import { GettextContext } from 'rootReact/context';
import Style from '../style.module.less';
import Translate from 'rootReact/Translate.jsx';

function ActionCell({ action, actionTypes, courses, templates, reportTypes, userName }) {
    const gettext = useContext(GettextContext);
    const itemActions = action.map(item => item.type);
    const value = itemActions.map(item => {
        for (let i = 0; i < actionTypes.length; i++) {
            if (actionTypes[i].id === item) {
                return actionTypes[i];
            }
        }
        return { name: gettext.getString('Неизвестное действие') };
    });

    const getCourse = (index) => {
        if (!action[index].action_mode || action[index].action_mode === 'any') {
            return courses.length
                    ? <span className={'scheduler_text'}><Translate>Все курсы</Translate></span>
                    : <span className={'scheduler_text text-danger'}><Translate>Нет доступных курсов</Translate></span>;
        }
        if (action[index].action_mode === 'many') {
            return action[index].courses.map(item => {
                return <span key={item.id} className={'scheduler_text'}>{item.name}</span>;
            });
        }
    };

    const getTemplate = (index) => {
        if (!action[index].template_id) {
            return <span className={'scheduler_text'}><Translate>Случайный шаблон</Translate></span>;
        }
        let template = templates.length && templates.filter(item => item.id === action[index].template_id);
        return <span className={'scheduler_text'}>{template[0].name}</span>
    }

    const getReport = (index) => {
        let reportName = '';
        let bodyText;
        reportTypes.forEach(item => {
            if (action[index][item.id]) {
                reportName = item.name;
            }
        });

        if (!action[index].report_to) {
            return <>
                <span style={{marginLeft: '1rem'}}><Translate>{reportName}</Translate></span>
                <span style={{marginLeft: '2rem'}}>
                        <Translate>администратору:</Translate>
                </span>
                <span style={{marginLeft: '2rem'}}>{userName}</span>
            </>

        }

        if (!action[index].report_to.type && action[index].report_to.administrators.length) {
            bodyText = action[index].report_to.administrators.reduce(
                (value, item) => {
                    let currentItem = <span style={{marginLeft: '2rem'}} key={item.id}>{item.fullName}</span>
                    value.push(currentItem);
                    return value;
                },
                [<span style={{marginLeft: '2rem'}} key={'index'}>{action[index].report_to.administrators.length > 1 ?
                        <Translate>администраторам:</Translate> :
                        <Translate>администратору:</Translate>}
                </span>]
            );
        }

        if (action[index].report_to.type === 3) {
            bodyText = <span style={{marginLeft: '2rem'}} key={'index'}>
                <Translate>руководителям</Translate>
            </span>
        }

        if (action[index].report_to.type === 2) {
            bodyText = action[index].report_to.emails.reduce(
                    (value, item) => {
                        let currentItem = <span style={{marginLeft: '2rem'}} key={item}>{item}</span>
                        value.push(currentItem);
                        return value;
                    },
                    [<span style={{marginLeft: '2rem'}} key={'index'}>{action[index].report_to.type === 3 ?
                            <Translate>руководителям</Translate> :
                            action[index].report_to.type === 2 && action[index].report_to.emails.length > 1 ?
                                    <Translate>по адресам:</Translate> :
                                    <Translate>по адресу:</Translate>
                    }
                    </span>]
            );
        }

        if (action[index].report_to.type === 1) {
            bodyText = action[index].report_to.targets.reduce(
                    (value, item) => {
                        let currentItem = <span style={{marginLeft: '2rem'}} key={item.id}>{item.fullName}</span>
                        value.push(currentItem);
                        return value;
                    },
                    [<span style={{marginLeft: '2rem'}} key={'index'}>{action[index].report_to.targets.length > 1 ?
                            <Translate>сотрудникам:</Translate> :
                            <Translate>сотруднику:</Translate>}
                </span>]
            );
        }

        return <>
            <span style={{marginLeft: '1rem'}}><Translate>{reportName}</Translate></span>
            {bodyText}
        </>
    }

    const returnedValue = value.reduce((reduce, item, index) => {
        let name = item.name.replace(/\.\.\./g, '').trim();
        return (
                <>{reduce}<div className={`row-center ${Style.actionWrapper}`}
                               style={{flexDirection: 'column'}}>
                    <span style={{display: 'flex', alignItems: 'end'}}>{reduce ?
                        <span style={{ marginRight: '5px' }}>
                            <Translate>и</Translate>
                        </span> : ''}{name} {
                            name === 'Отправить на обучение' ? <span style={{marginLeft: '5px'}}><StatusIcon status={4} /></span> : ''
                        }
                    </span>
                    {~action[index].type.indexOf('course') ? getCourse(index) : ''}
                    {~action[index].type.indexOf('target_attack') ? getTemplate(index) : ''}
                    {~action[index].type.indexOf('report_admin') ? getReport(index) : ''}
                </div></>);
    }, '');

    return returnedValue;
}

export default ActionCell;
