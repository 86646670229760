class RolesService {
    constructor (YiiModel, $resource) {
        this.YiiModel = YiiModel;
        this.$resource = $resource;
        this.all = YiiModel.all('roles?fullList=1');
        this.one = (id) => {
            return YiiModel.one('roles').$find(id);
        };
    }

    getSections = () => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/roles/sections', {}, { query: { method: 'GET', isArray: false } }).query();
    };

    getRights = () => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/roles/rights', {}, { query: { method: 'GET', isArray: false } }).query();
    };

    getAll = (options) => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/roles').query(options);
    };
}

export {
    RolesService
};
