import React from 'react';

function ModalHeader({ title, closeModal, styleText, stylePadding, disabled }) {
    return (
        <div style={stylePadding} className='react-modal-header_content' data-testid='react-modal-header'>
            <h4 style={styleText} className='react-modal-header_title'>
                {title}
            </h4>
            <button data-testid='close' disabled={disabled} onClick={closeModal} className='react-modal-header_btn'>
                 ×
            </button>
        </div>
    );
}

export default ModalHeader;
