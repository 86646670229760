import style from './style.modules.css';

class DaemonMessageViewController {
    constructor (scope) {
        this.style = style;
        this.scope = scope;
        this.listView = false;
    }

    $onInit () {}

    setView = () => {
        this.listView = !this.listView;
    }
}

DaemonMessageViewController.$inject = [
    '$scope'
];

export {
    DaemonMessageViewController
};
