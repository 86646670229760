import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';
import moment from 'moment';

const initialState = {
    status: 'idle',
    auth: {},
    error: false,
    targetCountStatus: 'idle',
    secondFactorStatus: 'idle',
    otp: null,
};

export const fetchAuth = createAsyncThunk(
    'auth/fetchAuthStatus',
    async (userId) => {
        const fetchApi = FetchApi('/auth');
        const response = await fetchApi.get(userId);
        return response;
    }
);

export const setTargetCount = createAsyncThunk(
    'auth/setTargetCount',
    async (count) => {
        const fetchApi = FetchApi('/settings/company-target-count');
        const response = await fetchApi.post({body: {companyTargetCount: count}});
        return response;
    }
);

export const toggleSecondFactor = createAsyncThunk(
    'auth/toggleSecondFactor',
    async ({enabled, body}) => {
        let url = enabled ? '/users/create-otp-secret' : '/users/disable-second-factor';
        const fetchApi = FetchApi(url);
        const response = await fetchApi.post({body});
        return response;
    }
)

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthInfo(state, { payload }) {
            state.auth = payload;
        },
        setAuthProps(state, {payload}) {
            state.auth = {...state.auth, ...payload};
        }
    },
    extraReducers: {
        [fetchAuth.fulfilled]: (state, { payload }) => {
            if (payload && payload.license && payload.license.validTill) {
                payload.license.validTill = moment(payload.license.validTill, 'YYYY-MM-DD HH:mm:ss').toDate();
            }
            state.auth = payload;
            state.status = 'load';
        },
        [fetchAuth.pending]: (state) => {
            state.status = 'pending';
        },
        [fetchAuth.rejected]: (state) => {
            state.status = 'rejected';
        },
        [setTargetCount.fulfilled]: (state, { payload }) => {
            state.auth = {...state.auth, ...payload};
            state.targetCountStatus = 'idle';
        },
        [setTargetCount.pending]: (state) => {
            state.targetCountStatus = 'pending';
        },
        [setTargetCount.rejected]: (state) => {
            state.targetCountStatus = 'rejected';
        },

        [toggleSecondFactor.fulfilled]: (state, { payload, meta }) => {
            const enabled = meta.arg.enabled;
            if (enabled) {
                state.otp = payload
            } else {
                state.auth = {...state.auth, ...payload};
            }
            state.secondFactorStatus = 'idle';
        },
        [toggleSecondFactor.pending]: (state) => {
            state.secondFactorStatus = 'pending';
        },
        [toggleSecondFactor.rejected]: (state) => {
            state.secondFactorStatus = 'rejected';
        },
    }
});

export const { setAuthInfo, setAuthProps } = authSlice.actions;
export default authSlice.reducer;
