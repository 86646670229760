import { useState } from 'react';
import { validRules } from '../helpers';

const setDefaultValue = (array) => {
    return array.reduce((prevItem, currentItem) => {
        prevItem[currentItem] = false;
        return prevItem;
    }, {});
};

export function useValidForm (values, rules, blurEventCallback) {
    const [names] = useState(Object.keys(rules));
    const [error, setError] = useState(setDefaultValue(names));
    const [touched, setTouched] = useState(setDefaultValue(names));
    const [focused, setFocused] = useState(setDefaultValue(names));
    const [fieldChange, setFieldChange] = useState(false);

    return names.reduce((prevItem, item) => {
        prevItem[item] = {
            inputAttr: {
                value : values[item].value,
                onChange: e => {
                    let value = '';
                    if (e.target) {
                        value = e.target.value;
                    } else {
                        value = e;
                    }
                    error[item] && setError({ ...error, [item]: false });
                    if (!fieldChange) setFieldChange(true);
                    values[item].setValue(value);
                },
                onFocus: () => {
                    setFocused({ ...focused, [item]: true });
                    setTouched({ ...touched, [item]: true });
                },
                onBlur: () => {
                    let err = validRules(rules, [{ name: item, value: values[item].value }]);
                    let val = { ...error };
                    let key = Object.keys(err);
                    key.forEach(item => {
                        val[item] = err[item];
                    });
                    if (blurEventCallback) {
                        blurEventCallback(item, values[item].value.trim());
                    }
                    setError(val);
                    setFocused({ ...focused, [item]: false });
                },
            },
            setValue: values[item].setValue,
            error: error[item],
            touched: touched[item],
            focused: focused[item],
        };
        return prevItem;
    }, { setError, fieldChange, error });
}
