import settingsOutlookTemplate from './view.html';

import { SettingsOutlookController } from './controller';

const SettingsOutlookComponent = {
    template: settingsOutlookTemplate,
    controller: SettingsOutlookController,
    controllerAs: '$',
    bindings: {
        domains: '<',
        manifestLink: '<',
        instructionLink: '<',
        instructionCustomFileName: '<'
    }
};

export {
    SettingsOutlookComponent
};
