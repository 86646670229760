import modalTemplate from './view.html';
import { CampaignModalController } from './controller';

import { CAMPAIGN_MODAL_CONTROLLER } from '../../const';

class CampaignModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    $onInit = () => {
        this.$uibModal;
    };

    open = (campaignId = null, copyId = null) => {
        this.modalInstance = this.$uibModal.open({
            template: modalTemplate,
            bindToController: true,
            controller: CAMPAIGN_MODAL_CONTROLLER,
            controllerAs: '$',
            size: 'lg',
            resolve: {
                campaignId: campaignId,
                copyId: copyId,
            },
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}

export {
    CampaignModal,
    CampaignModalController,
};
