import dashboardTemplate from './view.html';

import { DashboardOldController } from './controller';

const DashboardOldComponent = {
    template: dashboardTemplate,
    controller: DashboardOldController,
    controllerAs: '$',
    bindings: {
        dasboardParams: '<',
        accessDashboardView: '<',
        accessDashboardView2: '<',
        accessDashboardView3: '<',
        accessDashboardView4: '<',
    },
};

export {
    DashboardOldComponent,
};
