const regEmail = /(^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)|(^[a-zA-Z0-9+\-._]+\\[a-zA-Z0-9+\-._]+)$/;
const domain = /^[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!$&'()*+,;=.]+$/i;
export const validForm = (rules, fields, errors, valid) => {
    for (let field in rules) {
        if (!fields.hasOwnProperty(field)) continue;
        if (rules[field].required && !fields[field]) {
            errors[field] = 'required';
            if (valid.indexOf(field) > -1) {
                valid.splice(valid.indexOf(field), 1);
            }
            continue;
        }
        if (rules[field].email && !regEmail.test(fields[field])) {
            errors[field] = 'not email';
            if (valid.indexOf(field) > -1) {
                valid.splice(valid.indexOf(field), 1);
            }
            continue;
        }
        if (rules[field].url && !domain.test(fields[field])) {
            errors[field] = 'not valid domain';
            if (valid.indexOf(field) > -1) {
                valid.splice(valid.indexOf(field), 1);
            }
            continue;
        }
        if (rules[field].regexp && !rules[field].regexp.text(fields[field])) {
            errors[field] = 'error regexp';
            if (valid.indexOf(field) > -1) {
                valid.splice(valid.indexOf(field), 1);
            }
            continue;
        }
        if (errors.hasOwnProperty(field)) {
            delete errors[field];
        }
        valid.indexOf(field) === -1 && valid.push(field);
    }
    return { errors, valid };
};
