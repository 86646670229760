class SettingsService {
    constructor ($resource) {
        this.resourceSettings = $resource(window.config.SYSTEM_URL + window.config.API_URL + '/settings');
        this.resourceProfile = $resource(window.config.SYSTEM_URL + window.config.API_URL + '/settings/profile');
        this.resourcePassword = $resource(window.config.SYSTEM_URL + window.config.API_URL + '/settings/password');
        this.resourceGenerate = $resource(window.config.SYSTEM_URL + window.config.API_URL + '/settings/password-generate');
        this.resourceDaysAsNew = $resource(window.config.SYSTEM_URL + window.config.API_URL + '/settings/days-as-new');
    }

    getSettingsInfo = () => {
        return this.resourceSettings.get();
    };

    saveProfileInfo = (profile, ctrl, success, error) => {
        return this.resourceProfile.save({}, profile, function (response) {
            if (typeof (success) == 'function' && ctrl) {
                success(ctrl, response);
            }
        }, function (response) {
            if (typeof (error) == 'function' && ctrl) {
                error(ctrl, response);
            }
        });
    };

    changeProfilePassword = (data, ctrl, success, error) => {
        return this.resourcePassword.save({}, data, function (response) {
            if (typeof (success) == 'function' && ctrl) {
                success(ctrl, response);
            }
        }, function (response) {
            if (typeof (error) == 'function' && ctrl) {
                error(ctrl, response);
            }
        });
    };

    changeDaysAsNew = (data, ctrl, success, error) => {
        return this.resourceDaysAsNew.save({}, data, function (response) {
            if (typeof (success) == 'function' && ctrl) {
                success(ctrl, response);
            }
        }, function (response) {
            if (typeof (error) == 'function' && ctrl) {
                error(ctrl, response);
            }
        });
    };

    generatePassword = (data, ctrl, success, error) => {
        if (data && data.id) {
            delete data.id;
        }
        return this.resourceGenerate.save({}, data, function (response) {
            if (typeof (success) == 'function' && ctrl) {
                success(ctrl, response);
            }
        }, function (response) {
            if (typeof (error) == 'function' && ctrl) {
                error(ctrl, response);
            }
        });
    };
}

export {
    SettingsService
};
