class NotificationsService {
    constructor(YiiModel) {
        this.YiiModel = YiiModel;
        this.all = YiiModel.all('notifications');
        this.one = (id) => {
            return YiiModel.one('notifications').$find(id);
        };
    }
}

export {
    NotificationsService
};
