
class CampaignTargetsService {
    constructor ($resource) {
        this.$resource = $resource;
    }

    getCurrent = (attackId, ctrl, success, error) => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/attacks/' + attackId + '/targets').save({}, { current: 1 }, function (response) {
            if (typeof (success) == 'function' && ctrl) {
                success(ctrl, response);
            }
        }, function (response) {
            if (typeof (error) == 'function' && ctrl) {
                error(ctrl, response);
            }
        });
    };

    getAll = (attackId, ctrl, success, error) => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/attacks/' + attackId + '/targets').save({}, { all: 1 }, function (response) {
            if (typeof (success) == 'function' && ctrl) {
                success(ctrl, response);
            }
        }, function (response) {
            if (typeof (error) == 'function' && ctrl) {
                error(ctrl, response);
            }
        });
    };

    getNoAttacked = (attackId, ctrl, success, error) => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/attacks/' + attackId + '/targets').save({}, { noAttacked: 1 }, function (response) {
            if (typeof (success) == 'function' && ctrl) {
                success(ctrl, response);
            }
        }, function (response) {
            if (typeof (error) == 'function' && ctrl) {
                error(ctrl, response);
            }
        });
    };
}

export {
    CampaignTargetsService
};
