import classicMenuTemplate from './view.html';

import { ClassicMenuController } from './controller';

const ClassicMenuComponent = {
    template: classicMenuTemplate,
    controller: ClassicMenuController,
    controllerAs: '$',
    scope: true,
    bindings: {
        open: '=',
        onMenuOpen: '=',
        animateMenu: '<',
    },
};

export {
    ClassicMenuComponent,
};
