import React, { useContext, useState } from 'react';
import { GettextContext } from 'rootReact/context';
import { useSelector } from 'react-redux';
import FetchApi from '../../REST';

function SettingsAnonym({ licenseIsValid }) {
    const gettext = useContext(GettextContext);
    const anonymization = useSelector(state => state.auth.auth.license.features.anonymization);
    const [enabledAnonymization, setEnabledAnonymization] = useState(anonymization);

    const toggleAnonymization = async () => {
        await FetchApi('/settings/toggle-anonymization').get();
        location.reload();
    };

    return (
        <div className={'settings_item'}>
            <div className={'react-input_label nowrap'}>{gettext.getString('Анонимный режим')}</div>
            <div className={'settings_path-right'}>
                <div className={'react-button'}>
                    <button className={`${enabledAnonymization ? 'react-button_element__active' : ''} react-button_element react-button_element__sm react-button_element__left`}
                        disabled={!licenseIsValid}
                        onClick={toggleAnonymization}
                    >{gettext.getString('включен')}</button>
                    <button className={`${!enabledAnonymization ? 'react-button_element__active' : ''} react-button_element react-button_element__sm react-button_element__right`}
                        disabled={!licenseIsValid}
                        onClick={toggleAnonymization}
                    >{gettext.getString('выключен')}</button>
                </div>
            </div>
        </div>
    );
}

export default SettingsAnonym;
