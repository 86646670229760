import { GROUPS_SERVICE } from '../../const';
import angular from 'angular';
import style from './style.modules.css';
import { applyFilterDepartment } from '../../toolkit/actions';

class GroupModalController {
    constructor(GroupsService, groupId, parentGroupId, $ngRedux, gettextCatalog) {
        this.GroupsService = GroupsService;
        this.groupId = groupId;
        this.parentGroupId = parentGroupId;
        this.inProcess = false;
        this.loading = false;
        this.gettextCatalog = gettextCatalog;
        this.groups = [];
        this.style = style;
        this.unsubscribe = $ngRedux.connect(null, { applyFilterDepartment })(this);
    }

    setForm = (form) => {
        this.form = form;
    };

    $onInit = () => {
        if (this.groupId) {
            this.loading = true;
            this.GroupsService.one(this.groupId).then((group) => {
                this.group = group;
                this.loading = false;
            });
        } else {
            this.group = this.GroupsService.new();
            this.group.priority = 1;
        }
        this.errorText = null;
        this.loadGroups();
    };

    loadGroups = () => {
        this.loading = true;
        this.loadingError = null;
        this.GroupsService.simple.query({
            layout: 'select',
            ignoreGroup: this.groupId
        }).$promise.then((groups) => {
            this.groups = groups.filter(item => item.id !== -1);
            this.loading = false;
        }, () => {
            this.loading = false;
            this.loadingError = this.gettextCatalog.getString('Ошибка при загрузке групп');
        });
    };

    save = () => {
        this.errorText = null;
        this.form.$setSubmitted();
        if (this.form.$valid) {
            this.inProcess = true;
            this.group.$save().then((group) => {
                this.inProcess = false;
                if (group) {
                    this.applyFilterDepartment({
                        search: '',
                        callback: null,
                        reload: 'groups',
                    });
                    this.$close(group);
                }
            }).catch((e) => {
                window.console.log(e);
                this.inProcess = false;
                this.errorText = this.gettextCatalog.getString('Ошибка при сохранении группы');
            });
        }
    };

    isColor = () => {
        if (!this.group.color) {
            return true;
        }
        var s = new Option().style;
        s.color = this.group.color;
        return s.color.length > 0;
    }

    isValidGroup = () => {
        if (!this.loading && this.group && this.group.group) {
            let groupName = typeof (this.group.group) == 'string' ? this.group.group : '';
            if (this.group.group && this.group.group.fullName) {
                groupName = this.group.group.fullName;
            }
            if (groupName) {
                let group = this.groups.find((group) => {
                    return group.fullName == groupName;
                });
                if (group && group.id) {
                    if (!this.group.group || !this.group.group.id) {
                        this.group.group = group;
                    }
                    return true;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    checkGroupMatches = () => {
        this.groupHasMatches = false;
        if (this.groups && this.groups.length) {
            let groupName = this.group.group && this.group.group.fullName ? this.group.group.fullName : (typeof (this.group.group) == 'string' ? this.group.group : '');
            angular.forEach(this.groups, (group) => {
                if (!groupName || !group.fullName || groupName.indexOf(group.fullName) > -1 || group.fullName.indexOf(groupName) > -1) {
                    this.groupHasMatches = true;
                    return true;
                }
            });
        }
    };

    $onDestroy = () => {
        this.unsubscribe();
    };
}

GroupModalController.$inject = [
    GROUPS_SERVICE,
    'groupId',
    'parentGroupId',
    '$ngRedux',
    'gettextCatalog',
];

export {
    GroupModalController,
};
