import reportCampaignsTemplate from './view.html';

const ReportCampaignsComponent = {
    template: reportCampaignsTemplate,
    controller: 'CampaignsController',
    controllerAs: 'campaignsCtrl',
    bindings: {
        actual: '<',
        isReport: '<',
    },
};

export {
    ReportCampaignsComponent,
};
