import { createSelector } from 'reselect';

const learningConfig = (state) => {
    return state.learningConfig;
};

export const getLerningConfig = createSelector([learningConfig], learningConfig => {
    let sendConfig = {};
    sendConfig.current = {};
    let config = learningConfig.config;
    sendConfig.timeValue = config.timeValue ? config.timeValue : 1;
    sendConfig.timePeriod = config.timePeriod ? config.timePeriod : 'day';
    sendConfig.current.timeValue = config.timeValue;
    sendConfig.current.timePeriod = config.timePeriod;
    return sendConfig;
});
