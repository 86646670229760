
class AttachmentsService {
    constructor ($resource) {
        this.resource = $resource(window.config.SYSTEM_URL + window.config.API_URL + '/attachments');
    }

    getAll = () => {
        return this.resource.query();
    };
}

export {
    AttachmentsService
};
