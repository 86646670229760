import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FetchApi from "../React/REST";

const initialState = {
    loading: false,
    itemLoad: null,
    finalPages: [],
    error: false,
    errorText: "",
};

export const fetchFinalPages = createAsyncThunk("finalPages/status", async () => {
    const finalPagesApi = FetchApi("/final-pages");
    let finalPages = await finalPagesApi.get();
    return [...finalPages];
});


const finalPagesSlice = createSlice({
    name: "finalPages",
    initialState,
    reducers: {
        setFinalPages(state, { payload }) {
            state.finalPages = payload;
        },
    },
    extraReducers: {
        [fetchFinalPages.pending]: (state) => {
            state = initialState;
        },
        [fetchFinalPages.fulfilled]: (state, { payload }) => {
            state.loading = true;
            state.error = false;
            (state.errorText = ""), (state.finalPages = payload);
        },
        [fetchFinalPages.rejected]: (state, { payload }) => {
            state.loading = true;
            state.error = true;
            state.errorText = payload;
        },
    },
});

export const { setFinalPages } = finalPagesSlice.actions;
export default finalPagesSlice.reducer;
