class RisksService {
    constructor (YiiModel, $resource) {
        this.$resource = $resource;
    }

    getSoftwareInfo = () => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/risks/software').get();
    };

    bulkFix = (data, ctrl, success, error) => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/risks/bulkFix').save({}, data, function (response) {
            if (typeof (success) == 'function' && ctrl) {
                success(ctrl, response);
            }
        }, function (response) {
            if (typeof (error) == 'function' && ctrl) {
                error(ctrl, response);
            }
        });
    };
}

export {
    RisksService
};
