
class DirectoriesService {
    constructor ($resource) {
        this.getAll = (options) => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/directories', options).get();
        };
    }
}

export {
    DirectoriesService
};
