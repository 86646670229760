import style from './style.modules.css';

class UsbAttackTokenModalController {
    constructor(attack) {
        this.attack = attack;
        this.style = style;
    }
}

UsbAttackTokenModalController.$inject = [
    'attack',
];

export {
    UsbAttackTokenModalController,
};
