import usbTemplate from './view.html';

import { UsbAttackTargetsController } from './controller';

const UsbAttackTargetsComponent = {
    template: usbTemplate,
    controller: UsbAttackTargetsController,
    controllerAs: '$',
    bindings: {
        attack: '<',
    },
};

export {
    UsbAttackTargetsComponent,
};
