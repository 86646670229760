import style from './style.modules.css';
import { LDAP_PROFILES_SERVICE } from '../../const';

const defaultMap = { 'email': 'mail', 'phone': 'telephonenumber', 'firstName': 'sn', 'secondName': 'givenname', 'thirdName': '', 'fullName': 'displayname', 'position': 'title', 'department': 'department' };

class LdapFieldsMapController {
    constructor (LdapProfilesService, gettextCatalog) {
        this.LdapProfilesService = LdapProfilesService;
        this.gettextCatalog = gettextCatalog;
        this.style = style;
        this.emailFields = ['mail', 'rfc822mailbox'];
        this.phoneFields = ['telephonenumber', 'facsimiletelephonenumber', 'homephone', 'ipphone', 'mobiletelephonenumber', 'mobile', 'mobilephone', 'othertelephone', 'pagertelephonenumber', 'fax'];
        this.externalFields = ['employeeID'];
        this.managerFields = ['manager'];
        this.fullNameMode = 0;
        this.emailField = 'mail';
        this.phoneField = 'telephonenumber';
        this.firstNameField = 'sn';
        this.secondNameField = 'givenname';
        this.thirdNameField = '';
        this.fullNameMode = false;
        this.fullNameField = 'displayname';
        this.positionField = 'title';
        this.departmentField = 'department';
        this.fields = LdapProfilesService.fields;
    }

    $onInit = () => {
        if (typeof this.map !== 'object') {
            this.map = {};
        }
        this.fullNameMode = this.map.hasOwnProperty('fullName');
        for (let f in defaultMap) {
            if (!this.map.hasOwnProperty(f)) {
                this.map[f] = defaultMap[f];
            }
        }
        this.itemsMap = this.setItemsMap(this.map);
    };

    setItemsMap = (presetMap) => {
        const that = this;
        return {
            email: {
                label: that.gettextCatalog.getString('Email'),
                value: presetMap.email || null,
                selfFields: ['mail', 'rfc822mailbox']
            },
            phone: {
                label: that.gettextCatalog.getString('Номер телефона'),
                value: presetMap.phone || null,
                selfFields: ['telephonenumber', 'facsimiletelephonenumber', 'homephone', 'ipphone', 'mobiletelephonenumber', 'mobile', 'mobilephone', 'othertelephone', 'pagertelephonenumber', 'fax']
            },
            firstName: {
                label: that.gettextCatalog.getString('Фамилия'),
                value: presetMap.firstName || null,
                isLink: true,
                get hidden () {
                    return that.fullNameMode;
                },
                onHandleClick: () => {
                    that.setNameMode();
                }
            },
            secondName: {
                label: that.gettextCatalog.getString('Имя'),
                value: presetMap.secondName || null,
                isLink: true,
                get hidden () {
                    return that.fullNameMode;
                },
                onHandleClick: () => {
                    that.setNameMode();
                }
            },
            thirdName: {
                label: that.gettextCatalog.getString('Отчество'),
                value: presetMap.thirdName || null,
                isLink: true,
                get hidden () {
                    return that.fullNameMode;
                },
                onHandleClick: () => {
                    that.setNameMode();
                }
            },
            fullName: {
                label: that.gettextCatalog.getString('ФИО'),
                value: presetMap.fullName || null,
                isLink: true,
                get hidden () {
                    return !that.fullNameMode;
                },
                onHandleClick: () => {
                    that.setNameMode();
                }
            },
            position: {
                label: that.gettextCatalog.getString('Должность'),
                value: presetMap.position || null,
            },
            department: {
                label: that.gettextCatalog.getString('Отдел'),
                value: presetMap.department || null,
            },
        };
    }

    setNameMode = () => {
        this.fullNameMode = !this.fullNameMode;
        this.change();
    }

    change = () => {
        let map = {};
        for (let key in this.itemsMap) {
            if (['thirdName', 'secondName', 'firstName'].indexOf(key) !== -1 && this.fullNameMode) {
                continue;
            }
            if (key === 'fullName' && !this.fullNameMode) {
                continue;
            }
            map[key] = this.itemsMap[key].value;
        }
        this.changeMap(map);
    };
}

LdapFieldsMapController.$inject = [
    LDAP_PROFILES_SERVICE,
    'gettextCatalog'
];

export {
    LdapFieldsMapController
};
