import { hasAccess } from '../../reselect';
import style from './style.modules.css';
import { EmployeesModal } from '../employeesModal';
import { setDashboardState } from '../../toolkit/actions';
import { fetchAttackHistory, fetchEducationHistory, fetchMotivationHistory, fetchTargetHistory } from '../../toolkit/think';

class DashboardController {
    constructor($scope, $location, $localStorage, $ngRedux, gettextCatalog, $injector) {
        this.$scope = $scope;
        this.style = style;
        this.location = $location;
        this.$localStorage = $localStorage;
        this.gettextCatalog = gettextCatalog;
        this.$injector = $injector;
        this.activeState = 'people';
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { setDashboardState, fetchTargetHistory, fetchMotivationHistory, fetchAttackHistory, fetchEducationHistory })(this);
        this.strings = {
            trainings: gettextCatalog.getString('Тренировки')
        };
    }

    mapStateToThis(state) {
        return {
            accessDashboard: hasAccess(state, { sectionId: 0, rightName: 'view' }),
            activeState: state.dashboardState.state,
            licenceTarget: state.auth.auth.license.employeeCount,
            companyTargetCount: state.auth.auth.companyTargetCount,
            motivation: (state.auth.auth.motivation !== null && 'value' in state.auth.auth.motivation) ? state.auth.auth.motivation.value : null,
            attackError: state.attackHistory.isError,
            attackErrorMessage: state.attackHistory.error,
            targetSaveAccess: hasAccess(state, { sectionId: 1, rightName: 'target_save' }),
            attackViewAccess: hasAccess(state, { sectionId: 2, rightName: 'view' }),
            motivationHistory: state.motivationHistory.motivationHistory,
            motivationLoad: state.motivationHistory.load,
            companyName: state.auth.auth.companyName,
        };
    }

    $onInit() {
        const that = this;
        this.items = [
            {
                title: that.gettextCatalog.getString('Люди'),
                get params () {return that.setValuePersent(that.companyTargetCount, true).value;},
                get number () {return that.setValuePersent(that.companyTargetCount, true).numVal;},
                state: 'people',
                link: that.onTitleClick,
            },
            {
                title: that.gettextCatalog.getString('Обучение'),
                get params () {return that.setValuePersent(that.noCourse).value;},
                get number () {return that.setValuePersent(that.noCourse).numVal;},
                state: 'education',
                link: that.onTitleClick,
            },
            {
                title: that.gettextCatalog.getString('Тренировки'),
                get params () {return that.setValuePersent(that.noCampaign).value;},
                get number () {return that.setValuePersent(that.noCampaign).numVal;},
                state: 'attack',
                link: that.onTitleClickAttack,
            },
        ];
    }

    setValuePersent = (count, isPeople = false) => {
        let persent = `<span class="${this.style.percent}">%</span>`;
        let value;
        if (isPeople) {
            value = this.targetsInSystem * 100 / count || 0;
        } else {
            value = 100 - (count * 100 / this.targetsInSystem) || 0;
        }
        let numVal = value;
        if (value > 0 && value < 1) {
            value = `<1${persent}`;
        } else if (value < 100 && value > 99) {
            value = `>99${persent}`;
        } else if (value === 0 || value === 100) {
            value += persent;
        } else {
            value = Math.round(value) + persent;
        }
        return { value, numVal: Math.round(numVal) };
    }

    addEmployee = () => {
        if (this.targetSaveAccess) {
            this.modal = this.$injector.instantiate(EmployeesModal, 'ui.bootstrap');
            this.modal.open(0, 0).then(
                () => {
                    this.reloadGraph();
                },
                () => {});
        }
    };

    onTitleClick = (state) => {
        this.setDashboardState(state);
    }

    onTitleClickAttack = (state) => {
        if (this.attackViewAccess) {
            this.setDashboardState(state);
        }
    }

    reloadGraph = () => {
        switch (this.activeState) {
            case 'people':
                this.fetchTargetHistory();
                break;
            case 'education':
                this.fetchEducationHistory();
                break;
            case 'attack':
                this.fetchAttackHistory();
                break;
            case 'motivation':
                this.fetchMotivationHistory();
        }
    }

    setMotivationStyle = () => {
        let classMotivation = this.motivation === 'A' ? this.style['square-succsess'] :
            (this.motivation === 'B' ? this.style['square-medium'] :
                (this.motivation === 'C' ? this.style['square-warning'] : (
                    this.motivation === 'D' ? this.style['square-danger'] : this.style['square-gray'])
                )
            );
        return classMotivation;
    }

    setMotivation = (mot) => {
        this.motivation = mot;
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

DashboardController.$inject = [
    '$scope',
    '$location',
    '$localStorage',
    '$ngRedux',
    'gettextCatalog',
    '$injector'
];

export {
    DashboardController,
};
