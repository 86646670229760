import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    link: ''
};

const linkingSlice = createSlice({
    name: 'linking',
    initialState,
    reducers: {
        setLinkings(state, {payload}) {
            state.link = payload;
        }
    },
    extraReducers: {}
});

export const {setLinkings} = linkingSlice.actions;
export default linkingSlice.reducer;

