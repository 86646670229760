class DateSelectorController {
    constructor() {
        this.appCtrl = window.appCtrl;
    }

    select = (date, dateTime) => {
        if (this.appCtrl.hasAccess(2, 'report_attack')) {
            this.selected = {
                date: date,
                dateTime: dateTime,
            };
            this.onSelect(this.selected, 1);
        }
    };
}

DateSelectorController.$inject = [];

export {
    DateSelectorController,
};
