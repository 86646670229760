import pageFooterTemplate from './view.html';

import { PageFooterController } from './controller.js';

const PageFooterComponent = {
    template: pageFooterTemplate,
    controller: PageFooterController,
    controllerAs: '$',
    scope: true,
    bindings: {
        projectFooterName: '<',
        languages: '<',
        langCode: '<',
        projectUrl: '<',
        inProcessLang: '<',
        config: '<',
        supportPhone: '<',
        supportEmail: '<',
        supportEmailSubject: '<',
        supportPhonePure: '<',
        companyName: '<',
        setLanguage: '='
    }
};

export {
    PageFooterComponent
};
