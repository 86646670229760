import { CampaignModal } from '../campaignModal';
import { CAMPAIGNS_SERVICE } from '../../const';
import angular from 'angular';
import style from './style.modules.css';

class CampaignStartModalController {
    constructor($injector, $localStorage, CampaignsService, campaign) {
        this.$injector = $injector;
        this.$localStorage = $localStorage;
        this.CampaignsService = CampaignsService;
        this.campaign = campaign;
        this.isOpen = false;
        this.style = style;
    }

    edit = () => {
        this.modal = this.$injector.instantiate(CampaignModal);
        this.$close();
        this.modal.open(this.campaign.id).then((campaignData) => {
            if (campaignData) {
                angular.extend(this.campaign, campaignData);
                if (this.$localStorage.campaignStart) {
                    this.$localStorage.campaignStart = this.campaign;
                } else {
                    this.$localStorage.campaignStart = this.campaign;
                }
            }
        }, () => {
        });
    };

    setForm = (form) => {
        this.form = form;
    };

    start = () => {
        if (this.campaign.targetsCount > 0) {
            this.errorText = null;
            this.inProcess = true;
            this.CampaignsService.sender(this.campaign.id, 'start', this, (ctrl, response) => {
                ctrl.inProcess = false;
                if (response && response.state) {
                    ctrl.$close(response);
                }
            }, (ctrl, response) => {
                ctrl.inProcess = false;
                if (response && response.data) {
                    ctrl.errorText = response.data.error;
                }
            });
        }
    };
}

CampaignStartModalController.$inject = [
    '$injector',
    '$localStorage',
    CAMPAIGNS_SERVICE,
    'campaign',
];

export {
    CampaignStartModalController,
};
