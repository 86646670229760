import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FetchApi from "../React/REST";

const initialState = {
    loading: false,
    itemLoad: null,
    phishingPages: [],
    error: false,
    errorText: "",
};

export const fetchPhishingPages = createAsyncThunk("phishingPages/status", async () => {
    const phishingPagesApi = FetchApi("/phishing-pages");
    let phishingPages = await phishingPagesApi.get();
    return [...phishingPages];
});


const phishingPagesSlice = createSlice({
    name: "phishingPages",
    initialState,
    reducers: {
        setPhishingPages(state, { payload }) {
            state.phishingPages = payload;
        },
    },
    extraReducers: {
        [fetchPhishingPages.pending]: (state) => {
            state = initialState;
        },
        [fetchPhishingPages.fulfilled]: (state, { payload }) => {
            state.loading = true;
            state.error = false;
            (state.errorText = ""), (state.phishingPages = payload);
        },
        [fetchPhishingPages.rejected]: (state, { payload }) => {
            state.loading = true;
            state.error = true;
            state.errorText = payload;
        },
    },
});

export const { setPhishingPages } = phishingPagesSlice.actions;
export default phishingPagesSlice.reducer;
