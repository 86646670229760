import { GroupModal } from '../groupModal';
import getNextPage from '../../helpers/getNextPage';
import buildSearchString from '../../helpers/buildSearchString';
import angular from 'angular';
import rebuildSelectedData from '../../helpers/rebuildSelectedData';
import { EMPTY_SELECTED_DATA, SEPARATOR } from '../../const';
import buildSelectedDataCacheString from '../../helpers/buildSelectedDataCacheString';
import { ReportModal } from '../reportModal';
import { BulkDeleteModal } from '../bulkDeleteModal';
import AnyFor from 'any-foreach';
import { hasAccess } from '../../reselect';
import style from './style.modules.css';
import { setLinkings, setSearchData, setSelectedData, setBreadcrumbLink } from '../../toolkit/actions';

class GroupsTableController {
    constructor ($localStorage, $scope, gettextCatalog, $sessionStorage, $ngRedux, $injector, $filter, $location) {
        this.style = style;
        this.$localStorage = $localStorage;
        this.gettextCatalog = gettextCatalog;
        this.$sessionStorage = $sessionStorage;
        this.$injector = $injector;
        this.filter = $filter;
        this.loading = true;
        this.error = false;
        this.scope = $scope;
        this.nextPage = 0;
        this.sortBy = '';
        this.sortReverse = true;
        this.groups = [];
        this.groupCount = 0;
        this.groupsOpened = [];
        this.group = null;
        this.parentId = null;
        this.licenseIsValid = $localStorage.userInfo.license.isValid;
        this.sortableFields = [
            'name',
            'rating',
            'priority',
            'lastChange',
            'targetsCount',
            'vulnCount'
        ];
        this.targets = {};
        this.targetCount = [];
        this.diff = require('simple-array-diff');
        this.appCtrl = window.appCtrl;
        this.maxTargetCount = 0;
        this.location = $location.path();
        this.toggleInProcess = false;
        this.strings = {
            eduComplete: gettextCatalog.getString('прошел обучение'),
            eduProcess: gettextCatalog.getString('на обучении'),
            eduMiss: gettextCatalog.getString('не прошел обучение вовремя'),
            eduNew: gettextCatalog.getString('не обучался'),
            newEmployee: gettextCatalog.getString('новый сотрудник'),
            attackFail: gettextCatalog.getString('не выдержал атаку'),
        };
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { setLinkings, setSearchData, setSelectedData, setBreadcrumbLink })(this);
    }

    mapStateToThis (state) {
        return {
            selectedData: state.selectedData.selectedData,
            linkNames: state.breadcrumbLinks.link,
            hashPath: state.linking.link,
            accessGroupsDefault: hasAccess(state, { sectionId: 1, rightName: 'view' }),
            accessGroupsAttacks: hasAccess(state, { sectionId: 2, rightName: 'view' }),
            accessGroupsReports: hasAccess(state, { sectionId: 4, rightName: 'report_group' }),
            accessAttacksReports: hasAccess(state, { sectionId: 4, rightName: 'report_attack' })
        };
    }

    $onInit () {
        this.accessGroupsDefault = !this.isVuln && !this.isReport && this.accessGroupsDefault;
        this.accessGroupsRisks = this.isVuln && this.accessGroupsRisks;
        this.accessGroupsReports = ((!this.getReportParams && this.isReport) || (this.getReportParams && this.getReportParams().type == 'group')) && this.accessGroupsReports;
        this.accessAttacksReports = ((!this.getReportParams && this.isReport) || (this.getReportParams && this.getReportParams().type == 'attack')) && (this.accessAttacksReports || this.accessGroupsAttacks);
        if (!this.accessGroupsDefault && !this.accessGroupsRisks && !this.accessGroupsReports && !this.accessAttacksReports && !this.accessGroupsAttacks) {
            return;
        }
        this.groupsOpened = [];
        let parent = this.linkNames[this.linkNames.length - 1] && this.linkNames[this.linkNames.length - 1] ? this.linkNames[this.linkNames.length - 1] : null;
        this.parentId = parent && !parent.isTitle ? parent.id : null;
        this.watchHashpath();
        this.loadGroups(false, 1);
        if (!this.groupId) {
            this.scope.$watch(scope => {
                return scope.$.linkNames;
            }, (links, oldLinks) => {
                if (links.length === oldLinks.length) {
                    return;
                }
                if (!links.length && !this.courseId) {
                    this.parentId = null;
                    this.groups = [];
                    this.loadGroups(false, 1);
                    return;
                }
                if (links.length === 1 && links[0] &&
                    {}.hasOwnProperty.call(links[0], 'isTitle')) {
                    this.parentId = null;
                    this.groups = [];
                    this.loadGroups(false, 1);
                    return;
                }
                this.parentId = links[links.length - 1] ? links[links.length - 1].id : null;
                this.groups = [];
                this.loadGroups(false, 1);
            }, true);
        }
        this.scope.$watch(scope => {
            return scope.$.reloading;
        }, (newVal, oldVal) => {
            if (newVal && newVal !== oldVal) {
                this.groups = [];
                this.loadGroups(false, 1);
            }
            return;
        });
        this.scope.$watch(scope => {
            return scope.$.selectedData.search;
        }, (search, oldSearch) => {
            let searchString = buildSearchString(search);
            let oldSearchString = buildSearchString(oldSearch);
            if (searchString != oldSearchString) {
                this.groups = [];
                this.loadGroups(false, 1);
            }
        });
        this.scope.$watch(scope => {
            return scope.$.selectedData;
        }, (data, oldData) => {
            let searchString = buildSearchString(data.search);
            let oldSearchString = buildSearchString(oldData.search);
            if (searchString == oldSearchString) {
                this.setGroupCheckedAndPartial(angular.copy(data.groups), angular.copy(data.groupsUsed));
            }
        });
        this.scope.$watch(scope => {
            return scope.$.hashPath;
        }, (newVal, oldVal) => {
            if (this.location === '/reports') {
                return;
            }
            if (oldVal === newVal) {
                return;
            } else if (newVal === 'toolBarToggle') {
                this.onGroupClick(this.linkNames[this.linkNames.length - 1]);
                this.setLinkings('');
                return;
            } else if (newVal === 'toolBarGroupSelect') {
                this.onGroupClick(this.linkNames[this.linkNames.length - 1], 'select');
                this.setLinkings('');
                return;
            } else if (newVal === 'toolBarGroupUnselect') {
                this.onGroupClick(this.linkNames[this.linkNames.length - 1], 'unselect');
                this.setLinkings('');
                return;
            } else if (newVal === 'toolBarAll') {
                let prevData = null;
                if (buildSearchString(this.selectedData.search)) {
                    prevData = this.selectedData.prevData;
                }
                let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy({ all: 1, search: this.selectedData.search, prevData: prevData, filterData: this.selectedData.filterData }));
                this.setSelectedData(selectedData);
                this.setLinkings('');
                this.checkSelectedData();
            } else if (newVal === 'toolBarClear') {
                let prevData = null;
                if (buildSearchString(this.selectedData.search)) {
                    prevData = this.selectedData.prevData;
                }
                let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy({ all: 0, search: this.selectedData.search, prevData: prevData, filterData: this.selectedData.filterData }));
                if (prevData) {
                    selectedData.all = 1;
                    selectedData.unselectAll = 1;
                }
                this.setSelectedData(selectedData);
                this.setLinkings('');
                this.checkSelectedData();
            } else if (newVal === 'toolBarReverse') {
                let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData.prevData), angular.copy({ search: this.selectedData.search, filterData: this.selectedData.filterData }));
                this.setSelectedData(selectedData);
                this.setLinkings('');
                this.checkSelectedData(true);
            } else if (newVal.indexOf('rebuildSelectedData') > -1) {
                this.setLinkings('');
                this.checkSelectedData(false, true);
            } else {
                this.watchHashpath();
            }
            if (['selectAll', 'sendTraining', 'attacksStart'].indexOf(newVal) > -1) {
                this.loadGroups(false, 1);
            }
        });
    }

    updateMaxCount = () => {
        this.maxTargetCount = 0;
        let targetCount = 0;
        for (let i = 0; i < this.groups.length; i++) {
            targetCount = this.groups[i].targetsCount;
            if (targetCount > this.maxTargetCount) {
                this.maxTargetCount = targetCount;
            }
        }
    };

    sortCourses = () => {
        this.maxTargetCount = 0;
        let targetCount = 0;
        for (let i = 0; i < this.groups.length; i++) {
            targetCount = this.groups[i].targetsCount;
            this.groups[i].course.none = this.groups[i].targetsCount - this.groups[i].course.process - this.groups[i].course.complete - this.groups[i].course.miss;
            if (targetCount > this.maxTargetCount) {
                this.maxTargetCount = targetCount;
            }
        }
    };

    watchHashpath = () => {
        if (this.location === '/reports') {
            return;
        }
        if (this.hashPath.indexOf(SEPARATOR) !== -1) {
            let hashPath = this.hashPath.split(SEPARATOR);
            this.setLinkings(hashPath[0]);
            this.setSearch(hashPath[1]);
        }
    };

    onItemClick = (event, group, search) => {
        event.stopPropagation();
        if (this.location === '/reports') {
            return;
        }
        if (search) {
            this.setSearch(search);
        }
        this.loadGroup(group.id);
    };

    __toggleGroup = (selectedData, group, forceAction = false) => {
        selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(selectedData));
        const onlyUnique = (value, index, self) => {
            return self.indexOf(value) === index;
        };
        const notEmpty = (value) => {
            return +value > 0;
        };
        let groupCheckedCache = angular.copy(this.groupCheckedCache);
        let groupPartialCache = angular.copy(this.groupPartialCache);
        let groupAction = forceAction;
        let checkedDiff = this.diff(groupCheckedCache, [group.id]);
        let partialDiff = this.diff(groupPartialCache, [group.id]);
        let groupDiff = this.diff(selectedData.groups, [group.id]);
        let unGroupDiff = this.diff(selectedData.unselectedGroups, [group.id]);
        if (!groupAction) {
            if (checkedDiff.added.length > 0 && (partialDiff.added > 0 || selectedData.prevData)) {
                groupCheckedCache.push(group.id);
                checkedDiff = this.diff(groupCheckedCache, [group.id]);
                if (!forceAction) {
                    forceAction = 'select';
                }
                groupAction = 'select';
            } else {
                if (checkedDiff.added.length > 0) {
                    groupCheckedCache = checkedDiff.removed;
                }
                if (!forceAction) {
                    forceAction = 'unselect';
                }
                groupAction = 'unselect';
            }
        }
        if (groupAction == 'select') {
            if (checkedDiff.added > 0) {
                groupCheckedCache.push(group.id);
            }
            if (!unGroupDiff.added.length) {
                selectedData.unselectedGroups = unGroupDiff.removed.filter(onlyUnique).filter(notEmpty);
            }
            if (groupDiff.added.length > 0) {
                selectedData.groups.push(group.id);
            }
        }
        if (groupAction == 'unselect') {
            if (!checkedDiff.added.length) {
                groupCheckedCache = checkedDiff.removed.filter(onlyUnique).filter(notEmpty);
            }
            if (!groupDiff.added.length) {
                selectedData.groups = groupDiff.removed.filter(onlyUnique).filter(notEmpty);
            }
            if ((selectedData.prevData || selectedData.all) && unGroupDiff.added.length > 0) {
                selectedData.unselectedGroups.push(group.id);
                if (selectedData.groups.indexOf(group.id) > 0) {
                    selectedData.groups.splice(selectedData.groups.indexOf(group.id), 1);
                }
                if (selectedData.groupsUsed.indexOf(group.id) > 0) {
                    selectedData.groupsUsed.splice(selectedData.groupsUsed.indexOf(group.id), 1);
                }
                if (selectedData.targets[group.id]) {
                    delete selectedData.targets[group.id];
                }
            }
        }
        if (selectedData.unselectedTargets[group.id]) {
            delete selectedData.unselectedTargets[group.id];
        }
        if (selectedData.targets[group.id]) {
            delete selectedData.targets[group.id];
        }
        groupCheckedCache = groupCheckedCache.filter(onlyUnique).filter(notEmpty);
        groupCheckedCache.sort();
        groupPartialCache = groupPartialCache.filter(onlyUnique).filter(notEmpty);
        groupPartialCache.sort();
        this.groupCheckedCache = groupCheckedCache.filter(() => true);
        this.groupPartialCache = groupPartialCache.filter(() => true);
        if (group.childIds && group.childIds.length > 0) {
            if (forceAction == 'select') {
                let childDiff = this.diff(selectedData.groups, group.childIds);
                if (childDiff.added && childDiff.added.length > 0) {
                    selectedData.groups = [...selectedData.groups, ...childDiff.added];
                }
            }
            if (forceAction == 'unselect') {
                let childDiff = this.diff(group.childIds, selectedData.groups);
                selectedData.groups = [];
                if (childDiff.added && childDiff.added.length > 0) {
                    selectedData.groups = childDiff.added;
                }
                selectedData.unselectedGroups = [...selectedData.unselectedGroups, ...[group.id], ...group.childIds].filter(onlyUnique).filter(notEmpty);
            }
        }
        selectedData.groups = selectedData.groups.filter(onlyUnique).filter(notEmpty);
        selectedData.groups.sort();
        selectedData.unselectedGroups = selectedData.unselectedGroups.filter(onlyUnique).filter(notEmpty);
        selectedData.unselectedGroups.sort();
        return selectedData;
    };

    toggleGroup = (group, forceAction) => {
        if (this.isAnyLoading() || !group || !group.id || this.toggleInProcess) {
            return;
        }
        this.toggleInProcess = true;
        let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
        selectedData = this.__toggleGroup(selectedData, group, forceAction);
        this.setSelectedData(selectedData);
        this.checkSelectedData();
        this.toggleInProcess = false;
    };

    collapseGroups = () => {
        this.collapseAllGroups();
    };

    onGroupClick = (group, forceAction) => {
        if (this.getReportParams) {
            this.toggleTargets(group);
            return;
        }
        if (this.isReport) {
            this.showReport(group);
        } else {
            this.toggleGroup(group, forceAction);
        }
    };

    loadGroups = (fullList = false, page = 1, callback) => {
        if (!this.loading) {
            this.setGroupsLoading(true);
            this.error = false;
        }
        let bodyParams = null;
        if (this.buildSearchString().indexOf(this.gettextCatalog.getString('выбранные сотрудники')) > -1) {
            bodyParams = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), this.selectedData.prevData);
        }
        fetch(this.getAdress(fullList, page, bodyParams), {
            headers: {
                'X-Csrf-Token': window.csrfToken,
                'Enable-Session': 1,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: bodyParams ? JSON.stringify(bodyParams) : null
        }).then(response => {
            if (response.ok) {
                // eslint-disable-next-line
                let nextLink = response.headers.get('link').split(/\,/g);
                this.groupCount = response.headers.get(
                    'X-Pagination-Total-Count');
                this.nextPage = getNextPage(nextLink);
            }
            return response.json();
        }).then(json => {
            if (json.message) {
                this.setGroupsLoading(false);
                this.error = json.message;
                this.scope.$apply();
                return;
            }
            if (json.length === 0) {
                this.groups = [];
            }
            if (json && json.length > 0) {
                for (let i = 0; i < json.length; i++) {
                    json[i].textColor = this.checkColor(json[i].color);
                }
                if (fullList || page === 1) {
                    this.groups = json;
                } else {
                    let groups = [...this.groups.filter(item => item.id !== -1), ...json];
                    this.groups = groups;
                }
            }
            if (typeof (this.setGroupCount) == 'function') {
                let allGroupCount = +this.groupCount;
                angular.forEach(this.groups, (group) => {
                    allGroupCount = allGroupCount + group.groupsCount;
                });
                this.setGroupCount(allGroupCount);
            }
            this.sortGroups();
            this.updateMaxCount();
            if (!this.isReportModal && !this.isVulnModal) {
                this.setOpenedGroups(angular.copy(this.$localStorage.openedGroups) || []);
            }
            this.setGroupCheckedAndPartial(angular.copy(this.selectedData.groups), angular.copy(this.selectedData.groupsUsed));
            if (typeof (callback) == 'function') {
                callback();
            }
            if (this.setReloading) {
                this.setReloading(false);
            }
            this.setGroupsLoading(false);
        }).then(() => {
            if ((this.hashPath === 'selectAll' || this.hashPath === 'sendTraining' || this.hashPath === 'attacksStart') && !this.parentId) {
                let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy({ all: 1, search: this.selectedData.search, filterData: this.selectedData.filterData }));
                this.setSelectedData(selectedData);
            }
            if ((this.hashPath === 'selectAll' || this.hashPath === 'sendTraining' || this.hashPath === 'attacksStart') && this.parentId) {
                this.toggleGroup(this.linkNames[this.linkNames.length - 1]);
            }
            if (this.hashPath === 'sendTraining') {
                this.trainingOpen();
            }
            if (this.hashPath === 'attacksStart') {
                this.setLinkings('createAttack');
            } else {
                this.setLinkings('');
            }
            this.checkSelectedData();
            if (this.courseId) {
                this.sortCourses();
            }
            this.scope.$apply();
        }).catch((error) => {
            window.console.log(error);
            this.setGroupsLoading(false);
            this.error = true;
            this.scope.$apply();
        });
    };

    loadGroup = (id) => {
        this.setBreadcrumbLink([
            ...this.linkNames,
            ...this.groups.filter(item => item.id === id)]);
    };

    setSortBy = (str, noRevers = false) => {
        if (noRevers) {
            if (str === 'priority') {
                this.sortReverse = false;
            } else {
                this.sortReverse = true;
            }
        } else {
            if (this.sortBy === str) {
                this.sortReverse = !this.sortReverse;
            } else if (this.sortBy != str) {
                this.sortReverse = false;
            }
        }
        this.sortBy = str;
        if (this.sortableFields.indexOf(this.sortBy) > -1) {
            this.groups = [];
            this.loadGroups(
                this.groups.length === +this.groupCount, 1);
        } else {
            this.sortGroups();
        }
    };

    sortGroups = () => {
        let str = this.sortBy;
        if (str && this.sortableFields.indexOf(str) === -1) {
            let sorting = !this.loading;
            if (sorting) {
                this.setGroupsLoading(true);
            }
            this.groups = this.filter('orderBy')(this.groups, str);
            if (this.sortReverse) {
                this.groups.reverse();
            }
            if (sorting) {
                this.setGroupsLoading(false);
            }
        }
    };

    setGroupsLoading = (value) => {
        // TODO для паралельных загрузок
        this.loading = value;
        if (typeof (this.setLoading) == 'function') {
            this.setLoading('groupsLoading', this.loading);
        }
    };

    getFilter = (filter) => {
        this.setSearchData(filter || ['']);
        this.groups = [];
        this.loadGroups(false, 1);
    };

    edit = (id) => {
        this.modal = this.$injector.instantiate(GroupModal);
        this.modal.open(id).then(() => {
            this.groups = [];
            this.loadGroups(false, 1);
        }, () => {
        });
    };

    delete = (group) => {
        this.modal = this.$injector.instantiate(BulkDeleteModal);
        this.modal.open({
            'type': 'group',
            'entity': group
        }).then(() => {
            this.loadGroups(false, 1);
        }, () => {
        });
    };

    getAdress = (fullList, page, bodyParams) => {
        let adress = `${window.config.SYSTEM_URL}${window.config.API_URL}/groups/search?fullList=${+fullList}&page=${page}&per-page=10`;
        adress += this.getSearchStr();
        adress += this.getSortStr();
        if (this.courseId) {
            adress += this.getCoursesStr();
        }
        if (this.getReportParams || this.isReport) {
            adress += this.getReportParamsStr();
        }
        if (this.parentId) {
            adress += this.getParentIdStr();
        }
        if (this.isVuln) {
            adress += this.getVulnStr();
        }
        if (this.managerId) {
            adress += this.getManagerStr();
        }
        if (this.software) {
            adress += this.getSoftwareStr();
        }
        if (bodyParams || this.getReportParams || this.isVuln || this.managerId) {
            adress += '&actual=1';
        }
        return adress;
    };

    getReportParamsStr = () => {
        let str = this.getReportParams ? JSON.stringify(this.getReportParams()).replace(/{/g, '%7B').replace(/}/g, '%7D') : '{}';
        return `&reportParams=${str}`;
    };

    getSortStr = () => {
        return `&sortBy=${this.sortBy}&sortReverse=${this.sortReverse}`;
    };

    getSearchStr = () => {
        let search = this.buildSearchString();
        return `&search=${window.encodeURIComponent(search)}`;
    };

    getCoursesStr = () => {
        return `&courses=${this.courseId}`;
    };

    getManagerStr = () => {
        return `&managerId=${this.managerId}`;
    };

    getSoftwareStr = () => {
        return `&softwareId=${this.software.id}&vendorId=${this.software.vendorId}`;
    };

    getParentIdStr = () => {
        return `&parentId=${this.parentId}`;
    };

    getVulnStr = () => {
        return '&risk=1';
    };

    collapseAllGroups = () => {
        let openedDiff = this.diff(this.groupsOpened || [], angular.copy(this.$localStorage.openedGroups || []));
        this.groupsOpened = [];
        this.$localStorage.openedGroups = openedDiff.added;
    };

    toggleTargets = (group) => {
        let openedDiff = this.diff(this.groupsOpened || [], angular.copy(this.$localStorage.openedGroups || []));
        let otherIds = openedDiff.added;
        let index = this.groupsOpened.indexOf(group.id);
        if (index == -1) {
            if (this.getReportParams || this.isVulnModal || !group.groupsCount) {
                this.groupsOpened.push(group.id);
            }
        } else {
            this.groupsOpened.splice(index, 1);
        }
        this.$localStorage.openedGroups = this.groupsOpened.concat(otherIds);
    };

    buildSearchString = () => {
        return buildSearchString(this.selectedData.search);
    };

    onGroupNameClick = ($event, group, fromGraph = false) => {
        if (!group || (group.targetsCount === 0 && group.groupsCount === 0)) {
            return;
        }
        if (fromGraph) {
            this.reverse = false;
            this.setViewMode(0);
            this.$localStorage.openedGroups = [];
            this.groupsOpened = [];
        }
        if (this.isAnyLoading()) {
            $event.stopPropagation();
        } else {
            if (this.getReportParams || this.isVulnModal) {
                $event.stopPropagation();
                this.toggleTargets(group);
                return;
            }
            if (group && group.groupsCount > 0) {
                if (this.isDisclosed) {
                    $event.stopPropagation();
                    this.loadGroup(group.id);
                }
            } else if (!this.isReport) {
                $event.stopPropagation();
                this.toggleTargets(group);
            }
        }
    };

    reportPage = (group) => {
        this.toggleTargets(group);
    };

    getPartial = (option) => {
        this.setGroupCheckedAndPartial(option.checked || this.groupCheckedCache, option.partial || this.groupPartialCache);
    };

    checkSelectedData = (reverse = false, ignoreCache = false) => {
        if (this.rebuildLoading || !this.isSelectable) {
            return;
        }
        let ctrl = this;
        const { $localStorage, scope } = this;
        let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(ctrl.selectedData));
        if (buildSearchString(this.selectedData.search).indexOf(this.gettextCatalog.getString('выбранные сотрудники')) > -1) {
            selectedData.filterData = this.selectedData.filterData || this.selectedData.prevData || null;
        } else {
            delete selectedData.filterData;
        }
        let selectedDataJson = buildSelectedDataCacheString(selectedData, reverse);
        let clearAll = selectedData.unselectAll ? true : false;
        if (selectedDataJson != $localStorage.selectedDataCacheString || ignoreCache) {
            rebuildSelectedData(selectedData, {
                mode: 'groups',
                risk: ctrl.isVuln ? 1 : 0,
                managerId: $localStorage.openedManager,
                scope: scope,
                courseId: this.courseId || null,
                setLoadingFunction: (loading) => {
                    if (typeof (ctrl.setLoading) == 'function') {
                        ctrl.setLoading('reBuildLoading', loading);
                    }
                },
                setSelectedDataFunction: (newSelectedData) => {
                    if (clearAll && newSelectedData.selectedCount.targets == 0) {
                        newSelectedData = angular.copy(EMPTY_SELECTED_DATA);
                    } else {
                        newSelectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(newSelectedData));
                    }
                    newSelectedData.search = angular.copy(ctrl.selectedData.search);
                    $localStorage.selectedDataCacheString = buildSelectedDataCacheString(angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(newSelectedData)), reverse);
                    ctrl.setSelectedData(newSelectedData);
                    scope.$apply();
                },
                setGroupCheckedAndPartialFunction: (checked, partial) => {
                    ctrl.setGroupCheckedAndPartial(checked, partial);
                },
                callbackFunction: () => {
                    scope.$apply();
                },
                reverse: reverse
            });
        }
    };

    setOpenedGroups = (openedIds) => {
        if (openedIds.length > 0) {
            let currentIds = this.groups.map(d => d.id);
            openedIds = openedIds.filter((i) => {
                return currentIds.indexOf(i) > -1;
            });
        }
        this.groupsOpened = openedIds;
    };

    setGroupCheckedAndPartial = (checked = [], partial = []) => {
        if ((checked && checked.length > 0) || (partial && partial.length > 0)) {
            let parent = this.linkNames[this.linkNames.length - 1] && this.linkNames[this.linkNames.length - 1] ? this.linkNames[this.linkNames.length - 1] : null;
            let groups = angular.copy(parent && angular.isArray(parent.childs) ? parent.childs : this.groups);
            if (!angular.isArray(groups)) {
                groups = [];
            }
            if (parent) {
                let parentGroup = groups.find((item) => {
                    return item.id == parent.id;
                });
                if (!parentGroup) {
                    groups.unshift(parent);
                }
            }
            angular.forEach(groups, (group) => {
                if (group.childIds && group.childIds.length > 0) {
                    let childDiff = this.diff(checked, group.childIds);
                    if (checked.indexOf(group.id) > -1 && childDiff.added && childDiff.added.length > 0) {
                        partial.push(group.id);
                    }
                    if (checked.indexOf(group.id) == -1 && childDiff.common && childDiff.common.length > 0) {
                        if (checked.added && checked.added.length > 0) {
                            if (partial.indexOf(group.id) == -1) {
                                partial.push(group.id);
                            }
                        } else {
                            if (group.ownTargets > 0) {
                                if (checked.indexOf(group.id) == -1) {
                                    checked.push(group.id);
                                }
                            } else {
                                if (partial.indexOf(group.id) == -1) {
                                    partial.push(group.id);
                                }
                            }
                        }
                    }
                }
            });
        }
        if (angular.isArray(checked)) {
            checked.sort();
            this.groupCheckedCache = angular.copy(checked);
        }
        if (angular.isArray(partial)) {
            partial.sort();
            this.groupPartialCache = angular.copy(partial);
        }
    };

    isGroupChildChecked = (group, checked, partial) => {
        if (group.childs && group.childs.length > 0 && group.targetsCount > 0) {
            let subResult = true;
            AnyFor(group.childs).for((groupChild) => {
                let groupChildChecked = this.isGroupChildChecked(groupChild, checked, partial);
                if (!subResult && checked.indexOf(groupChild.id) > -1 || groupChildChecked) {
                    subResult = true;
                }
                if (subResult && ((checked.indexOf(groupChild.id) == -1 && groupChild.ownTargetsCount > 0) || partial.indexOf(groupChild.id) > -1)) {
                    subResult = false;
                    return true;
                }
                if (subResult && !groupChildChecked) {
                    subResult = false;
                    return true;
                }
            });
            return subResult;
        } else {
            return checked.indexOf(group.id) > -1;
        }
    };

    isGroupChildPartial = (group, checked, partial, groupChecked = false) => {
        if (group.childs && group.childs.length > 0) {
            let subResult = false;
            let notCheckedCount = 0;
            let checkedCount = 0;
            AnyFor(group.childs).for((groupChild) => {
                if (!subResult && partial.indexOf(groupChild.id) > -1) {
                    subResult = true;
                    checkedCount++;
                    return true;
                }
                let groupChildPartial = this.isGroupChildPartial(groupChild, checked, partial, groupChecked);
                if (!subResult && groupChildPartial) {
                    subResult = true;
                    checkedCount++;
                    return true;
                }
                if (!subResult && ((notCheckedCount > 0 && checked.indexOf(groupChild.id) > -1) || (checkedCount > 0 && checked.indexOf(groupChild.id) == -1))) {
                    subResult = true;
                    checkedCount++;
                    return true;
                }
                if (checked.indexOf(groupChild.id) > -1) {
                    checkedCount++;
                } else {
                    notCheckedCount++;
                }
            });
            return subResult;
        } else {
            return partial.indexOf(group.id) > -1;
        }
    };

    showReport = (group) => {
        if (this.appCtrl && this.appCtrl.hasAccess(4, 'report_group')) {
            let params = {
                id: group.id,
                title: group.name,
                type: 'group',
                total: group.searchTargets,
                fromReport: true
            };
            this.modal = this.$injector.instantiate(ReportModal);
            this.modal.open(params).then(() => {
            }, () => {
            });
        }
    };

    checkColor = (hex) => {
        if (!hex) {
            return true;
        }
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        let r = parseInt(result[1], 16);
        let g = parseInt(result[2], 16);
        let b = parseInt(result[3], 16);

        return (r*0.299 + g*0.587 + b*0.114) > 186;
    }

    $onDestroy () {
        this.unsubscribe();
    }
}

GroupsTableController.$inject = [
    '$localStorage',
    '$scope',
    'gettextCatalog',
    '$sessionStorage',
    '$ngRedux',
    '$injector',
    '$filter',
    '$location'
];

export {
    GroupsTableController
};
