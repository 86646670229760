import style from './style.modules.css';

class LoginFormController {
    constructor ($scope, gettextCatalog) {
        this.$scope = $scope;
        this.gettextCatalog = gettextCatalog;
        this.style = style;
        this.isSSO = false;
        this.langCode = window.appCtrl.langCode;
    }

    $onInit () {
        this.loginFormBeforeText = this.getCustomText('loginFormBeforeText');
        this.loginFormEmailPlaceholder = this.getCustomText('loginFormEmailPlaceholder');
        this.loginFormPasswordPlaceholder = this.getCustomText('loginFormPasswordPlaceholder');
        this.loginFormAfterText = this.getCustomText('loginFormAfterText');
        // this.inProcess = true;
    }

    setSSO = (bool) => {
        this.isSSO = bool;
    }

    setForm = (form) => {
        this.form = form;
    };

    enterClick = () => {
        this.signIn(this.form);
    }

    getCustomText = (phrase) => {
        if (typeof (getCustomText) == 'function') {
            // eslint-disable-next-line
            return getCustomText(this.langCode, phrase);
        }
    };
}

LoginFormController.$inject = [
    '$scope',
    'gettextCatalog'
];

export {
    LoginFormController
};
