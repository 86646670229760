import { AUTH_SERVICE } from '../../const';
import angular from 'angular';

class UserAuthSettingsModalController {
    constructor($localStorage, AuthService) {
        this.$localStorage = $localStorage;
        this.authSettings = null;
        this.origSettings = null;
        this.AuthService = AuthService;
        this.inProcess = false;
        this.errorText = null;
        this.settingsIsSaved = false;
    }

    loadSettings = () => {
        this.loadingSettings = true;
        this.errorSettings = false;
        this.AuthService.getSettings().$promise.then(response => {
            this.authSettings = angular.copy(response);
            this.origSettings = angular.copy(response);
            this.loadingSettings = false;
        }, () => {
            this.loadingSettings = false;
            this.errorSettings = true;
        }).catch(() => {
            this.loadingSettings = false;
            this.errorSettings = true;
        });
        this.errorSettings = null;
    };

    $onInit = () => {
        this.loadSettings();
    };

    setForm = (form) => {
        this.form = form;
    };

    hasChanges = () => {
        let changed = false;
        angular.forEach(this.authSettings, (value, key) => {
            if (['$promise', 'global'].indexOf(key) == -1 && this.origSettings[key] != value) {
                changed = true;
            }
        });
        if (!changed) {
            this.errorSettings = null;
        }
        return changed;
    };

    saveSettings = () => {
        this.settingsIsSaved = false;
        this.errors = {};
        this.form.$setSubmitted();
        if (this.form.$valid && this.hasChanges()) {
            this.inProcess = true;
            this.AuthService.saveSettings(this.authSettings, this,
                (ctrl, response) => {
                    ctrl.inProcess = null;
                    ctrl.authSettings = response;
                    ctrl.settingsIsSaved = true;
                    ctrl.$close(response.data);

                }, (ctrl, error) => {
                    let key = Object.keys(error.data)[0];
                    ctrl.inProcess = false;
                    ctrl.errors[key] = true;
                    ctrl.errorText = error.data[key];
                });
        }
    };
}

UserAuthSettingsModalController.$inject = [
    '$localStorage',
    AUTH_SERVICE,
];

export {
    UserAuthSettingsModalController,
};
