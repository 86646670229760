import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FetchApi from "../React/REST";

const initialState = {
    loading: false,
    itemLoad: null,
    wifiTargets: [],
    error: false,
    errorText: "",
};

export const fetchWifiTargets = createAsyncThunk("wifiTargets/status", async (id) => {
    const wifiTargets = FetchApi(`/wifi-attacks`);
    let response = await wifiTargets.get(id + `/targets`);
    return [...response];
});


const wifiTargetsSlice = createSlice({
    name: "wifiTargets",
    initialState,
    reducers: {
        setWifiTargets(state, { payload }) {
            state.wifiTargets = payload;
        },
        setWifiTargetsOne(state, { payload }) {
            state.wifiTargets.push(payload);
        },
    },
    extraReducers: {
        [fetchWifiTargets.pending]: (state) => {
            state.loading = true;
        },
        [fetchWifiTargets.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.error = false;
            (state.errorText = ""), (state.wifiTargets = payload);
        },
        [fetchWifiTargets.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = true;
            state.errorText = payload;
        },
    },
});

export const { setWifiTargets, setWifiTargetsOne } = wifiTargetsSlice.actions;
export default wifiTargetsSlice.reducer;
