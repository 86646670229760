import style from './style.modules.css';
import angular from 'angular';

class HighlightController {
    $onInit = () => {
        this.style = style;
        let self = this;
        if (self.text) {
            self.text = self.text + '';
        } else {
            self.text = '';
        }
        if (self.search) {
            if (!angular.isArray(self.search)) {
                self.search = [self.search + ''];
            }
        } else {
            self.search = [];
        }
    };
}

HighlightController.$inject = [];

export {
    HighlightController,
};
