import style from './style.modules.css';

class ArrayInputController {
    constructor() {
        this.style = style;
    }

    $onInit() {}

    onBlur = () => {
        this.getData(this.arrayData);
    }

    remove = (email) => {
        let items = this.arrayData.filter(item => item !== email);
        this.getData(items);
    }
}

ArrayInputController.$inject = [
    'gettextCatalog',
];

export {
    ArrayInputController,
};
