import style from './style.modules.css';
import setOptions from '../../helpers/setOptions';
import { LdapFieldsMapModal } from '../ldapFieldsMapModal';
const defaultMap = { 'email': 'mail', 'phone': 'telephonenumber', 'firstName': 'sn', 'secondName': 'givenname', 'thirdName': '', 'fullName': 'displayname', 'position': 'title', 'department': 'department' };

class RoleSyncController {
    get MANUAL () {return 2;}
    get AUTO () {return 1;}
    get UNIT_DAY () {return 'day';}
    get UNIT_HOUR () {return 'hour';}
    constructor(scope, injector, $timeout) {
        this.style = style;
        this.scope = scope;
        this.$timeout = $timeout;
        this.injector = injector;
        this.profiles = [null];
        this.groups = [];
        this.groupNames = [];
        this.groupsList = {};
        this.groupLoading = [];
        this.map = [];
        this.periodValue = 1;
        this.periodUnit = this.UNIT_HOUR;
        this.groupStatus = {};
        this.syncType = this.MANUAL;
    }

    $onInit = () => {
        this.initParams();
    }

    initParams = () => {
        if (this.initSyncParams && this.initSyncParams.mode) {
            this.syncType = this.initSyncParams.mode;
        }
        if (this.initSyncParams && this.initSyncParams.synchronizeInterval) {
            let int = this.initSyncParams.synchronizeInterval / 3600;
            this.periodUnit = int/24 === ~~(int/24) ? this.UNIT_DAY : this.UNIT_HOUR;
            this.periodValue = int/24 === ~~(int/24) ? int/24 : int;
        }
        this.setSyncParams();
        if (!this.initProfiles || !this.initProfiles.length) return;
        this.initProfiles.forEach((item, index) => {
            this.profiles[index] = this.ldapProfiles.filter(profile => profile.id === item.profileId)[0];
            this.map[index] = item.fieldsMap;
            this.loadLdapGroup(this.profiles[index].id, index, item.groupNames);
        });
    }

    onProfileSelect = (item, index) => {
        this.$timeout(this.loadLdapGroup(item.id, index), 50);
    }

    getLdapList = () => {
        let profilesId = this.profiles.filter(item => item)
            .map(item => item.id);
        let ldapProfiles = this.ldapProfiles.filter(item => profilesId.indexOf(item.id) === -1);
        return ldapProfiles;
    }

    addProfile = () => {
        if (!this.ldapProfiles.length) return;
        if (!this.profiles[this.profiles.length - 1]) return;
        if (this.profiles.length === this.ldapProfiles.length) return;
        this.profiles.push(null);
        this.map.push(defaultMap);
    }

    getGroups = (query, groups) => {
        groups = groups || [];
        var regexp = new RegExp(query, 'i');
        let groupsNames = groups.filter((item) => {
            return item.name.search(regexp) != -1;
        }).map((item) => {
            return item.name;
        });
        return groupsNames;
    };

    groupAdd = (tag, index) => {
        if (!this.groupNames[index]) {
            this.groupNames[index] = [];
        }
        this.groupNames[index].push(tag.text);
        this.setSyncRef();
    }

    groupRemove = (tag, index) => {
        this.groupNames[index] = this.groupNames[index].filter(item => item !== tag.text);
        this.setSyncRef();
    }

    removeProfile = (index, id) => {
        if (!index && !id) return;
        if (!index && this.profiles.length === 1) {
            this.profiles = [null];
            this.setSyncRef();
            return;
        }
        if (!id) {
            this.profiles = this.profiles.filter((item, i) => i !== index);
            this.groups = this.groups.filter((item, i) => i !== index);
            this.map = this.map.filter((item, i) => i !== index);
            this.setSyncRef();
            return;
        }
        let newIndex = null;
        this.profiles = this.profiles.filter((item, i) => {
            if (!item) return true;
            if (item.id === id) {
                newIndex = i;
            }
            return item.id !== id;
        });
        this.groups = this.groups.filter((item, i) => i !== newIndex);
        this.map = this.map.filter((item, i) => i !== newIndex);
        this.setSyncRef();
    }

    openFieldsMap = (map, index) => {
        this.modal = this.injector.instantiate(LdapFieldsMapModal);
        this.modal.open(map).then((map) => {
            if (!map) return;
            this.map[index] = map;
            this.setSyncRef();
        }, () => {});
    };

    loadLdapGroup = async (profileId, index, groupsAdd = null) => {
        try {
            this.groupStatus[index] = 'load';
            this.groupLoading[index] = true;
            this.setGroupLoading();
            let options = setOptions();
            let response = await fetch(window.config.SYSTEM_URL + window.config.API_URL + '/targets/import/' + profileId + '/ldap-groups', options);
            if (!response.ok) throw true;
            this.groupsList[index] = await response.json();
            if (groupsAdd) {
                this.groups[index] = [...groupsAdd];
                this.groupNames[index] = [...groupsAdd];
            }
            this.groupStatus[index] = 'success';
            this.groupLoading[index] = false;
            this.setSyncRef();
            this.setGroupLoading();
            this.scope.$apply();
        } catch (err) {
            console.log(err);
            this.groupStatus[index] = 'error';
            this.groupLoading[index] = false;
            this.setGroupLoading();
            this.scope.$apply();
        }
    }

    hasOwnProfile = () => {
        if (this.profiles.length > 1) return true;
        if (this.profiles[0]) return true;
        return false;
    }

    setPeriodUnit = (period) => {
        this.periodUnit = period;
        this.setSyncParams();
    }

    setSyncType = (type) => {
        this.syncType = type;
        this.setSyncParams();
    }

    setGroupLoading = () => {
        let loading = this.groupLoading.filter(item => item);
        this.getGroupLoading(!!loading.length);
    }

    setSyncRef = () => {
        let hasField = this.profiles.filter(item => item);
        let syncRef = [];
        try {
            syncRef = hasField.map((item, index) => {
                let syncParam = {
                    profileId: item.id,
                    groupNames: this.groupNames[index],
                    fieldsMap: this.map[index] || defaultMap
                };
                return syncParam;
            });
            this.getSyncRef(syncRef);
        } catch (err) {
            console.log(err);
        }
    }

    setSyncParams = () => {
        let syncParams = {
            mode: this.syncType,
            synchronizeInterval: this.periodValue * 3600 * (this.periodUnit === this.UNIT_HOUR ? 1: 24),
        };
        this.getSyncParams(syncParams);
    }

    $onDestroy() {}
}

RoleSyncController.$inject = [
    '$scope',
    '$injector',
    '$timeout'
];

export {
    RoleSyncController
};

