import urlInputTemplate from './view.html';

import { UrlInputController } from './controller';

const UrlInputComponent = {
    template: urlInputTemplate,
    controller: UrlInputController,
    controllerAs: '$',
    bindings: {
        urlName: '<',
        urlRequired: '<',
        fieldName: '=',
        urlDisabled: '=',
        urlReadonly: '=',
        castomStyle: '=',
        urlOnBlur: '=',
        httpsOnly: '<',
        noPattern: '<'
    },
};

export {
    UrlInputComponent,
};
