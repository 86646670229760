import { classNames } from '../../helpers/classNames.js';

function PopupComponent(props) {
    const {
        className,
        children,
        ...otherProps
    } = props;

    return (
        <div className={classNames('react-popup', {}, [className])} {...otherProps}>
            {children}
        </div>
    );
}

export default PopupComponent;
