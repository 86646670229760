import template from './view.html';

import { RoleSyncController } from './controller';

const RoleSyncComponent = {
    template: template,
    controller: RoleSyncController,
    controllerAs: '$',
    scope: true,
    bindings: {
        isDisabled: '<',
        ldapProfiles: '<',
        initProfiles: '<',
        initSyncParams: '<',
        getSyncRef: '=',
        getSyncParams: '=',
        getGroupLoading: '='
    },
};

export {
    RoleSyncComponent
};
