import angular from 'angular';
import style from './style.modules.css';

class LogsListController {
    constructor() {
        this.limitTo = 10;
        this.saving = false;
        this.style = style;
    }

    download = (content, fileName, mimeType) => {
        let a = document.createElement('a');
        mimeType = mimeType || 'application/octet-stream';
        if (navigator.msSaveBlob) { // IE10
            navigator.msSaveBlob(new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), content], {
                type: mimeType,
            }), fileName);
        } else if (URL && 'download' in a) { //html5 A[download]
            a.href = URL.createObjectURL(new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), content], {
                type: mimeType,
            }));
            a.setAttribute('download', fileName);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } else {
            location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
        }
    };

    save = () => {
        this.saving = true;
        let csvContent = '';
        let universalBOM = '\uFEFF';
        let maxColumnCount = 0;
        angular.forEach(this.logs, (log) => {
            let logData = log.split(',');
            angular.forEach(logData, (logValue, logKey) => {
                if (logValue.match(/;/)) {
                    logData[logKey] = '"' + logValue.trim().replace('"', '\\"') + '"';
                } else {
                    logData[logKey] = logValue.trim();
                }
            });
            while (logData.length < maxColumnCount) {
                logData.push('');
            }
            if (maxColumnCount < logData.length) {
                maxColumnCount = logData.length;
            }
            csvContent += logData.join(';') + '\n';
        });
        this.download(universalBOM + csvContent, 'log.csv', 'text/csv;encoding:utf-8');
        this.saving = false;
    };
}

LogsListController.$inject = [];

export {
    LogsListController,
};
