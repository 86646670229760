import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';

const initialState = {
    status: 'idle',
    certs: {},
    error: false
};

export const fetchNotification = createAsyncThunk(
    'notification/status',
    async () => {}
);

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setDefaultNotification (state) {
            state = initialState;
        },
        setNotification (state, {payload}) {
            state.certs = payload;
        }
    },
    extraReducers: {}
});

export const {setDefaultNotification, setNotification} = notificationSlice.actions;
export default notificationSlice.reducer;

