import setOptions from './setOptions';
import { checkDefaultLanguage, getLocales } from './content';

const getMethod = (params, id = null) => {
    if (!params.body && !id) return 'GET';
    if (!id) return 'POST';
    return 'PUT';
};

export const contentFetch = async (params, id = null) => {
    let options = setOptions(params);
    options.method = params.method ? params.method : getMethod(params, id);
    options.body = params.body;
    let response = await fetch(`${window.config.SYSTEM_URL}${window.config.API_URL}/${params.content}${id ? '/' + id : ''}`, options);
    return response.json();
};

export async function fetchEntity (params, schema, callback) {
    try {
        this[params.loading] = true;
        this[params.error] = false;
        let options = setOptions();
        let response = await fetch(`${window.config.SYSTEM_URL}${window.config.API_URL}/${params.urlName}/${params.id}`, options);
        if (!response.ok) {
            throw response.error;
        }
        this[params.name] = await response.json();
        this.locales = getLocales(this[params.name].locales, schema, this.languages);
        let defaultLanguage = checkDefaultLanguage(this.locales, this.languages);
        this.active = defaultLanguage.id;
        this.defaultLanguage = defaultLanguage.id;
        this.setActiveLanguage(this.active);
        this.setBtnConfig();
        if (callback) {
            callback();
        }
        this[params.loading] = false;
        this[params.error] = false;
        this.scope.$apply();
    } catch (error) {
        this[params.loading] = false;
        this[params.error] = true;
        window.console.log(error);
    }
}
