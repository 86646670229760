import campaignTemplate from './view.html';

import { CampaignController } from '../campaign/controller';

const ReportCampaignComponent = {
    template: campaignTemplate,
    controller: CampaignController,
    controllerAs: '$',
    scope: true,
    bindings: {
        campaign: '=',
        isReport: '<',
    },
};

export {
    ReportCampaignComponent,
};
