import { Controller } from './controller';
import { AUTH_SERVICE } from '../const';
import setOptions from '../helpers/setOptions';
import { setAuthInfo } from '../toolkit/actions';

const CODE_NEED_CHANGE_PASSWORD = 11;

const userFields = [
    'password_new',
    'password_new_repeat',
    'password',
    'oneTimePassword',
    'username'
];

class LoginController extends Controller {

    constructor($scope, $localStorage, AuthService, $ngRedux) {
        super();
        this.$scope = $scope;
        this.$localStorage = $localStorage;
        this.AuthService = AuthService;
        this.user = AuthService.new();
        this.isSSO = false;
        this.showPasswordForm = false;
        this.passwordShow = false;
        this.twoFactorEnable = false;
        this.signInBackUrl = window.config.SIGN_IN_BACK_URL;
        this.gettextCatalog = window.appCtrl.gettextCatalog;
        this.unsubscribe = $ngRedux.connect(null, { setAuthInfo })(this);
    }

    $onInit() {
        const { $localStorage } = this;
        if ($localStorage.userInfo && $localStorage.userInfo.email) {
            this.$scope.appCtrl.go($localStorage.userInfo.defaultPage || 'targets');
        } else {
            document.body.classList.add('body-login');
        }
    }

    setSSO = (bool) => {
        this.isSSO = bool;
        this.errorText = '';
    }

    signInSSO = async (form) => {
        if (!form.$valid) return;
        try {
            this.inProcess = true;
            const options = setOptions();
            options.method = 'GET';
            let response = await fetch('/sso/login?username=' + this.user.username, options);
            let parsed = await response.json();
            const {
                ok
            } = response;
            if (ok) {
                if (parsed.ssoRedirectUrl) {
                    window.location.href = parsed.ssoRedirectUrl;
                }
                this.inProcess = false;
            }
            throw Error(parsed.error ? parsed.error : 'Ошибка аутентификации');
        } catch (error) {
            this.inProcess = false;
            this.errorText = this.gettextCatalog.getString(error.message);
            this.$scope.$apply();
        }
    }

    signIn = async (form) => {
        this.errorText = null;
        form.$setSubmitted();
        if (this.isSSO) {
            this.signInSSO(form);
            return;
        }
        if (form.$valid) {
            try {
                this.inProcess = true;
                let options = setOptions();
                options.method = 'POST';
                options.headers['Content-Type'] = 'application/json';
                let body = {};
                if (!this.user.oneTimePassword) {
                    delete this.user.oneTimePassword;
                }
                userFields.forEach(item => {
                    if (this.user[item]) {
                        body[item] = this.user[item];
                    }
                });
                options.body = JSON.stringify(body);
                let response = await fetch(window.config.SYSTEM_URL + window.config.API_URL + '/auth', options);
                if (+response.status === 449) {
                    this.twoFactorEnable = true;
                    this.inProcess = false;
                    this.$scope.$apply();
                    return;
                }
                if (!response.ok) {
                    throw true;
                }
                let user = await response.json();
                this.inProcess = false;
                this.setUser(user);
                this.$scope.$apply();
            } catch (e) {
                this.inProcess = false;
                this.errorText = this.gettextCatalog.getString('Ошибка аутентификации');
                this.$scope.$apply();
            }

        }
    };

    setUser = (user) => {
        const { $localStorage } = this;
        if (user && user.email) {
            $localStorage.userInfo = user;
            if (user.lastMotivation && user.lastMotivation.updatedAt) {
                user.lastMotivation.updatedAt = user.lastMotivation.updatedAt * 1000;
            }
            this.setAuthInfo(user);
            document.body.classList.remove('body-login');
            this.$scope.appCtrl.go(user.defaultPage || 'targets');
        } else {
            if (user && user.error) {
                this.errorText = this.gettextCatalog.getString(user.error);
            } else {
                this.errorText = this.gettextCatalog.getString('Ошибка аутентификации');
            }
            this.showPasswordForm = user.code && user.code === CODE_NEED_CHANGE_PASSWORD;
            this.changePasswordText = user.code && user.code === CODE_NEED_CHANGE_PASSWORD && user.error;
        }
    }

    get oneTimePass () {
        if (!this.user.oneTimePassword) {
            this.user.oneTimePassword = '';
        }
        let arr = this.user.oneTimePassword.match( /\d{1,3}/g ) || [];
        return arr.join(' ');
    }

    set oneTimePass (key) {
        if (!key) {
            this.user.oneTimePassword = null;
            return;
        }
        this.user.oneTimePassword = key.split(' ').join('');
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

LoginController.$inject = [
    '$scope',
    '$localStorage',
    AUTH_SERVICE,
    '$ngRedux'
];

export {
    LoginController
};
