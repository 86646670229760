import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';

const initialState = {
    DBversion: 1,
    targets: 0,
    attacks: 0,
    risks: 0,
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setDashboardRound(state, {payload}) {
            state.targets = payload.targets || state.targets;
            state.attacks = payload.attacks || state.attacks;
            state.risks = payload.risks || state.risks;
        },
        setDashboardVersion(state, {payload}) {
            state.DBversion = payload;
        }
    }
});

export const { setDashboardRound, setDashboardVersion } = dashboardSlice.actions;
export default dashboardSlice.reducer;
