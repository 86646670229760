import daemonSaveFileTemplate from './view.html';

import { DaemonSaveFileController } from './controller';

const DaemonSaveFileComponent = {
    template: daemonSaveFileTemplate,
    controller: DaemonSaveFileController,
    controllerAs: '$',
    scope: true,
    bindings: {
        daemonId: '<',
        titleText: '<',
        type: '<',
        onClickLink: '='
    }
};

export {
    DaemonSaveFileComponent,
};
