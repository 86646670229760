import style from './style.modules.css';

class PracticeController {
    constructor() {
        this.style = style;
    }

    $onInit() {}
}

PracticeController.$inject = [];

export {
    PracticeController,
};
