import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';

const initialState = {
    status: 'idle',
    campaigns: {},
    restoreData: {campaigns: 0, tasks: 0},
    error: false
};

// export const fetchCampaigns = createAsyncThunk(
//     'campaigns/',
//     async () => {}
// );

const campaignsSlice = createSlice({
    name: 'campaigns',
    initialState,
    reducers: {
        setCampaignsRestoreData (state, {payload}) {
            state.restoreData = payload;
        }
    },
    extraReducers: {}
});

export const {setCampaignsRestoreData} = campaignsSlice.actions;
export default campaignsSlice.reducer;

