import style from './style.modules.css';
import { DepartmentModal } from '../departmentModal';
import getNextPage from '../../helpers/getNextPage';
import buildSearchString from '../../helpers/buildSearchString';
import angular from 'angular';
import rebuildSelectedData from '../../helpers/rebuildSelectedData';
import { EMPTY_SELECTED_DATA, SEPARATOR } from '../../const';
import buildSelectedDataCacheString from '../../helpers/buildSelectedDataCacheString';
import { ReportModal } from '../reportModal';
import AnyFor from 'any-foreach';
import { hasAccess } from '../../reselect';
import { setSearchData, setLinkings, setBreadcrumbLink, setSelectedData } from '../../toolkit/actions';

const actions = {
    setSearchData,
    setLinkings,
    setBreadcrumbLink,
    setSelectedData
};

class DepartmentsTableController {
    constructor ($localStorage, $scope, gettextCatalog, $sessionStorage, $ngRedux, $injector, $filter, $location) {
        this.style = style;
        this.$localStorage = $localStorage;
        this.gettextCatalog = gettextCatalog;
        this.$sessionStorage = $sessionStorage;
        this.$injector = $injector;
        this.filter = $filter;
        this.loading = false;
        this.error = false;
        this.scope = $scope;
        this.nextPage = 0;
        this.sortBy = '';
        this.sortReverse = true;
        this.departments = [];
        this.departmentCount = 0;
        this.departmentsOpened = [];
        this.department = null;
        this.parentId = null;
        this.licenseIsValid = $localStorage.userInfo.license.isValid;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, actions)(this);
        this.sortableFields = [
            'name',
            'rating',
            'lastChange',
            'targetsCount',
            'vulnCount'
        ];
        this.targets = {};
        this.targetCount = [];
        this.diff = require('simple-array-diff');
        this.appCtrl = window.appCtrl;
        this.maxTargetCount = 0;
        this.location = $location.path();
        this.toggleInProcess = false;
        this.strings = {
            eduComplete: gettextCatalog.getString('прошел обучение'),
            eduProcess: gettextCatalog.getString('на обучении'),
            eduMiss: gettextCatalog.getString('не прошел обучение вовремя'),
            eduNew: gettextCatalog.getString('не обучался'),
            newEmployee: gettextCatalog.getString('новый сотрудник'),
            attackFail: gettextCatalog.getString('не выдержал атаку'),
            ratingMinus: gettextCatalog.getString('рейтинг отрицательный'),
            ratingDown: gettextCatalog.getString('рейтинг ухудшился'),
            ratingUp: gettextCatalog.getString('рейтинг улучшился'),
            ratingPlus: gettextCatalog.getString('рейтинг положительный'),
            attackNew: gettextCatalog.getString('не проверялся')
        };
    }

    mapStateToThis (state) {
        return {
            selectedData: state.selectedData.selectedData,
            linkNames: state.breadcrumbLinks.link,
            hashPath: state.linking.link,
            accessDepartmentsDefault: hasAccess(state, { sectionId: 1, rightName: 'view' }),
            accessDepartmentsAttacks: hasAccess(state, { sectionId: 2, rightName: 'view' }),
            accessDepartmentsReports: hasAccess(state, { sectionId: 4, rightName: 'report_department' }),
            accessAttacksReports: hasAccess(state, { sectionId: 4, rightName: 'report_attack' }),
            anonymization: state.auth.auth.license.features.anonymization,
        };
    }

    $onInit () {
        this.accessDepartmentsDefault = !this.isVuln && !this.isReport && this.accessDepartmentsDefault;
        this.accessDepartmentsRisks = this.isVuln && this.accessDepartmentsRisks;
        this.accessDepartmentsReports = ((!this.getReportParams && this.isReport) || (this.getReportParams && this.getReportParams().type == 'department')) && this.accessDepartmentsReports;
        this.accessAttacksReports = ((!this.getReportParams && this.isReport) || (this.getReportParams && this.getReportParams().type == 'attack')) && (this.accessAttacksReports || this.accessDepartmentsAttacks);
        if (!this.accessDepartmentsDefault && !this.accessDepartmentsRisks && !this.accessDepartmentsReports && !this.accessAttacksReports && !this.accessDepartmentsAttacks) {
            return;
        }
        this.departmentsOpened = [];
        let parent = this.linkNames[this.linkNames.length - 1] && this.linkNames[this.linkNames.length - 1] ? this.linkNames[this.linkNames.length - 1] : null;
        this.parentId = parent && !parent.isTitle ? parent.id : null;
        this.watchHashpath();
        this.loadDepartments(false, 1);
        if (!this.departmentId) {
            this.scope.$watch(scope => {
                return scope.$.linkNames;
            }, (links, oldLinks) => {
                if (links.length === oldLinks.length) {
                    return;
                }
                if (!links.length && !this.courseId) {
                    this.parentId = null;
                    this.departments = [];
                    this.loadDepartments(false, 1);
                    return;
                }
                if (links.length === 1 && links[0] &&
                    {}.hasOwnProperty.call(links[0], 'isTitle')) {
                    this.parentId = null;
                    this.departments = [];
                    this.loadDepartments(false, 1);
                    return;
                }
                this.parentId = links[links.length - 1] ? links[links.length - 1].id : null;
                this.departments = [];
                this.loadDepartments(false, 1);
            }, true);
        }
        this.scope.$watch(scope => {
            return scope.$.reloading;
        }, (newVal, oldVal) => {
            if (newVal && newVal !== oldVal) {
                this.departments = [];
                this.loadDepartments(false, 1);
            }
            return;
        });
        this.scope.$watch(scope => {
            return scope.$.selectedData.search;
        }, (search, oldSearch) => {
            let searchString = buildSearchString(search);
            let oldSearchString = buildSearchString(oldSearch);
            if (searchString != oldSearchString) {
                this.departments = [];
                this.loadDepartments(false, 1);
            }
        });
        this.scope.$watch(scope => {
            return scope.$.selectedData;
        }, (data, oldData) => {
            let searchString = buildSearchString(data.search);
            let oldSearchString = buildSearchString(oldData.search);
            if (searchString == oldSearchString) {
                this.setDepartmentCheckedAndPartial(angular.copy(data.departments), angular.copy(data.departmentsUsed));
            }
        });
        this.scope.$watch(scope => {
            return scope.$.hashPath;
        }, (newVal, oldVal) => {
            if (this.location === '/reports') {
                return;
            }
            if (oldVal === newVal) {
                return;
            } else if (newVal === 'reload') {
                this.departments = [];
                this.loadDepartments(false, 1);
                this.setLinkings('');
            } else if (newVal === 'toolBarToggle') {
                this.onDepartmentClick(this.linkNames[this.linkNames.length - 1]);
                this.setLinkings('');
                return;
            } else if (newVal === 'toolBarDepartmentSelect') {
                this.onDepartmentClick(this.linkNames[this.linkNames.length - 1], 'select');
                this.setLinkings('');
                return;
            } else if (newVal === 'toolBarDepartmentUnselect') {
                this.onDepartmentClick(this.linkNames[this.linkNames.length - 1], 'unselect');
                this.setLinkings('');
                return;
            } else if (newVal === 'toolBarAll') {
                let prevData = null;
                if (buildSearchString(this.selectedData.search)) {
                    prevData = this.selectedData.prevData;
                }
                let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy({ all: 1, search: this.selectedData.search, prevData: prevData, filterData: this.selectedData.filterData }));
                this.setSelectedData(selectedData);
                this.setLinkings('');
                this.checkSelectedData();
            } else if (newVal === 'toolBarClear') {
                let prevData = null;
                if (buildSearchString(this.selectedData.search)) {
                    prevData = this.selectedData.prevData;
                }
                let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy({ all: 0, search: this.selectedData.search, prevData: prevData, filterData: this.selectedData.filterData }));
                if (prevData) {
                    selectedData.all = 1;
                    selectedData.unselectAll = 1;
                }
                this.setSelectedData(selectedData);
                this.setLinkings('');
                this.checkSelectedData();
            } else if (newVal === 'toolBarReverse') {
                let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData.prevData), angular.copy({ search: this.selectedData.search, filterData: this.selectedData.filterData }));
                this.setSelectedData(selectedData);
                this.setLinkings('');
                this.checkSelectedData(true);
            } else if (newVal.indexOf('rebuildSelectedData') > -1) {
                this.setLinkings('');
                this.checkSelectedData(false, true);
            } else {
                this.watchHashpath();
            }
            if (['selectAll', 'sendTraining', 'attacksStart'].indexOf(newVal) > -1) {
                this.loadDepartments(false, 1);
            }
        });
    }

    updateMaxCount = () => {
        this.maxTargetCount = 0;
        let targetCount = 0;
        for (let i = 0; i < this.departments.length; i++) {
            targetCount = this.departments[i].targetsCount;
            if (targetCount > this.maxTargetCount) {
                this.maxTargetCount = targetCount;
            }
        }
    };

    sortCourses = () => {
        this.maxTargetCount = 0;
        let targetCount = 0;
        for (let i = 0; i < this.departments.length; i++) {
            targetCount = this.departments[i].targetsCount;
            this.departments[i].course.none = this.departments[i].targetsCount - this.departments[i].course.process - this.departments[i].course.complete - this.departments[i].course.miss;
            if (targetCount > this.maxTargetCount) {
                this.maxTargetCount = targetCount;
            }
        }
    };

    watchHashpath = () => {
        if (this.location === '/reports') {
            return;
        }
        if (this.hashPath.indexOf(SEPARATOR) !== -1) {
            let hashPath = this.hashPath.split(SEPARATOR);
            this.setLinkings(hashPath[0]);
            this.setSearch(hashPath[1]);
        }
    };

    onItemClick = (id, search, event) => {
        event.stopPropagation();
        if (this.location === '/reports') {
            return;
        }
        if (search) {
            this.setSearch(search);
        }
        this.loadDepartment(id);
    };

    __toggleDepartment = (selectedData, department, forceAction = false) => {
        selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(selectedData));
        const onlyUnique = (value, index, self) => {
            return self.indexOf(value) === index;
        };
        const notEmpty = (value) => {
            return +value > 0;
        };
        let departmentCheckedCache = angular.copy(this.departmentCheckedCache);
        let departmentPartialCache = angular.copy(this.departmentPartialCache);
        let departmentAction = forceAction;
        let checkedDiff = this.diff(departmentCheckedCache, [department.id]);
        let partialDiff = this.diff(departmentPartialCache, [department.id]);
        let departmentDiff = this.diff(selectedData.departments, [department.id]);
        let unDepartmentDiff = this.diff(selectedData.unselectedDepartments, [department.id]);
        if (!departmentAction) {
            if (checkedDiff.added.length > 0 && (partialDiff.added > 0 || selectedData.prevData)) {
                departmentCheckedCache.push(department.id);
                checkedDiff = this.diff(departmentCheckedCache, [department.id]);
                if (!forceAction) {
                    forceAction = 'select';
                }
                departmentAction = 'select';
            } else {
                if (checkedDiff.added.length > 0) {
                    departmentCheckedCache = checkedDiff.removed;
                }
                if (!forceAction) {
                    forceAction = 'unselect';
                }
                departmentAction = 'unselect';
            }
        }
        if (departmentAction === 'select') {
            if (checkedDiff.added > 0) {
                departmentCheckedCache.push(department.id);
            }
            if (!unDepartmentDiff.added.length) {
                selectedData.unselectedDepartments = unDepartmentDiff.removed.filter(onlyUnique).filter(notEmpty);
            }
            if (departmentDiff.added.length > 0) {
                selectedData.departments.push(department.id);
            }
        }
        if (departmentAction === 'unselect') {
            if (!checkedDiff.added.length) {
                departmentCheckedCache = checkedDiff.removed.filter(onlyUnique).filter(notEmpty);
            }
            if (!departmentDiff.added.length) {
                selectedData.departments = departmentDiff.removed.filter(onlyUnique).filter(notEmpty);
            }
            if ((selectedData.prevData || selectedData.all) && unDepartmentDiff.added.length > 0) {
                selectedData.unselectedDepartments.push(department.id);
                if (selectedData.departments.indexOf(department.id) > 0) {
                    selectedData.departments.splice(selectedData.departments.indexOf(department.id), 1);
                }
                if (selectedData.departmentsUsed.indexOf(department.id) > 0) {
                    selectedData.departmentsUsed.splice(selectedData.departmentsUsed.indexOf(department.id), 1);
                }
            }
        }
        if (selectedData.unselectedTargets[department.id]) {
            delete selectedData.unselectedTargets[department.id];
        }
        if (selectedData.targets[department.id]) {
            delete selectedData.targets[department.id];
        }
        departmentCheckedCache = departmentCheckedCache.filter(onlyUnique).filter(notEmpty);
        departmentCheckedCache.sort();
        departmentPartialCache = departmentPartialCache.filter(onlyUnique).filter(notEmpty);
        departmentPartialCache.sort();
        this.departmentCheckedCache = departmentCheckedCache.filter(() => true);
        this.departmentPartialCache = departmentPartialCache.filter(() => true);
        if (department.childIds && department.childIds.length > 0) {
            if (forceAction == 'select') {
                let childDiff = this.diff(selectedData.departments, department.childIds);
                if (childDiff.added && childDiff.added.length > 0) {
                    selectedData.departments = [...selectedData.departments, ...childDiff.added];
                }
            }
            if (forceAction == 'unselect') {
                let childDiff = this.diff(department.childIds, selectedData.departments);
                selectedData.departments = [];
                if (childDiff.added && childDiff.added.length > 0) {
                    selectedData.departments = childDiff.added;
                }
                selectedData.unselectedDepartments = [...selectedData.unselectedDepartments, ...[department.id], ...department.childIds].filter(onlyUnique).filter(notEmpty);
            }
        }
        selectedData.departments = selectedData.departments.filter(onlyUnique).filter(notEmpty);
        selectedData.departments.sort();
        selectedData.unselectedDepartments = selectedData.unselectedDepartments.filter(onlyUnique).filter(notEmpty);
        selectedData.unselectedDepartments.sort();
        return selectedData;
    };

    toggleDepartment = (department, forceAction) => {
        if (this.isAnyLoading() || !department || !department.id || this.toggleInProcess) {
            return;
        }
        this.toggleInProcess = true;
        let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
        selectedData = this.__toggleDepartment(selectedData, department, forceAction);
        this.setSelectedData(selectedData);
        this.checkSelectedData();
        this.toggleInProcess = false;
    };

    collapseDepartments = () => {
        this.collapseAllDepartments();
    };

    onDepartmentClick = (department, forceAction) => {
        if (this.getReportParams) {
            this.toggleTargets(department);
            return;
        }
        if (this.isReport) {
            this.showReport(department);
        } else {
            this.toggleDepartment(department, forceAction);
        }
    };

    loadDepartments = (fullList = false, page = 1, callback) => {
        if (!this.loading) {
            this.setDepartmentsLoading(true);
            this.error = false;
            let bodyParams = null;
            if (this.buildSearchString().indexOf(this.gettextCatalog.getString('выбранные сотрудники')) > -1) {
                bodyParams = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), this.selectedData.prevData);
            }
            fetch(this.getAdress(fullList, page, bodyParams), {
                headers: {
                    'X-Csrf-Token': window.csrfToken,
                    'Enable-Session': 1,
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: bodyParams ? JSON.stringify(bodyParams) : null
            }).then(response => {
                if (response.ok) {
                    // eslint-disable-next-line
                    let nextLink = response.headers.get('link').split(/\,/g);
                    this.departmentCount = response.headers.get(
                        'X-Pagination-Total-Count');
                    this.nextPage = getNextPage(nextLink);
                }
                return response.json();
            }).then(json => {
                if (json.message) {
                    this.setDepartmentsLoading(false);
                    this.error = json.message;
                    this.scope.$apply();
                    return;
                }
                if (json && json.length > 0) {
                    if (fullList || page === 1) {
                        this.departments = json;
                    } else {
                        let departments = [...this.departments, ...json];
                        this.departments = departments;
                    }
                }
                if (typeof (this.setDepartmentCount) == 'function') {
                    let allDepartmentCount = +this.departmentCount;
                    angular.forEach(this.departments, (department) => {
                        allDepartmentCount = allDepartmentCount + department.departmentsCount;
                    });
                    this.setDepartmentCount(allDepartmentCount);
                }
                this.sortDepartments();
                this.updateMaxCount();
                if (!this.isReportModal && !this.isVulnModal) {
                    this.setOpenedDepartments(angular.copy(this.$localStorage.openedDepartments) || []);
                }
                this.setDepartmentCheckedAndPartial(angular.copy(this.selectedData.departments), angular.copy(this.selectedData.departmentsUsed));
                if (typeof (callback) == 'function') {
                    callback();
                }
                if (this.setReloading) {
                    this.setReloading(false);
                }
                this.setDepartmentsLoading(false);
            }).then(() => {
                if ((this.hashPath === 'selectAll' || this.hashPath === 'sendTraining' || this.hashPath === 'attacksStart') && !this.parentId) {
                    let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy({ all: 1, search: this.selectedData.search, filterData: this.selectedData.filterData }));
                    this.setSelectedData(selectedData);
                }
                if ((this.hashPath === 'selectAll' || this.hashPath === 'sendTraining' || this.hashPath === 'attacksStart') && this.parentId) {
                    this.toggleDepartment(this.linkNames[this.linkNames.length - 1]);
                }
                if (this.hashPath === 'sendTraining') {
                    this.trainingOpen();
                }
                if (this.hashPath === 'attacksStart') {
                    this.setLinkings('createAttack');
                } else {
                    this.setLinkings('');
                }
                this.checkSelectedData();
                if (this.courseId) {
                    this.sortCourses();
                }
                this.scope.$apply();
            }).catch((error) => {
                window.console.log(error);
                this.setDepartmentsLoading(false);
                this.error = true;
                this.scope.$apply();
            });
        }
    };

    loadDepartment = (id) => {
        this.setBreadcrumbLink([
            ...this.linkNames,
            ...this.departments.filter(item => item.id === id)]);
    };

    setSortBy = (str) => {
        if (this.sortBy === str) {
            this.sortReverse = !this.sortReverse;
        } else if (this.sortBy != str) {
            this.sortReverse = false;
        }
        this.sortBy = str;
        if (this.sortableFields.indexOf(this.sortBy) > -1) {
            this.departments = [];
            this.loadDepartments(
                this.departments.length === +this.departmentCount, 1);
        } else {
            this.sortDepartments();
        }
    };

    sortDepartments = () => {
        let str = this.sortBy;
        if (str && this.sortableFields.indexOf(str) === -1) {
            let sorting = !this.loading;
            if (sorting) {
                this.setDepartmentsLoading(true);
            }
            this.departments = this.filter('orderBy')(this.departments, str);
            if (this.sortReverse) {
                this.departments.reverse();
            }
            if (sorting) {
                this.setDepartmentsLoading(false);
            }
        }
    };

    setDepartmentsLoading = (value) => {
        // TODO для паралельных загрузок
        this.loading = value;
        if (typeof (this.setLoading) == 'function') {
            this.setLoading('departmentsLoading', this.loading);
        }
    };

    getFilter = (filter) => {
        this.setSearchData(filter || ['']);
        this.departments = [];
        this.loadDepartments(false, 1);
    };

    edit = (id) => {
        this.modal = this.$injector.instantiate(DepartmentModal);
        this.modal.open(id, this.parentId).then(() => {
            this.departments = [];
            this.loadDepartments(false, 1);
            this.checkSelectedData(false, true);
        }, () => {
        });
    };

    getAdress = (fullList, page, bodyParams) => {
        let onlyFirst = fullList ? 0 : 1;
        let adress = `${window.config.SYSTEM_URL}${window.config.API_URL}/departments/search?fullList=${+fullList}&page=${page}&per-page=10&onlyFirst=${+onlyFirst}`;
        adress += this.getSearchStr();
        adress += this.getSortStr();
        if (this.courseId) {
            adress += this.getCoursesStr();
        }
        if (this.getReportParams || this.isReport) {
            adress += this.getReportParamsStr();
        }
        if (this.parentId) {
            adress += this.getParentIdStr();
        }
        if (this.isVuln) {
            adress += this.getVulnStr();
        }
        if (this.managerId) {
            adress += this.getManagerStr();
        }
        if (this.software) {
            adress += this.getSoftwareStr();
        }
        if (bodyParams || this.getReportParams || this.isVuln || this.managerId) {
            adress += '&actual=1';
        }
        return adress;
    };

    getReportParamsStr = () => {
        let str = this.getReportParams ? JSON.stringify(this.getReportParams()).replace(/{/g, '%7B').replace(/}/g, '%7D') : '{}';
        return `&reportParams=${str}`;
    };

    getSortStr = () => {
        return `&sortBy=${this.sortBy}&sortReverse=${this.sortReverse}`;
    };

    getSearchStr = () => {
        let search = this.buildSearchString();
        return `&search=${window.encodeURIComponent(search)}`;
    };

    getCoursesStr = () => {
        return `&courses=${this.courseId}`;
    };

    getManagerStr = () => {
        return `&managerId=${this.managerId}`;
    };

    getSoftwareStr = () => {
        return `&softwareId=${this.software.id}&vendorId=${this.software.vendorId}`;
    };

    getParentIdStr = () => {
        return `&parentId=${this.parentId}`;
    };

    getVulnStr = () => {
        return '&risk=1';
    };

    $onDestroy () {
        this.unsubscribe();
    }

    collapseAllDepartments = () => {
        let openedDiff = this.diff(this.departmentsOpened || [], angular.copy(this.$localStorage.openedDepartments || []));
        this.departmentsOpened = [];
        this.$localStorage.openedDepartments = openedDiff.added;
    };

    toggleTargets = (department) => {
        let openedDiff = this.diff(this.departmentsOpened || [], angular.copy(this.$localStorage.openedDepartments || []));
        let otherIds = openedDiff.added;
        let index = this.departmentsOpened.indexOf(department.id);
        if (index == -1) {
            if (this.getReportParams || this.isVulnModal || !department.departmentsCount) {
                this.departmentsOpened.push(department.id);
            }
        } else {
            this.departmentsOpened.splice(index, 1);
        }
        this.$localStorage.openedDepartments = this.departmentsOpened.concat(otherIds);
    };

    buildSearchString = () => {
        return buildSearchString(this.selectedData.search);
    };

    onDepartmentNameClick = ($event, department) => {
        if (this.isAnyLoading()) {
            $event.stopPropagation();
        } else {
            if (this.getReportParams || this.isVulnModal) {
                $event.stopPropagation();
                this.toggleTargets(department);
                return;
            }
            if (department && department.departmentsCount > 0) {
                if (this.isDisclosed) {
                    $event.stopPropagation();
                    this.loadDepartment(department.id);
                }
            } else if (!this.isReport) {
                $event.stopPropagation();
                this.toggleTargets(department);
            }
        }
    };

    reportPage = (department) => {
        this.toggleTargets(department);
    };

    getPartial = (option) => {
        this.setDepartmentCheckedAndPartial(option.checked || this.departmentCheckedCache, option.partial || this.departmentPartialCache);
    };

    checkSelectedData = (reverse = false, ignoreCache = false) => {
        if (this.rebuildLoading || !this.isSelectable) {
            return;
        }
        let ctrl = this;
        const { $localStorage, scope } = this;
        let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(ctrl.selectedData));
        if (buildSearchString(this.selectedData.search).indexOf(this.gettextCatalog.getString('выбранные сотрудники')) > -1) {
            selectedData.filterData = this.selectedData.filterData || this.selectedData.prevData || null;
        } else {
            delete selectedData.filterData;
        }
        let selectedDataJson = buildSelectedDataCacheString(selectedData, reverse);
        let clearAll = selectedData.unselectAll ? true : false;
        if (selectedDataJson != $localStorage.selectedDataCacheString || ignoreCache) {
            rebuildSelectedData(selectedData, {
                mode: 'departments',
                risk: ctrl.isVuln ? 1 : 0,
                managerId: $localStorage.openedManager,
                scope: scope,
                courseId: this.courseId || null,
                setLoadingFunction: (loading) => {
                    if (typeof (ctrl.setLoading) == 'function') {
                        ctrl.setLoading('reBuildLoading', loading);
                    }
                },
                setSelectedDataFunction: (newSelectedData) => {
                    if (clearAll && newSelectedData.selectedCount.targets == 0) {
                        newSelectedData = angular.copy(EMPTY_SELECTED_DATA);
                    } else {
                        newSelectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(newSelectedData));
                    }
                    newSelectedData.search = angular.copy(ctrl.selectedData.search);
                    $localStorage.selectedDataCacheString = buildSelectedDataCacheString(angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(newSelectedData)), reverse);
                    ctrl.setSelectedData(newSelectedData);
                    scope.$apply();
                },
                setDepartmentCheckedAndPartialFunction: (checked, partial) => {
                    ctrl.setDepartmentCheckedAndPartial(checked, partial);
                },
                callbackFunction: () => {
                    scope.$apply();
                },
                reverse: reverse
            });
        }
    };

    setOpenedDepartments = (openedIds) => {
        if (openedIds.length > 0) {
            let currentIds = this.departments.map(d => d.id);
            openedIds = openedIds.filter((i) => {
                return currentIds.indexOf(i) > -1;
            });
        }
        this.departmentsOpened = openedIds;
    };

    setDepartmentCheckedAndPartial = (checked = [], partial = []) => {
        if ((checked && checked.length > 0) || (partial && partial.length > 0)) {
            let parent = this.linkNames[this.linkNames.length - 1] && this.linkNames[this.linkNames.length - 1] ? this.linkNames[this.linkNames.length - 1] : null;
            let departments = angular.copy(parent && angular.isArray(parent.childs) ? parent.childs : this.departments);
            if (!angular.isArray(departments)) {
                departments = [];
            }
            if (parent) {
                let parentDepartment = departments.find((item) => {
                    return item.id == parent.id;
                });
                if (!parentDepartment) {
                    departments.unshift(parent);
                }
            }
            angular.forEach(departments, (department) => {
                if (department.childIds && department.childIds.length > 0) {
                    let childDiff = this.diff(checked, department.childIds);
                    if (checked.indexOf(department.id) > -1 && childDiff.added && childDiff.added.length > 0) {
                        partial.push(department.id);
                    }
                    if (checked.indexOf(department.id) == -1 && childDiff.common && childDiff.common.length > 0) {
                        if (checked.added && checked.added.length > 0) {
                            if (partial.indexOf(department.id) == -1) {
                                partial.push(department.id);
                            }
                        } else {
                            if (department.ownTargets > 0) {
                                if (checked.indexOf(department.id) == -1) {
                                    checked.push(department.id);
                                }
                            } else {
                                if (partial.indexOf(department.id) == -1) {
                                    partial.push(department.id);
                                }
                            }
                        }
                    }
                }
            });
        }
        if (angular.isArray(checked)) {
            checked.sort();
            this.departmentCheckedCache = angular.copy(checked);
        }
        if (angular.isArray(partial)) {
            partial.sort();
            this.departmentPartialCache = angular.copy(partial);
        }
    };

    isDepartmentChildChecked = (department, checked, partial) => {
        if (department.childs && department.childs.length > 0 && department.targetsCount > 0) {
            let subResult = true;
            AnyFor(department.childs).for((departmentChild) => {
                let departmentChildChecked = this.isDepartmentChildChecked(departmentChild, checked, partial);
                if (!subResult && checked.indexOf(departmentChild.id) > -1 || departmentChildChecked) {
                    subResult = true;
                }
                if (subResult && ((checked.indexOf(departmentChild.id) == -1 && departmentChild.ownTargetsCount > 0) || partial.indexOf(departmentChild.id) > -1)) {
                    subResult = false;
                    return true;
                }
                if (subResult && !departmentChildChecked) {
                    subResult = false;
                    return true;
                }
            });
            return subResult;
        } else {
            return checked.indexOf(department.id) > -1;
        }
    };

    isDepartmentChildPartial = (department, checked, partial, departmentChecked = false) => {
        if (department.childs && department.childs.length > 0) {
            let subResult = false;
            let notCheckedCount = 0;
            let checkedCount = 0;
            AnyFor(department.childs).for((departmentChild) => {
                if (!subResult && partial.indexOf(departmentChild.id) > -1) {
                    subResult = true;
                    checkedCount++;
                    return true;
                }
                let departmentChildPartial = this.isDepartmentChildPartial(departmentChild, checked, partial, departmentChecked);
                if (!subResult && departmentChildPartial) {
                    subResult = true;
                    checkedCount++;
                    return true;
                }
                if (!subResult && ((notCheckedCount > 0 && checked.indexOf(departmentChild.id) > -1) || (checkedCount > 0 && checked.indexOf(departmentChild.id) == -1))) {
                    subResult = true;
                    checkedCount++;
                    return true;
                }
                if (checked.indexOf(departmentChild.id) > -1) {
                    checkedCount++;
                } else {
                    notCheckedCount++;
                }
            });
            return subResult;
        } else {
            return partial.indexOf(department.id) > -1;
        }
    };

    showReport = (department) => {
        if (this.appCtrl && this.appCtrl.hasAccess(4, 'report_department')) {
            let params = {
                id: department.id,
                title: department.name,
                type: 'department',
                total: department.searchTargets,
                fromReport: true
            };
            this.modal = this.$injector.instantiate(ReportModal);
            this.modal.open(params).then(() => {
            }, () => {
            });
        }
    };
}

DepartmentsTableController.$inject = [
    '$localStorage',
    '$scope',
    'gettextCatalog',
    '$sessionStorage',
    '$ngRedux',
    '$injector',
    '$filter',
    '$location'
];

export {
    DepartmentsTableController
};
