import style from './style.modules.css';
import { SsoProfileModal } from '../ssoProfileModal';
import { hasAccess } from '../../reselect';
import { deleteSSO } from '../../toolkit/think';

class SettingsSsoProfileController {
    constructor (gettextCatalog, injector, $ngRedux) {
        this.style = style;
        this.injector = injector;
        this.gettextCatalog = gettextCatalog;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { deleteSSO })(this);
    }

    mapStateToThis = (state) => {
        return {
            licenseValid: state.auth.auth.license.isValid,
            userSaveAccess: hasAccess(state, { sectionId: 101, rightName: 'user_save' }),
            deleteStatus: state.ssoProfile.modalStatus
        };
    }

    $onInit = () => {};

    openProfile = (profile = null) => {
        this.modal = this.injector.instantiate(SsoProfileModal);
        this.modal.open(profile).then(() => { },
            () => { }
        );
    }

    deleteProfile = (id) => {
        if (!confirm(this.gettextCatalog.getString('Вы действительно хотите удалить профиль?'))) return;
        this.deleteSSO(id);
    }
}

SettingsSsoProfileController.$inject = [
    'gettextCatalog',
    '$injector',
    '$ngRedux'
];

export {
    SettingsSsoProfileController,
};
