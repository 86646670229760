import angular from 'angular';

export default (search, prevSearch) => {
    let searchData = angular.isArray(search) && search.length > 0 ? search : [''];
    if (!angular.isArray(search) && search) {
        searchData = [search];
    }
    if (prevSearch) {
        let prevSearchData = prevSearch.split(' + ');
        searchData = angular.isArray(prevSearchData) && prevSearchData.length > 0 ? prevSearchData : [''];
    }
    if (searchData && searchData.length > 0) {
        let filtered = searchData.filter((el) => {
            return el;
        });
        return filtered.join(' + ').trim();
    }
    return '';
};
