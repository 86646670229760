import dashboardSceletonTemplate from './view.html';
import { DashboardSceletonController } from './controller';

const DashboardSceletonComponent = {
    template: dashboardSceletonTemplate,
    controller: DashboardSceletonController,
    controllerAs: '$',
    bindings: {
        itemTitle: '<',
        chartId: '<',
        accessDashboardView: '<',
        accessDashboardView2: '<',
        accessDashboardView3: '<',
        accessDashboardView4: '<',
    },
};

export {
    DashboardSceletonComponent
};
