import { LayoutController } from './layout';

class InnerLayoutController extends LayoutController {
    constructor($state, $location, AuthService, DashboardService) {
        super($state, $location, AuthService, DashboardService);
    }
}

export {
    InnerLayoutController
};
