import React, { useContext, useState, useEffect, useRef } from 'react';
import { GettextContext, InjectorContext } from 'rootReact/context';
import { useSelector, useDispatch } from 'react-redux';
import { LdapProfileModal } from 'root/assets/webpack/src/js/components/ldapProfileModal';
import { deleteLdapProfile, fetchLdapProfiles, fetchDaemonsMessage } from 'root/assets/webpack/src/js/toolkit/think'
import { setProfile, editProfile, setLinkings } from 'root/assets/webpack/src/js/toolkit/actions';
import SettingsHeader from 'rootReact/components/SettingsSectionHeader';
import SettingsTable from 'rootReact/components/SettingsTable';
import { ldapSchema } from './SettingsLdapSchema.jsx';
import FetchApi from '../../REST';
import SaveFile from 'rootReact/components/SaveFile';
import { hasAccess } from 'root/assets/webpack/src/js/reselect';

function SettingsLdap({ licenseIsValid }) {
    const gettext = useContext(GettextContext);
    const injector = useContext(InjectorContext);
    const myRef = useRef(null);
    const learningType = useSelector(state => state.learningConfig.config.type);
    const hashPath = useSelector(state => state.linking.link);
    const profileFirstLoading = useSelector(state => state.ldapProfiles.firstLoading);
    const profileLoading = useSelector(state => state.ldapProfiles);
    const profiles = useSelector(state => state.ldapProfiles.ldapProfiles);
    const ldapSave = useSelector(state => hasAccess(state, { sectionId: 10, rightName: 'ldap_save' }));
    const ldapDelete = useSelector(state => hasAccess(state, { sectionId: 10, rightName: 'ldap_delete' }));
    const ldapReportImport = useSelector(state => hasAccess(state, { sectionId: 10, rightName: 'report_import' }));
    const targetImport = useSelector(state => hasAccess(state, { sectionId: 1, rightName: 'target_ldap' }))
    const [syncStatus, setSyncStatus] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchLdapProfiles());
    }, [])

    useEffect(() => {
        if (hashPath === 'ldapSaveField') {
            const executeScroll = () => myRef.current.scrollIntoView(false);
            executeScroll();
            openModal();
            dispatch(setLinkings(''));
        }
        if (hashPath === 'ldapField') {
            const executeScroll = () => myRef.current.scrollIntoView(false);
            executeScroll();
            dispatch(setLinkings(''));
        }
    },[hashPath])

    const files = [
        [gettext.getString('журнал синхронизаций'), '/v2/ldap-imports/report', 'ldap[data].csv']
    ];

    const getHeaderLink = () => {
        return (
            <div style={{display: 'flex'}}>
                <a onClick={() => licenseIsValid && ldapSave && openModal()} className={`settings_path-right ${!licenseIsValid || !ldapSave ? 'nolink' : ''}`}>{gettext.getString('добавить')}</a>
                <SaveFile disabled={!ldapReportImport} files={files}/>
            </div>
        );
    };

    const openModal = async (profile = 0) => {
        console.log(1);
        let modal = injector.instantiate(LdapProfileModal);
        let result = await modal.open(profile, learningType ? 0 : 1);
        if (result && !profile) {
            dispatch(setProfile(result));
        }
        if (result && profile) {
            dispatch(editProfile(result));
        }
    };

    const handleRow = (id) => {
        openModal(id);
    };

    const iconDelete = (e, id) => {
        e.stopPropagation();
        if (!ldapDelete) return;
        if (confirm(gettext.getString('Удалить профиль LDAP?'))) {
            dispatch(deleteLdapProfile(id));
        }
    };

    const syncLdap = async (e, id) => {
        e.stopPropagation();
        setSyncStatus({...syncStatus, [id]: {loading: true}});
        let fetchApi = new FetchApi(`/ldap-profiles/${id}/sync`);
        let response = await fetchApi.get();
        if (response.isError) {
            setSyncStatus({...syncStatus, [id]: {
                    error: true, loading: false}});
            setTimeout(() => {setSyncStatus({...syncStatus, [id]: {loading: false}})}, 3000);
            return;
        }
        setSyncStatus({...syncStatus, [id]: {loading: false}});
        window.LdapCtrl.getImports();
    }

    return (
        <div id={'ldap-save-field'} ref={myRef}>
            <SettingsHeader title={gettext.getString('Профили LDAP')} right>{getHeaderLink()}</SettingsHeader>
            <div className={'settings_item'} id={'ldap-field'}>
                <SettingsTable header={[
                    { name: gettext.getString('Название') },
                    { name: gettext.getString('URL') },
                    { name: gettext.getString('DN') },
                    { name: gettext.getString('Синхронизация') },
                    { name: '' },
                    { name: '' }
                ]}
                body={profiles}
                assets={{ empty: gettext.getString('Нет профилей') }}
                cellSchema={
                    ldapSchema({
                        iconHandle:[iconDelete],
                        handleRow,
                        disabled: !licenseIsValid || !ldapDelete,
                    }, syncLdap, syncStatus, targetImport)}
                ></SettingsTable>
            </div>
        </div>
    );
}

export default SettingsLdap;
