import style from './style.modules.css';
import angular from 'angular';

class LdapDepartmentLinkController {
    constructor($scope) {
        this.scope = $scope;
        this.links = [];
        this.style = style;
    }

    $onInit = () => {
        this.scope.$watch((scope) => {
            return scope.$.chosenDepartmentName;
        }, (newDepartment) => {
            let links = newDepartment.split(' / ');
            this.links = [];
            let i = 0;
            while (i < links.length) {
                let currentLinks = angular.copy(links);
                currentLinks.splice(i + 1, links.length - i);
                let name = currentLinks[i];
                this.links.push({ name: name, fullName: currentLinks.join(' / ') });
                i++;
            }
        });
    };

    clearName = () => {
        this.departmentName = '';
    };
}

LdapDepartmentLinkController.$inject = [
    '$scope',
    '$ngRedux'
];

export {
    LdapDepartmentLinkController,
};
