export function defaultConfig (actions, disable) {
    const that = this;
    let config = [
        {
            title: that.gettextCatalog.getString('Отменить'),
            action: () => {
                actions[0]();
            },
            get disabled() {
                return disable[0]();
            },
            class: 'default'
        },
        {
            title: that.gettextCatalog.getString('Сохранить'),
            action: () => {
                actions[1]();
            },
            get disabled() {
                return disable[1]();
            },
            class: 'primary'
        }
    ];
    return config;
}
