import appTemplate from './views/app.html';
import layoutTemplate from './views/layout/layout.html';

import departmentsTemplate from './views/departments.html';
import campaignsTemplate from './views/campaigns.html';
import risksTemplate from './views/risks.html';
import reportsTemplate from './views/reports.html';
import settingsTemplate from './views/settings.html';
import loginTemplate from './views/login.html';
import errorTemplate from './views/error.html';
// import practicePlanTemplate from './views/practicePlan.html';
import dashboardGraphTemplate from './views/dashboardGraph.html';

export default function routing(
    $urlRouterProvider,
    $locationProvider,
    $stateProvider
) {
    $locationProvider.html5Mode({
        enabled: true,
        requireBase: false
    });
    $urlRouterProvider.otherwise('/login');

    $stateProvider
        .state('app', {
            abstract: true,
            url: '',
            controller: 'AppController as appCtrl',
            template: appTemplate,
        })
        .state('app.index', {
            abstract: true,
            url: '',
            controller: 'LayoutController as ctrl',
            template: layoutTemplate,
        })
        .state('app.login', {
            url: '/login',
            template: loginTemplate,
            controller: 'LoginController as loginCtrl',
        })
        // .state('app.index.trainings', {
        //     url: '/trainings',
        //     template: practicePlanTemplate,
        //     controller: 'PracticePlan as $',
        // })
        .state('app.error', {
            url: '/error',
            template: errorTemplate,
            controller: 'ErrorController as errorCtrl',
        })
        .state('app.index.dashboard', {
            url: '/dashboard',
            template: dashboardGraphTemplate,
            controller: 'DashboardGraph as $',
        })
        .state('app.index.targets', {
            url: '/targets',
            template: departmentsTemplate,
            controller: 'DepartmentsController as departmentsCtrl',
        })
        .state('app.index.departments', {
            url: '/targets/:id',
            template: departmentsTemplate,
            controller: 'DepartmentsController as departmentsCtrl',
        })
        .state('app.index.attacks', {
            url: '/attacks',
            template: campaignsTemplate,
            controller: 'CampaignsController as campaignsCtrl'
        })
        .state('app.index.risks', {
            url: '/risks',
            template: risksTemplate,
            controller: 'RisksController as risksCtrl'
        })
        .state('app.index.subRisks', {
            url: '/risks/:id',
            template: risksTemplate,
            controller: 'RisksController as risksCtrl',
        })
        .state('app.index.reports', {
            url: '/reports',
            template: reportsTemplate,
            controller: 'ReportsController as reportsCtrl'
        })
        .state('app.index.subReports', {
            url: '/reports/:id',
            template: reportsTemplate,
            controller: 'ReportsController as reportsCtrl',
        })
        .state('app.index.settings', {
            url: '/settings',
            template: settingsTemplate,
            controller: 'SettingsController as settingsCtrl'
        });
}
