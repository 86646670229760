import React from 'react';

function FaIcon({ type, text = null, disable, testId }) {
    const getIcon = () => {
        switch (type) {
            case 'error':
                return (
                    <div className={'text-danger'}>
                        <span className={'fa-icon-react fa fa-exclamation-triangle'}> </span>
                        {text}
                    </div>
                );
            case 'pending':
                return (
                    <div>
                        <span className={'fa-icon-react fa fa-circle-o-notch fa-spin'}> </span>
                        {text}
                    </div>
                );
            case 'success':
                return (
                    <div data-testid={testId}>
                        <span className={'fa-icon-react fa fa-check-circle-o'}> </span>
                        {text}
                    </div>
                );
            case 'copy':
                return (
                    <span>
                        <span className={`ico-copy-manifest react-icon_copy ${disable ? 'react-icon_copy__disabled' : '' }`}></span>
                    </span>
                );
        }
    };

    return getIcon();
}

export default FaIcon;
