export function classNames(cls, mods = {}, additional = []) {
    return [
        cls,
        ...additional.filter(Boolean),
        ...Object.entries(mods)
            .filter(([value]) => Boolean(value))
            .map(([className]) => className),
    ].join(' ');
}

/*
example
classNames('app', {hovered: true, selectable: false, color: 'red'}, ['paddingWith'])
const obj: Mods = {
    'hovered': false,
    'seletable': 'ttt',
}
*/
