import sectionHeaderTemplate from './view.html';

import { SectionHeaderController } from './controller';

const SectionHeaderComponent = {
    template: sectionHeaderTemplate,
    controller: SectionHeaderController,
    controllerAs: '$',
    scope: true,
    bindings: {
        leftText: '<',
        rightText: '<'
    },
};

export {
    SectionHeaderComponent,
};
