import saveFileSelectTemplate from './view.html';

import { SaveFileSelectController } from './controller';

const SaveFileSelectComponent = {
    template: saveFileSelectTemplate,
    controller: SaveFileSelectController,
    controllerAs: '$',
    bindings: {
        reports: '<',
    },
};

export {
    SaveFileSelectComponent,
};
