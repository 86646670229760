class DepartmentBreadcrumbController {
    constructor() {
        this.appCtrl = window.appCtrl;
    }

    go = () => {
        if (this.breadcrumb && this.breadcrumb.id && this.prefix) {
            this.appCtrl.go(this.prefix + '/' + this.breadcrumb.id);
        }
    };
}

DepartmentBreadcrumbController.$inject = [];

export {
    DepartmentBreadcrumbController,
};
