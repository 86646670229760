import { AUTH_SERVICE, DEFAULT_CAMPAIGNS, DEFAULT_COURSES, DEFAULT_DEPARTMENTS, DEFAULT_REPORTS, DEFAULT_RISKS } from '../const';
import angular from 'angular';
import moment from 'moment';
import { UAParser } from 'ua-parser-js';
import styleLayout from '../../styles/layout.modules.css';
import { isAuth } from '../reselect/auth';
import { fetchVariables, fetchAuth, fetchLang, fetchDashboardParams } from '../toolkit/think';
import { setSelectedData, clearSelectedData, setAuthInfo } from '../toolkit/actions';

moment.updateLocale('ru', {
    monthsShort: {
        format: 'янв_фев_мар_апр_мая_июня_июля_авг_сен_окт_ноя_дек'.split('_'),
        standalone: 'янв_фев_март_апр_май_июнь_июль_авг_сен_окт_ноя_дек'.split('_')
    }
});

class AppController {
    constructor ($location, $anchorScroll, gettextCatalog, gettext, $timeout, $cookies, $window, $localStorage, $state, $scope, $ngRedux, tmhDynamicLocale, AuthService) {
        this.config = window.config;
        this.now = new Date;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.gettextCatalog = gettextCatalog;
        this.gettextCatalog.setStrings('en', window.translations.en);
        this.gettextCatalog.setStrings('it', angular.merge(window.translations.en, window.translations.it));
        this.gettextCatalog.setStrings('de', angular.merge(window.translations.en, window.translations.de));
        this.gettext = gettext;
        this.$timeout = $timeout;
        this.$cookies = $cookies;
        this.$localStorage = $localStorage;
        this.$scope = $scope;
        this.$state = $state;
        this.tmhDynamicLocale = tmhDynamicLocale;
        this.AuthService = AuthService;
        this.headerScrollPosition = 0;
        this.styleLayout = styleLayout;
        this.defaultLangCode = window.config.SYSTEM_LANGUAGE;
        this.languages = {
            en: 'English',
            it: 'Italiano',
            ru: 'Русский',
            // de: 'Deutsch'
        };
        this.uaParser = new UAParser;
        window.twoDigits = (d) => {
            if (0 <= d && d < 10) {
                return '0' + d.toString();
            }
            if (-10 < d && d < 0) {
                return '-0' + (-1 * d).toString();
            }
            return d.toString();
        };
        Date.prototype.toMysqlFormat = () => {
            return this.getUTCFullYear() + '-' + window.twoDigits(1 + this.getUTCMonth()) + '-' + window.twoDigits(this.getUTCDate()) + ' ' + window.twoDigits(this.getUTCHours()) + ':' + window.twoDigits(this.getUTCMinutes()) + ':' + window.twoDigits(this.getUTCSeconds());
        };
        Array.prototype.diff = function (a) {
            return this.filter(function (i) {
                return a.indexOf(i) < 0;
            });
        };
        this.projectName = window.config.PROJECT_NAME;
        this.projectFooterName = window.config.PROJECT_FOOTER_NAME;
        this.projectDescription = window.config.PROJECT_DESCRIPTION;
        this.projectUrl = window.config.PROJECT_URL;
        this.supportEmail = window.config.SUPPORT_EMAIL;
        this.supportEmailSubject = window.config.SUPPORT_EMAIL_SUBJECT;
        this.supportPhone = window.config.SUPPORT_PHONE;
        this.supportPhonePure = this.supportPhone ? this.supportPhone.replace(/\D+/gim, '') : this.supportPhone;
        this.langCode = this.$cookies.get('langCode');
        if (!this.langCode) {
            let lang = $window.navigator.language || $window.navigator.userLanguage;
            angular.forEach(this.languages, (langName, langCode) => {
                if (lang.indexOf(langCode) == 0) {
                    this.langCode = langCode;
                }
            });
        }
        if (!this.langCode || typeof (this.languages[this.langCode]) == 'undefined') {
            this.langCode = this.defaultLangCode;
        }
        if (!this.langCode) {
            this.langCode = 'en';
        }
        if (window.config['PROJECT_URL_' + this.langCode.toUpperCase()]) {
            this.projectUrl = window.config['PROJECT_URL_' + this.langCode.toUpperCase()];
        }
        this.setLanguage(this.langCode);
        this.$localStorage.campaignData = null;
        this.$localStorage.reportData = null;
        this.userInfoLoading = false;
        window.appCtrl = this;
        this.browsersSupport = [
            { name: 'Chrome', version: 66 },
            { name: 'IE', version: 10 },
            { name: 'Edge', version: 15 },
            { name: 'Firefox', version: 59 },
            { name: 'Opera', version: 12.10 },
            { name: 'Safari Mac OS', version: 10.1 },
            { name: 'Safari iOS', version: 10.3 },
            { name: 'Android Browser', version: 4.4 }
        ];
        this.browserList = [
            { name: 'Chrome', viewName: 'Google Chrome' },
            { name: 'Firefox', viewName: 'Mozilla Firefox' },
            { name: 'Safari', viewName: 'Safari' },
            { name: 'Edge', viewName: 'Microsoft Edge' },
            { name: 'IE', viewName: 'Internet Explorer' }
        ];
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis,
            {
                fetchAuth,
                setAuthInfo,
                setSelectedData,
                clearSelectedData,
                fetchVariables,
                fetchLang,
                fetchDashboardParams,
            })(this);
        this.csrfToken = null;
        //this.gettextCatalog.debug = config.DEBUG;
        //this.gettextCatalog.showTranslatedMarkers = true;
        this.manualLdapSyncLoading = false;
    }

    mapStateToThis = (state) => {
        return {
            isAuth: isAuth(state)
        };
    }

    setLanguage = (langCode) => {
        let options = {};
        options.headers = {};
        options.headers['X-Csrf-Token'] = window.csrfToken;
        let oldLangCode = this.langCode;
        if (oldLangCode != langCode) {
            this.inProcessLang = true;
            this.$timeout(() => {
                this.inProcessLang = false;
            }, 500);
        }
        this.langCode = langCode;
        this.$cookies.put('langCode', langCode);
        if (oldLangCode != langCode) {
            fetch(window.config.SYSTEM_URL + window.config.API_URL + '/cache/clean', options).then(() => {
                window.location.reload();
            });
        } else {
            this.gettextCatalog.setCurrentLanguage(langCode);
            this.tmhDynamicLocale.set(langCode);
        }
    };

    getBrowserList = (exclude) => {
        let browsers = this.browserList.filter((browser) => {
            return browser.name !== exclude ? browser : null;
        });
        return browsers.reduce((accumulator, currentValue, index) => {
            if (index === 0) {
                return accumulator + currentValue.viewName + ',\r\n';
            }
            if (index === browsers.length - 2) {
                return accumulator + currentValue.viewName + ` ${this.gettextCatalog.getString('или')} `;
            }
            if (index === browsers.length - 1) {
                return accumulator + currentValue.viewName + '.';
            } else {
                return accumulator + currentValue.viewName + ', ';
            }
        }, '');
    };

    detectBrowser = () => {
        let browsDetect = null;
        let uaParse = this.uaParser.getBrowser();
        this.cliBrowser = uaParse.name;
        let uaOS = this.uaParser.getOS();
        this.browsersSupport.forEach(item => {
            if (uaParse.name === 'Safari' && uaOS.name === 'iOS' && item.name === 'Safari iOS') {
                browsDetect = {
                    oldBrow: item.version > parseFloat(uaParse.version, 10),
                    message: `${uaParse.name} ${parseInt(uaParse.version, 10)}`
                };
            }
            if (uaParse.name === 'Safari' && uaOS.name === 'Mac OS' && item.name === 'Safari Mac OS') {
                browsDetect = {
                    oldBrow: item.version > parseFloat(uaParse.version, 10),
                    message: `${uaParse.name} ${parseInt(uaParse.version, 10)}`
                };
            }
            if (item.name === uaParse.name) {
                browsDetect = {
                    oldBrow: item.version > parseFloat(uaParse.version, 10),
                    message: `${uaParse.name} ${parseInt(uaParse.version, 10)}`
                };
            }
        });
        return browsDetect;
    };

    checkAuth = () => {
        const { $localStorage } = this;
        $localStorage.userInfo = '';
        let browserSupport = this.detectBrowser();
        if (browserSupport && browserSupport.oldBrow) {
            this.browserError = browserSupport.message;
            window.errorMessage = this.browserError;
        }
        if (!this.userInfoLoading) {
            this.userInfoLoading = true;
            this.fetchAuth();
            this.AuthService.getUserInfo().$promise.then((userInfo) => {
                if (userInfo) {
                    if (userInfo.email) {
                        if (userInfo.license && userInfo.license.validTill) {
                            userInfo.license.validTill = moment(userInfo.license.validTill, 'YYYY-MM-DD HH:mm:ss').toDate();
                        }
                        $localStorage.userInfo = userInfo;
                        if (userInfo.lastMotivation && userInfo.lastMotivation.updatedAt) {
                            userInfo.lastMotivation.updatedAt = userInfo.lastMotivation.updatedAt * 1000;
                        }
                        this.setAuthInfo(userInfo);
                        this.fetchLang();
                        if (window.errorMessage) {
                            this.go('error');
                        } else {
                            if (this.$location.path() == '/login') {
                                this.go(userInfo.defaultPage || 'targets');
                            }
                        }
                    } else {
                        if (window.errorMessage) {
                            this.go('error');
                        } else {
                            $localStorage.userInfo = null;
                            this.go('login');
                        }
                    }
                    if (window.errorMessage) {
                        this.go('error');
                    }
                }
                this.userInfoLoading = false;
            }, () => {
                this.userInfoLoading = false;
                if (window.errorMessage) {
                    this.go('error');
                } else {
                    $localStorage.userInfo = null;
                    this.go('login');
                }
            });
        }
    };

    loadCsrf = () => {
        this.AuthService.getCsrf().$promise.then((csrfToken) => {
            if (csrfToken.token) {
                this.csrfToken = csrfToken.token;
                window.csrfToken = csrfToken.token;
            }
            if (!this.csrfToken) {
                this.loadCsrf();
            }
        }, () => {
            this.go('error');
        });
    };

    $onInit () {
        this.loadCsrf();
        this.$scope.$watch(scope => {
            return scope.appCtrl.csrfToken;
        }, (newValue) => {
            if (newValue) {
                this.afterCsrf();
                this.fetchVariables();
            }
        });
    }

    afterCsrf = () => {
        this.$scope.$watch(scope => {
            return scope.appCtrl.headerScrollPosition;
        }, (position) => {
            this.dashboardSmall = (position <= (this.getDashboardBottom() - 131));
        });
        this.checkAuth();
        this.$scope.$watch(() => this.isAuth, (n, o) => {
            if (n && o && n.email != o.email) {
                this.checkAuth();
                this.fetchDashboardParams();
            }
        }, true);
        this.clearSelectedData();
        this.logoMode = window.config.PROJECT_LOGO_SVG ? true : false;
        this.logoModeTop = window.config.PROJECT_LOGO_TOP || 0;
        this.logoModeHeight = window.config.PROJECT_LOGO_HEIGHT || 70;
        this.setDefaultLocalStorage('courses', DEFAULT_COURSES);
        this.setDefaultLocalStorage('reports', DEFAULT_REPORTS);
        this.setDefaultLocalStorage('risks', DEFAULT_RISKS);
        this.setDefaultLocalStorage('departments', DEFAULT_DEPARTMENTS);
        this.setDefaultLocalStorage('campaigns', DEFAULT_CAMPAIGNS);
    };

    toTheTop = function () {
        this.$location.hash('top');
        this.$anchorScroll();
    };

    setDefaultLocalStorage = (field, defaultField) => {
        if (!this.$localStorage[field]) {
            this.$localStorage[field] = defaultField;
        }
    };

    go = (path) => {
        if (window.errorMessage) {
            if (this.$location.path() != '/error') {
                this.$location.path('error');
                this.$location.hash('');
            }
        } else {
            this.$location.path(path);
            this.$location.hash('');
        }
    };

    getVersionDashboard = () => {
        return +this.$cookies.get('dashVer');
    };

    getDashboardBottom = () => {
        let version = this.$cookies.get('dashVer');
        return version == 2 ? 300 : 210;
    };

    getCsrfToken = () => {
        return window.csrfToken;
    };

    hasAccess = (sectionId, rightName) => {
        const { $localStorage } = this;
        if (this.isAuth) {
            if ((rightName == 'report_anonym_stat' && !$localStorage.userInfo.anonymousStatisticsEnabled) ||
                (sectionId == 8 && !$localStorage.userInfo.license.features.manageCourse)) {
                return false;
            }
            if ($localStorage.userInfo.rights && $localStorage.userInfo.rights[sectionId]) {
                return $localStorage.userInfo.rights[sectionId].indexOf(rightName) > -1 ? true : false;
            }
            if (!$localStorage.userInfo.adminId) {
                return true;
            }
        }
        return false;
    };
}

AppController.$inject = [
    '$location',
    '$anchorScroll',
    'gettextCatalog',
    'gettext',
    '$timeout',
    '$cookies',
    '$window',
    '$localStorage',
    '$state',
    '$scope',
    '$ngRedux',
    'tmhDynamicLocale',
    AUTH_SERVICE
];

export {
    AppController
};
