import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';

const initialState = {
    status: 'idle',
    deleteId: null,
    notification: [],
    error: false
};

export const fetchNotification = createAsyncThunk(
    'systemNotification/status',
    async () => {
        const fetchApi = new FetchApi('/notifications');
        const response = await fetchApi.get();
        return response;
    }
);

export const systemNotificationToggle = createAsyncThunk(
    'systemNotification/toggle',
    async ({id, params}) => {
        const fetchApi = new FetchApi(`/notifications`);
        const answer = await fetchApi.put(id, params);
        if (!answer.err) {
            const response = await fetchApi.get();
            return response;
        }
        return answer;
    }
);

export const systemNotificationPut = createAsyncThunk(
    'systemNotification/put',
    async ({id, params}) => {
        const fetchApi = new FetchApi(`/notifications`);
        const response = await fetchApi.put(id, params);
        return response;
    }
);

const systemNotificationSlice = createSlice({
    name: 'systemNotification',
    initialState,
    reducers: {
        setDefaultSystemNotification (state) {
            state = initialState;
        }
    },
    extraReducers: {
        [fetchNotification.fulfilled]: (state, { payload }) => {
            state.notification = payload;
            state.status = 'load';
        },
        [fetchNotification.pending]: (state) => {
            state.status = 'pending';
        },
        [fetchNotification.rejected]: (state) => {
            state.status = 'rejected';
        },
        [systemNotificationToggle.fulfilled]: (state, { payload }) => {
            state.notification = payload;
        },
        [systemNotificationToggle.pending]: () => {},
        [systemNotificationToggle.rejected]: () => {},
        [systemNotificationPut.fulfilled]: (state, { payload }) => {
            state.notification = state.notification.map(item => {
                if (item.id === payload.id) return payload;
                return item;
            });
            state.modalStatus = 'load';
        },
        [systemNotificationPut.pending]: (state) => {
            state.modalStatus = 'pending';
        },
        [systemNotificationPut.rejected]: (state) => {
            state.modalStatus = 'rejected';
        },
    }
});

export const {setDefaultSystemNotification} = systemNotificationSlice.actions;
export default systemNotificationSlice.reducer;

