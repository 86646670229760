import angular from 'angular';
import style from './style.modules.css';
import { setSearchData } from '../../toolkit/actions';

class SearchFieldController {
    constructor($ngRedux, $localStorage, $scope) {
        this.$localStorage = $localStorage;
        this.scope = $scope;
        this.search = [''];
        this.style = style;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { setSearchData })(this);
    }

    $onInit = () => {};

    $onDestroy() {
        this.unsubscribe();
    }

    mapStateToThis(state) {
        return {
            selectedData: state.selectedData.selectedData,
        };
    }

    clearSearch = (searchKey) => {
        searchKey = +searchKey;
        let search = angular.copy(this.selectedData.search || ['']);
        if (searchKey == -1) {
            search = [''];
        } else {
            search = search.filter((item, i) => {
                return searchKey !== i;
            });
            if (search.length == 0) {
                search = [''];
            }
        }
        this.search = angular.copy(search || ['']);
        this.setSearchData(this.search);
        if (this.setReloading) {
            this.setReloading(true);
        }
    };

    setSearch = () => {
        this.setSearchData(this.search.filter(() => true));
    };
}

SearchFieldController.$inject = [
    '$ngRedux',
    '$localStorage',
    '$scope',
];

export {
    SearchFieldController,
};
