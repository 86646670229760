class SenderDomainsService {
    constructor (YiiModel, $resource) {
        this.$resource = $resource(window.config.SYSTEM_URL + window.config.API_URL + '/sender-domains');
        this.YiiModel = YiiModel;
        this.one = (id) => {
            return YiiModel.one('sender-domains').$find(id);
        };
        this.factory = $resource(window.config.SYSTEM_URL + window.config.API_URL + '/sender-domains/:action', {}, {
            checkDomain: {
                method: 'GET',
                params: {
                    action: 'check'
                }
            }
        });
    }

    getAll = () => {
        return this.$resource.query();
    };
}

export {
    SenderDomainsService
};
