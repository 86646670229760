import componentTemplate from './view.html';

import { RiskSoftwareController } from './controller';

const RiskSoftwareComponent = {
    template: componentTemplate,
    controller: RiskSoftwareController,
    controllerAs: '$',
    scope: true,
    bindings: {
        software: '=',
        risksCtrl: '=',
        licenseValid: '<',
    },
};

export {
    RiskSoftwareComponent,
};
