import { DEPARTMENTS_SERVICE, GROUPS_SERVICE, MARKS_SERVICE, TARGETS_SERVICE } from '../../const';
import angular from 'angular';
import { setAuthProps, updateLinings, applyFilterDepartment } from '../../toolkit/actions';
import { fetchDashboardParams } from '../../toolkit/think';
import { getLanguage } from 'root/assets/webpack/src/js/reselect/extensibleData';
import style from './style.modules.css';

class EmployeesSingleController {
    constructor ($scope, TargetsService, DepartmentsService, GroupsService, MarksService, $ngRedux, gettextCatalog, $timeout) {
        this.style = style;
        this.TargetsService = TargetsService;
        this.DepartmentsService = DepartmentsService;
        this.GroupsService = GroupsService;
        this.MarksService = MarksService;
        this.scope = $scope;
        this.separateNames = false;
        this.inProcess = false;
        this.$timeout = $timeout;
        this.loading = {
            employee: false,
            attacks: false,
            departments: false,
            groups: false,
        };
        this.error = {
            attacks: false,
            attackName: false,
            departments: false,
            groups: false,
        };
        this.firstNameField = '';
        this.secondNameField = '';
        this.department = '';
        this.languages = [];
        this.language = {};
        this.group = '';
        this.attacks = [];
        this.attackName = '';
        this.gettextCatalog = gettextCatalog;
        this.departments = [];
        this.groups = [];
        this.markLength = 0;
        this.showRealAttackField = false;
        this.langCode = window.appCtrl.langCode || 'ru';
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { setAuthProps, updateLinings, applyFilterDepartment, fetchDashboardParams })(this);
    }

    mapStateToThis = (state) => {
        return {
            targetCount: state.auth.auth.targetCount,
            anonymization: state.auth.auth.license.features.anonymization,
            languages: getLanguage(state),
        };
    };

    setSeparateNames = (value) => {
        this.separateNames = value;
        if (!value && this.employee.fullName) {
            this.employee.fullName = this.employee.fullName.split(' ').join(' ');
        } else {
            let fullName = this.employee.fullName.split(' ');
            this.firstNameField = fullName[1];
            this.secondNameField = fullName[0];
            if (this.langCode == 'ru' && fullName.length > 2) {
                this.employee.thirdName = fullName[2];
            }
        }
    };

    setForm = (form) => {
        this.form = form;
    };

    updateTransliterate = () => {
        this.TargetsService.getTransliterate({ text: this.employee.fullName }, this, (ctrl, response) => {
            if (response.result) {
                ctrl.employee.fullName = response.result;
                if (response.result.search(/[А-Яа-яёЁ]/g) === -1) {
                    ctrl.employee.doTransliterate = false;
                } else {
                    ctrl.employee.doTransliterate = true;
                }
            }
        });
    };

    get firstName() {
        return this.langCode == 'ru' ? this.firstNameField : this.secondNameField;
    }

    set firstName(someName) {
        if (this.langCode == 'ru') {
            this.firstNameField = someName;
        } else {
            this.secondNameField = someName;
        }
    }

    get name() {
        return this.langCode !== 'ru' ? this.firstNameField : this.secondNameField;
    }

    set name(someName) {
        if (this.langCode !== 'ru') {
            this.firstNameField = someName;
        } else {
            this.secondNameField = someName;
        }
    }

    loadTarget = () => {
        if (typeof (this.targetId) != 'undefined' && this.targetId) {
            this.targetLoading = true;
            this.targetError = false;
            this.loading.employee = true;
            this.updateParentProcess(this.targetLoading);
            this.TargetsService.one(this.targetId).then((target) => {
                this.firstNameField = this.langCode != 'ru' ? target.secondName : target.firstName;
                this.secondNameField = this.langCode != 'ru' ? target.firstName : target.secondName;
                this.employee = target;
                this.employee.doTransliterate = target.doTransliterate == 1;
                let emailData = target.email.split('@');
                if (target.languageId) {
                    this.language = this.languages.filter(item => item.id === target.languageId)[0];
                }
                if (emailData && emailData.length == 2) {
                    this.email = {
                        name: emailData[0],
                        domain: emailData[1]
                    };
                }
                if (this.langCode != 'ru') {
                    this.employee.fullName = [this.firstNameField, this.secondNameField].join(' ');
                }
                if ((this.employee.firstName && this.employee.firstName.split(' ').length > 1) || (this.employee.secondName && this.employee.secondName.split(' ').length > 1)) {
                    this.separateNames = true;
                }
                this.department = target.department;
                this.group = target.group;
                this.loadAttacks();
                this.loading.employee = false;
            }, () => {
                this.targetLoading = false;
                this.loading.employee = false;
                this.updateParentProcess(this.targetLoading);
                this.targetError = this.gettextCatalog.getString('Ошибка при загрузке сотрудника');
            });
            this.loading.departments = false;
            this.updateParentProcess(this.loading.departments);
        } else {
            this.targetLoading = false;
            this.targetError = false;
            this.updateParentProcess(this.targetLoading);
        }
    };

    loadDepartments = () => {
        this.loading.departments = true;
        this.error.departments = false;
        this.updateParentProcess(this.loading.departments);
        this.DepartmentsService.simple.query({ layout: 'select' }).$promise.then((departments) => {
            this.departments = departments;
            this.loading.departments = false;
            if (typeof (this.targetId) != 'undefined' && this.targetId) {
                this.loadTarget();
            } else {
                this.employee = this.TargetsService.new();
                this.department = this.departments.find((department) => {
                    return department.id == this.departmentId;
                });
                this.employee.department = angular.copy(this.department);
                this.updateParentProcess(this.loading.departments);
            }
        }, () => {
            this.loading.departments = false;
            this.updateParentProcess(this.loading.departments);
            this.error.departments = this.gettextCatalog.getString('Ошибка при загрузке отделов');
        });
    };

    loadGroups = () => {
        this.loading.groups = true;
        this.error.groups = false;
        this.updateParentProcess(this.loading.groups);
        this.GroupsService.simple.query({ layout: 'select' }).$promise.then((groups) => {
            this.groups = groups;
            this.loading.groups = false;
            this.loadDepartments();
        }, () => {
            this.loading.groups = false;
            this.updateParentProcess(this.loading.groups);
            this.error.groups = this.gettextCatalog.getString('Ошибка при загрузке групп риска');
        });
    };

    $onInit = () => {
        this.loadGroups();
        this.errorText = null;
    };

    loadTags = (query) => (
        this.MarksService.loadTags(query).$promise.then((response) => {
            this.markLength = response.length;
            return response;
        })
    );

    loadAttacks = () => {
        this.loading.attacks = true;
        this.error.attacks = false;
        this.updateParentProcess(this.loading.attacks);
        this.TargetsService.attacks(this.employee.id, this, (ctrl, response) => {
            this.attacks = response;
            this.loading.attacks = false;
            this.updateParentProcess(this.loading.attacks);
        }, () => {
            this.loading.attacks = false;
            this.error.attacks = true;
            this.updateParentProcess(this.loading.attacks);
        });
    };

    isValidDepartment = () => {
        if (!this.loading.employee && !this.loading.attacks && this.employee && this.employee.department) {
            let departmentName = typeof (this.employee.department) == 'string' ? this.employee.department : '';
            if (this.employee.department && this.employee.department.fullName) {
                departmentName = this.employee.department.fullName;
            }
            if (departmentName) {
                let department = this.departments.find((department) => {
                    return department.fullName == departmentName;
                });
                if (department && department.id) {
                    if (!this.employee.department || !this.employee.department.id) {
                        this.employee.department = department;
                    }
                    return true;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    isValidGroup = () => {
        if (!this.loading.employee && !this.loading.attacks && this.employee && this.employee.department) {
            let groupName = typeof (this.employee.group) == 'string' ? this.employee.group : '';
            if (this.employee.group && this.employee.group.fullName) {
                groupName = this.employee.group.fullName;
            }
            if (groupName) {
                let group = this.groups.find((group) => {
                    return group.fullName == groupName;
                });
                if (group && group.id) {
                    if (!this.employee.group || !this.employee.group.id) {
                        this.employee.group = group;
                    }
                    return true;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    checkDepartmentMatches = () => {
        this.departmentHasMatches = false;
        if (this.departments && this.departments.length) {
            let departmentName = this.employee.department && this.employee.department.fullName ? this.employee.department.fullName : (typeof (this.employee.department) == 'string' ? this.employee.department : '');
            angular.forEach(this.departments, (department) => {
                if (!departmentName || !department.fullName || departmentName.indexOf(department.fullName) > -1 || department.fullName.indexOf(departmentName) > -1) {
                    this.departmentHasMatches = true;
                    return true;
                }
            });
        }
    };

    checkGroupMatches = () => {
        this.groupHasMatches = false;
        if (this.groups && this.groups.length) {
            let groupName = this.employee.group && this.employee.group.fullName ? this.employee.group.fullName : (typeof (this.employee.group) == 'string' ? this.employee.group : '');
            angular.forEach(this.groups, (group) => {
                if (!groupName || !group.fullName || groupName.indexOf(group.fullName) > -1 || group.fullName.indexOf(groupName) > -1) {
                    this.groupHasMatches = true;
                    return true;
                }
            });
        }
    };

    save = () => {
        this.$timeout(() => {
            this.errorText = null;
            this.form.$setSubmitted();
            if (this.form.$valid) {
                this.inProcess = true;
                this.updateParentProcess(this.inProcess);
                this.employee.email = `${this.email && this.email.name || ''}@${this.email && this.email.domain || ''}`;
                if (typeof this.department !== 'string') {
                    this.employee.department = this.departments.filter(item => {
                        if (this.department.id) {
                            return item.id === this.department.id;
                        }
                        if (this.department === item.fullName) {
                            return item;
                        }
                    })[0];
                } else {
                    this.employee.department = { id: 0, name: this.department, fullName: this.department };
                }
                if (this.langCode == 'ru') {
                    this.employee.fullName = this.firstNameField + ' ' + this.secondNameField + ' ' + this.employee.thirdName;
                } else {
                    this.employee.fullName = this.secondNameField + ' ' + this.firstNameField;
                }

                if (typeof this.group !== 'string') {
                    this.employee.group = this.groups.filter(item => {
                        if (this.group && this.group.id) {
                            return item.id === this.group.id;
                        }
                        if (this.group === item.fullName) {
                            return item;
                        }
                    })[0];
                } else {
                    this.employee.group = {
                        id: 0,
                        name: this.group
                    };
                }
                let marks = this.employee.marks;
                this.employee.marks = [];
                if (typeof (marks) != 'undefined' && marks && marks.length > 0) {
                    marks.forEach((mark) => {
                        this.employee.marks.push(mark.text ? mark.text : mark);
                    });
                }

                this.employee.firstName = this.langCode == 'ru' ? this.firstNameField : this.secondNameField;
                this.employee.secondName = this.langCode == 'ru' ? this.secondNameField : this.firstNameField;
                this.employee.languageId = this.language && this.language.id || null;
                this.employee.$save().then((employee) => {
                    if (employee) {
                        this.updateLinings(employee.department);
                        this.setAuthProps({ targetCount: ++this.targetCount });
                        this.applyFilterDepartment({
                            search: '',
                            callback: null,
                            reload: 'departments'
                        });
                        this.fetchDashboardParams();
                        this.modalClose(employee);
                    } else {
                        let count = this.employee.fullName.split(' ').length;
                        if (count < 3) {
                            this.employee.thirdName = null;
                        }
                        if (this.separateNames) {
                            this.employee.fullName = [
                                this.employee.firstName,
                                this.employee.secondName,
                                this.employee.thirdName
                            ].join('|');
                        }
                        if (this.employee.department) {
                            this.employee.department = this.employee.department.fullName;
                        }
                        if (this.employee.group) {
                            this.employee.group = this.employee.group.fullName;
                        }
                        this.inProcess = false;
                        this.updateParentProcess(this.inProcess);
                        this.fetchDashboardParams();
                    }
                }, () => {
                    this.employee.department = this.employee.department.fullName;
                    this.employee.group = this.employee.group.fullName;
                    this.inProcess = false;
                    this.updateParentProcess(this.inProcess);
                    this.errorText = this.gettextCatalog.getString('Ошибка при сохранении сотрудника');
                });
            }
        }, 10);
    };

    addAttack = () => {
        this.error.attackName = false;
        if (!this.attackName) {
            this.error.attackName = true;
        } else {
            let attack = {
                id: null,
                name: null,
                datetime: Date.now(),
                comment: this.attackName
            };
            this.attacks.push(attack);
            this.employee.attacks.push(attack);
            this.attackName = '';
            this.showRealAttackField = false;
        }
    };

    $onDestroy = () => {
        this.unsubscribe();
    };
}

EmployeesSingleController.$inject = [
    '$scope',
    TARGETS_SERVICE,
    DEPARTMENTS_SERVICE,
    GROUPS_SERVICE,
    MARKS_SERVICE,
    '$ngRedux',
    'gettextCatalog',
    '$timeout'
];

export {
    EmployeesSingleController
};
