import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';

const initialState = {
    status: 'idle',
    accountUsers: {},
    error: false,
};

export const fetchAccount = createAsyncThunk(
    'accountUsers/fetchAccountStatus',
    async (userId) => {
        let url = userId ? '/users' : '/users?mode=settings&fullList=1&layout=select'
        const fetchApi = FetchApi(url);
        const response = await fetchApi.get(userId);
        return response;
    }
);

export const deleteAccount = createAsyncThunk(
    'accountUsers/deleteAccount',
    async (id) => {
        const fetchApi = FetchApi('/users');
        const response = await fetchApi.delete(id);
        return response;
    }
);

const accountUsersSlice = createSlice({
    name: 'accountUsers',
    initialState,
    reducers: {
        setAccountUsers(state, { payload }) {
            state.accountUsers = payload;
        },
        addAccount(state, { payload }) {
            state.accountUsers.push(payload);
        },
        updateAccount(state, { payload }) {
            state.accountUsers = state.accountUsers.map((item) => {
                if (item.id !== payload.id) return item;
                return payload;
            });
        },
    },
    extraReducers: {
        [fetchAccount.fulfilled]: (state, { payload }) => {
            state.accountUsers = payload;
            state.status = 'load';
        },
        [fetchAccount.pending]: (state) => {
            state.status = 'pending';
        },
        [fetchAccount.rejected]: (state) => {
            state.status = 'rejected';
        },
        [deleteAccount.fulfilled]: (state, { payload }) => {
            state.deleteLoading = false;
            state.error = false;
            state.accountUsers = state.accountUsers.filter((item) => item.id !== payload);
        },
        [deleteAccount.pending]: (state) => {
            state.targetCountStatus = 'pending';
        },
        [deleteAccount.rejected]: (state) => {
            state.targetCountStatus = 'rejected';
        },
    }
});

export const { setAccountUsers, addAccount, updateAccount } = accountUsersSlice.actions;
export default accountUsersSlice.reducer;
