
class UsbService {
    constructor (YiiModel, $resource) {
        this.YiiModel = YiiModel;
        this.all = YiiModel.all('usb-attacks');
        this.new = () => {
            return YiiModel.one('usb-attacks');
        };
        this.one = (id) => {
            return YiiModel.one('usb-attacks').$find(id);
        };
        this.deleteAttack = (id, params, ctrl, success, error) => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/usb-attacks/' + id + '/delete').delete(params, {}, function (response) {
                if (typeof (success) == 'function' && ctrl) {
                    success(ctrl, response);
                }
            }, function (response) {
                if (typeof (error) == 'function' && ctrl) {
                    error(ctrl, response);
                }
            });
        };
        this.sender = (attackId, action, ctrl, success, error) => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/usb-attacks/' + attackId + '/' + action).save({}, {}, function (response) {
                if (typeof (success) == 'function' && ctrl) {
                    success(ctrl, response);
                }
            }, function (response) {
                if (typeof (error) == 'function' && ctrl) {
                    error(ctrl, response);
                }
            });
        };
        this.targets = (attackId) => {
            return YiiModel.all('usb-attacks/' + attackId + '/targets');
        };
        this.updateName = params => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/usb-attacks/' + params.id + '/name-update').save({}, params);
        };
    }
}

export {
    UsbService
};
