import { hasAccess } from '../../reselect';
import style from './style.modules.css';
import { setAuthProps } from '../../toolkit/actions';

class SettingsLisenseController {
    constructor($ngRedux, gettextCatalog, $cookies, $scope) {
        this.config = window.config;
        this.langCode = $cookies.get('langCode');
        this.scope = $scope;
        this.gettextCatalog = gettextCatalog;
        this.licenseTargetOpen = false;
        this.hiddenInput = false;
        this.style = style;
        this.saveDebugLink = this.gettextCatalog.getString('Сохранить отладочную информацию');
        this.saveDebugLoading = this.gettextCatalog.getString('Подготовка отладочной информации');
        this.saveDebugError = this.gettextCatalog.getString('Ошибка при подготовке отладочной информации');
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { setAuthProps })(this);
    }

    mapStateToThis = (state) => {
        return {
            settingsDebug: hasAccess(state, { sectionId: 5, rightName: 'settings_debug' }),
            companyTargetSaveAccess: hasAccess(state, { sectionId: 5, rightName: 'settings_company_target_count' })
        };
    };

    $onInit() {
        this.licenseCompanyTargetCount = this.companyTargetCount || this.userInfo.companyTargetCount;
    }

    showLink = () => {
        if (!this.companyTargetSaveAccess) {
            return;
        }
        this.licenseTargetOpen = !this.licenseTargetOpen;
        this.hiddenInput = !this.hiddenInput;
    }

    setTotalTargets = () => {
        if (this.licenseCompanyTargetCount < this.userInfo.license.employeeCount
            || this.licenseCompanyTargetCount === this.userInfo.companyTargetCount){
            this.showLink();
            return;
        }
        let body = {
            companyTargetCount: this.licenseCompanyTargetCount
        };
        let options = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Enable-Session': 1,
                'X-Csrf-Token': window.csrfToken

            },
        };
        fetch(window.config.SYSTEM_URL + window.config.API_URL + '/settings/company-target-count', options).then(response => {
            return response.json();
        }).then((response)=>{
            this.userInfo.companyTargetCount = this.licenseCompanyTargetCount;
            this.setAuthProps({ companyTargetCount: response.companyTargetCount });
            this.showLink();
            this.scope.$apply();
        }).catch(()=> {
            this.showLink();
            this.scope.$apply();
        });
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

SettingsLisenseController.$inject = [
    '$ngRedux',
    'gettextCatalog',
    '$cookies',
    '$scope'
];

export {
    SettingsLisenseController,
};
