import viewTemplate from './view.html';
import style from './style.modules.css';

class LangSelectorController {
    constructor () {
        this.style = style;
    }

    $onInit = () => {}
}

LangSelectorController.$inject = [
    '$scope'
];

const LangSelector = {
    template: viewTemplate,
    controller: LangSelectorController,
    controllerAs: '$',
    bindings: {
        lang: '<',
        active: '<',
        setActive: '=',
        setDefault: '=',
        default: '<'
    }
};

export {
    LangSelector
};
