class MarksService {
    constructor ($resource) {
        this.resource = $resource(window.config.SYSTEM_URL + window.config.API_URL + '/marks');
    }

    loadTags = (name) => {
        return this.resource.query({ name: name });
    };
}

export {
    MarksService
};
