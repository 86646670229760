import angular from 'angular';

const __build = (selectedData, reverse) => {
    let newSelectedData = {};
    newSelectedData.all = selectedData && selectedData.all ? 1 : 0;
    newSelectedData.unselectAll = selectedData && selectedData.unselectAll ? 1 : 0;
    newSelectedData.search = selectedData && selectedData.search || [''];
    newSelectedData.departments = selectedData && selectedData.departments || [];
    if (!angular.isArray(newSelectedData.departments)) {
        newSelectedData.departments = [];
    }

    newSelectedData.departments.sort();
    newSelectedData.departmentsUsed = selectedData && selectedData.departmentsUsed || [];
    if (!angular.isArray(newSelectedData.departmentsUsed)) {
        newSelectedData.departments = [];
    }
    newSelectedData.departmentsUsed.sort();

    newSelectedData.groups = (selectedData.groups || []).sort();
    newSelectedData.groupsUsed = (selectedData.groupsUsed || []).sort();

    newSelectedData.targets = {};
    if (selectedData.targets) {
        angular.forEach(selectedData.targets, (targets, departmentId) => {
            if (targets && targets.length > 0 && departmentId > 0) {
                targets.sort();
                newSelectedData.targets[departmentId] = targets;
            }
        });
    }
    newSelectedData.unselectedTargets = {};
    if (selectedData.unselectedTargets) {
        angular.forEach(selectedData.unselectedTargets, (targets, departmentId) => {
            if (targets && targets.length > 0 && departmentId > 0) {
                targets.sort();
                newSelectedData.unselectedTargets[departmentId] = targets;
            }
        });
    }
    if (selectedData.prevData) {
        newSelectedData.prevData = __build(selectedData.prevData);
    }
    newSelectedData.reverse = reverse ? 1 : 0;
    return JSON.stringify(newSelectedData);
};

export default (selectedData, reverse) => {
    return __build(selectedData, reverse);
};
