import { useContext, useState, useEffect } from 'react';
import { GettextContext } from 'rootReact/context';
import { useSelector, useDispatch } from 'react-redux';
import BasketList from './BasketList.jsx';
import loader from '../../../../images/loading.svg';
import FetchApi from '../../REST';
import { fetchListWifi } from 'root/assets/webpack/src/js/toolkit/think';

function AttacksBasket({ }) {
    const dispatch = useDispatch();
    const gettext = useContext(GettextContext);
    const [pagination, setPagination] = useState(10);
    const wifiAttack = useSelector((state) => state.wifi.wifi);
    const [attacks, setAttaks] = useState([]);
    const findChecked = attacks?.filter((item) => item.checked === true);
    const ids = findChecked.map((item) => item.id);

    useEffect(() => {
        async function fetchApi() {
            let response = await FetchApi('/wifi-attacks/bucket').get();
            if (response && response.isError) {
                throw response;
            }
            response = response.length
                ? response.map((item) => {
                    item.checked = false;
                    return item;
                })
                : [];
            setAttaks(response);
        }
        try {
            fetchApi();
        } catch (e) {
            console.log(e);
        }
    }, []);

    const wifiBasketList = attacks?.slice(0, pagination).map((obj) =>
        <BasketList key={obj.id} {...obj} attacks={attacks} setAttaks={setAttaks} />);

    const onClickShowMore = () => {
        setPagination((prevValue) => prevValue + 10);
    };

    const handleReturn = async () => {
        try {
            const response = await FetchApi('/wifi-attacks/restore').post({
                body: {
                    ids,
                },
            });
            if (response && response.isError) {
                throw response;
            }
            setAttaks([...attacks].filter((item) => {
                return !ids.includes(item.id);
            }));
            dispatch(fetchListWifi());
        } catch (e) {
            console.log(e);
        }
    };

    const handleDelete = async () => {
        try {
            const response = await FetchApi('/wifi-attacks/delete-completely').post({
                body: {
                    ids,
                },
            });
            if (response && response.isError) {
                throw response;
            }
            setAttaks([...attacks].filter((item) => {
                return !ids.includes(item.id);
            }));
        } catch (e) {
            console.log(e);
        }

    };

    // const closeDeletePopup = () => {
    //     setPopupDelete(false)
    //     setPopupDeleteDone(false)
    //     setbasketName('')
    // }

    // useEffect(() => {
    //     if (setPopupDeleteDone) {
    //         setTimeout(closeDeletePopup, 8000)
    //     }
    // }, [popupDeleteDone])

    return (
        <>
            <div className='wifi-basket' data-testid='wifi-basket'>
                <div className='wifi-basket_toolbar'>
                    <div className='wifi-basket_toolbar-content'>
                        <button
                            onClick={handleReturn}
                            className={`wifi-basket_btn ${findChecked.length && 'wifi-basket_active-btn'}`}
                        >
                            <svg className={`wifi-basket_btn-restore ${findChecked.length && 'wifi-basket_active-icon'}`}>
                                <use xlinkHref={'/image/icoSprite.svg#wifi-restore'}></use>
                            </svg>
                            {gettext.getString('Восстановить')}
                        </button>
                        <button
                            onClick={handleDelete}
                            className={`wifi-basket_btn ${findChecked.length && 'wifi-basket_active-btn'}`}
                        >
                            <svg className={`wifi-basket_btn-delete ${findChecked.length && 'wifi-basket_active-icon'}`}>
                                <use xlinkHref={'/image/icoSprite.svg#delete-wifi-new'}></use>
                            </svg>
                            {gettext.getString('Удалить совсем')}
                        </button>
                    </div>
                </div>
                <div className='wifi-basket_full-list' data-testid='wifi-basket-list'>
                    {!wifiBasketList?.length ? (
                        <div className={'wifi-target_empty'}>{gettext.getString('Нет удаленных атак')}</div>
                    ) : (
                        wifiBasketList
                    )}
                </div>
            </div>
            {pagination < attacks?.length ? (
                <div
                    data-testid='wifi-basketPagination'
                    onClick={() => onClickShowMore()}
                    className={'wifi-attack_pagination'}
                >
                    <button className={'wifi-attack_pagination-btn'}>{gettext.getString('Показать еще')}</button>
                </div>
            ) : (
                ''
            )}
            {/* {popupDelete &&
                <div className={"wifi-basket_delete-popup"}>
                    <div className={"wifi-basket_delete-popup_content"}>
                        {
                            popupDeleteDone ?
                                <div>
                                    <svg className={"wifi-basket_delete-popup_img"}> <use xlinkHref={`/image/icoSprite.svg#popup-delete-icon-done`}></use> </svg>
                                </div>

                                :
                                <div>
                                    <img className={"wifi-basket_report-popup_load"} src={loadReport} />
                                    <svg className={"wifi-basket_delete-popup_img"}> <use xlinkHref={`/image/icoSprite.svg#popup-delete-icon`}></use> </svg>
                                </div>
                        }
                        <span className={"wifi-basket_delete-popup_text"}>
                            {gettext.getString(`${basketName.length > 1 ? 'Вы удалили атаки' : 'Вы удалили атаку'}`)}
                            {gettext.getString(` ${basketName}`)}
                        </span>
                        {
                            !popupDeleteDone && <button data-testid="return-basket" onClick={handleReturnbasket} className={"wifi-basket_delete-popup_btn"}>{gettext.getString("Вернуть")}</button>
                        }
                    </div>
                </div>
            } */}
        </>
    );
}

export default AttacksBasket;
