import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FetchApi from "../React/REST";

const initialState = {
    status: "idle",
    departments: [],
    error: false,
    loading: false,
};

export const fetchDepartments = createAsyncThunk("departments/status", async () => {
    const api = FetchApi("/departments?layout=select");
    let response = await api.get();
    return [...response];
});

const departmentsSlice = createSlice({
    name: "departments",
    initialState,
    reducers: {
        setDepartments(state, { payload }) {
            state.departments = payload;
        },
    },
    extraReducers: {
        [fetchDepartments.pending]: (state) => {
            state = initialState;
        },
        [fetchDepartments.fulfilled]: (state, { payload }) => {
            state.loading = true;
            state.error = false;
            (state.errorText = ""), (state.departments = payload);
        },
        [fetchDepartments.rejected]: (state, { payload }) => {
            state.loading = true;
            state.error = true;
            state.errorText = payload;
        },
    },
});

export const { setDepartments } = departmentsSlice.actions;
export default departmentsSlice.reducer;
