import ldapSearchPanelTemplate from './view.html';

import { LdapSearchPanelController } from './controller';

const LdapSearchPanelComponent = {
    template: ldapSearchPanelTemplate,
    controller: LdapSearchPanelController,
    controllerAs: '$',
    scope: true,
    bindings: {
        search: '=',
        searchString: '<',
        disabled: '<',
    },
};

export {
    LdapSearchPanelComponent,
};
