import React, { useState, useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GettextContext } from 'rootReact/context';
import TargetsAttack from '../WifiAttack/TargetsAttack.jsx';
import FetchApi from '../../REST';
import { fetchDaemonsMessage, fetchWifiTargets } from 'root/assets/webpack/src/js/toolkit/think';
import Tooltip from '../Tooltip';
import loader from '../../../../images/loading.svg';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';


function BasketList({ id, name, checked, attacks, setAttaks }) {
    const gettext = useContext(GettextContext);
    const dispatch = useDispatch();
    const [targetsData, setTargetsData] = useState([]);
    const [loadTargets, setLoadTargets] = useState(false);
    const [openTarget, setOpenTarget] = useState(false);

    const handleCheckboxSelect = (id) => {
        setAttaks([...attacks]?.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    checked: !item.checked,
                };
            } else {
                return item;
            }
        }));
    };

    useEffect(() => {
        async function fetchApi() {
            setLoadTargets(true);
            const wifiTargets = FetchApi('/wifi-attacks');
            let response = await wifiTargets.get(id + '/targets');
            if (response && response.isError) {
                throw response;
            }
            setTargetsData(response);
            setLoadTargets(false);
        }
        try {
            if (id || openTarget) {
                fetchApi();
            }
        } catch (e) {
            setLoadTargets(true);
            console.log(e);
        }
    }, [id, openTarget]);

    const onClickToogleStatistic = () => {
        setOpenTarget((prev) => !prev);
    };

    const targetsList = targetsData.length ? (
        targetsData.map((obj) => <TargetsAttack key={obj.id} {...obj} />)
    ) : (
        <tr className={'wifi-target_empty'}>
            <td>{gettext.getString('Нет подключений')}</td>
        </tr>
    );

    return (

        <div
            className={`wifi-attack-item_wrapper ${checked ? 'wifi-attack-item_wrapper-active' : ''}`}
            data-testid='attack-item'
        >
            <div className={'wifi-attack_row'}>
                <div className={'wifi-attack_row-top'}>
                    <input
                        checked={checked}
                        onChange={() => handleCheckboxSelect(id)}
                        className='wifi-attack-checkbox'
                        type='checkbox'
                        data-testid='attack-checkbox'
                    />
                    <label className='wifi-attack-checkbox_label'></label>
                    <div className={'wifi-attack_label'} data-testid='attack-edit'>
                        {name}
                    </div>
                </div>
                <Tooltip reactTip={gettext.getString('посмотреть статистику')}>
                    <button
                        data-testid='wifi-attackToggleStatistic'
                        onClick={onClickToogleStatistic}
                        className='wifi-attack_icon'
                    >
                        <svg
                            className={`wifi-attack_icon-arrow ${openTarget ? 'wifi-attack_icon-arrow-open' : ''
                            }`}
                        >
                            <use xlinkHref={'/image/icoSprite.svg#wifi-arrow-list'}></use>
                        </svg>
                    </button>
                </Tooltip>
            </div>
            {openTarget && (
                <OverlayScrollbarsComponent
                    options={{
                        scrollbars: { autoHide: 'move', visibility: 'auto' },
                        className: 'wifi-attack-scroll',
                    }}
                    defer
                >
                    <table className={'wifi-attack_table'} data-testid='target-table'>
                        <thead data-testid='target-head'>
                            <tr className={'wifi-attack-statistic'} data-testid='target-item'>
                                <th className={'wifi-attack-statistic_title'} data-testid='target-label'>{gettext.getString('Когда')}</th>
                                <th className={'wifi-attack-statistic_title'} data-testid='target-label'>
                                    {gettext.getString('Тип устройства и имя')}
                                </th>
                                <th className={'wifi-attack-statistic_title'} data-testid='target-label'>
                                    {gettext.getString('Браузер')}
                                </th>
                                <th className={'wifi-attack-statistic_title'} data-testid='target-label'>
                                    {gettext.getString('MAC адрес')}
                                </th>
                                <th className={'wifi-attack-statistic_title'} data-testid='target-label'>
                                    {gettext.getString('Сотрудник')}
                                </th>
                                <th className={'wifi-attack-statistic_title'} data-testid='target-label'>{gettext.getString('Отдел')}</th>
                            </tr>
                        </thead>
                        <tbody data-testid='target-list'>{openTarget && targetsList}</tbody>
                    </table>
                </OverlayScrollbarsComponent>
            )}
        </div>
    );
}

export default BasketList;