import {
    BOSS_KADROVIK_SERVICE,
    DEFAULT_PER_PAGE_SIZE,
    EMPTY_LDAP_SELECTED_DATA,
    EMPTY_SELECTED_DATA,
    LDAP_PROFILES_SERVICE,
    LDAP_IMPORTS_SERVICE,
    TARGETS_SERVICE,
    GROUPS_SERVICE,
} from '../../const';
import style from './ldap.modules.css';
import { LdapProfileModal } from '../../components/ldapProfileModal';
import { BossKadrovikProfileModal } from '../../components/bossKadrovikModal';
import angular from 'angular';
import setOptions from '../../helpers/setOptions';
import { setDefaultLdap, setChoosenLdapDepartment } from '../../toolkit/actions';

class EmployeesImportLdapController {
    options = [];
    emailFields = [];
    externalFields = [];
    managerFields = [];

    constructor ($injector, $scope, $timeout, LdapProfilesService, TargetsService, BossKadrovikProfilesService, $ngRedux, GroupsService, $interval, LdapImportsService) {
        this.$injector = $injector;
        this.$scope = $scope;
        this.externalIDEnabled = false;
        this.$interval = $interval;
        this.$timeout = $timeout;
        this.GroupsService = GroupsService;
        this.LdapProfilesService = LdapProfilesService;
        this.BossKadrovikProfilesService = BossKadrovikProfilesService;
        this.LdapImportsService = LdapImportsService;
        this.TargetsService = TargetsService;
        this.ldapProfiles = LdapProfilesService.all;
        this.ldapTargets = TargetsService.ldapTargets;
        this.ldapDepartments = TargetsService.ldapDepartments;
        this.ldapDepartmentTargets = {};
        this.loading = {
            ldap: true,
            groups: false,
            import: false,
            departments: false
        };
        this.errors = {
            ldap: null,
            groups: null,
            import: false,
            departments: false
        };
        this.groups = null;
        this.selectedData = angular.copy(EMPTY_LDAP_SELECTED_DATA);
        this.step = 0;
        this.style = style;
        this.language = null;
        this.languageId = null;
        this.optionsTab = 1;
        this.fullNameMode = 0;
        this.emailField = 'mail';
        this.firstNameField = 'givenname';
        this.secondNameField = 'sn';
        this.thirdNameField = '';
        this.fullNameField = 'displayname';
        this.positionField = 'title';
        this.departmentField = 'department';
        this.riskGroupField = '';
        this.externalField = 'employeeID';
        this.managerField = 'manager';
        this.groupFilter = [];
        this.importStatus = {
            progress: 0
        };
        this.modal = {
            ldap: null,
            delete: null
        };
        this.saveConfig = false;
        this.succsessMessage = '';
        this.isConfigSave = false;
        this.groupLength = 0;
        this.openedDepartments = [];
        this.gettextCatalog = window.appCtrl.gettextCatalog;
        this.ldapUsersCount = 0;
        this.marks = [];
        this.sysGroups = [];
        this.groupRisk = '';
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { setDefaultLdap, setChoosenLdapDepartment })(this);
    }

    mapStateToThis = (state) => {
        return {
            languages: state.languages.languages,
            gpbMode: state.auth.auth.gpbMode,
            chosenDepartmentName: state.ldapDepartments.selectedData.chosenDepartmentName
        };
    }

    updateParentProcess = (bossLoading = false) => {
        if (bossLoading) {
            window.employeesModalCtrl.inProcess = true;
            return;
        }
        if (window.employeesModalCtrl) {
            let loading = false;
            if (this.loading.import ||
                this.loading.ldap ||
                this.loading.groups ||
                this.loading.usersCount ||
                this.loading.departments) {
                loading = true;
            }
            if (window.employeesModalCtrl.inProcess != loading) {
                window.employeesModalCtrl.inProcess = loading;
            }
        }
    };

    $onInit () {
        this.selectedData = angular.extend({}, EMPTY_LDAP_SELECTED_DATA);
        this.userInfo = window.employeesModalCtrl.userInfo;
        this.loading.userInfo = true;
        this.userInfo.$promise.then(() => {
            this.loading.userInfo = false;
            if (this.userInfo && this.userInfo.targetImport && this.userInfo.targetImport.ldapExternalIdEnabled) {
                this.externalIDEnabled = true;
            }
            this.loadLdapProfiles();
            this.loadSysGroups();
            if (this.userInfo && this.userInfo.targetImport && this.userInfo.targetImport.bossKadrovikEnabled) {
                this.loadBossKadrovikProfiles();
            }
        });
    }

    setStep = (step) => {
        this.step = step;
    };

    loadLdapProfiles = () => {
        this.loading.ldap = true;
        this.updateParentProcess();
        this.errors.ldap = null;
        let options = setOptions();
        try {
            fetch(window.config.SYSTEM_URL + window.config.API_URL + '/ldap-profiles', options)
                .then(response => {
                    if (!response.ok) {
                        throw response.error;
                    }
                    return response.json();
                }).then (data => {
                    this.ldapProfilesList = data;
                    this.loading.ldap = false;
                    this.updateParentProcess();
                    this.$scope.$apply();
                });
        } catch (error) {
            this.loading.ldap = false;
            this.updateParentProcess();
            this.errors.ldap = true;
        }
    };

    loadMoreLdapProfiles = () => {
        this.loading.ldap = true;
        this.updateParentProcess();
        this.errors.ldap = null;
        if (this.ldapProfilesList.$existNext()) {
            const prevData = this.ldapProfilesList.$data;
            this.ldapProfiles.$nextPage().then((data) => {
                this.ldapProfilesList.$meta = data.$meta;
                this.ldapProfilesList.$data = [...prevData, ...data.$data];
                this.loading.ldap = false;
                this.updateParentProcess();
                if (this.ldapProfilesList.$existNext()) {
                    this.loadMoreLdapProfiles();
                }
            }, () => {
                this.loading.ldap = false;
                this.updateParentProcess();
                this.errors.ldap = true;
            });
        }
    };

    loadBossKadrovikProfiles = () => {
        this.bossKadrovikProfiles = this.BossKadrovikProfilesService.all;
        this.loading.bossKadrovik = true;
        this.updateParentProcess();
        this.errors.bossKadrovik = null;
        this.bossKadrovikProfiles.$page = 1;
        this.bossKadrovikProfiles.$load(DEFAULT_PER_PAGE_SIZE).then((data) => {
            this.bossKadrovikProfilesList = data;
            this.loading.bossKadrovik = false;
            this.updateParentProcess();
            if (this.bossKadrovikProfilesList.$existNext()) {
                this.loadMoreBossKadrovikProfiles();
            }
        }, () => {
            this.loading.bossKadrovik = false;
            this.updateParentProcess();
            this.errors.bossKadrovik = true;
        });
    };

    loadMoreBossKadrovikProfiles = () => {
        this.loading.bossKadrovik = true;
        this.updateParentProcess();
        this.errors.bossKadrovik = null;
        if (this.bossKadrovikProfilesList.$existNext()) {
            const prevData = this.bossKadrovikProfilesList.$data;
            this.bossKadrovikProfiles.$nextPage().then((data) => {
                this.bossKadrovikProfilesList.$meta = data.$meta;
                this.bossKadrovikProfilesList.$data = [...prevData, ...data.$data];
                this.loading.bossKadrovik = false;
                this.updateParentProcess();
                if (this.bossKadrovikProfilesList.$existNext()) {
                    this.loadMoreBossKadrovikProfiles();
                }
            }, () => {
                this.loading.bossKadrovik = false;
                this.updateParentProcess();
                this.errors.bossKadrovik = true;
            });
        }
    };

    getGroups = (query) => {
        var regexp = new RegExp(query, 'i');
        let groups = this.groups ? this.groups.filter((item) => {
            return item.name.search(regexp) != -1;
        }).map((item) => {
            return item.name;
        }) : [];
        return groups;
    };

    onProfileSelect = (profile, clearForm = true) => {
        this.targets = null;
        this.setStep(1);
        this.errors.groups = null;
        this.errors.groupText = null;
        if (clearForm) {
            this.groupFilter = [];
            this.targets = null;
            this.selectedData = angular.extend({}, angular.copy(EMPTY_LDAP_SELECTED_DATA));
            this.fields = this.LdapProfilesService.fields;
            this.emailFields = ['mail', 'rfc822mailbox'];
            this.externalFields = ['employeeID'];
            this.managerFields = ['manager'];
            this.bossKadrovikProfile = null;
            this.marks = [];
            this.emailField = 'mail';
            this.departmentField = profile && profile.schema == 'FreeIPA' ? 'ou' : 'department';
            this.riskGroupField = '';
            this.firstNameField = 'givenname';
            this.secondNameField = 'sn';
            this.thirdNameField = '';
            this.fullNameField = 'displayname';
            this.positionField = 'title';
            this.externalField = 'employeeID';
            this.managerField = 'manager';
            this.isConfigSave = false;
        }
        if (profile && profile.id) {
            this.LdapProfilesService.one(profile.id).then((response) => {
                if (!response.selectedData) {
                    response.selectedData = [];
                }
                this.isConfigSave = !angular.isArray(response.selectedData);
                if (this.isConfigSave) {
                    this.selectedData = angular.extend({}, angular.copy(EMPTY_LDAP_SELECTED_DATA), response.selectedData);
                    this.selectedData.rebuild = true;
                    this.bossKadrovikProfile = response.activeDefaults.enabledfilters.bossKadrovik;
                    this.emailField = response.activeDefaults.fieldMapping.email;
                    this.firstNameField = response.activeDefaults.fieldMapping.firstName;
                    this.secondNameField = response.activeDefaults.fieldMapping.secondName;
                    this.thirdNameField = response.activeDefaults.fieldMapping.thirdName;
                    this.fullNameField = response.activeDefaults.fieldMapping.fullName;
                    this.fullNameMode = response.activeDefaults.fieldMapping.mode;
                    this.positionField = response.activeDefaults.fieldMapping.position;
                    this.departmentField = response.activeDefaults.fieldMapping.department;
                    this.riskGroupField = response.activeDefaults.fieldMapping.riskGroup;
                    this.managerField = response.activeDefaults.fieldMapping.manager;
                    this.groupFilter = response.activeDefaults.enabledfilters['in-groups'] ? response.activeDefaults.enabledfilters['in-groups'].split('|') : null;
                    this.marks = response.activeDefaults.marks;
                    this.groupRisk = response.activeDefaults.groupRisk;
                    this.languageId = response.activeDefaults.languageId;
                    this.language = response.activeDefaults.languageId ? this.languages.find(item => item.id === response.activeDefaults.languageId) : null;
                    this.setChoosenLdapDepartment(this.selectedData);
                }
                this.ldapProfile = response;
                this.loadGroups(response);
            });
        }
    };

    onBossKadrovikSelect = () => {
        this.targets = null;
        this.selectedData = angular.extend({}, angular.copy(EMPTY_LDAP_SELECTED_DATA));
        this.setStep(1);
    };

    loadGroups = async (profile) => {
        if (this.gpbMode) {
            this.showBossKadrovikSelector = true;
            this.loading.groups = false;
            this.errors.groups = null;
            this.errors.groupText = null;
            this.errorMessage = null;
            return;
        }
        this.showBossKadrovikSelector = false;
        this.errors.groups = null;
        this.loading.groups = true;
        this.errors.groupText = null;
        this.errorMessage = null;
        this.updateParentProcess();
        try {
            if (profile && profile.id) {
                this.loading.groups = true;
                this.updateParentProcess();
                let options = setOptions();
                let response = await fetch(`${window.config.SYSTEM_URL}${window.config.API_URL}/ldap/${profile.id}/groups`, options);
                const responseJson = await response.json();
                this.showBossKadrovikSelector = true;
                this.loading.groups = false;
                if (!response.ok) {
                    throw responseJson.error;
                }
                this.groups = responseJson;
                this.groupLength = this.groups.length;
                this.updateParentProcess();
                this.$scope.$apply();
                if (this.isConfigSave && !this.selectedData.all) {
                    this.setStep(2);
                    this.$scope.$apply();
                }
            }
        } catch (err) {
            this.updateParentProcess();
            this.errors.groups = err ? err : 'Ошибка при загрузке групп';
        }
    };

    loadUsersCount = (profile) => {
        this.errors.usersCount = null;
        this.loading.usersCount = true;
        this.updateParentProcess();
        this.step = 1;
        const requestOption = {
            profile: profile.id,
            filter: this.groupFilter ? this.groupFilter.map(function (item) {
                if (item.text) return item.text;
                return item;
            }).join('|') : null
        };
        this.TargetsService.getLdapUsersCount(requestOption, this, (ctrl, response) => {
            ctrl.loading.usersCount = false;
            ctrl.updateParentProcess();
            if (response && response.count) {
                ctrl.ldapUsersCount = response.count;
            }
            this.updateParentProcess();
        }, (ctrl, response) => {
            ctrl.loading.usersCount = false;
            ctrl.updateParentProcess();
            ctrl.errors.usersCount = true;
            if (response && response.data && response.data.error) {
                ctrl.errors.usersCount = response.data.error;
            }
            if (response && response.status && response.status == 504) {
                ctrl.errors.usersCount = this.gettextCatalog.getString('Превышен лимит ожидания подсчета количества сотрудников');
            }
            this.updateParentProcess();
        });
    };

    loadSysGroups = () => {
        this.GroupsService.simple.query({ layout: 'select' }).$promise.then((groups) => {
            this.sysGroups = groups;
        }, () => {
        });
    };

    calcUsersCount = () => {
        this.targets = null;
        this.selectedData = angular.extend({}, angular.copy(EMPTY_LDAP_SELECTED_DATA));
        this.setStep(1);
        this.loadUsersCount(this.ldapProfile);
    };

    isAnyLoading = () => {
        return this.loading.ldap ||
            this.loading.bossKadrovik ||
            this.loading.departments ||
            this.loading.departmentsMore ||
            this.loading.targets ||
            this.loading.targetsMore ||
            this.loading.import ||
            this.loading.usersCount;
    };

    saveLdapProfile = () => {
        this.modal.ldap = this.$injector.instantiate(LdapProfileModal);
        this.modal.ldap.open(this.ldapProfile ? this.ldapProfile.id : null).then((profileData) => {
            if (profileData) {
                this.ldapProfile = profileData;
                this.loadLdapProfiles();
                this.onProfileSelect(profileData);
            }
        }, () => {
        });
    };

    saveBossKadrovikProfile = () => {
        this.modal.ldap = this.$injector.instantiate(BossKadrovikProfileModal);
        this.modal.ldap.open(this.bossKadrovikProfile ? this.bossKadrovikProfile.id : null).then((profileData) => {
            if (profileData) {
                this.bossKadrovikProfile = profileData;
                this.loadBossKadrovikProfiles();
                this.onBossKadrovikSelect(profileData);
            }
        }, () => {
        });
    };

    setLanguageId = (id) => {
        this.languageId = id;
    }

    getSelectedData = (selectedData) => {
        this.selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData), angular.copy(selectedData));
    }

    saveLdapConfig = (action) => {
        this.saveConfig = action;
        this.errorConfig = false;
        const fieldMapping = {
            email: this.emailField,
            firstName: this.firstNameField,
            secondName: this.secondNameField,
            thirdName: this.thirdNameField,
            fullName: this.fullNameField,
            mode: this.fullNameMode,
            position: this.positionField,
            department: this.departmentField,
            riskGroup: this.riskGroupField,
            employeeID: this.externalField,
            manager: this.managerField,
        };
        const enabledfilters = {
            bossKadrovik: this.bossKadrovikProfile,
            'in-groups': this.groupFilter ? this.groupFilter.reduce((prev, item) => {
                if (prev) {
                    return prev + '|' + item.text;
                }
                return item.text;
            }, null) : null
        };
        const marks = angular.copy(this.marks);
        const selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData), { all: this.step === 1 ? 1 : 0 });
        let ldap = {};
        this.LdapProfilesService.one(this.ldapProfile.id).then(profile => {
            ldap = profile;
            if (action > -1) {
                let groupRisk = this.groupRisk ? (this.groupRisk.fullName ? this.groupRisk.fullName : this.groupRisk) : '';
                ldap.activeDefaults = {
                    fieldMapping,
                    enabledfilters,
                    marks,
                    groupRisk
                };
                ldap.selectedData = {
                    ...selectedData
                };
                if (this.languageId) {
                    ldap.activeDefaults.languageId = this.languageId;
                }
            }
            if (action == -1) {
                ldap.activeDefaults = {
                    fieldMapping: {},
                    enabledfilters: {},
                    marks: []
                };
                ldap.selectedData = [];
            }
            ldap.$save().then(() => {
                this.isConfigSave = action == -1 ? false : true;
                this.succsessMessage = this.saveConfig == -1 ? this.gettextCatalog.getString('Настройки импорта сброшены') : this.gettextCatalog.getString('Настройки импорта сохранены');
                this.saveConfig = false;
                if ( this.step === 1) {
                    this.selectedData.all = 0;
                }
                this.$timeout(() => {
                    this.succsessMessage = '';
                }, 3000);
            }).catch(() => {
                this.errorConfig = true;
                this.saveConfig = false;
                if ( this.step === 1) {
                    this.selectedData.all = 0;
                }
                this.errorMessage = this.gettextCatalog.getString('Ошибка при сохранении настроек импорта');
            });
        }).catch(() => {
            this.errorConfig = true;
            this.saveConfig = false;
            if ( this.step === 1) {
                this.selectedData.all = 0;
            }
            this.errorMessage = this.gettextCatalog.getString('Ошибка при сохранении настроек импорта');
        });
    };

    getFilterOptions = () => {
        let filterOptions = {
            'filter-search-query': this.selectedData.search,
            profile: this.ldapProfile.id,
            filter: (this.groupFilter ? this.groupFilter.map(function (item) {
                item = item.text ? item.text : item;
                return item.replace('#', '[hashtag]');
            }).join('|') : null),
            emailField: this.emailField,
            departmentField: this.departmentField,
            riskGroupField: this.riskGroupField,
            positionField: this.positionField,
            managerField: this.managerField,
            fullNameMode: this.fullNameMode,
            firstNameField: this.firstNameField,
            secondNameField: this.secondNameField,
            languageId: this.languageId || 0,
            thirdNameField: this.thirdNameField,
            fullNameField: this.fullNameField,
            ldapDepartmentName: this.chosenDepartmentName,
            marks: this.marks || [],
            groupRisk: this.groupRisk
        };
        if (this.bossKadrovikProfile && this.bossKadrovikProfile.id) {
            filterOptions.bossKadrovik = this.bossKadrovikProfile.id;
        }
        if (this.externalIDEnabled) {
            filterOptions.externalID = this.externalField;
        }
        return filterOptions;
    };

    setParentLoading = () => {
        let loading = this.loadingImport || this.loadingTargets || this.loadingDepartments;
        window.employeesModalCtrl.setInProcess(loading);
    };

    importSelectedTargets = () => {
        this.setLoading('import',true);
        this.alertMessages = [];
        this.importResult = null;
        this.errorsImportText = null;
        this.setError('import', false);
        this.setParentLoading();
        let data = angular.extend({
            selectedData: angular.extend({}, angular.copy(EMPTY_LDAP_SELECTED_DATA), { all: 1 })
        }, this.getFilterOptions());
        data.marks = [];
        if (typeof (this.marks) != 'undefined' && this.marks && this.marks.length > 0) {
            this.marks.forEach((mark) => {
                data.marks.push(mark.text ? mark.text : mark);
            });
        }
        data.groupRisk = '';
        if (this.groupRisk) {
            if (this.groupRisk.fullName) {
                data.groupRisk = this.groupRisk.fullName;
            } else {
                data.groupRisk = this.groupRisk;
            }
        }
        this.TargetsService.ldapImport(data, this, (ctrl, response) => {
            this.importResult = response;
            if (response && response.id) {
                this.checkImport();
            }
        }, (ctrl, response) => {
            ctrl.setLoading('import', false);
            ctrl.setError('import', true);
            if (response.data) {
                if (response.data.error) {
                    ctrl.errorsImportText = response.data.error;
                }
                if (response.data.message) {
                    ctrl.errorsImportText = response.data.message;
                }
                if (response.status && response.status == 504) {
                    ctrl.errorsImportText = this.gettextCatalog.getString('Превышен лимит ожидания импорта сотрудников');
                }
            }
            ctrl.setParentLoading();
        });
    };

    checkImport = () => {
        this.$interval.cancel(this.updateImport);
        fetch(`${window.config.SYSTEM_URL}${window.config.API_URL}/ldap-imports/${this.importResult.id}`, {
            method: 'GET',
            headers: {
                'X-Csrf-Token': window.csrfToken,
                'Enable-Session': 1
            }
        }).then(response => {
            return response.json();
        }).then(response => {
            this.importResult = response;
            if (this.importResult.completed < 1) {
                this.updateImport = this.$interval(this.checkImport, 5000);
            } else {
                this.setLoading('import', false);
                this.setParentLoading();
                this.$scope.$apply();
                this.loadDashboard();
                this.LdapImportsService.action(this.importResult.id, 'decline', this, () => {});
            }
        }).catch(() => {
            if (this.importResult && this.importResult.completed < 1) {
                this.updateImport = this.$interval(this.checkImport, 5000);
            } else {
                this.setLoading('import', false);
                this.setParentLoading();
                this.$scope.$apply();
            }
        });
    };

    setLoading = (loadingType = 'import', value) => {
        this.loading[loadingType] = value ? true : false;
    };

    setError = (errorType = 'import', value) => {
        this.errors[errorType] = value ? true : false;
    };

    setSelectedData = (selectedData) => {
        this.selectedData = angular.extend({}, angular.copy(EMPTY_LDAP_SELECTED_DATA), selectedData);
    };

    clearField = (field) => {
        this[field] = null;
        this.step = 1;
    };

    $onDestroy () {
        this.setDefaultLdap();
        this.unsubscribe();
    }
}

EmployeesImportLdapController.$inject = [
    '$injector',
    '$scope',
    '$timeout',
    LDAP_PROFILES_SERVICE,
    TARGETS_SERVICE,
    BOSS_KADROVIK_SERVICE,
    '$ngRedux',
    GROUPS_SERVICE,
    '$interval',
    LDAP_IMPORTS_SERVICE
];

export {
    EmployeesImportLdapController
};
