import angular from 'angular';
import * as moment from 'moment-timezone';

const filterDepartments = (StatusService) => {
    return (departments, query) => {
        if (departments && query && query.length > 2) {
            const pattern = new RegExp(query, 'i');
            angular.forEach(departments, (department) => {
                let filtered = 0;
                angular.forEach(department.department_targets, (target) => {
                    if (pattern.test(target.fullName) ||
                        pattern.test(target.email) ||
                        pattern.test(target.marks.join('|')) ||
                        pattern.test(StatusService.getFullInfo(target.situation.status, target.situation.hasVulnerabilities))) {
                        filtered++;
                        target.filtered = true;
                    } else {
                        target.filtered = false;
                    }
                });
                department.filtered = filtered > 0 || pattern.test(department.departmentName);
                department.filteredExact = pattern.test(department.departmentName);
            });
        }
        return departments;
    };
};

const highlightSearch = () => {
    return (text, query = '') => {
        if (text) {
            let search = query.length ? query : [query];
            angular.forEach(search, (query) => {
                if (query && query.length > 0) {
                    angular.forEach(['\\', '/', '[', ']', '(', ')', '{', '}', '?', '+', '*', '|', '.', '^', '$'], (symbol) => {
                        query = query.replace(new RegExp(`\\${symbol}`, 'g'), `\\${symbol}`);
                    });
                    if (query.length > 2) {
                        const pattern = new RegExp(query, 'gi');
                        let matches = text.matchAll(pattern);
                        for (const match of matches) {
                            if (match[0]) {
                                text = text.replace(match[0], '<span class="highlight">' + match[0] + '</span>');
                            }
                        }
                    }
                }
            });
            let words = text.split(' ');
            angular.forEach(words, (word, wordKey) => {
                if (word.indexOf('<span') == -1 && word.indexOf('class="highlight"') == -1 && word.indexOf('</span>') == -1 && word.length > 0) {
                    words[wordKey] = word.replace(word, '<span class="nowrap">' + word + '</span>');
                }
            });
            text = words.join(' ');
        }
        return text;
    };
};

const isoDate = () => {
    return function (str) {
        return str.replace(' ', 'T');
    };
};

const localTimeZone = ($localStorage) => {
    return function (str) {
        if ($localStorage.userInfo && $localStorage.userInfo.timeZone) {
            moment.tz.setDefault('Europe/London');
            let utcDate = moment.utc(str);
            str = utcDate.tz($localStorage.userInfo.timeZone).format('YYYY-MM-DD HH:mm:ss');
        }
        return str;
    };
};

const dateFormat = ($filter) => {
    return (str, format) => {
        let strLocal = $filter('localTimeZone')(str);
        let strISO = $filter('isoDate')(strLocal, format);
        return $filter('date')(strISO, format);
    };
};

const plural = () => {
    return (number, ends) => {
        let ending, i;
        let iNumber = number % 100;

        if (iNumber >= 11 && iNumber <= 19) {
            ending = ends[2];
        } else {
            i = iNumber % 10;
            if (i == 1) {
                ending = ends[0];
            } else if (i <= 4) {
                ending = ends[1];
            } else {
                ending = ends[2];
            }
        }

        return ending;
    };
};

const parseUrls = () => {
    const urls = new RegExp('(\\b(https?|ftp):\\/\\/[A-Z0-9+&@#\\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\\/%=~_|])', 'gim');
    const emails = new RegExp('(\\w+@[a-zA-Z_]+?\\.[a-zA-Z]{2,6})', 'gim');
    return (text) => {
        if (text.match(urls)) {
            text = text.replace(urls, '<a href="$1" target="_blank">$1</a>');
        }
        if (text.match(emails)) {
            text = text.replace(emails, '<a href="mailto:$1">$1</a>');
        }
        return text;
    };
};

const parseDmy = ($filter) => {
    const dates = new RegExp('(\\d{4})-(\\d{2})-(\\d{2}) (\\d{2}):(\\d{2}):(\\d{2})', 'gim');
    return (text) => {
        let results;
        while ((results = dates.exec(text)) !== null) {
            let strLocal = $filter('localTimeZone')(results[0]);
            text = text.replace(results[0], $filter('date')(strLocal, 'd MMMM yyyy'));
        }
        return text;
    };
};

export {
    filterDepartments,
    highlightSearch,
    isoDate,
    dateFormat,
    plural,
    parseUrls,
    parseDmy,
    localTimeZone
};
