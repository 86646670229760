import dateSelectorTemplate from './view.html';

import { DateSelectorController } from './controller';

const DateSelectorComponent = {
    template: dateSelectorTemplate,
    controller: DateSelectorController,
    controllerAs: '$',
    scope: true,
    bindings: {
        data: '<',
        onSelect: '<',
        selected: '=',
    },
};

export {
    DateSelectorComponent,
};
