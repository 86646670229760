import { GettextContext } from 'rootReact/context';
import { useContext } from 'react';

export const mailTemplateSchema = ({ handleRow, iconHandle, disabled }) => {
    const gettext = useContext(GettextContext);
    return {
        row: { handleRow, disabled: () => false },
        cell: [
            {
                text: 'name',
            },
            {
                text: 'location',
            },
            {
                text: 'username',
            }
        ],
        icon: [
            {
                isIcon: true,
                icon: disabled ? 'noIcon' : 'edit delete',
                handle: [
                    ...iconHandle
                ]
            }
        ]
    };
};

