import { COURSES_SERVICE } from '../../const';
import style from './style.modules.css';
import { hasAccess, notExtensibleCoursesList } from '../../reselect';
import {
    getLocales,
    checkDefaultLanguage,
    setDefaultLanguage,
    setLocales
} from '../../helpers/content';
import { contentFetch, fetchEntity } from '../../helpers/contentFetch';
import setOptions from '../../helpers/setOptions';
import { defaultConfig } from '../../helpers/modalFooterDefault';
import { addCourse, updateCourse } from '../../toolkit/actions';
import CoursesModal from '../../React/components/Courses/CoursesModal';

const FROM_SERVER = 'FROM_SERVER';
const schema = {
    files: {
        dropzone: 'courseFile',
        imageFile: 'previewFile',
    },
    data: [
        'languageId',
        'isDefault',
        'passScore',
        'name',
        'courseFilename',
        'previewFilename',
        'previewBase64'
    ],
};

class CourseModalController {
    constructor(Upload, CoursesService, courseId, $scope, gettextCatalog, $ngRedux) {
        this.Upload = Upload;
        this.CoursesService = CoursesService;
        this.style = style;
        this.scope = $scope;
        this.courseId = courseId;
        this.inProcess = false;
        this.course = null;
        this.extensions = ['zip'];
        this.imageType = '';
        this.certificateTemplate = null;
        this.imageFile = null;
        this.certDate = 1;
        this.imageSource = null;
        this.activeIndex = 0;
        this.imageDeleting = false;
        this.sizeSuccess = false;
        this.result = null;
        this.gettextCatalog = gettextCatalog;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { addCourse, updateCourse })(this);
        this.CoursesModal = CoursesModal;
        this.redux = $ngRedux;
    }

    mapStateToThis(state) {
        return {
            languages: state.languages.languages,
            accessCoursesSave: hasAccess(state, { sectionId: 8, rightName: 'course_save' }),
            accessCoursesDelete: hasAccess(state, { sectionId: 8, rightName: 'course_delete' }),
            accessCoursesReport: hasAccess(state, { sectionId: 8, rightName: 'report_education' }),
            certificates: state.certificate.certs,
            isCertEnabled: state.auth.auth.license.features.cert,
            coursesList: notExtensibleCoursesList(state)
        };
    }

    $onInit = () => {
        if (this.courseId) {
            this.loadCourse();
        } else {
            this.createNewCourse();
        }
        this.errorText = null;
        this.editable = this.accessCoursesSave;
        this.headerText = !this.editable ?
            this.gettextCatalog.getString('Просмотр курса') :
            (this.courseId ? this.gettextCatalog.getString('Редактирование курса') :
                this.gettextCatalog.getString('Новый курс'));
    };

    createNewCourse = () => {
        let locales = getLocales([], schema, this.languages);
        this.course = this.CoursesService.YiiModel.one('courses');
        let defaultLanguage = checkDefaultLanguage(locales, this.languages);
        this.locales = setDefaultLanguage(defaultLanguage, locales);
        this.active = defaultLanguage.id;
        this.defaultLanguage = defaultLanguage.id;
        if (this.isCertEnabled) {
            this.certificateTemplate = this.getDefaultCert();
            this.certDelay = 365;
        }
        this.languages.forEach((item, index) => {
            if (item.id === this.defaultLanguage) {
                this.activeIndex = index;
            }
        });
        this.setBtnConfig();
    }

    get getFilename() {
        return this.locales[this.activeIndex].dropzone && this.locales[this.activeIndex].dropzone.name || this.locales[this.activeIndex].courseFilename;
    }

    clear = () => {
        this.locales[this.activeIndex].dropzone = null;
        this.locales[this.activeIndex].courseFilename = null;
    }

    setActiveLanguage = (active) => {
        let findIndex = false;
        this.locales.forEach((item, index) => {
            if (item && item.languageId === active) {
                this.activeIndex = index;
                findIndex = true;
            }
        });
        if (!findIndex) {
            this.activeIndex = this.locales.length;
            this.locales[this.activeIndex] = {};
            this.locales[this.activeIndex].languageId = active;
        }
        this.active = active;
    }

    checkTranslations = () => {
        let keys = [];
        let locales = [];
        let changeDefault = true;
        for (let i = 0; i < this.locales.length; i++) {
            if ((!this.locales[i].dropzone && !this.locales[i].courseFilename) ||
                !this.locales[i].passScore ||
                !this.locales[i].name) {
                keys.push(i);
            }
        }
        locales = this.locales.filter((item, index) => keys.indexOf(index) === -1);
        locales.forEach(item => {
            if (item.isDefault === undefined) {
                item.isDefault = 0;
            }
            if (item.isDefault) {
                changeDefault = false;
            }
        });
        if (changeDefault) {
            this.setDefaultLanguage();
        }
        return locales;
    }

    setDefaultLanguage = (languageId) => {
        this.defaultLanguage = languageId;
        this.locales.forEach((item, index) => {
            if (index === this.activeIndex) {
                item.isDefault = 1;
            } else {
                item.isDefault = 0;
            }
        });
    };

    imegeFile = (file) => {
        if (!file) {
            return;
        }
        this.imageType = file.type.split('/')[1].toUpperCase();
        if (this.imageType !== 'GIF' && this.imageType !== 'PNG') {
            this.deleteImage();
            return;
        }
        this.readerImageFile(file);
    };

    readerImageFile = (image) => {
        let ctrl = this;
        let reader = new FileReader();
        let img = new Image();
        img.onload = function () {
            ctrl.locales[ctrl.activeIndex].imageSize = this.width + ' x ' + this.height;
            ctrl.locales[ctrl.activeIndex].imgWidth = this.width;
            ctrl.locales[ctrl.activeIndex].imgHeight = this.height;
            ctrl.locales[ctrl.activeIndex].sizeSuccess = (1.8 > this.width / this.height && this.width / this.height > 1.6);
            ctrl.scope.$apply();
        };
        reader.onload = function (event) {
            ctrl.locales[ctrl.activeIndex].previewBase64 = event.target.result;
            ctrl.locales[ctrl.activeIndex].imageSource = event.target.result;
            img.src = event.target.result;
            ctrl.scope.$apply();
        };
        reader.readAsDataURL(image);
    };

    setCourseFileName = (fileName) => {
        this.locales[this.activeIndex].courseFilename = fileName;
        if (!this.locales[this.activeIndex].name) {
            this.locales[this.activeIndex].name = fileName;
        }
    }

    deleteImage = (languageId) => {
        let ctrl = this;
        this.imageDeleting = true;
        if (this.locales[this.activeIndex].previewFile === FROM_SERVER) {
            let options = setOptions();
            options.method = 'DELETE';
            fetch(`${window.config.SYSTEM_URL}${window.config.API_URL}/courses/${ctrl.courseId}/locales/${languageId}/preview`, options)
                .then(response => {
                    if (response.ok) {
                        ctrl.locales[this.activeIndex].previewFile = null;
                        ctrl.locales[this.activeIndex].imageSource = null;
                        ctrl.locales[this.activeIndex].sizeSuccess = false;
                        ctrl.locales[this.activeIndex].previewBase64 = null;
                        ctrl.imageDeleting = false;
                        ctrl.scope.$apply();
                    }
                });
        } else {
            this.locales[this.activeIndex].previewFile = null;
            this.locales[this.activeIndex].imageSource = null;
            this.locales[this.activeIndex].sizeSuccess = false;
            this.locales[this.activeIndex].previewBase64 = null;
            this.imageDeleting = false;
            this.getImageFromServer(this.locales);
        }
    };

    setForm = (form) => {
        this.form = form;
    };

    getImageType = (imageUrl) => {
        this.imageType = imageUrl.slice(11, 14).toUpperCase();
    };

    getImageFromServer = (locales) => {
        const ctrl = this;
        locales.forEach((item, i) => {
            if (item.previewBase64) {
                let img = new Image();
                this.getImageType(item.previewBase64, i);
                img.onload = function () {
                    ctrl.locales[i].imageSize = this.width + ' x ' + this.height;
                    ctrl.locales[i].imgWidth = this.width;
                    ctrl.locales[i].imgHeight = this.height;
                    ctrl.locales[i].sizeSuccess = (1.8 > this.width / this.height && this.width / this.height > 1.6);
                    ctrl.scope.$apply();
                };
                img.src = item.previewBase64;
                ctrl.locales[i].imageSource = item.previewBase64;
                if (!ctrl.locales[i].previewFile) {
                    ctrl.locales[i].previewFile = FROM_SERVER;
                }
            }
        });
    };

    getDefaultCert = () => {
        if (!this.isCertEnabled) return;
        let cert = this.certificates.find(item => item.isDefault);
        return cert;
    }

    getCert = (id) => {
        if (!this.isCertEnabled) return;
        let cert = this.certificates.find(item => item.id === id);
        return cert;
    }

    loadCourse = () => {
        let callback = () => {
            this.getImageFromServer(this.locales);
            if (this.isCertEnabled) {
                this.certificateTemplate = this.course.certificate_id ? this.getCert(this.course.certificate_id) : this.getDefaultCert();
                this.certDelay = parseInt(this.course.certificate_duration);
            }
            this.allow_self_appointment = this.course.allow_self_appointment;
        };
        fetchEntity.call(this, {
            id: this.courseId,
            name: 'course',
            urlName: 'courses',
            loading: 'loadingCourse',
            error: 'errorCourse'
        }, schema, callback);
    };

    setFormData = async () => {
        if (this.saveCourse) return;
        try {
            this.saveCourse = true;
            let locales = this.checkTranslations();
            let value = await setLocales(locales, this.languages, schema);
            if (this.allow_self_appointment) {
                value.append('allow_self_appointment', +this.allow_self_appointment);
            } else {
                value.append('allow_self_appointment', 0);
            }
            if (this.certificateTemplate) {
                value.append('certificate_id', this.certificateTemplate.id);
            }
            if (this.certDelay) {
                value.append('certificate_duration', this.certDelay);
            }
            this.save(value);

        } catch (err) {
            this.saveCourse = false;
        }
    }

    save = async (formData) => {
        try {
            this.errorCert = false;
            this.errorText = null;
            let course = await contentFetch({ body: formData, content: 'courses' }, this.courseId);
            if (this.courseId) {
                this.updateCourse(course);
            } else {
                this.addCourse(course);
            }
            if (course.error) {
                throw course.error;
            }
            this.saveCourse = false;
            this.$close(course);
        } catch (err) {
            this.saveCourse = false;
            this.errorCert = true;
            this.errorText = [err] || [this.gettextCatalog.getString('Что-то пошло не так :-(')];
            this.scope.$apply();
        }
    };

    parseResponseErrors = (errors) => {
        if (errors && errors.length) {
            errors.forEach((error) => {
                if (['id', 'file', 'name'].indexOf(error.field) > -1) {
                    this.errorText = error.message;
                }
            });
        }
    };

    modalClose = () => {
        if (this.saveCourse) return;
        this.$close();
    }

    disable = () => {
        return this.saveCourse ||
            (!this.locales[this.activeIndex].dropzone &&
                !this.locales[this.activeIndex].courseFilename);
    }

    isLoading = () => {
        return this.saveCourse;
    }

    setBtnConfig = () => {
        this.btnConf = defaultConfig.call(this, [this.modalClose, this.setFormData], [this.isLoading, this.disable]);
    }
}

CourseModalController.$inject = [
    'Upload',
    COURSES_SERVICE,
    'courseId',
    '$scope',
    'gettextCatalog',
    '$ngRedux'
];

export {
    CourseModalController
};
