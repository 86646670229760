import React, { useState, useEffect } from 'react';
import Translate from 'rootReact/Translate.jsx';
import FetchApi from '../../REST';
import { EMPTY_SELECTED_DATA } from 'root/assets/webpack/src/js/const';
import { Calendar } from 'rootReact/shared/decorator/Calendar';
import { CertLink } from 'rootReact/components/CertDownload/CertLink.jsx';
import { fetchDaemonsMessage } from '../../../toolkit/think';
import { useDispatch, useSelector } from 'react-redux';
import { getCertDaemon } from '../../../reselect';
import { saveAs } from 'file-saver';

export const CertDownload = ({}) => {
    const daemonsMessage = useSelector(state => getCertDaemon(state));
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [noneCertificate, setNoneCertificate] = useState(false);
    const dispatch = useDispatch();

    const clearDate = (date) => {
        try {
            return new Date(date).toISOString().replace('T', ' ').split('.')[0];
        } catch (e) {
            return date;
        }
    }

    useEffect(() => {
        setLoading(daemonsMessage && !daemonsMessage.isReady);
        setReady(daemonsMessage && daemonsMessage.isReady);
    }, [daemonsMessage])

    const setDownloadCert = () => {
        setTimeout(() => {
            setNoneCertificate(false);
        }, 2000);
    };

    const handleClick = async (data) => {
        const dateFrom = clearDate(data.dateFrom);
        const dateTo = clearDate(data.dateTo);
        const apiFetch = FetchApi('/daemon/start');
        let response = await apiFetch.post({
            body: {
                data: {
                    selectedData: {...EMPTY_SELECTED_DATA, all: 1},
                    itemId: null,
                    dateFrom,
                    dateTo
                },
                type: 1
            }
        });
        if (response.success) {
            dispatch(fetchDaemonsMessage());
        } else {
            setNoneCertificate(true);
            setDownloadCert();
        }
    }

    const getFilename = (header) => {
        return header.get('Content-Disposition')
                .split(' ')
                .filter(item => item.indexOf('filename') !== -1)[0]
                .replace('filename="', '')
                .replace('"', '');
    }

    const download = async () => {
        let fetchApi = FetchApi('/files/download');
        let response = await fetchApi.post({body: {daemon: daemonsMessage.id}}, () => {}, true);
        let fileName = getFilename(fetchApi.headers);
        saveAs(response, fileName);
        dispatch(fetchDaemonsMessage());
    }

    const cancelDownload = () => {
        let id = daemonsMessage.id;
        const apiFetch = FetchApi('/daemon/cancel?id=' + id);
        apiFetch.get();
    }

    if (ready) {
        return (
                <div><a onClick={download}><Translate>Скачать сертификаты сотрудников архивом</Translate></a></div>
        );
    }

    if (loading) {
        return (
                <div style={{display: 'flex'}}>
                    <span style={{marginRight: '.3rem'}}><Translate>Готовим архив сертификатов...</Translate></span>
                    <a className={'text-danger'} onClick={cancelDownload}><Translate>Отменить</Translate></a>
                </div>
        );
    }

    if (noneCertificate) {
        return (<div><Translate>Нет сертификатов для скачивания</Translate></div>);
    }

    return (
            <Calendar popupText={'Подготовить архив'}
                      handleClick={handleClick}>
                <CertLink />
            </Calendar>
    );
};
