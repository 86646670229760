import style from './style.modules.css';

class LdapFieldsMapModalController {
    constructor (map) {
        this.map = map;
        this.style = style;
    }

    changeMap = (map) => {
        this.map = map;
    }
}

LdapFieldsMapModalController.$inject = [
    'map',
];

export {
    LdapFieldsMapModalController
};
