import dashboardTemplate from './view.html';

import { DashboardItemController } from './controller';

const DashboardItemComponent = {
    template: dashboardTemplate,
    controller: DashboardItemController,
    controllerAs: '$',
    bindings: {
        accessDashboard: '<',
        accessDashboardView: '<',
        accessDashboardEducation: '<',
        accessDashboardAttack: '<',
        itemConf: '<',
        chartId: '<',
        chartIndex: '<',
        chartLabel: '<',
        square: '<',
        itemTitle: '<',
        targets: '<',
        licenseValid: '<',
        selenium: '<',
    },
};

export {
    DashboardItemComponent,
};
