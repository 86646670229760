import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import { GettextContext } from 'rootReact/context';
import Select, { components } from 'react-select';
import FaIcon from 'rootReact/components/FaIcon';
import FetchApi from '../../REST';

function ModalRole({ onModalClose }) {
    const [formError, setFormError] = useState([]);
    const [save, setSave] = useState(false);
    const [loading, setLoading] = useState(false);
    const gettext = useContext(GettextContext);
    const ldapProfiles = useSelector((state) => state.ldapProfiles.ldapProfiles);
    const [ldap, setLdap] = useState({});
    const [sections, setSections] = useState([]);
    const [rights, setRights] = useState([]);
    const [checked, setChecked] = useState(false);
    const [activeCheckbox, setActiveCheckbox] = useState([]);
    //const [sectionsResult, setSectionsResult] = useState({});

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props} className='modal-role_drop-select'>
                <svg width='10' height='5' viewBox='0 0 10 5'>
                    <path d='M0 0L5 5L10 0H0Z' fill='#333333' />
                </svg>
            </components.DropdownIndicator>
        );
    };

    const getValueSelect = () => {
        ldap ? optionsLdap.find((item) => item.value === ldap) : '';
    };
    const onChangeSelect = (newValue) => {
        setLdap({ ...ldap, newValue });
    };

    const optionsLdap = ldapProfiles.map((item) => {
        const newItem = {};
        newItem.value = item.id;
        newItem.label = item.title;
        return newItem;
    });

    useEffect(() => {
        async function fetchApi() {
            let responseRights = await FetchApi('/roles/rights').get();
            let responseSections = await FetchApi('/roles/sections').get();
            if ((responseRights && responseRights.isError) || (responseSections && responseSections.isError)) {
                throw responseRights || responseSections;
            }
            setRights(responseRights);
            setSections(responseSections);
        }
        try {
            fetchApi();
        } catch (e) {
            console.log(e);
        }
    }, []);

    const saveSettings = () => {};

    //добавляем в главный объект sections поле rights из объекта rights
    let sectionsResult = {};
    let initRights = { access: -1, rights: [] };
    for (let key in sections) {
        sections[key].sections = true;
        sectionsResult[key] = { ...initRights, type: key };
        if (sections[key].list) {
            //у sections есть доп.поле list, в которое добавляются rights
            for (let x in sections[key].list) {
                sections[key].list[x].sections = true;
                sectionsResult[x] = { ...initRights, type: x };
                if (rights[x]) {
                    sections[key].list[x].rights = rights[x];
                }
            }
        }
        if (rights[key]) {
            sections[key].rights = rights[key];
        }
    }

    function nameUpperCase(str) {
        if (!str) return str;
        return str[0].toUpperCase() + str.slice(1);
    }

    const idRights = () => {
        const idRights = [];
        const arrValuesRights = Object.values(rights); // [{}, {}]
        for (let id of arrValuesRights) {
            const key = Object.keys(id);
            key.forEach((i) => idRights.push(i));
        }
        return idRights;
    };

    const idListRights = () => {
        let idListRights = [];
        const arrValuesRights = Object.values(rights); // [{}, {}]
        for (let obj of arrValuesRights) {
            let arr = Object.values(obj);
            for (let obj of arr) {
                if (obj.hasOwnProperty('list')) {
                    let objList = Object.keys(obj.list);
                    objList.forEach((i) => idListRights.push(i));
                }
            }
        }
        return idListRights;
    };

    const idListSections = () => {
        const idListSections = [];
        const arrValuesSections = Object.values(sections); // [{}, {}]
        for (let obj of arrValuesSections) {
            if (obj.hasOwnProperty('list')) {
                const objList = obj.list;
                const arr = Object.keys(objList);
                idListSections.push(arr);
            }
        }
        return idListSections;
    };

    const onRemoveCheckbox = (id) => {
        const idSections = Object.keys(sections);
        //const idRights = idRights();
        //const idListSections = idListSections();
        //const idListRights = idListRights();

        if (idSections.includes(id)) {
            const arrValuesSections = Object.values(sections);
            const idRemoveSections = [];
            const idRemoveRights = [];
            for (let obj of arrValuesSections) {
                if (obj.hasOwnProperty('onRemove')) {
                    const objOnRemove = obj.onRemove;
                    if (objOnRemove.hasOwnProperty('rights')) {
                        const result = Object.values(objOnRemove.rights);
                        idRemoveRights.push(result);
                        idRemoveRights.forEach((i) =>
                            setActiveCheckbox(
                                activeCheckbox.filter((item) => {
                                    if (item.id !== i.toString()) {
                                        return [...activeCheckbox];
                                    }
                                })
                            )
                        );
                    }
                }
            }
        }
        if (idRights().includes(id)) {
            const arrValuesRights = Object.values(rights);
            const idRemoveSections = [];
            const idRemoveRights = [];
            for (let obj of arrValuesRights) {
                if (obj.hasOwnProperty('onRemove')) {
                    const objOnRemove = obj.onRemove;
                    if (objOnRemove.hasOwnProperty('rights')) {
                        const result = Object.values(objOnRemove.rights);
                        idRemoveRights.push(result);
                        idRemoveRights.forEach((i) =>
                            setActiveCheckbox(
                                activeCheckbox.filter((item) => {
                                    if (item.id !== i.toString()) {
                                        return [...activeCheckbox];
                                    }
                                })
                            )
                        );
                    }
                }
            }
        }
        /*if (idListRights.includes(id)) {
        }
        if (idListSections.includes(id)) {
        }*/
    };

    const onAddCheckbox = (id) => {};

    const onClickCheckbox = (obj, parentId = null) => {
        const activeItem = activeCheckbox.find((item) => item.id === obj.id);
        if (activeCheckbox.some((e) => e.id === obj.id)) {
            if (obj.id === activeItem.id) {
                setActiveCheckbox(
                    activeCheckbox.filter((item) => {
                        if (item.id !== obj.id) {
                            return [...activeCheckbox];
                        }
                    })
                );
                //удаление связанного

            }
        } else {
            setActiveCheckbox((prevState) => {
                return [...prevState, { id: obj.id, parentId: parentId, onRemove: obj.onRemove, onAdd: obj.onAdd }];
                //добавление связанному
            });
        }
    };

    const sectionsList = Object.keys(sections).map(
        (itemId) =>
            sections[itemId].name && (
                <div key={itemId} className='modal-role_checkbox-group'>
                    <div key={itemId} className='modal-role_checkbox-label' onClick={() => onClickCheckbox(sections[itemId])}>
                        <div
                            className={`react-checkbox modal-role_checkbox ${
                                activeCheckbox.some((e) => e.id === itemId) ? 'react-checkbox_active' : ''
                            }`}
                        ></div>
                        {gettext.getString(`${nameUpperCase(sections[itemId].name)}`)}
                    </div>
                    {activeCheckbox.some((e) => e.id === itemId) && (
                        <div className='modal-role_checkbox-subgroup'>
                            {sections[itemId].list &&
                                Object.keys(sections[itemId].list).map(
                                    (listId) =>
                                        !sections[itemId].list[listId].disabled && (
                                            <div
                                                key={listId}
                                                className='modal-role_checkbox-label'
                                                onClick={() => onClickCheckbox(sections[itemId].list[listId], itemId)}
                                            >
                                                <div
                                                    className={`react-checkbox modal-role_checkbox ${
                                                        activeCheckbox.some((e) => e.id === listId)
                                                            ? 'react-checkbox_active'
                                                            : ''
                                                    }`}
                                                ></div>
                                                {gettext.getString(
                                                    `${nameUpperCase(sections[itemId].list[listId].name)}`
                                                )}
                                            </div>
                                        )
                                )}
                            {sections[itemId].rights &&
                                Object.keys(sections[itemId].rights).map(
                                    (valueId) =>
                                        sections[itemId].rights[valueId].name && (
                                            <>
                                                <div
                                                    key={valueId}
                                                    className='modal-role_checkbox-label'
                                                    onClick={() => onClickCheckbox(sections[itemId].rights[valueId], itemId)}
                                                >
                                                    <div
                                                        className={`react-checkbox modal-role_checkbox ${
                                                            activeCheckbox.some((e) => e.id === valueId)
                                                                ? 'react-checkbox_active'
                                                                : ''
                                                        }`}
                                                    ></div>
                                                    {gettext.getString(`${sections[itemId].rights[valueId].name}`)}
                                                </div>
                                                {activeCheckbox.some((e) => e.id === valueId) && (
                                                    <div className='modal-role_checkbox-subgroup'>
                                                        {sections[itemId].rights[valueId].list &&
                                                            Object.keys(sections[itemId].rights[valueId].list).map(
                                                                (keyId) =>
                                                                    sections[itemId].rights[valueId].list[keyId]
                                                                        .name && (
                                                                        <div
                                                                            key={keyId}
                                                                            className='modal-role_checkbox-label'
                                                                            onClick={() =>
                                                                                onClickCheckbox(sections[itemId].rights[valueId].list[keyId], itemId)
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={`react-checkbox modal-role_checkbox ${
                                                                                    activeCheckbox.some(
                                                                                        (e) => e.id === keyId
                                                                                    )
                                                                                        ? 'react-checkbox_active'
                                                                                        : ''
                                                                                }`}
                                                                            ></div>
                                                                            {gettext.getString(
                                                                                `${sections[itemId].rights[valueId].list[keyId].name}`
                                                                            )}
                                                                        </div>
                                                                    )
                                                            )}
                                                    </div>
                                                )}
                                            </>
                                        )
                                )}
                        </div>
                    )}
                </div>
            )
    );

    return (
        <>
            <ModalHeader title={gettext.getString('Новая роль')} closeModal={() => onModalClose()} />
            <div className='modal-role'>
                <button onClick={() => console.log(activeCheckbox)}>TEST</button>
                <div className='modal-role_block role-mb'>
                    <label htmlFor='' className='modal-role_label'>
                        {gettext.getString('Название')}
                    </label>
                    <input type='text' className='modal-role_input' />
                </div>
                <div className='modal-role_block'>
                    <label htmlFor='' className='modal-role_label'>
                        {gettext.getString('Страница по-умолчанию')}
                    </label>
                    <div className='modal-role_btns'>
                        <button className='modal-role_btns-item left'>{gettext.getString('Сотрудники')}</button>
                        <button className='modal-role_btns-item'>{gettext.getString('Атаки')}</button>
                        <button className='modal-role_btns-item'>{gettext.getString('Уязвимости')}</button>
                        <button className='modal-role_btns-item'>{gettext.getString('Отчеты')}</button>
                        <button className='modal-role_btns-item right'>{gettext.getString('Настройки')}</button>
                    </div>
                </div>
                <div className='modal-role_block'>
                    <label htmlFor='' className='modal-role_label'>
                        {gettext.getString('Профиль LDAP')}
                    </label>
                    <div className='modal-role_select-block'>
                        <Select
                            classNamePrefix='modal-role_select'
                            onChange={onChangeSelect}
                            value={getValueSelect()}
                            options={optionsLdap}
                            isSearchable={false}
                            placeholder={gettext.getString('Выберите профиль')}
                            components={{ DropdownIndicator }}
                            //defaultValue={optionsPhishingPage[indexSelectPhishingPage]}
                            data-testid='modal-role-select'
                        />
                    </div>
                </div>
                <div className='modal-role_block bottom-group'>
                    <label htmlFor='' className='modal-role_label'>
                        {gettext.getString('Доступы')}
                    </label>
                    <div className='modal-role_block-checkbox'>{sectionsList}</div>
                </div>
            </div>
            <div className='modal-role_process'>
                {formError.length !== 0 ? <FaIcon type={'error'} text={formError} /> : ''}
                {save ? <FaIcon type={'pending'} text={'Сохранение...'} /> : ''}
                <ModalFooter
                    styleBtnOk={{ backgroundColor: '#37397F' }}
                    titleBtnOk={gettext.getString('Добавить')}
                    titleBtnClose={gettext.getString('Отменить')}
                    closeModal={() => onModalClose()}
                    //disabled={}
                    disabledCancel={loading || save ? true : false}
                    onEnter={saveSettings}
                />
            </div>
        </>
    );
}

export default ModalRole;
