import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FetchApi from "../React/REST";

const initialState = {
    ldapProfiles: [],
    error: false,
    loading: false,
    firstLoading: false,
};

export const fetchLdapProfiles = createAsyncThunk("ldapProfiles/status", async () => {
    const api = FetchApi("/ldap-profiles");
    let response = await api.get();
    if (response.isError) {
        throw response;
    }
    return response;
});

export const deleteLdapProfile = createAsyncThunk('ldapProfiles/delete', async (id) => {
    const api = FetchApi("/ldap-profiles");
    let response = await api.delete(id);
    if (response.isError) {
        throw response;
    }
    return;
});

const ldapProfilesSlice = createSlice({
    name: "ldapProfiles",
    initialState,
    reducers: {
        setLdapProfiles(state, { payload }) {
            state.ldapProfiles = payload;
        },
        setProfile(state, { payload }) {
            state.ldapProfiles.unshift(payload);
        },
        editProfile(state, { payload }) {
            state.ldapProfiles = state.ldapProfiles.map(item => {
                if (payload.id === item.id) {
                    return payload;
                }
                return item;
            });
        }
    },
    extraReducers: {
        [fetchLdapProfiles.pending]: (state) => {
            state.loading = true;
            state.firstLoading = false;
        },
        [fetchLdapProfiles.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.firstLoading = true;
            state.error = false;
            (state.errorText = ""), (state.ldapProfiles = payload);
        },
        [fetchLdapProfiles.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = true;
            state.errorText = payload;
        },
        [deleteLdapProfile.fulfilled]: (state, { meta }) => {
            let id = meta.arg;
            state.error = false;
            state.ldapProfiles = state.ldapProfiles.filter(item => id !== item.id);
        },
        [deleteLdapProfile.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = true;
            state.errorText = payload;
        },
    },
});

export const { setLdapProfiles, setProfile, editProfile } = ldapProfilesSlice.actions;
export default ldapProfilesSlice.reducer;
