import React from 'react';
import style from './table.module.less';

export function TableCell({ children, Style }) {

    return (
        <td className={`react-table_cell ${style.cell} ${Style}`}>
            {children}
        </td>
    );
}
