import tpl from './view.html';

import { LdapFieldsMapController } from './controller';

const LdapFieldsMapComponent = {
    template: tpl,
    controller: LdapFieldsMapController,
    controllerAs: '$',
    bindings: {
        map: '<',
        changeMap: '='
    }
};

export {
    LdapFieldsMapComponent
};
