import settingsEducationTemplate from './view.html';

import { SettingsEducationController } from './controller';

const SettingsEducationAFComponent = {
    template: settingsEducationTemplate,
    controller: SettingsEducationController,
    controllerAs: '$',
    scope: true,
    bindings: {
        getLearningParams: '=',
        learningConfig: '<',
        languages: '<'
    },
};

export {
    SettingsEducationAFComponent,
};
