import React, { useContext, useEffect, useRef } from 'react';
import { GettextContext, InjectorContext } from 'rootReact/context';
import SettingsHeader from 'rootReact/components/SettingsSectionHeader';
import SettingsTable from 'rootReact/components/SettingsTable';
import { useSelector, useDispatch } from 'react-redux';
import { accountSchema } from './SettingsAccountSchema.jsx';
import { UserModal } from 'root/assets/webpack/src/js/components/userModal';
import { deleteAccount, fetchAccount } from 'root/assets/webpack/src/js/toolkit/think';
import { UserPolicyModal } from 'root/assets/webpack/src/js/components/userPolicyModal';
import { AuthSettingsModal } from 'root/assets/webpack/src/js/components/userAuthSettingsModal';
import { setLinkings } from 'root/assets/webpack/src/js/toolkit/actions';
import SaveFile from 'rootReact/components/SaveFile';

function SettingsAccount({ licenseIsValid }) {
    const gettext = useContext(GettextContext);
    const injector = useContext(InjectorContext);
    const accounts = useSelector(state => state.accountUsers.accountUsers);
    const hashPath = useSelector(state => state.linking.link);
    const myRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAccount());
    }, []);

    useEffect(() => {
        if (hashPath === 'accountCreate') {
            const executeScroll = () => myRef.current.scrollIntoView(false);
            executeScroll();
            openModal();
            dispatch(setLinkings(''));
        }
        if (hashPath === 'accountField') {
            const executeScroll = () => myRef.current.scrollIntoView(false);
            executeScroll();
            dispatch(setLinkings(''));
        }
    }, [hashPath]);

    const files = [
        [gettext.getString('журнал действий'), '/v2/reports/download/user', 'user[data].csv'],
        [gettext.getString('журнал пользователей'), '/v2/stats/users', 'users[data].csv'],
        [gettext.getString('журнал авторизованных пользователей'), '/v2/stats/sessions', 'sessions[data].csv']
    ];

    const getHeaderLink = () => {
        return (
            <div style={{ display: 'flex' }}>
                <a onClick={() => licenseIsValid && openModal()}
                    className={`settings_path-right ${!licenseIsValid ? 'nolink' : ''}`}>{gettext.getString('добавить')}</a>
                <a onClick={() => licenseIsValid && openPolicy()}
                    style={{ marginRight: '.3rem' }}
                    className={!licenseIsValid ? 'nolink' : ''}>{gettext.getString('требования к паролю')}</a>
                {` ${gettext.getString('и')} `}
                <a onClick={() => licenseIsValid && openAuthSettings()}
                    style={{ marginLeft: '.3rem', marginRight: '15px' }}
                    className={!licenseIsValid ? 'nolink' : ''}>{gettext.getString('авторизации')}</a>
                <SaveFile files={files}/>
            </div>
        );
    };

    const openModal = (id = 0) => {
        let modal = injector.instantiate(UserModal);
        modal.open(id);
    };

    const openPolicy = () => {
        let modal = injector.instantiate(UserPolicyModal);
        modal.open(0);
    };

    const openAuthSettings = () => {
        let modal = injector.instantiate(AuthSettingsModal);
        modal.open(0);
    };

    const handleRow = (id) => {
        openModal(id);
    };

    const nameForDelete = (id) => {
        return accounts?.find(item => item.id === id)?.fullName;
    };

    const iconDelete = (e, id) => {
        e.stopPropagation();
        let name = nameForDelete(id);
        let result = confirm(`${gettext.getString('Удалить аккаунт')} ${name}?`);
        if (result) {
            dispatch(deleteAccount(id));
        }
    };

    return (
        <div id={'account-create'} ref={myRef} style={{ marginLeft: '15px', marginRight: '15px' }}>
            <SettingsHeader title={gettext.getString('Учетные записи')} right>{getHeaderLink()}</SettingsHeader>
            <div className={'settings_item'}>
                <SettingsTable header={[
                    { name: gettext.getString('ФИО') },
                    { name: gettext.getString('Email') },
                    { name: gettext.getString('Телефон') },
                    { name: gettext.getString('Отдел') },
                    { name: gettext.getString('Должность') },
                    { name: gettext.getString('Двухфакторная аутентификация') },
                    { name: '' }
                ]}
                body={accounts}
                assets={{ empty: gettext.getString('Нет учетных записей') }}
                cellSchema={
                    accountSchema({
                        iconHandle:[iconDelete],
                        handleRow,
                        disabled: !licenseIsValid
                    })}
                ></SettingsTable>
            </div>
        </div>
    );
}

export default SettingsAccount;
