import modalTemplate from './view.html';

import { UserAuthSettingsModalController } from './controller';

import { USER_AUTH_SETTINGS_MODAL_CONTROLLER } from '../../const';

class AuthSettingsModal {
    constructor ($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    open = (userId) => {
        this.modalInstance = this.$uibModal.open({
            template: modalTemplate,
            bindToController: true,
            controller: USER_AUTH_SETTINGS_MODAL_CONTROLLER,
            controllerAs: '$',
            size: 'md',
            resolve: {
                userId: userId
            },
            backdrop: 'static',
            keyboard: false
        });

        return this.modalInstance.result;
    };
}

export {
    UserAuthSettingsModalController,
    AuthSettingsModal
};
