import schedulerTaskModalTemplate from './view.html';

import { SchedulerTaskModalController } from './controller';

import { SCHEDULER_TASK_MODAL_CONTROLLER } from '../../const';

class SchedulerTaskModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    open = (taskId, settingsCtrl) => {
        /*
        * Проблема у РТК: если новое правило, то у него id 0 и у них БД из-за настроек
        * генерило исключение по дублированию первичного ключа со значением "0". Ставим null чтобы избежать этого.
        */
        if (taskId === 0) {taskId = null;}
        this.modalInstance = this.$uibModal.open({
            template: schedulerTaskModalTemplate,
            bindToController: true,
            controller: SCHEDULER_TASK_MODAL_CONTROLLER,
            controllerAs: '$',
            size: '885',
            windowClass: 'center-modal',
            resolve: {
                taskId: taskId,
                settingsCtrl: settingsCtrl,
            },
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}

export {
    SchedulerTaskModal,
    SchedulerTaskModalController,
};
