import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';

const initialState = {
    task: [],
    error: false,
    loading: false,
    taskLoading: false,
};

export const fetchSchedulerTasksStatus = createAsyncThunk(
    'schedulerTasks/fetchSchedulerTasksStatus',
    async (userId) => {
        const fetchApi = FetchApi('/scheduler-tasks?fullList=1');
        const response = await fetchApi.get(userId, {params: 'fullList=1'});
        if(response.isError) {
            throw response;
        }
        return response;
    }
);

export const deleteSchedulerTasks = createAsyncThunk(
    'schedulerTasks/deleteSchedulerTasks',
    async (id) => {
        const fetchApi = FetchApi('/scheduler-tasks');
        const response = await fetchApi.delete(id);
        if(response.isError) {
            throw response;
        }
        return response;
    }
);

export const toggleSchedulerTask = createAsyncThunk(
    'schedulerTasks/toggle',
    async ({id, status}) => {
        const fetchApi = FetchApi('/scheduler-tasks');
        const response = await fetchApi.put(id, {body:{
                id: id,
                task_status: status,
            }});
        if(response.isError) {
            throw response;
        }
        return response;
    }
)

const schedulerTasksSlice = createSlice({
    name: 'schedulerTasks',
    initialState,
    reducers: {
        setSchedulerTasks(state, { payload }) {
            state.task = payload;
        },
        setNewSchedulerTask(state, {payload}) {
            state.task.unshift(payload);
        },
        editSchedulerTask(state, {payload}) {
            state.task = state.task.map(item => {
                if (item.id === payload.id) {
                    return payload;
                } else {
                    return  item;
                }
            });
        }
    },
    extraReducers: {
        [fetchSchedulerTasksStatus.fulfilled]: (state, { payload }) => {
            state.task = payload;
            state.error = false;
            state.loading = false;
        },
        [fetchSchedulerTasksStatus.pending]: (state) => {
            state.error = false;
            state.loading = true;
        },
        [fetchSchedulerTasksStatus.rejected]: (state) => {
            state.loading = false;
            state.error = true;
        },
        [deleteSchedulerTasks.fulfilled]: (state, { meta }) => {
            let id = meta.arg;
            state.task = state.task.filter(item => item.id !== id);
            state.taskLoading = false;
        },
        [deleteSchedulerTasks.pending]: (state) => {
            state.taskLoading = true;
        },
        [deleteSchedulerTasks.rejected]: (state) => {
            state.taskLoading = false;
        },
        [toggleSchedulerTask.fulfilled]: (state, { payload, meta }) => {
            state.task = state.task.map(item => {
                if (payload.id === item.id) {
                    return payload;
                }
                return item;
            })
            state.error = false;
            state.loading = false;
            state.taskLoading = false;
        },
        [toggleSchedulerTask.pending]: (state) => {
            state.error = false;
            state.loading = false;
            state.taskLoading = true;
        },
        [toggleSchedulerTask.rejected]: (state) => {
            state.loading = false;
            state.error = true;
            state.taskLoading = false;
        },
    }
});

export const { setSchedulerTasks, setNewSchedulerTask, editSchedulerTask } = schedulerTasksSlice.actions;
export default schedulerTasksSlice.reducer;
