import groupsGraphTemplate from './view.html';

import { GroupsGraphController } from './controller';

const GroupsGraphComponent = {
    template: groupsGraphTemplate,
    controller: GroupsGraphController,
    controllerAs: '$',
    scope: true,
    bindings: {
        groupsList: '<',
        isLoading: '<',
        isAnyLoading: '=',
        setSortBy: '=',
        loadGroups: '='
    },
};

export {
    GroupsGraphComponent,
};
