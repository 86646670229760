import selfProgressBarTemplate from './view.html';

import { SelfProgressBarController } from './controller';

const SelfProgressBarComponent = {
    template: selfProgressBarTemplate,
    controller: SelfProgressBarController,
    controllerAs: '$',
    scope: true,
    bindings: {
        companyTargetCount: '<',
        companyTargets: '<',
        targetInSystem: '<'
    },
};

export {
    SelfProgressBarComponent,
};
