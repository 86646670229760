import { useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GettextContext, InjectorContext } from 'rootReact/context';
import WifiAttackModal from '../WifiAttackModal';
import { ReactModal } from 'root/assets/webpack/src/js/components/ReactModal';
import FetchApi from '../../REST';
import { updateWifi, updateWifiChecked } from '../../../toolkit/actions';
import { fetchDaemonsMessage, deleteWifi } from 'root/assets/webpack/src/js/toolkit/think';

function WifiButtonGroup({
    popupDelete,
    setErrorAttacks,
    setPopupError,
    setReportStart,
    findChecked,
    setPopupDelete,
    setPopupDeleteDone,
    setAttackName,
    setDeleteIds
}) {
    const gettext = useContext(GettextContext);
    const injector = useContext(InjectorContext);
    const dispatch = useDispatch();
    const wifiAttack = useSelector((state) => state.wifi.wifi);
    const [statusStop, setStatusStop] = useState(false);
    const [activeBtn, setActiveBtn] = useState(false);
    const [btnToken, setBtnToken] = useState('');
    const [deleteTimeout, setDeleteTimeout] = useState(null);
    const [activeStartCheck, setActiveStartCheck] = useState(false);
    const [buttonLoad, setButtonLoad] = useState(false);
    const idGroup = findChecked.map((item) => item.id);
    const checkedName = findChecked.map((item) => item.name);

    useEffect(() => {
        if (findChecked.length === 1 && findChecked[0].status === 1) {
            setBtnToken(findChecked[0].device.token);
        } else {
            setBtnToken('');
        }
    }, [findChecked]);

    //0 - новая, 1 - запущена, 2 - остановлена

    //null - новая, 1 - запущена, 0 - остановлена

    useEffect(() => {
        findChecked.every((item) => {
            if (item.status === 1) {
                setStatusStop(true);
            } else if (item.status === 2 || item.status == 0) {
                setStatusStop(false);
            }
        });
    }, [findChecked]);

    useEffect(() => {
        if (findChecked.length !== 0) {
            setActiveBtn(true);
        } else {
            setActiveBtn(false);
        }
    }, [findChecked]);

    //следит за статусами у выбранных чекбоксов, если разные статусы, то кнопки остановить/запустить дизейбл
    useEffect(() => {
        const statusStopNew = findChecked.every((el) => el.status === 0 || el.status === 2);
        const statusActive = findChecked.every((el) => el.status === 1);
        if ((statusStopNew || statusActive) && findChecked.length !== 0) {
            setActiveStartCheck(true);
        } else {
            setActiveStartCheck(false);
        }
    }, [findChecked]);

    const stopAttack = async (ids) => {
        setButtonLoad(true);
        try {
            const response = await FetchApi('/wifi-attacks/stop').post({
                body: {
                    ids,
                },
            });
            if (response && response.isError) {
                throw response;
            }
            for (let key in response.success) {
                if (ids.includes(Number(key))) {
                    const attack = wifiAttack.find((item) => item.id === Number(key));
                    const updateStatus = { ...attack, status: 2 };
                    dispatch(updateWifi(updateStatus));
                    dispatch(updateWifiChecked(Number(key)));
                }
            }
            setButtonLoad(false);
        } catch (e) {
            console.log(e);
            setButtonLoad(false);
        }
    };

    const startAttack = async (ids) => {
        setButtonLoad(true);
        try {
            const response = await FetchApi('/wifi-attacks/start').post({
                body: {
                    ids,
                },
            });
            if (response && response.isError) {
                throw response;
            }
            for (let key in response.success) {
                if (ids.includes(Number(key))) {
                    const attack = wifiAttack.find((item) => item.id === Number(key));
                    const updateStatus = { ...attack, status: 1 };
                    dispatch(updateWifi(updateStatus));
                    dispatch(updateWifiChecked(Number(key)));
                }
            }
            if (response.errors) {
                setErrorAttacks(Object.values(response.errors));
                setPopupError(true);
            }
            setButtonLoad(false);
        } catch (e) {
            console.log(e);
            setButtonLoad(false);
        }
    };

    const deleteWifiAttack = async (ids) => {
        setAttackName(checkedName);
        setPopupDelete(true);
        await dispatch(deleteWifi(ids));
        setDeleteIds(ids);
    };

    useEffect(() => {
        if (popupDelete) {
            setDeleteTimeout(setTimeout(() => setPopupDeleteDone(true), 8000));
        } else {
            setDeleteTimeout(clearTimeout(deleteTimeout));
        }
    }, [popupDelete]);


    const copyWifiAttack = async (ids) => {
        const modal = injector.instantiate(ReactModal);
        let wifiModal = await modal.open({
            component: WifiAttackModal,
            props: {
                id: ids,
                copy: true,
            },
        });
    };

    const startSaveFile = async (ids) => {
        setReportStart(true);
        setButtonLoad(true);
        try {
            const response = await FetchApi('/daemon/start').post({
                body: {
                    type: 2,
                    data: {
                        ids,
                        reportType: 'wifi',
                    },
                },
            });
            if (response && response.isError) {
                throw response;
            }
            dispatch(fetchDaemonsMessage(response));
            setButtonLoad(false);
        } catch (e) {
            console.log(e);
            setReportStart(false);
            setButtonLoad(false);
        }
    };

    return (
        <div className='wifi-attack_btns'>
            {!statusStop ? (
                <button
                    data-testid='wifi-attackStartAttack'
                    onClick={() => startAttack(idGroup)}
                    className={`wifi-attack_btn ${activeStartCheck ? 'wifi-attack_active-btn' : ''}`}
                    disabled={!activeStartCheck || buttonLoad}
                >
                    <svg className={`wifi-attack_btn-play ${activeStartCheck ? 'wifi-attack_active-icon' : ''}`}>
                        <use xlinkHref={'/image/icoSprite.svg#play-wifi-new'}></use>
                    </svg>
                    {gettext.getString('Запустить')}
                </button>
            ) : (
                <button
                    data-testid='wifi-attackStopAttack'
                    onClick={() => stopAttack(idGroup)}
                    className={`wifi-attack_btn ${activeStartCheck ? 'wifi-attack_active-btn' : ''}`}
                    disabled={!activeStartCheck || buttonLoad}
                >
                    <svg className={`wifi-attack_btn-pause ${activeStartCheck ? 'wifi-attack_active-icon' : ''}`}>
                        <use xlinkHref={'/image/icoSprite.svg#pause-wifi-new'}></use>
                    </svg>
                    {gettext.getString('Остановить')}
                </button>
            )}
            <button
                data-testid='wifi-attackCheckAttack'
                onClick={() => {
                    const url = `/payload/wifi/connect/${btnToken}`;
                    window.open(url, '_blank');}
                }
                className={`wifi-attack_btn ${btnToken ? 'wifi-attack_active-btn' : ''}`}
                disabled={!btnToken}
            >

                <svg className={`wifi-attack_btn-check ${btnToken ? 'wifi-attack_active-icon' : ''}`}>
                    <use xlinkHref={'/image/icoSprite.svg#check-wifi-new'}></use>
                </svg>

                {gettext.getString('Проверить')}
            </button>

            <button
                data-testid='wifi-attackReport'
                onClick={() => startSaveFile(idGroup)}
                className={`wifi-attack_btn ${activeBtn ? 'wifi-attack_active-btn' : ''}`}
                disabled={findChecked.length === 0 || buttonLoad}
            >
                <svg className={`wifi-attack_btn-report ${activeBtn ? 'wifi-attack_active-icon' : ''}`}>
                    <use xlinkHref={'/image/icoSprite.svg#report-wifi-new'}></use>
                </svg>
                {gettext.getString('Скачать отчет')}
            </button>
            <button
                data-testid='wifi-attackCopyAttack'
                onClick={() => copyWifiAttack(idGroup)}
                className={`wifi-attack_btn ${findChecked.length === 1 ? 'wifi-attack_active-btn' : ''}`}
                disabled={findChecked.length !== 1 || buttonLoad}
            >
                <svg className={`wifi-attack_btn-copy ${findChecked.length === 1 ? 'wifi-attack_active-icon' : ''}`}>
                    <use xlinkHref={'/image/icoSprite.svg#copy-wifi-new'}></use>
                </svg>
                {gettext.getString('Дублировать')}
            </button>
            <button
                data-testid='wifi-attackDelete'
                onClick={() => deleteWifiAttack(idGroup)}
                className={`wifi-attack_btn ${activeBtn ? 'wifi-attack_active-btn' : ''}`}
                disabled={findChecked.length === 0 || buttonLoad}
            >
                <svg className={`wifi-attack_btn-delete ${activeBtn ? 'wifi-attack_active-icon' : ''}`}>
                    <use xlinkHref={'/image/icoSprite.svg#delete-wifi-new'}></use>
                </svg>
                {gettext.getString('Удалить')}
            </button>
        </div>
    );
}

export default WifiButtonGroup;
