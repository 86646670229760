import { createSelector } from 'reselect';

const coursesList = (state) => {
    return state.courses.courses;
};
export const notExtensibleCoursesList = createSelector([coursesList], coursesList => {
    return coursesList.map((item) => {
        let newObj = {};
        for (let key in item) {
            newObj[key] = item[key];
        }
        return newObj;
    });
});
