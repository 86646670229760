class PolicyService {

    constructor ($resource) {
        this.$resource = $resource;
    }

    getPolicy = () => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/settings/policy').get();
    };

    savePolicy = (data, ctrl, success, error) => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/settings/policy').save({}, data, function (response) {
            if (typeof (success) == 'function' && ctrl) {
                success(ctrl, response);
            }
        }, function (response) {
            if (typeof (error) == 'function' && ctrl) {
                error(ctrl, response);
            }
        });
    };
}

export {
    PolicyService
};
