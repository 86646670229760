export const ssoSchema = ({ handleRow, iconHandle, disabled }) => {
    let schema = {
        row: { handleRow, disabled: () => false },
        cell: [
            {
                text: 'name',
            }
        ],
        icon: [
            {
                isIcon: true,
                icon: disabled ? 'noIcon' : 'delete',
                handle: [
                    ...iconHandle
                ]
            }
        ]
    };
    return schema;
};

