import { EMPTY_SELECTED_DATA } from '../../const';
import angular from 'angular';
import { setSelectedData, applyFilterDepartment } from '../../toolkit/actions';

class NotificationErrorListController {
    constructor($ngRedux) {
        this.limitTo = 100;
        this.unsubscribe = $ngRedux.connect(null, { setSelectedData, applyFilterDepartment })(this);
    }

    applyErrorFilter = (error) => {
        let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(error.selectedData));
        this.setSelectedData(selectedData);
        this.applyFilterDepartment({
            search: '',
            callback: 'rebuildSelectedData',
            reload: false,
        });
    };

    $onDestroy() {
        this.unsubscribe();
    }
}

NotificationErrorListController.$inject = [
    '$ngRedux',
];

export {
    NotificationErrorListController,
};
