class AuthService {
    constructor (YiiModel, $resource) {
        this.$resource = $resource;
        this.new = () => {
            return YiiModel.one('auth');
        };
    }

    getCsrf = () => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/csrf').get();
    };

    getUserInfo = (filter) => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/auth' + (filter ? ('?filter=' + filter) : '')).get();
    };

    getBossCadrovikInfo = () => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/auth?filter=bossKadrovikEnabled');
    };

    getSettings = () => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/settings/auth').get();
    };

    saveSettings = (params, ctrl, success, error) => {
        return this.$resource(window.config.SYSTEM_URL + window.config.API_URL + '/settings/auth').save({}, params, function (response) {
            if (typeof (success) == 'function' && ctrl) {
                success(ctrl, response);
            }
        }, function (response) {
            if (typeof (error) == 'function' && ctrl) {
                error(ctrl, response);
            }
        });
    };
}

export {
    AuthService
};
