import modalTemplate from './view.html';

import { DepartmentModalController } from './controller';

import { DEPARTMENT_MODAL_CONTROLLER } from '../../const';

class DepartmentModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    open = (departmentId, parentDepartmentId) => {
        this.modalInstance = this.$uibModal.open({
            template: modalTemplate,
            bindToController: true,
            controller: DEPARTMENT_MODAL_CONTROLLER,
            controllerAs: '$',
            size: 'md',
            resolve: {
                departmentId: departmentId,
                parentDepartmentId: parentDepartmentId,
            },
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}

export {
    DepartmentModalController,
    DepartmentModal,
};
