class LdapImportsService {
    constructor (YiiModel, $resource) {
        this.YiiModel = YiiModel;
        this.all = YiiModel.all('ldap-imports');
        this.one = (id) => {
            return YiiModel.one('ldap-imports').$find(id);
        };
        this.logs = (importId, dataType) => {
            return $resource(`${window.config.SYSTEM_URL}${window.config.API_URL}/ldap-imports/${importId}/logs/${dataType}`).query();
        };
        this.action = (importId, action, ctrl, success, error) => {
            return $resource(`${window.config.SYSTEM_URL}${window.config.API_URL}/ldap-imports/${importId}/${action}`).save({}, {}, function (response) {
                if (typeof (success) == 'function' && ctrl) {
                    success(ctrl, response);
                }
            }, function (response) {
                if (typeof (error) == 'function' && ctrl) {
                    error(ctrl, response);
                }
            });
        };
    }
}

export {
    LdapImportsService
};
