import React, { useState, useContext } from 'react';
import { ReactTableRow } from './ReactTableRow.jsx';
import style from 'rootReact/components/SettingsRole/settingsRole.module.less';
import Translate from 'rootReact/Translate.jsx';
import { GettextContext } from 'rootReact/context';

export function ReactTableBody({ assets, body, cellSchema }) {
    const gettext = useContext(GettextContext);
    const [showIndex, setShowIndex] = useState(10);

    const getFooterTable = () => {
        if (body.length <= 10) return;
        if (body.length > showIndex) {
            return (<tr>
                <td colSpan={cellSchema.cell.length}
                    style={{ paddingLeft: '.5rem' }}>
                    <a onClick={() => setShowIndex(showIndex + 10)}
                        className={style.showMore}
                    >
                        <Translate>Показать еще</Translate> ↓
                    </a>
                    <a onClick={() => setShowIndex(body.length)}>
                        {gettext.getString('Показать все')} ↓
                    </a>
                </td>
            </tr>);
        } else {
            return (<tr>
                <td colSpan={cellSchema.cell.length}
                    style={{ paddingLeft: '.5rem' }}>
                    <a onClick={() => setShowIndex(10)}><Translate>Свернуть</Translate> ↑</a>
                </td>
            </tr>);
        }
    };

    const getTableBody = () => {
        if (!body.length) {
            return <tr><td className={'react-table__empty'} colSpan={cellSchema.cell.length}>{assets.empty}</td></tr>;
        }
        return body.map((item, i) => {
            if (i < showIndex) {
                return <ReactTableRow
                    key={item.id}
                    tableCell={item}
                    cellSchema={cellSchema}
                    id={item.id}
                />;
            }
        });
    };

    return (
        <tbody>
            {getTableBody()}
            {getFooterTable()}
        </tbody>
    );
}
