import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';

const initialState = {
    status: 'idle',
    modalStatus: 'idle',
    deleteId: null,
    ssoProfiles: {},
    error: false
};

export const fetchSSO = createAsyncThunk(
    'ssoProfile/status',
    async () => {
        const fetchApi = new FetchApi('/sso');
        const response = await fetchApi.get();
        return response;
    }
);

export const fetchSSOPost = createAsyncThunk(
    'ssoProfile/post',
    async (params) => {
        const fetchApi = new FetchApi('/sso');
        const response = await fetchApi.post(params);
        return response;
    }
);

export const fetchSSOPut = createAsyncThunk(
    'ssoProfile/put',
    async ({id, params}) => {
        const fetchApi = new FetchApi(`/sso`);
        const response = await fetchApi.put(id, params);
        return response;
    }
);

export const deleteSSO = createAsyncThunk(
    'ssoProfile/delete',
    async (id) => {
        const fetchApi = new FetchApi('/sso');
        const response = await fetchApi.delete(id);
        return response;
    }
);

const ssoProfileSlice = createSlice({
    name: 'ssoProfile',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchSSO.fulfilled]: (state, { payload }) => {
            state.ssoProfiles = payload;
            state.status = 'load';
        },
        [fetchSSO.pending]: (state) => {
            state.status = 'pending';
        },
        [fetchSSO.rejected]: (state) => {
            state.status = 'rejected';
        },

        [fetchSSOPost.fulfilled]: (state, { payload }) => {
            state.ssoProfiles.push(payload);
            state.modalStatus = 'load';
        },
        [fetchSSOPost.pending]: (state) => {
            state.status = 'pending';
            state.modalStatus = 'pending';
        },
        [fetchSSOPost.rejected]: (state) => {
            state.status = 'rejected';
            state.modalStatus = 'rejected';
        },

        [fetchSSOPut.fulfilled]: (state, { payload }) => {
            state.ssoProfiles = state.ssoProfiles.map(item => {
                if (item.id !== payload.id) return item;
                return payload;
            });
            state.modalStatus = 'load';
        },
        [fetchSSOPut.pending]: (state) => {
            state.modalStatus = 'pending';
        },
        [fetchSSOPut.rejected]: (state) => {
            state.modalStatus = 'rejected';
        },

        [deleteSSO.fulfilled]: (state, { payload }) => {
            state.ssoProfiles = state.ssoProfiles.filter(item => item.id !== payload);
            state.deleteId = payload
        },
        [deleteSSO.pending]: (state, {meta}) => {
            state.deleteId = meta.arg;
        },
        [deleteSSO.rejected]: (state) => {
            state.deleteId = 'rejected';
        },

    }
});

export default ssoProfileSlice.reducer;

