import { createSlice } from '@reduxjs/toolkit';
import { fetchSettings } from 'root/assets/webpack/src/js/toolkit/settings';

const initialState = {
    actionTypes: [],
    triggerTypes: [],
    reportTypes: []
};

const typesSlice = createSlice({
    name: 'types',
    initialState,
    reducers: {
        setReportTypes (state, {payload}) {
            state.reportTypes = payload;
        },
        setActionTypes (state, {payload}) {
            state.actionTypes = payload;
        },
        setTriggerTypes (state, {payload}) {
            state.triggerTypes = payload
        }
    },
    extraReducers: {
        [fetchSettings.fulfilled]: (state, { payload }) => {
            state.actionTypes = payload.actionTypes;
            state.triggerTypes = payload.triggerTypes;
            state.reportTypes = payload.reportTypes;
        }
    }
});

export const {setReportTypes, setActionTypes, setTriggerTypes} = typesSlice.actions;
export default typesSlice.reducer;

