import motivationTemplate from './view.html';

import { MotivationComponentController } from './controller.js';

const MotivationComponent = {
    template: motivationTemplate,
    controller: MotivationComponentController,
    controllerAs: '$',
    scope: true,
    bindings: {
        motivationProps: '<',
        motivationTestAccess: '<',
        setAuthMotivation: '='
    },
};

export {
    MotivationComponent,
};
