import passwordTemplate from './view.html';

import style from './style.modules.css';

class PasswordFieldController {
    constructor () {
        this.style = style;
        this.passwordShow = false;
    }

    $onInit = () => {};

    togglePassword = () => {
        this.passwordShow = !this.passwordShow;
    }
}

PasswordFieldController.$inject = [];

const PasswordFieldComponent = {
    template: passwordTemplate,
    controller: PasswordFieldController,
    controllerAs: '$',
    bindings: {
        passwordField: '=',
        onEnterClick: '=',
        passwordLabelClass: '=',
        passwordFieldClass: '=',
        label: '<',
        htmlId: '<',
        selenium: '<'
    }
};

export {
    PasswordFieldComponent
};
