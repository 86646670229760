import dashboardEducation from './view.html';

import { DashboardEducation } from './controller';

const DashboardEducationComponent = {
    template: dashboardEducation,
    controller: DashboardEducation,
    controllerAs: '$',
    bindings: {
        targetsOnCourse: '<',
        targetsInSystem: '<',
        month: '<',
        companyTargetCount: '<',
        addEmployee: '=',
        sendToLearning: '='
    }
};

export {
    DashboardEducationComponent
};
