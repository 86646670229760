import React, { useContext, useState, useEffect } from 'react';
import { GettextContext } from 'rootReact/context';
import { useInput } from './useValidForm.jsx';
import { Editor } from '@tinymce/tinymce-react';
import { getSettings } from './getSettings';
import DropdownSelect from 'rootReact/components/DropdownSelect';

function Locale({
    initValue,
    setValue,
    activeLanguage,
    updateEmptyError,
    save,
    setDefaultLanguage,
    domainList,
    editAccess,
}) {
    const gettext = useContext(GettextContext);
    const topicLetter = useInput(initValue.subject, { isEmpty: true });
    const sender = useInput(initValue.fromName, { isEmpty: true });
    const address = useInput(initValue.from, { isEmpty: true, addressError: true });
    const domain = useInput(initValue.domain, { isEmpty: true, domainError: true });
    const editorValue = useInput(initValue.text, { isEmpty: true });
    const [checkbox, setCheckbox] = useState(initValue.isDefault);
    const [emptyLocale, setEmptyLocale] = useState(false);
    const [isEmptyEditorValue, setIsEmptyEditorValue] = useState(false);
    const setLocale = (isDefault, field, event) => {
        if (field) {
            field.onBlur(event);
        }
        let locale = {
            subject: topicLetter.value,
            fromName: sender.value,
            from: address.value,
            domain: domain.value,
            text: editorValue.value,
            languageId: activeLanguage,
            isDefault: isDefault,
        };
        setValue(locale);
    };

    const setDefault = () => {
        setCheckbox(() => {
            setDefaultLanguage(activeLanguage);
            setLocale(1);
            return 1;
        });
    };

    useEffect(() => {
        const srtingOfEditorValue = editorValue.value.replace(/\s/g, '');
        const isEmptySrtingBetweenBodyTags = srtingOfEditorValue.includes('<body></body>');
        setIsEmptyEditorValue(isEmptySrtingBetweenBodyTags);
        if (!sender.value.length ||
                !topicLetter.value.length ||
                !address.value.length ||
                !domain.value.length ||
                isEmptySrtingBetweenBodyTags ||
                domain.domainError ||
                address.addressError) {
            updateEmptyError(true);
        } else {
            updateEmptyError(false);
        }
        if (save) {
            setEmptyLocale(true);
        }
        setLocale(checkbox);
    }, [save, sender.value, topicLetter.value, address.value, domain.value, editorValue.value, domain.domainError, address.addressError]);

    const setDomain = (item) => {
        domain.onChange(item.name || '');
        // setLocale(checkbox);
    };

    return (
        <>
            <div className='react-notification-modal_block'>
                <input
                    checked={checkbox}
                    onChange={setDefault}
                    disabled={!editAccess}
                    // onClick={setDefault}
                    className='react-notification-modal_checkbox-input'
                    type='checkbox'
                    id='checkbox'
                />
                <label className='react-notification-modal_checkbox-label' htmlFor='checkbox'>
                    {gettext.getString('Язык по умолчанию')}
                </label>
            </div>
            <div className='react-notification-modal_block'>
                <label
                    className={
                        (emptyLocale && !topicLetter.value.length) || (topicLetter.isDirty && topicLetter.isEmpty)
                            ? 'react-notification-modal_label text-error'
                            : 'react-notification-modal_label'
                    }
                    htmlFor='topicLetter'
                >
                    {gettext.getString('Тема письма')}
                </label>
                <input
                    onChange={(e) => topicLetter.onChange(e.target.value)}
                    onBlur={topicLetter.onBlur}
                    value={topicLetter.value}
                    autoComplete={'off'}
                    disabled={!editAccess}
                    className={
                        (emptyLocale && !topicLetter.value.length) || (topicLetter.isDirty && topicLetter.isEmpty)
                            ? 'react-notification-modal_input input-error'
                            : 'react-notification-modal_input'
                    }
                    type='text'
                    id='topicLetter'
                />
            </div>
            <div className='react-notification-modal_block'>
                <label
                    className={
                        (emptyLocale && !sender.value.length) || (sender.isDirty && sender.isEmpty)
                            ? 'react-notification-modal_label text-error'
                            : 'react-notification-modal_label'
                    }
                    htmlFor='sender'
                >
                    {gettext.getString('Отправитель')}
                </label>
                <input
                    onChange={(e) => sender.onChange(e.target.value)}
                    onBlur={sender.onBlur}
                    value={sender.value}
                    disabled={!editAccess}
                    className={
                        (emptyLocale && !sender.value.length) || (sender.isDirty && sender.isEmpty)
                            ? 'react-notification-modal_input input-error'
                            : 'react-notification-modal_input'
                    }
                    type='text'
                    autoComplete={'off'}
                    id='sender'
                />
            </div>
            <div className='react-notification-modal_block'>
                <label
                    className={
                        (emptyLocale && !address.value.length) ||
                        (emptyLocale && !domain.value.length) ||
                        (address.isDirty && address.isEmpty && domain.isDirty && domain.isEmpty)
                            ? 'react-notification-modal_label text-error'
                            : 'react-notification-modal_label'
                    }
                    htmlFor='adress'
                >
                    {gettext.getString('Адрес')}
                </label>
                <div className='react-notification-modal_domain'>
                    <input
                        onChange={(e) => address.onChange(e.target.value)}
                        value={address.value}
                        onBlur={address.onBlur}
                        disabled={!editAccess}
                        className={
                            (emptyLocale && !address.value.length) ||
                            (address.isDirty && address.isEmpty) ||
                            (address.isDirty && address.addressError)
                                ? 'react-notification-modal_domain-input input-error'
                                : 'react-notification-modal_domain-input'
                        }
                        type='text'
                        autoComplete={'off'}
                        id='address'
                        placeholder={gettext.getString('noreply')}
                    />
                    <span className='react-notification-modal_domain-span'>@</span>
                    <div className={'react-notification-modal_domain_drop-wrapper'}>
                        <DropdownSelect list={domainList}
                            Style={`react-input_field ${domain.isDirty && (domain.isEmpty || domain.domainError) ? 'input-error' : ''}`}
                            inputAttr={domain}
                            setValueInput={domain.onChange}
                            optionsName={'name'}
                            handleClick={setDomain}
                            onBlur={domain.onBlur}
                            disabled={!editAccess}
                            isCreate
                            testId={'domain-list'} />
                    </div>
                </div>
            </div>
            <div className={`react-notification-modal_editor ${(editorValue.isDirty && isEmptyEditorValue) ? 'input-error' : ''}`}>
                <Editor
                    init={getSettings(gettext, gettext.currentLanguage)}
                    onEditorChange={(editor) => {
                        editorValue.onChange(editor);
                    }
                    }
                    disabled={!editAccess}
                    onBlur={editorValue.onBlur}
                    value={editorValue.value}
                />
            </div>
        </>
    );
}

export default Locale;
