import { Controller } from './controller';
import angular from 'angular';
import { hasAccess } from '../reselect';
import { ReportModal } from '../components/reportModal';
import style from '../../styles/risks.modules.css';
import go from '../helpers/go';
import { setBreadcrumbLink, setSearchData } from '../toolkit/actions';

class ReportsController extends Controller {
    constructor ($injector, $localStorage, $scope, $filter, $ngRedux, gettextCatalog, $location) {
        super();
        this.style = style;
        this.$injector = $injector;
        this.$localStorage = $localStorage;
        this.$scope = $scope;
        this.$filter = $filter;
        this.$location = $location;
        this.gettextCatalog = gettextCatalog;
        this.departmentsLoading = false;
        this.campaignsLoading = false;
        this.reloading = false;
        this.ignoreSetLoading = {
            departmentsLoading: false,
            campaignsLoading: false
        };
        this.linkNames = [];
        this.breadcrumbTitle = this.gettextCatalog.getString('Отчеты');
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { setBreadcrumbLink, setSearchData })(this);
    }

    mapStateToThis = (state) => {
        return {
            selectedData: state.selectedData.selectedData,
            reportDepartmentAccess: hasAccess(state, { sectionId: 4, rightName: 'report_department' }),
            viewAccess: hasAccess(state, { sectionId: 4, rightName: 'view' })
        };
    };

    $onInit () {
        const { $localStorage, $location } = this;
        if (!this.viewAccess) {
            if ($localStorage.userInfo.defaultPage) {
                go($location, $localStorage.userInfo.defaultPage);
            }
            return;
        }
        $localStorage.campaignData = null;
        $localStorage.openedDepartments = [];
        this.setSearchData(['']);
        this.viewMode = $localStorage.reports && $localStorage.reports.viewMode || 0;
        let search = this.selectedData.search && angular.isArray(this.selectedData.search) ? this.selectedData.search : [''];
        this.setSearchData(search);
        this.linkNames = [];
        this.setBreadcrumbLink([...this.linkNames]);
    }

    setViewMode = (viewMode) => {
        const { $localStorage } = this;
        this.viewMode = viewMode;
        if (typeof ($localStorage.reports) == 'undefined') {
            $localStorage.reports = {};
        }
        $localStorage.reports.viewMode = viewMode;
        if (viewMode == 1) {
            this.linkNames = [];
        }
        this.setBreadcrumbLink([...this.linkNames]);
    };

    showReport = (department) => {
        if (this.reportDepartmentAccess) {
            let params = {
                id: department.id,
                title: department.name,
                type: 'department',
                total: department.searchTargets,
                fromReport: 1
            };
            this.modal = this.$injector.instantiate(ReportModal);
            this.modal.open(params).then(() => {
            }, () => {
            });
        }
    };

    setDepartment = (id = 'clear') => {
        let index = null;
        for (let i = 0; i < this.linkNames.length; i++) {
            if (this.linkNames[i].id === id) {
                index = i;
            }
        }
        if (id === 'clear') {
            this.linkNames = [];
            return;
        }
        if (index > 0 || index === 0) {
            this.loadDepartment(this.linkNames[index].id);
            return;
        }
        this.setBreadcrumbLink([...this.linkNames, ...this.departments.filter(item => item.id === id)]);
    };

    $onDestroy = () => {
        this.unsubscribe();
    };

    isAnyLoading = () => {
        return this.departmentsLoading;
    };

    setReload = (value) => {
        this.reloading = value;
    };

    setLoading = (loadingType = 'departmentsLoading', value = false) => {
        value = !!value;
        if (!this.ignoreSetLoading[loadingType]) {
            if (value != this[loadingType]) {
                this[loadingType] = value;
            }
        } else {
            this.ignoreSetLoading[loadingType] = false;
        }
    };
}

ReportsController.$inject = [
    '$injector',
    '$localStorage',
    '$scope',
    '$filter',
    '$ngRedux',
    'gettextCatalog',
    '$location'
];

export {
    ReportsController
};
