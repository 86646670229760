import style from './style.modules.css';

class LoginHeaderController {
    constructor($scope, gettextCatalog) {
        this.$scope = $scope;
        this.gettextCatalog = gettextCatalog;
        this.style = style;
    }

    $onInit() {}
}

LoginHeaderController.$inject = [
    '$scope',
    'gettextCatalog',
];

export {
    LoginHeaderController,
};
