
class SystemUpdateController {
    constructor ($timeout, FileUploader, gettextCatalog) {
        this.config = window.config;
        this.$timeout = $timeout;
        this.error = false;
        this.inProcess = false;
        this.systemIsUpdated = false;
        this.gettextCatalog = gettextCatalog;
        this.systemUploader = new FileUploader();
        this.systemUploader.onCompleteItem = (item, response, status) => {
            this.inProcess = false;
            if (status == 200) {
                this.systemIsUpdated = true;
                this.systemUploader.queue = [];
                this.$timeout(() => {
                    this.systemIsUpdated = false;
                }, 3000);
            }
        };
        this.systemUploader.onErrorItem = (item, response) => {
            this.inProcess = false;
            if (response && response.error && response.error != '') {
                this.error = response.error;
            } else if (response && response.status && response.status != 200 && response.message && response.message != '') {
                this.error = response.message;
            } else {
                this.error = this.gettextCatalog.getString('Ошибка при обновлении системы');
            }
        };
    }

    $onInit () {
    }

    updateSystem = () => {
        this.error = false;
        if (this.systemUploader.queue.length > 0) {
            this.inProcess = true;
            this.systemUploader.queue[this.systemUploader.queue.length - 1].upload();
        } else {
            this.error = this.gettextCatalog.getString('Не выбран файл с обновлением');
        }
    };

    cancelUpdate = () => {
        this.systemUploader.queue = [];
        this.error = false;
    };
}

SystemUpdateController.$inject = [
    '$timeout',
    'FileUploader',
    'gettextCatalog'
];

export {
    SystemUpdateController
};
