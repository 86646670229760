import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';
import { DOWNLOAD_TYPE } from '../const';

const getType = (messages) => {
    if (!messages.length) return [];
    return messages.map(item => {
        if (item.type !== 2) {
            return item;
        }
        item.reportType = item.details.type.replace('report_', '').trim();
        for (let key in DOWNLOAD_TYPE) {
            if (item.reportType === DOWNLOAD_TYPE[key]) {
                item.type = +key;
            }
        }
        return item;
    });
};

const initialState = {
    certs: {},
    notifications: [],
    courses: [],
    daemons: [],
    loading: false,
};

export const fetchDaemonsMessage = createAsyncThunk(
    'daemons/status',
    async () => {
        const fetchApi = new FetchApi('/learning/queue');
        const response = await fetchApi.get();
        response.daemons = getType(response.daemons);
        return response;
    }
);

const daemonsSlice = createSlice({
    name: 'daemons',
    initialState,
    reducers: {
        daemonsMessageDefault (state, {payload}) {
            state.daemons = state.daemons.filter(item => {
                return item.type !== payload;
            })
        }
    },
    extraReducers: {
        [fetchDaemonsMessage.fulfilled]: (state, { payload }) => {
            for (let key in payload) {
                state[key] = payload[key];
            }
            state.status = 'load';
            state.loading = false
        },
        [fetchDaemonsMessage.pending]: (state) => {
            state.status = 'pending';
            state.loading = true
        },
        [fetchDaemonsMessage.rejected]: (state) => {
            state.status = 'rejected';
            state.loading = false
        },
    }
});

export const {daemonsMessageDefault} = daemonsSlice.actions;
export default daemonsSlice.reducer;

