import { MAIL_SERVERS_SERVICE } from '../../const';
import { CampaignModal } from '../campaignModal';
import angular from 'angular';

class MailServerDetailsModalController {
    constructor(MailServersService, serverId, $localStorage, $injector) {
        this.MailServersService = MailServersService;
        this.serverId = serverId;
        this.$injector = $injector;
        this.$localStorage = $localStorage;
        this.showMore = false;
        this.loading = true;
        this.gettextCatalog = window.appCtrl.gettextCatalog;
        this.filters = {
            'receiveStamp': this.gettextCatalog.getString('По дате'),
            'openedStamp': this.gettextCatalog.getString('Просмотренные'),
            'useStamp': this.gettextCatalog.getString('Сохраненные'),
        };
        this.activeFilter = this.gettextCatalog.getString('Сортировка...');
        this.filterQuery = '';
        this.showFilters = false;
        this.lastFiterParameter = null;
        this.lastFiterDirection = -1;
        this.windowName = this.gettextCatalog.getString('Письма с шаблонами');
        if ('mailServerDetailsData' in this.$localStorage && 'windowName' in this.$localStorage.mailServerDetailsData && this.$localStorage.mailServerDetailsData.windowName != '')
            this.windowName = this.$localStorage.mailServerDetailsData.windowName;
        this.strings = {
            sorting: this.gettextCatalog.getString('Сортировка...')
        };
    }

    changeSort = (value) => {
        if (value == '') {
            value = this.lastFiterParameter;
            this.lastFiterDirection = this.lastFiterDirection * -1;
        } else {
            this.lastFiterParameter = value;
        }
        this.activeFilter = this.filters[value];
        this.details = this.details.sort((p, n) => {
            return (p.date[value] - n.date[value]) * this.lastFiterDirection;
        });
        this.showFilters = false;
    };

    filterByQuery = () => {
        let q = this.filterQuery.toLowerCase();
        if (this.filterQuery == '')
            this.details = angular.copy(this._details);
        else {
            this.details = this._details.filter(item => {
                return item.subject.toLowerCase().includes(q) || item.fullName.toLowerCase().includes(q);
            });
        }
    };

    getDetails = data => {
        data.forEach(item => {
            if (item.fullName.length > 19)
                item.fullName = item.fullName.substr(0, 19) + '...';
            this.details.push(item);
        });
        this._details = angular.copy(this.details);
    };

    $onInit = () => {
        this.details = [];
        this.nextPage();
    };

    nextPage = (goDown) => {
        this.showMore = false;
        this.loading = true;
        this.MailServersService.details(this.serverId, this.details.length, goDown, (response) => {
            this.showMore = response.length;
            this.getDetails(response);
            this.loading = false;
        }, () => {
            this.showMore = true;
            this.loading = false;
            this.$close();
        });
    };

    openOne = (key, details) => {
        if (!this.loading) {
            this.loading = true;
            this.MailServersService.detailsOne(this.serverId, details.mail_id, (response) => {
                this.$localStorage.dataForCampaign = details;
                this.$localStorage.dataForCampaign.content = response[0].content;
                this.$localStorage.dataForCampaign.attachments = response[0].attachments;
                this.$injector.instantiate(CampaignModal).open();
                this.details[key].date.openedStamp = Math.round(new Date().getTime() / 1000);
                this.loading = false;
                this.$close();
            }, () => {
                this.loading = false;
            });
        }
    };
}

MailServerDetailsModalController.$inject = [
    MAIL_SERVERS_SERVICE,
    'serverId',
    '$localStorage',
    '$injector',
];

export {
    MailServerDetailsModalController,
};
