import style from './style.modules.css';
import { DEPARTMENTS_SERVICE, EMPTY_SELECTED_DATA, MARKS_SERVICE, TARGETS_SERVICE, GROUPS_SERVICE } from '../../const';
import angular from 'angular';
import { getLanguage } from 'root/assets/webpack/src/js/reselect/extensibleData';
import { setBreadcrumbLink } from 'root/assets/webpack/src/js/toolkit/actions.js';

class EditSelectedModalController {
    constructor(DepartmentsService, GroupsService, MarksService, TargetsService, $ngRedux) {
        this.style = style;
        this.DepartmentsService = DepartmentsService;
        this.GroupsService = GroupsService;
        this.MarksService = MarksService;
        this.TargetsService = TargetsService;
        this.gettextCatalog = window.appCtrl.gettextCatalog;
        this.employee = {
            names: ['first_name', 'second_name', 'third_name'],
            doTransliterate: 0,
        };
        this.loading = true;
        this.marksLengtg = 0;
        this.groupChoosen = null;
        this.hasEmptyThirdName = true;
        this.hasTransliterate0 = false;
        this.hasTransliterate1 = false;
        this.initialLanguage = {};
        this.language = {};
        this.languages = [];
        this.canTransliterate = false;
        this.hasTagsChanges = false;
        this.departments = [];
        this.groups = [];
        this.moveToDepartments = [];
        this.moveToDepartment = null;
        this.moveToGroups = [];
        this.moveToGroup = null;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { setBreadcrumbLink })(this);
    }

    mapStateToThis = (state) => {
        return {
            selectedData: state.selectedData.selectedData,
            linkNames: state.breadcrumbLinks.link,
            languages: getLanguage(state),
        };
    };

    $onInit = () => {
        this.loadTags('');
        this.DepartmentsService.simple.query({ layout: 'select' }).$promise.then((departments) => {
            this.departments = departments;
            let data = {
                layout: 'select',
                moveSelected: JSON.stringify(this.selectedData),
            };
            if ('departments' in this.selectedData && this.selectedData.departments.length > 0) {
                data.ignoreDepartment = this.selectedData.departments.join(',');
            }
            this.DepartmentsService.simple.query(data).$promise.then((departments) => {
                this.moveToDepartments = departments;
                this.TargetsService.hasData(this.selectedData, this, (ctrl, reponse) => {
                    ctrl.hasEmptyThirdName = window.appCtrl.langCode != 'ru' ? true : (reponse && reponse.emptyThirdName > 0 ? true : false);
                    ctrl.hasTransliterate0 = reponse && reponse.doTransliterate0 > 0 ? true : false;
                    ctrl.hasTransliterate1 = reponse && reponse.doTransliterate1 > 0 ? true : false;
                    if (!ctrl.hasTransliterate0 && ctrl.hasTransliterate1) {
                        this.changeTransliterate(true);
                    }
                    if (ctrl.hasTransliterate0 && !ctrl.hasTransliterate1) {
                        this.changeTransliterate(false);
                    }
                    this.TargetsService.similarTags(this.selectedData, this, (ctrl, data) => {
                        this.employee.marks_origin = angular.copy(data.tags);
                        this.employee.marks = angular.copy(data.tags);
                        this.employee.initMarks = angular.copy(data.tags);
                    });
                    this.TargetsService.similarLanguage(this.selectedData, this, (ctrl, data) => {
                        if (Object.prototype.hasOwnProperty.call(data, 'language') && data.language !== null) {
                            this.initialLanguage = angular.copy(data.language);
                            this.language = angular.copy(data.language);
                        }
                    });
                    data = {
                        layout: 'select',
                    };
                    if ('groups' in this.selectedData && this.selectedData.groups.length > 0) {
                        data.ignoreGroup = this.selectedData.groups.join(',');
                    }
                    this.GroupsService.simple.query(data).$promise.then((groups) => {
                        this.groups = groups;
                        this.GroupsService.simple.query({
                            layout: 'select',
                            moveSelected: JSON.stringify(this.selectedData),
                        }).$promise.then((groups) => {
                            this.moveToGroups = groups;
                            this.loading = false;
                        });
                    });
                }, (ctrl) => {
                    ctrl.loading = false;
                    ctrl.hasEmptyThirdName = true;
                    ctrl.canTransliterate = false;
                });
            });
        });
    };

    loadTags = (query) => (
        this.MarksService.loadTags(query).$promise.then((response) => {
            this.marksLengtg = response.length;
            return response;
        })
    );

    isMarksChanged = () => {
        let result = false;
        let employeeMarks = [];
        if (typeof (this.employee.marks) != 'undefined' && this.employee.marks && this.employee.marks.length > 0) {
            angular.forEach(this.employee.marks, (mark) => {
                employeeMarks.push(mark.text ? mark.text : mark);
            });
        }
        if (this.employee.initMark && this.employee.initMarks.length > 0) {
            angular.forEach(employeeMarks, (mark) => {
                if (this.employee.initMarks.indexOf(mark) == -1) {
                    result = true;
                }
                if (employeeMarks.length != this.employee.initMarks.length) {
                    result = true;
                }
            });
        } else if (employeeMarks.length > 0) {
            result = true;
        }
        return result;
    };

    get group() {
        return this.groupChoosen && this.groupChoosen.name ? this.groupChoosen.name : this.groupChoosen;
    }

    set group(data) {
        this.groupChoosen = data;
    }

    isLanguageChanged = () => {
        const initialLanguageId = Object.prototype.hasOwnProperty.call(this.initialLanguage, 'id') ?
            this.initialLanguage.id : null;
        const currentLanguageId = Object.prototype.hasOwnProperty.call(this.language, 'id') ?
            this.language.id : null;

        return initialLanguageId !== currentLanguageId;
    };

    isMarksSame = () => {
        if (typeof (this.employee.marks) != 'undefined') {
            if (this.employee.marks.length != this.employee.marks_origin.length) {
                return true;
            }
            let same = false;
            angular.forEach(this.employee.marks, (mark) => {
                if (!this.employee.marks_origin.includes(mark.text)) {
                    same = true;
                }
            });
            return same;
        }
        return false;
    };

    isFormChanged = () => {
        return this.moveToDepartment || this.moveToGroup || this.canTransliterate || this.isMarksSame()
            || this.employee.department
            || this.groupChoosen
            || this.employee.position
            || this.employee.names[0] != 'first_name'
            || this.employee.names[1] != 'second_name'
            || this.employee.names[2] != 'third_name'
            || this.isLanguageChanged();
    };

    save = () => {
        this.errorText = null;
        this.inProcess = false;
        this.employee.selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
        let marks = this.employee.marks;
        this.employee.marks = [];
        if (typeof (marks) != 'undefined' && marks && marks.length > 0) {
            angular.forEach(marks, (mark) => {
                this.employee.marks.push(mark.text ? mark.text : mark);
            });
        }
        let initMarks = this.employee.initMarks;
        this.employee.initMarks = [];
        if (typeof (initMarks) != 'undefined' && initMarks && initMarks.length > 0) {
            angular.forEach(initMarks, (initMark) => {
                this.employee.initMarks.push(initMark.text ? initMark.text : initMark);
            });
        }
        if (this.employee.department && !this.employee.department.name) {
            this.employee.department = {
                id: 0,
                name: this.employee.department,
            };
        }
        this.employee.group = this.groupChoosen;
        if (this.employee.group && !this.employee.group.name) {
            this.employee.group = {
                id: 0,
                name: this.employee.group,
            };
        }
        if (this.moveToDepartment) {
            this.employee.moveToDepartment = this.moveToDepartment;
        }
        if (this.moveToGroup) {
            this.employee.moveToGroup = this.moveToGroup;
        }
        this.employee.languageId = this.language && this.language.id || null;
        if (this.isFormChanged()) {
            this.inProcess = true;
            this.TargetsService.bulkUpdate(this.employee, this, (ctrl, response) => {
                if (ctrl.employee.moveToDepartment) {
                    ctrl.setBreadcrumbLink([]);
                }
                ctrl.inProcess = false;
                ctrl.$close(response);
            }, (ctrl, response) => {
                ctrl.inProcess = false;
                if (response && response.data && response.data.error) {
                    ctrl.errorText = response.data.error;
                } else {
                    ctrl.errorText = ctrl.gettextCatalog.getString('Ошибка при обновлении целей');
                }
            });
        } else {
            this.errorText = this.gettextCatalog.getString('Не введено данных для обновления целей');
        }
    };

    changeTransliterate = (value) => {
        this.employee.doTransliterate = +value;
        this.canTransliterate = false;
        if (!this.employee.doTransliterate && !this.hasTransliterate0 && this.hasTransliterate1) {
            this.canTransliterate = true;
        }
        if (this.employee.doTransliterate && !this.hasTransliterate1 && this.hasTransliterate0) {
            this.canTransliterate = true;
        }
    };

    onMarksChange = () => {
        this.errorText = null;
        this.hasTagsChanges = this.isMarksChanged();
    };

    grValid = () => {
        return this.isValidGroup /*&& ! ( 'group' in this.employee.$errors)*/;
    };

    isValidGroup = () => {
        if (!this.loading && this.employee && this.employee.group) {
            let groupName = typeof (this.employee.group) == 'string' ? this.employee.group : '';
            if (this.employee.group && this.employee.group.fullName) {
                groupName = this.employee.group.fullName;
            }
            if (groupName) {
                let group = this.groups.find((group) => {
                    return group.fullName === groupName;
                });
                if (group && group.id) {
                    if (!this.employee.group || !this.employee.group.id) {
                        this.employee.group = group;
                    }
                    return true;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    checkDepartmentMatches = () => {
        this.departmentHasMatches = false;
        if (this.departments && this.departments.length) {
            let departmentName = this.employee.department && this.employee.department.fullName ? this.employee.department.fullName : (typeof (this.employee.department) == 'string' ? this.employee.department : '');
            angular.forEach(this.departments, (department) => {
                if (!departmentName || !department.fullName || departmentName.indexOf(department.fullName) > -1 || department.fullName.indexOf(departmentName) > -1) {
                    this.departmentHasMatches = true;
                    return true;
                }
            });
        }
    };

    checkMoveToDepartmentMatches = () => {
        this.moveToDepartmentHasMatches = false;
        if (this.moveToDepartments && this.moveToDepartments.length) {
            let departmentName = this.moveToDepartment && this.moveToDepartment.fullName ? this.moveToDepartment.fullName : (typeof (this.moveToDepartment) == 'string' ? this.moveToDepartment : '');
            angular.forEach(this.moveToDepartments, (department) => {
                if (!departmentName || !department.fullName || departmentName.indexOf(department.fullName) > -1 || department.fullName.indexOf(departmentName) > -1) {
                    this.moveToDepartmentHasMatches = true;
                    return true;
                }
            });
        }
    };

    checkGroupMatches = () => {
        this.groupHasMatches = false;
        if (this.groups && this.groups.length) {
            let groupName = this.employee.group && this.employee.group.fullName ? this.employee.group.fullName : (typeof (this.employee.group) == 'string' ? this.employee.group : '');
            angular.forEach(this.groups, (group) => {
                if (!groupName || !group.fullName || groupName.indexOf(group.fullName) > -1 || group.fullName.indexOf(groupName) > -1) {
                    this.groupHasMatches = true;
                    return true;
                }
            });
        }
    };
    checkMoveToGroupMatches = () => {
        this.moveToGroupHasMatches = false;
        if (this.moveToGroups && this.moveToGroups.length) {
            let groupName = this.moveToGroup && this.moveToGroup.fullName ? this.moveToGroup.fullName : (typeof (this.moveToGroup) == 'string' ? this.moveToGroup : '');
            angular.forEach(this.moveToGroups, (group) => {
                if (!groupName || !group.fullName || groupName.indexOf(group.fullName) > -1 || group.fullName.indexOf(groupName) > -1) {
                    this.moveToGroupHasMatches = true;
                    return true;
                }
            });
        }
    };

    $onDestroy() {
        this.unsubscribe();
    }
}

EditSelectedModalController.$inject = [
    DEPARTMENTS_SERVICE,
    GROUPS_SERVICE,
    MARKS_SERVICE,
    TARGETS_SERVICE,
    '$ngRedux',
];

export {
    EditSelectedModalController,
};
