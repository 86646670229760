import style from './style.modules.css';
import { validForm } from '../../helpers/validForm';
import { fetchSSO, fetchSSOPut, fetchSSOPost } from '../../toolkit/think';

class SsoProfileModalController {
    constructor(profile, gettextCatalog, scope, $ngRedux, $scope) {
        this.style = style;
        this.scope = $scope;
        this.profileId = profile ? profile.id : null;
        this.gettextCatalog = gettextCatalog;
        this.scope = scope;
        this.name = profile ? profile.name : '';
        this.idpEntityId = profile ? profile.idpEntityId.replace('https://', '') : '';
        this.idpSingleSignOnServiceUrl = profile && profile.idpSingleSignOnServiceUrl ? profile.idpSingleSignOnServiceUrl.replace('https://', '') : '';
        this.idpSingleLogoutServiceUrl = profile && profile.idpSingleLogoutServiceUrl ? profile.idpSingleLogoutServiceUrl.replace('https://', '') : '';
        this.idpMetaUrl = profile ? profile.idpMetaUrl.replace('https://', '') : '';
        this.idp_x509cert = profile ? profile.idp_x509cert : '';
        this.sp_x509cert = profile ? profile.sp_x509cert : '';
        this.spPrivateKey = profile ? profile.spPrivateKey : '';
        this.errors = {};
        this.valid = [];
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { fetchSSO, fetchSSOPut, fetchSSOPost })(this);
    }

    mapStateToThis = (state) => {
        return {
            loading: state.ssoProfile.modalStatus,
        };
    };

    $onInit = () => {
        this.star();
        this.profileId ? this.validAll() : null;
        this.scope.$watch(scope => {
            return scope.$.loading;
        }, (newVal, oldVal) => {
            if (newVal === oldVal) return;
            if (newVal === 'load') {
                this.$close();
            }
        });
    };

    star() {
        const that = this;
        this.fields = {
            name: {
                name: 'name',
                type: 'text',
                label: that.gettextCatalog.getString('Имя профиля SSO'),
                required: true,
                get hasError() {
                    return that.errors.hasOwnProperty(this.name);
                }
            },
            idpMetaUrl: {
                name: 'idpMetaUrl',
                url: true,
                label: that.gettextCatalog.getString('MetaData URL'),
                required: true,
                get hasError() {
                    return that.errors.hasOwnProperty(this.name);
                }
            }
        };
        this.btnConf = [
            {
                title: that.gettextCatalog.getString('Отменить'),
                action: () => {
                    that.$close();
                },
                class: 'default'
            },
            {
                title: that.gettextCatalog.getString('Сохранить'),
                action: () => {
                    that.save();
                },
                get disabled() {
                    return Object.keys(that.errors).length;
                },
                class: 'primary'
            }
        ];
    }

    sendProfile = () => {
        if (this.profileId) {
            this.fetchSSOPut({ id: this.profileId, params: { body: this.setBody() } });
            return;
        }
        this.fetchSSOPost({ body: this.setBody() });
    }

    setBody = () => {
        const {
            name,
            idpEntityId,
            idpSingleSignOnServiceUrl,
            idpSingleLogoutServiceUrl,
            idpMetaUrl,
            idp_x509cert,
            sp_x509cert,
            spPrivateKey
        } = this;
        const body = {
            name,
            idpEntityId: 'https://' + idpEntityId,
            idpSingleSignOnServiceUrl: 'https://' + idpSingleSignOnServiceUrl,
            idpSingleLogoutServiceUrl: 'https://' + idpSingleLogoutServiceUrl,
            idpMetaUrl: 'https://' + idpMetaUrl,
            idp_x509cert,
            sp_x509cert,
            spPrivateKey
        };
        if (this.profileId) {
            body.id = this.profileId;

        }
        return body;
    }

    validate = (test) => {
        const { errors, valid } = validForm(this.fields, { [test]: this[test] }, this.errors, this.valid);
        this.errors = errors;
        this.valid = valid;
    }

    validAll = () => {
        const {
            name,
            idpEntityId,
            idpSingleSignOnServiceUrl,
            idpSingleLogoutServiceUrl,
            idpMetaUrl,
            idp_x509cert,
            sp_x509cert,
            spPrivateKey
        } = this;
        const valField = {
            name,
            idpEntityId,
            idpSingleSignOnServiceUrl,
            idpSingleLogoutServiceUrl,
            idpMetaUrl,
            idp_x509cert,
            sp_x509cert,
            spPrivateKey
        };
        const { errors, valid } = validForm(this.fields, valField, this.errors, this.valid);
        this.errors = errors;
        this.valid = valid;
    }

    save = () => {
        this.validAll();
        if (Object.keys(this.errors).length) return;
        this.sendProfile();
    }
}

SsoProfileModalController.$inject = [
    'profile',
    'gettextCatalog',
    '$scope',
    '$ngRedux',
    '$scope'
];

export {
    SsoProfileModalController
};
