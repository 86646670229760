import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    link: []
};

const breadcrumbLinksSlice = createSlice({
    name: 'breadcrumbLinks',
    initialState,
    reducers: {
        setBreadcrumbLink (state, {payload}) {
            state.link = payload;
        },
        updateLinings (state, {payload}) {
            state.link.forEach(item => {
                if (item.id === payload.id) {
                    item = payload;
                }
            });
        }
    },
    extraReducers: {}
});

export const {setBreadcrumbLink, updateLinings} = breadcrumbLinksSlice.actions;
export default breadcrumbLinksSlice.reducer;

