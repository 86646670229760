import dashboardMotivation from './view.html';

import { DashboardMotivation } from './controller';

const DashboardMotivationComponent = {
    template: dashboardMotivation,
    controller: DashboardMotivation,
    controllerAs: '$',
    bindings: {
        targetsOnCourse: '<',
        targetsInSystem: '<',
        motivationHistory: '<',
        companyTargetCount: '<',
        motivation: '<',
        attackCount: '<'
    },
};

export {
    DashboardMotivationComponent
};
