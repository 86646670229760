import style from './style.modules.css';

class TextDashboardItemController {
    constructor(gettextCatalog, scope) {
        this.style = style;
        this.scope = scope;
        this.gettextCatalog = gettextCatalog;
        this.percent = [];
        this.targetCount = [];
        this.strings = {
            eduNew: gettextCatalog.getString('не обучался'),
            eduProcess: gettextCatalog.getString('на обучении'),
            eduComplete: gettextCatalog.getString('прошел обучение'),
            eduMiss: gettextCatalog.getString('не прошел обучение вовремя'),
            attackNew: gettextCatalog.getString('не был проверен'),
            attackProof: gettextCatalog.getString('выдержал атаку'),
            attackOpenMail: gettextCatalog.getString('открыл письмо'),
            attackOpenLink: gettextCatalog.getString('перешел по ссылке или по ссылке в QR'),
            attackOpenFile: gettextCatalog.getString('открыл вложение или файл по ссылке'),
            attackSubmitForm: gettextCatalog.getString('ввел данные в форму'),
            ratingUp: gettextCatalog.getString('рейтинг улучшился'),
            noVulnerabilities: gettextCatalog.getString('нет уязвимостей'),
            hasVulnerabilities: gettextCatalog.getString('есть уязвимости'),
            ratingDown: gettextCatalog.getString('рейтинг ухудшился')
        };
    }

    $onInit () {
        const setConfig = () => {
            let percent = [];
            let target = [];
            for (let key in this.itemConf) {
                if (this.itemConf[key] && this.itemConf[key].index) {
                    percent[+this.itemConf[key].index] = this.itemConf[key].value;
                    target[+this.itemConf[key].index] = this.itemConf[key].target;
                }
            }
            this.percent = percent;
            this.targetCount = target;
        };
        this.scope.$watch(scope => {
            return scope.$.itemConf;
        }, () => {
            setConfig();
        });
        setConfig();
    }
}

TextDashboardItemController.$inject = [
    'gettextCatalog',
    '$scope',
];

export {
    TextDashboardItemController,
};
