import bossKadrovikProfileModalTemplate from './view.html';

import { BossKadrovikProfileModalController } from './controller';

import { BOSS_KADROVIK_PROFILE_MODAL_CONTROLLER } from '../../const';

class BossKadrovikProfileModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    open = (profileId) => {
        this.modalInstance = this.$uibModal.open({
            template: bossKadrovikProfileModalTemplate,
            bindToController: true,
            controller: BOSS_KADROVIK_PROFILE_MODAL_CONTROLLER,
            controllerAs: '$',
            size: 'md',
            resolve: {
                profileId: profileId,
            },
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}

export {
    BossKadrovikProfileModal,
    BossKadrovikProfileModalController,
};
