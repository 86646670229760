import headerLogoTemplate from './view.html';

import { HeaderLogoController } from './controller.js';

const HeaderLogoComponent = {
    template: headerLogoTemplate,
    controller: HeaderLogoController,
    controllerAs: '$',
    scope: true,
    bindings: {
        logoMode: '<',
        logoModeTop: '<',
        logoModeHeight: '<',
        hideAfLogo: '<',
        scrolled: '<',
    },
};

export {
    HeaderLogoComponent,
};
