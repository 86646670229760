export const checkTranslations = (localfull) => {
    let keys = [];
    let locales = [];
    for (let i = 0; i < localfull.length; i++) {
        if ((!localfull[i].dropzone && !localfull[i].courseFilename) ||
            !localfull[i].passScore ||
            !localfull[i].name) {
            keys.push(i);
        }
    }
    locales = localfull.filter((item, index) => keys.indexOf(index) === -1);
    return locales;
};