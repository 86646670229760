import modalHeaderTemplate from './view.html';

import { ModalHeaderController } from './controller.js';

const ModalHeaderComponent = {
    template: modalHeaderTemplate,
    controller: ModalHeaderController,
    controllerAs: '$',
    scope: true,
    bindings: {
        closeModal: '<',
        headerText: '<',
        selenium: '<'
    },
};

export {
    ModalHeaderComponent,
};
