import {
    AUTH_SERVICE,
    NOTIFICATIONS_SERVICE,
    SENDER_DOMAINS_SERVICE,
    VARIABLES_SERVICE,
} from '../../const';
import {
//     getLocales,
//     checkDefaultLanguage,
//     setDefaultLanguage,
    setLocales
} from '../../helpers/content';
import { fetchEntity, contentFetch } from '../../helpers/contentFetch';
import { defaultConfig } from '../../helpers/modalFooterDefault';
import { hasAccess } from '../../reselect';

const schema = {
    data: [
        'fromName',
        'subject',
        'text',
        'domain',
        'from',
        'languageId',
        'isDefault',
        'userId'
    ],
};

class NotificationModalController {
    constructor(gettextCatalog, $location, $anchorScroll, $uibModalInstance, $textEditorNotificationOptions, $localStorage, $injector, NotificationsService, SenderDomainsService, AuthService, VariablesService, notificationId, $ngRedux, scope) {
        this.$location = $location;
        this.scope = scope;
        this.$anchorScroll = $anchorScroll;
        this.$localStorage = $localStorage;
        this.$injector = $injector;
        this.processing = false;
        this.NotificationsService = NotificationsService;
        this.senderDomains = SenderDomainsService.getAll();
        AuthService.getUserInfo('licenseFeatures').$promise.then((userInfo) => {
            this.userInfo = userInfo;
        });
        this.notificationId = notificationId;
        this.$uibModalInstance = $uibModalInstance;
        VariablesService.getAll().$promise.then((variables) => {
            window.mceTags = variables;
            this.tinymceOptions = $textEditorNotificationOptions;
        });
        this.inProcess = false;
        this.errorText = '';
        this.gettextCatalog = gettextCatalog;
        this.appCtrl = window.appCtrl;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, null)(this);
    }

    mapStateToThis(state) {
        return {
            languages: state.languages.languages,
            editable: hasAccess(state, { sectionId: 7, rightName: 'notification_save' })
        };
    }

    loadNotification = () => {
        this.notificationLoading = true;
        this.notificationError = false;
        fetchEntity.call(this,{
            id: this.notificationId,
            name: 'notification',
            urlName: 'notifications',
            loading: 'notificationLoading',
            error: 'notificationError'
        }, schema);
    };

    $onInit = () => {
        this.loadNotification();
    };

    setActiveLanguage = (active) => {
        let findIndex = false;
        this.locales.forEach((item, index) => {
            if (item && item.languageId === active) {
                this.activeIndex = index;
                findIndex = true;
            }
        });
        if (!findIndex) {
            this.activeIndex = this.locales.length;
            this.locales[this.activeIndex] = {};
            this.locales[this.activeIndex].languageId = active;
        }
        this.active = active;
    }

    setForm = (form) => {
        this.form = form;
    };

    setDefaultLanguage = (languageId) => {
        this.defaultLanguage = languageId;
        this.locales.forEach((item, index) => {
            if (index === this.activeIndex) {
                item.isDefault = 1;
            } else {
                item.isDefault = 0;
            }
        });
    };

    checkTranslations = () => {
        let keys = [];
        let locales = [];
        let changeDefault = true;
        for (let i = 0; i < this.locales.length; i++) {
            if (!this.locales[i].fromName ||
                !this.locales[i].text ||
                !this.locales[i].domain ||
                !this.locales[i].from ||
                !this.locales[i].subject) {
                keys.push(i);
            }
        }
        locales = this.locales.filter((item, index) => keys.indexOf(index) === -1);
        locales.forEach(item => {
            if (item.isDefault === undefined) {
                item.isDefault = 0;
            }
            if (item.isDefault) {
                changeDefault = false;
            }
        });
        if (changeDefault) {
            this.setDefaultLanguage();
        }
        return locales;
    }

    setFormData = async () => {
        if (this.inProcess) return;
        try {
            this.inProcess = true;
            let locales = this.checkTranslations();
            let value = await setLocales(locales, this.languages, schema);
            value.append('userId', this.notification.userId);
            value.append('name', this.notification.name);
            value.append('type', this.notification.type);
            this.save(value);
        } catch (err) {
            this.inProcess = false;
        }
    }

    save = async (formData) => {
        try {
            this.notificationError = false;
            this.errorText = null;
            let notification = await contentFetch({ body: formData, content: 'notifications' }, this.notification.userId ? this.notificationId : null);
            if (notification.error) {
                throw notification.error;
            }
            this.inProcess = false;
            this.$close(notification);
        } catch (err) {
            this.inProcess = false;
            this.notificationError = true;
            this.errorText = this.gettextCatalog.getString('Что-то пошло не так :-(');
        }
    };

    disable = () => {
        return this.inProcess ||
            !this.locales[this.activeIndex].fromName ||
            !this.locales[this.activeIndex].subject ||
            !this.locales[this.activeIndex].text ||
            !this.locales[this.activeIndex].domain ||
            !this.locales[this.activeIndex].from;
    }

    isLoading = () => {
        return this.inProcess;
    }

    setBtnConfig = () => {
        this.btnConf = defaultConfig.call(this,[this.modalClose, this.setFormData],[this.isLoading ,this.disable]);
    }

    modalClose = () => {
        if (this.inProcess) return;
        this.$close();
    }
}

NotificationModalController.$inject = [
    'gettextCatalog',
    '$location',
    '$anchorScroll',
    '$uibModalInstance',
    '$textEditorNotificationOptions',
    '$localStorage',
    '$injector',
    NOTIFICATIONS_SERVICE,
    SENDER_DOMAINS_SERVICE,
    AUTH_SERVICE,
    VARIABLES_SERVICE,
    'notificationId',
    '$ngRedux',
    '$scope'
];

export {
    NotificationModalController,
};
