import React, { useState, useEffect, useMemo } from 'react';
import fetchApi from 'rootReact/REST/fetchApi';
import DropdownSelect from 'rootReact/components/DropdownSelect';

function DepartmentsSelect({Style, inputAttr, handleClick, disabled, list, visibleArrow, isCreate, testIdSearch, testIdList}) {
    const [departments, setDepartments] = useState([]);
    const [searchQuery, setSearchString] = useState(inputAttr && inputAttr.value && inputAttr.value.name ? inputAttr.value.name : '');
    const getDepartments = async () => {
        let departments = await fetchApi('/departments?layout=select').get();
        departments.sort((a,b) => {
            if (a.fullName > b.fullName) return 1;
            if (a.fullName < b.fullName) return -1;
        });
        setDepartments(departments);
    };

    useEffect(() => {
        getDepartments();
    }, []);

    useEffect(() => {
        if (isCreate) {
            handleClick(searchQuery);
        }
    }, [searchQuery]);

    const searchDepartments = useMemo(() => {
        return departments?.filter((item) => {
            if (!searchQuery) return true;
            return item.name.toLowerCase().includes(searchQuery.toLowerCase());
        });
    }, [searchQuery, departments]);

    const getItems = () => {
        return searchDepartments.map(item => (
            <span
                key={item.fullName}
                onClick={() => handleClick(item)}
                className={'departments-select_item react-dropdown_item closes'}
                style={{ paddingLeft: 20 + (20 * item.deep) }}
            >{item.name}</span>
        ));
    };
    return (
        <div className={'departments-select'}>
            <DropdownSelect
                idClass={'department_chose'}
                disabled={disabled}
                inputAttr={{ ...inputAttr, value: inputAttr.value ? inputAttr.value.name : '' }}
                Style={Style}
                list={list}
                parentFilterString={setSearchString}
                visibleArrow={visibleArrow}
                testIdSearch={testIdSearch}
                testIdList={testIdList}
            >
                {getItems()}
            </DropdownSelect>
        </div>
    );
}

export default DepartmentsSelect;
