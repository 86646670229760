import searchTemplate from './view.html';

import { SearchFieldController } from './controller';

const SearchFieldComponent = {
    template: searchTemplate,
    controller: SearchFieldController,
    controllerAs: '$',
    scope: true,
    bindings: {
        isDisabled: '<',
        searchKey: '<',
        isAnyLoading: '=',
        setReloading: '=',
    },
};

export {
    SearchFieldComponent,
};
