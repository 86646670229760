
class DepartmentsService {
    constructor (YiiModel, $resource) {
        return {
            all: () => (YiiModel.all('departments')),
            new: () => {
                return YiiModel.one('departments');
            },
            one: (id) => {
                return YiiModel.one('departments').$find(id);
            },
            simple: $resource(window.config.SYSTEM_URL + window.config.API_URL + '/departments'),
            count: (data, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/departments/count').save({}, data, function (response) {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, function (response) {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            },
            bulkDelete: (data, ctrl, success, error) => {
                return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/departments/bulk-delete').save({}, data, function (response) {
                    if (typeof (success) == 'function' && ctrl) {
                        success(ctrl, response);
                    }
                }, function (response) {
                    if (typeof (error) == 'function' && ctrl) {
                        error(ctrl, response);
                    }
                });
            },
            coursesFactory: $resource(window.config.SYSTEM_URL + window.config.API_URL + '/courses/:id', null, {})
        };
    }
}

export {
    DepartmentsService
};
