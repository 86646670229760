import React from 'react';

function SettingsLicenseProgress({ companyTargets, companyTargetCount, targetInSystem }) {
    return (
        <div id='gradient' className={'settings_progress-wrapper'}>
            <div className={'settings_licence-count'}
                style={{ width: companyTargets * 100 / companyTargetCount + '%' }}>
                <div
                    className={'settings_real-count'}
                    style={{ width: targetInSystem * 100 / companyTargetCount + '%' }}
                />
                <div
                    className={'settings_not-licence'}
                    style={{ width: (companyTargetCount - companyTargets) * 100 / companyTargetCount + '%' }}
                />
            </div>
        </div>
    );
}

export default SettingsLicenseProgress;
