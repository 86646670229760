import { createSelector } from 'reselect';

const dashboardInfo = (state) => {
    return state.dashboard;
};

export const getDashboardVersion = createSelector([dashboardInfo], info => {
    return info.DBversion;
});

export const getDashboardRound = (createSelector([dashboardInfo], info => {
    return info;
}));


