import arrayInputTemplate from './view.html';

import { ArrayInputController } from './controller';

const ArrayInputComponent = {
    template: arrayInputTemplate,
    controller: ArrayInputController,
    controllerAs: '$',
    scope: true,
    bindings: {
        labelText: '<',
        arrayData: '<',
        domains: '<',
        getData: '=',
        isValidEmail: '='
    },
};

export {
    ArrayInputComponent,
};
