import style from './style.modules.css';
import { DEFAULT_PER_PAGE_SIZE } from '../../const';

class LdapTargetsController {
    constructor($scope, gettextCatalog) {
        this.$scope = $scope;
        this.gettextCatalog = gettextCatalog;
        this.style = style;
        this.sortField = '';
        this.sortReverse = 0;
        this.totalCount = 0;
        this.showTargets = DEFAULT_PER_PAGE_SIZE;
    }

    $onInit() {
        this.setHeader();
    }

    toggleTarget = (target) => {
        this.toggleTargetsLdap(target);
    };

    sortTargetsBy = (sortBy) => {
        if (sortBy == this.sortField) {
            this.sortReverse = !this.sortReverse;
        }
        this.sortField = sortBy;
        this.targets.sort((a, b) => {
            if (a[sortBy] > b[sortBy]) return this.sortReverse ? -1 : 1;
            if (a[sortBy] < b[sortBy]) return this.sortReverse ? 1 : -1;
        });
    };

    showMore = (count = 0) => {
        if (count) {
            this.showTargets = count;
        } else {
            this.showTargets += 10;
        }
    }

    setHeader = () => {
        const ctrl = this;
        this.headerCell = [
            {
                content: ctrl.gettextCatalog.getString('ФИО'),
                titleClass: '',
                get arrowRevers () {return ctrl.sortField === 'firstName';},
                isShow: true,
                onLinkClick: () => ctrl.sortTargetsBy('firstName')
            },
            {
                content: ctrl.gettextCatalog.getString('Электронная почта'),
                titleClass: 'email',
                get arrowRevers () {return ctrl.sortField === 'email';},
                isShow: true,
                onLinkClick: () => ctrl.sortTargetsBy('email')
            },
            {
                content: ctrl.gettextCatalog.getString('Отдел'),
                titleClass: '',
                get arrowRevers () {return ctrl.sortField === 'department';},
                isShow: true,
                onLinkClick: () => ctrl.sortTargetsBy('department')
            },
            {
                content: ctrl.gettextCatalog.getString('Должность'),
                titleClass: '',
                get arrowRevers () {return ctrl.sortField === 'position';},
                isShow: true,
                onLinkClick: () => ctrl.sortTargetsBy('position')
            },
            {
                content: ctrl.gettextCatalog.getString('КФЛ'),
                titleClass: '',
                get arrowRevers () {return ctrl.sortField === 'ldapExternalId';},
                isShow: ctrl.externalIdEnabled,
                onLinkClick: () => ctrl.sortTargetsBy('ldapExternalId')
            }
        ];
    }
}

LdapTargetsController.$inject = [
    '$scope',
    'gettextCatalog',
];

export {
    LdapTargetsController,
};
