import style from './style.modules.css';

class DashboardNewController {
    constructor(gettextCatalog, scope, location, timeout) {
        this.style = style;
        this.scope = scope;
        this.location = location;
        this.timeout = timeout;
        this.gettextCatalog = gettextCatalog;
        this.knowledgeLabels = [
            gettextCatalog.getString('не обучались'),
            gettextCatalog.getString('на обучении'),
            gettextCatalog.getString('прошли обучение'),
            (gettextCatalog.getString('не прошли | обучение вовремя').split('|').map(s => s.trim())),
        ];
        this.campaignLabels = [
            gettextCatalog.getString('не проверялись'),
            gettextCatalog.getString('выдержали атаку'),
            gettextCatalog.getString('открыли письмо'),
            gettextCatalog.getString('перешел по ссылке | или по ссылке в QR').split('|').map(s => s.trim()),
            (gettextCatalog.getString('открыли вложение | или файл по ссылке').split('|').map(s => s.trim())),
            (gettextCatalog.getString('ввели данные | в форму').split('|').map(s => s.trim())),
        ];
        this.vulnerabilityLabels = [
            gettextCatalog.getString('не проверялись'),
            (gettextCatalog.getString('не имеют | уязвимых приложений').split('|').map(s => s.trim())),
            (gettextCatalog.getString('имеют уязвимые | приложения').split('|').map(s => s.trim())),
        ];
        this.strings = {
            knowledge: gettextCatalog.getString('Знания'),
            skills: gettextCatalog.getString('Навыки'),
            rating: gettextCatalog.getString('Рейтинг'),
            vulnerabilities: gettextCatalog.getString('Уязвимости')
        };
    }

    $onInit () {}

    $onDestroy() {}
}

DashboardNewController.$inject = [
    'gettextCatalog',
    '$scope',
    '$location',
    '$interval'
];

export {
    DashboardNewController,
};
