import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';
import { fetchAttackHistory } from './attackHistory';

const initialState = {
    educationHistory: {},
    load: true,
    isError: false,
    error: ''
};

export const fetchEducationHistory = createAsyncThunk(
    'educationHistory/status',
    async (data) => {
        const fetchApi = new FetchApi('/history/education');
        let response = await fetchApi.post(data);
        if (response.length) {
            response = response.map(item => {
                item.date = item.date*1000;
                return item;
            });
            let arrUnshift = [];
            let educationHistory = [];
            let firstDate = new Date(response[0].date).getDate();
            let firstDay = new Date(new Date(response[0].date).getFullYear(), new Date(response[0].date).getMonth(), 1).valueOf();
            for (let i = 0; i < firstDate; i++) {
                arrUnshift.push({
                    completeEducation: 0,
                    noEducation: 0,
                    onEducation: 0,
                    missEducation: 0,
                    date: firstDay + 1000 * 60 * 60 * 24 * i
                });
            }
            educationHistory = [...arrUnshift, ...response];
            return educationHistory;
        }
        return [];
    }
);

const educationHistorySlice = createSlice({
    name: 'educationHistory',
    initialState,
    reducers: {
        educationHistoryDefault (state) {
            state = initialState;
        }
    },
    extraReducers: {
        [fetchEducationHistory.fulfilled]: (state, { payload }) => {
            state.educationHistory = payload;
            state.load = false;
        },
        [fetchEducationHistory.pending]: (state) => {
            state.load = true;
            state.isError = false;
            state.error = ''
        },
        [fetchEducationHistory.rejected]: (state, {payload}) => {
            state.load = false;
            state.isError = true;
            state.error = payload;
        },
    }
});

export const { educationHistoryDefault } = educationHistorySlice.actions;
export default educationHistorySlice.reducer;

